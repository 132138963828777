var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { RegistrationPhase1Modal, PostRegistrationPhase1Modal, RegistrationPhase2Modal, PostRegistrationPhase2Modal } from '../classes';
import { RecruiterService } from '../../services/recruiter.operations';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
var RegistrationComponent = /** @class */ (function () {
    function RegistrationComponent(apiService, router, RecruiterService) {
        this.apiService = apiService;
        this.router = router;
        this.RecruiterService = RecruiterService;
    }
    RegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.RecruiterService.getBasicDetails().subscribe(function (response) {
            _this.logo = response.data.logo;
            _this.companyName = response.data.companyName,
                _this.phase1.companyname = JSON.parse(localStorage.getItem('companyName'));
        }, function (error) {
            console.log(error);
        });
        this.registrationphase = 1;
        this.registrationphase1status = 0;
        this.registrationphase2status = 0;
        this.companylogo;
        this.phase1 = {};
        this.phase2 = {};
        this.getBasicData();
        if (localStorage.getItem('isRecruiter')) {
            // console.log(' its inside local' , JSON.parse(localStorage.getItem('isRecruiter')));
            this.isRecruiter = JSON.parse(localStorage.getItem('isRecruiter')) == true ? false : true;
        }
    };
    RegistrationComponent.prototype.checkFormPhase1Input = function () {
        var validationStatus = false;
        if (this.phase1.logofile !== undefined && this.phase1.logofile !== '') {
            this.phase1.logofileerror = false;
            validationStatus = true;
        }
        else {
            this.phase1.logofileerror = true;
            validationStatus = false;
        }
        if (this.phase1.website !== undefined && this.phase1.website !== '' && ((/^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/).test(this.phase1.website))) {
            this.phase1.websiteerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.websiteerror = true;
            validationStatus = false;
        }
        if (this.phase1.addressline1 !== undefined && this.phase1.addressline1 !== '') {
            this.phase1.addressline1error = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.addressline1error = true;
            validationStatus = false;
        }
        if (this.phase1.city !== undefined && this.phase1.city !== '') {
            this.phase1.cityerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.cityerror = true;
            validationStatus = false;
        }
        if (this.phase1.pincode !== undefined && this.phase1.pincode !== '' && this.phase1.pincode.length === 6 && ((/^\d+$/).test(this.phase1.pincode))) {
            this.phase1.pincodeerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.pincodeerror = true;
            validationStatus = false;
        }
        if (this.phase1.state !== undefined && this.phase1.state !== '') {
            this.phase1.stateerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.stateerror = true;
            validationStatus = false;
        }
        if (this.phase1.country !== undefined && this.phase1.country !== '') {
            this.phase1.countryerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.countryerror = true;
            validationStatus = false;
        }
        if (this.phase1.gstin !== undefined && this.phase1.gstin !== '' && ((/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z]{1}/).test(this.phase1.gstin)) && this.phase1.gstin.length === 15) {
            this.phase1.gstinerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.gstinerror = true;
            validationStatus = false;
        }
        if (this.phase1.sachac !== undefined && this.phase1.sachac !== '') {
            this.phase1.sachacerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.sachacerror = true;
            validationStatus = false;
        }
        if (this.phase1.contact !== undefined && this.phase1.contact !== '' && this.phase1.contact.length === 10 && ((/^\d+$/).test(this.phase1.contact))) {
            this.phase1.contacterror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.contacterror = true;
            validationStatus = false;
        }
        if (this.phase1.companyname !== undefined && this.phase1.companyname !== '') {
            this.phase1.companynameerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.companynameerror = true;
            validationStatus = false;
        }
        if (this.phase1.companyindustry !== undefined && this.phase1.companyindustry !== '') {
            this.phase1.companyindustryerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.companyindustryerror = true;
            validationStatus = false;
        }
        if (this.phase1.location !== undefined && this.phase1.location !== '') {
            this.phase1.locationerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase1.locationerror = true;
            validationStatus = false;
        }
        return validationStatus;
    };
    RegistrationComponent.prototype.onRegistrationPhase1Submit = function () {
        var _this = this;
        if (this.checkFormPhase1Input()) {
            var address = this.phase1.addressline1 + "#$" + this.phase1.city + "#$" + this.phase1.pincode + "#$" + this.phase1.state + "#$" + this.phase1.country;
            var postdata = new PostRegistrationPhase1Modal(this.phase1.companyname, address, this.phase1.website, this.phase1.location, this.phase1.gstin, this.phase1.sachac, this.fileURL, this.phase1.contact, this.phase1.companyindustry);
            this.apiService.updateBasicDetails(postdata).subscribe(function (res) {
                _this.companylogo = res.data.logo;
            }, function (err) {
                console.log(err);
            });
            this.registrationphase = 2;
            this.getAdvanceData();
        }
        else {
            this.registrationphase = 1;
        }
    };
    RegistrationComponent.prototype.onRegistrationPhase2Submit = function () {
        var _this = this;
        if (this.checkFormPhase2Input()) {
            var registrationphase2data = new PostRegistrationPhase2Modal(this.phase2.aboutus, this.phase2.culture, this.phase2.employeestrength, this.phase2.genderdiversity, this.phase2.additionalbenifits, this.phase2.benifits, this.phase2.otherBenefits);
            this.apiService.updateAdvanceDetails(registrationphase2data).subscribe(function (res) {
                _this.userData = res;
                if ((_this.userData.responseStatus === 200)) {
                    console.log('200-Success');
                    _this.loading = false;
                    _this.erroMsg = '';
                    Swal({
                        title: 'Success',
                        text: res.message,
                        type: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#535BE2',
                        cancelButtonColor: '#FF7E39',
                        confirmButtonText: 'OK',
                    }).then(function (confirm) {
                        if (confirm) {
                            _this.router.navigate(['/employe-dashboard']);
                        }
                    });
                }
            }, function (err) {
                console.log(err);
            });
        }
    };
    RegistrationComponent.prototype.checkFormPhase2Input = function () {
        var validationStatus = false;
        if (this.phase2.aboutus !== undefined && this.phase2.aboutus !== '') {
            this.phase2.aboutuserror = false;
            validationStatus = true;
        }
        else {
            this.phase2.aboutuserror = true;
            validationStatus = false;
        }
        if (this.phase2.culture !== undefined && this.phase2.culture !== '') {
            this.phase2.cultureerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase2.cultureerror = true;
            validationStatus = false;
        }
        if (this.phase2.employeestrength !== undefined && this.phase2.employeestrength !== '' && ((/^\d+$/).test(this.phase2.employeestrength))) {
            this.phase2.employeestrengtherror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase2.employeestrengtherror = true;
            validationStatus = false;
        }
        if (this.phase2.genderdiversity !== undefined && this.phase2.genderdiversity !== '') {
            this.phase2.genderdiversityerror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase2.genderdiversityerror = true;
            validationStatus = false;
        }
        if (this.phase2.additionalbenifits !== undefined && this.phase2.additionalbenifits !== '') {
            this.phase2.additionalbenifitserror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase2.additionalbenifitserror = true;
            validationStatus = false;
        }
        if (this.phase2.benifits !== undefined && this.phase2.benifits.length > 0) {
            this.phase2.benifitserror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase2.benifitserror = true;
            validationStatus = false;
        }
        if (this.phase2.otherBenefits !== undefined && this.phase2.otherBenefits !== '') {
            this.phase2.otherBenefitserror = false;
            validationStatus = validationStatus ? true : false;
        }
        else {
            this.phase2.otherBenefitserror = true;
            validationStatus = false;
        }
        return validationStatus;
    };
    RegistrationComponent.prototype.onHandlePhase1Form = function (e) {
        var _this = this;
        var _a = e.target, name = _a.name, value = _a.value;
        switch (name) {
            case 'website':
                this.phase1 = __assign({}, this.phase1, { website: value, websiteerror: false });
                break;
            case 'addressline1':
                this.phase1 = __assign({}, this.phase1, { addressline1: value, addressline1error: false });
                break;
            case 'city':
                this.phase1 = __assign({}, this.phase1, { city: value, cityerror: false });
                break;
            case 'pincode':
                this.phase1 = __assign({}, this.phase1, { pincode: value, pincodeerror: false });
                break;
            case 'state':
                this.phase1 = __assign({}, this.phase1, { state: value, stateerror: false });
                break;
            case 'country':
                this.phase1 = __assign({}, this.phase1, { country: value, countryerror: false });
                break;
            case 'gstin':
                this.phase1 = __assign({}, this.phase1, { gstin: value, gstinerror: false });
                break;
            case 'sachac':
                this.phase1 = __assign({}, this.phase1, { sachac: value, sachacerror: false });
                break;
            case 'contact':
                this.phase1 = __assign({}, this.phase1, { contact: value, contacterror: false });
                break;
            case 'companyname':
                this.phase1 = __assign({}, this.phase1, { companyname: value, companynameerror: false });
                break;
            case 'companyindustry':
                this.phase1 = __assign({}, this.phase1, { companyindustry: value, companyindustryerror: false });
                break;
            case 'location':
                this.phase1 = __assign({}, this.phase1, { location: value, locationerror: false });
                break;
            case 'logofile':
                var file = e.target.files[0];
                var imageForm = new FormData();
                imageForm.append('file', file);
                this.apiService.uploadFiletoS3(imageForm).subscribe(function (res) {
                    var data = res;
                    _this.fileURL = data.fileURL;
                }, function (err) {
                    console.log(err);
                });
                var reader = new FileReader();
                reader.onload = this._handleReaderLoaded.bind(this);
                reader.readAsBinaryString(file);
                break;
        }
    };
    RegistrationComponent.prototype.onHandlePhase2Form = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        switch (name) {
            case 'aboutus':
                this.phase2 = __assign({}, this.phase2, { aboutus: value, aboutuserror: false });
                break;
            case 'culture':
                this.phase2 = __assign({}, this.phase2, { culture: value, cultureerror: false });
                break;
            case 'employeestrength':
                this.phase2 = __assign({}, this.phase2, { employeestrength: value, employeestrengtherror: false });
                break;
            case 'genderdiversity':
                this.phase2 = __assign({}, this.phase2, { genderdiversity: value, genderdiversityerror: false });
                break;
            case 'additionalbenifits':
                this.phase2 = __assign({}, this.phase2, { additionalbenifits: value, additionalbenifitserror: false });
                break;
            case 'otherBenefits':
                this.phase2 = __assign({}, this.phase2, { otherBenefits: value, otherBenefitserror: false });
                break;
            case 'benifits':
                if (this.phase2.benifits !== undefined) {
                    var benifits = this.phase2.benifits;
                    if (benifits.indexOf(value) !== -1) {
                        benifits.splice(benifits.indexOf(value), 1);
                    }
                    else {
                        benifits.push(value);
                    }
                    this.phase2.benifits = benifits;
                }
                else {
                    this.phase2 = __assign({}, this.phase2, { benifits: [value], benifitserror: false });
                }
                break;
        }
    };
    RegistrationComponent.prototype._handleReaderLoaded = function (readerEvt) {
        var binaryString = readerEvt.target.result;
        this.phase1 = __assign({}, this.phase1, { logofile: btoa(binaryString), logofileerror: false });
    };
    RegistrationComponent.prototype.onBack = function () {
        window.scrollTo(0, 0);
        this.registrationphase = 1;
        this.getBasicData();
    };
    RegistrationComponent.prototype.getBasicData = function () {
        var _this = this;
        this.apiService.getBasicDetails().subscribe(function (response) {
            var _a = response.data, address = _a.address, companyName = _a.companyName, contactNo = _a.contactNo, gstin = _a.gstin, industry = _a.industry, location = _a.location, logo = _a.logo, sacorhac = _a.sacorhac, website = _a.website;
            var addresssplit = address.split('#$');
            var addressline1 = (addresssplit[0] === undefined) ? "" : addresssplit[0];
            var city = (addresssplit[1] === undefined) ? "" : addresssplit[1];
            var pincode = (addresssplit[2] === undefined) ? "" : addresssplit[2];
            var state = (addresssplit[3] === undefined) ? "" : addresssplit[3];
            var country = (addresssplit[4] === undefined) ? "" : addresssplit[4];
            _this.phase1 = new RegistrationPhase1Modal(website, false, addressline1, false, city, false, pincode, false, state, false, country, false, gstin, false, sacorhac, false, contactNo, false, companyName, false, industry, false, location, false, logo, false);
            _this.companylogo = logo;
        }, function (error) {
        });
    };
    RegistrationComponent.prototype.getAdvanceData = function () {
        var _this = this;
        this.apiService.getAdvanceDetails().subscribe(function (res) {
            var _a = res.data, aboutus = _a.aboutus, otherBenefits = _a.otherBenefits, womenCentricPolicies = _a.womenCentricPolicies, additionalMaternityBenefits = _a.additionalMaternityBenefits, coverPhoto = _a.coverPhoto, culture = _a.culture, employeeStrength = _a.employeeStrength, genderDiversity = _a.genderDiversity;
            _this.phase2 = new RegistrationPhase2Modal(aboutus, false, culture, false, employeeStrength, false, genderDiversity, false, additionalMaternityBenefits, false, womenCentricPolicies, false, otherBenefits, false, coverPhoto, false);
        }, function (err) {
            console.log(err);
        });
    };
    RegistrationComponent.prototype.onLogout = function () {
        localStorage.removeItem('companyName');
        localStorage.removeItem('authtoken');
        localStorage.removeItem('emailId');
        this.router.navigate(['/employe-login']);
    };
    RegistrationComponent.prototype.onWindowScroll = function (e) {
        if (window.pageYOffset > 50) {
            var element = document.getElementById('header_data');
            element.style.background = "rgba(255,255,255,0.9)";
            element.style.position = "fixed";
            element.style.color = " rgba(0, 0, 0, 0.6)";
            element.style.marginTop = "-2px";
            element.style.transitionDuration = "0.6s";
            element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
        }
        else {
            var element = document.getElementById('header_data');
            element.style.background = "";
            element.style.position = "";
            element.style.marginTop = "";
            element.style.boxShadow = "";
            element.style.color = "#fff";
        }
    };
    return RegistrationComponent;
}());
export { RegistrationComponent };
