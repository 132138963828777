import { Http, Headers, Response } from "@angular/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment"; 
import { Router } from "@angular/router";
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';


@Injectable()
export class WebinarService {
  public redirectUrl: string;
  constructor(private http: Http, private router: Router) {
  }

  // get all webinars
  public getWebinarDetails() {
    return this.http.get(environment.endPointApi + 'subscriptions/getallwebinars').map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      }
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

  // get completed webinars
  public getCompletedWebinars() {
    return this.http.get(environment.endPointApi + 'subscriptions/getcompletedwebinars').map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      }
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

  public AddParticipants(formdata) {
    var headers = new Headers();
    return this.http.put(environment.endPointApi + 'subscriptions/updatewebinarregistration', formdata, { headers: headers }).map((res) => {
      console.log(res, ' its Events register res');
      return res.json();
    }).catch((error) => {
      return this.handleError(error);
    });
  }

  handleError(error: any) {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
  }


}