
import { Component, OnInit } from '@angular/core';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

constructor(private modalService: NgbModal) { 
}

ngOnInit() {
}

}