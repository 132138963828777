

<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
  <div class="cp_logo_wrapper index_2_logo ">
    <a routerLink="/partners-admin">
      <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
    </a>
  </div>

  <div class="menu_btn_box jb_cover">
    <label class="dropdown">
      <p style="font-weight:400;">
        <!-- <img src="assets/sheImages/nav_menu.svg" class="nice-select userPic_header" alt="img" style="width: 62px;height: 60px;"> -->

        <img src="assets/sheImages/nav_menu.svg" alt="img" style="width: 62px;height: 60px; margin-left: 150px;border-radius: 50%;">
      </p>
      <!-- <input type="checkbox" class="dd-input" id="test"> -->
      <ul class="dd-menu">
        <!-- Employee -->
        <!-- <li><a routerLink="/job-dashboard"><i class="fas fa-outdent"></i> Job Dashboard</a></li>
        <li><a routerLink="/user-profile"><i class="fas fa-edit"></i> View/Edit Profile</a></li> -->
        
    
        <!-- Employer -->
        <!-- <li><a routerLink="/recruiterprofile"><i class="fas fa-edit"></i> View /Edit </a></li>
        <li><a routerLink="/manage-recruiters"><i class="fas fa-user-edit"></i> Manage Recruiters </a></li>
        <li><a routerLink="/schedule-events"><i class="fas fa-tasks"></i> Schedule Events </a></li> -->

        <!-- Partner -->
        <!-- <li><a *ngIf="usertype" routerLink="/partners-addrecruiter"><i class="fa fa-users"></i>Add Associates</a></li> -->
        <!-- <li><a *ngIf="usertype" routerLink="/partners-subscription"><i class="fa fa-credit-card"></i>Payments Plan</a></li> -->
        <li><a routerLink="/partners-admin"><i class="fas fa-outdent"></i> Dashboard</a></li>
        <!-- Common -->
        <li><a (click)="onLogout()"><i class="fas fa-sign-in-alt"></i> Logout</a></li>
      </ul>
    </label>
  </div>
</div>
<div class="grow_next_wrapper jb_cover">
  <div class="row">
    <div class="slider_small_shape44">
      <img src="assets/sheImages/jobseeker/c1.png" class="img-responsive " alt="img">
    </div>
    <div class="counter_jbbb2 jb_cover">
      <img src="assets/sheImages/jobseeker/c2.png" class="img-responsive" alt="img">
    </div>
  </div>
  <div class="container">
    <!-- Add Employer Button -->
    <div class="text-right pt-3">
      <button (click)="onRecruiterAdd()" type="submit" class="btn add-button"> Add Recruiter <i
          class="fas fa-user-plus"></i> </button>
    </div>
    <!-- Add Employer Button Ends-->
    <div class="row pt-4 pb-4">

      <!-- Employer List -->
      <div class="col-md-4 pb-3">
        <div class="card registration-card">
          <div class="pt-4">
            <h4 class="pl-md-4 pr-md-5 pb-4 pl-2">Recruiter's List</h4>
            <hr>
          </div>
          <div class="card-view">
            <!-- employer card chip -->
            <div class="media pt-3 pb-1 cursor-pointer" [ngClass]="{ 'bgInactive': !recruiter.accountStatus }" *ngFor="let recruiter of recruitersList" (click)="onShowRecruiterDetails($event, recruiter.emailId)" >
              <!-- <img src="/assets/sheImages/recruiter/user_pic.png" alt="img"
                style="width: 64px;height: 64px;border-radius: 50%;margin-right: 10px;"> -->
              <div class="media-body m-1">
                <h5 class="mb-2">{{recruiter.recruiterName}}</h5>
                <p> {{recruiter.emailId}} </p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Employer Details -->
      <div class="col-md-8 pb-3">

        <div class="card registration-card">
          <div class="row pr-4">
            <div class="col-6 pr-2">
              <h4 class="pl-md-5 pr-md-5 pt-4 pb-4 pl-2">{{title}}</h4>
            </div>

            <div class="col-6 text-right pr-md-4 pt-4 pb-4" id="rec-edit-panel">
              <a class="pl-2 cursor-pointer" (click)="onRecruiterEdit()" title="Edit Recruiter" [attr.disabled]="editDisabled ? true : null">
                <i class="fas fa-user-edit"></i>
              </a>
              <a class="pl-3 cursor-pointer" (click)="onRecruiterDelete()" title="Delete Recruiter" [attr.disabled]="editDisabled ? true : null">
                <i class="fas fa-trash"></i>
              </a>
            </div>
          </div>
          <hr>

          <div class="card-body card-view pl-md-5 pr-md-5">
            
            <form method="post" [ngClass]="{ 'hide': !viewMode }"> 
              <div class="row">
                  <div class="col-md-12">
                      <div class="tab-content">
                          <div class="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="font-weight-bold">Recruiter Name</label>
                                </div>
                                <div class="col-md-7">
                                    <p>{{recruiterDetails.recruiterName}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="font-weight-bold">Recruiter Company</label>
                                </div>
                                <div class="col-md-7">
                                    <p>{{recruiterDetails.companyName}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="font-weight-bold">Recruiter Email</label>
                                </div>
                                <div class="col-md-7">
                                    <p>{{recruiterDetails.emailId}}</p>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">
                                  <label class="font-weight-bold">Recruiter Address</label>
                              </div>
                              <div class="col-md-7">
                                  <p>{{recruiterDetails.address || "-"}}</p>
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="font-weight-bold">Recruiter Contact No</label>
                                </div>
                                <div class="col-md-7">
                                    <p>{{recruiterDetails.contactNo || "-"}}</p>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">
                                  <label class="font-weight-bold">Recruiter Linkedin Id</label>
                              </div>
                              <div class="col-md-7">
                                  <p>{{recruiterDetails.linkedinId || "-"}}</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">
                                  <label class="font-weight-bold">Recruiter Location</label>
                              </div>
                              <div class="col-md-7">
                                  <p>{{recruiterDetails.location || "-"}}</p>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </form>
          <div [ngClass]="{ 'hide': !editMode }" >
            <div class="form-group">
                <label class="font-weight-bold">Recruiter Name</label>
                <input [(ngModel)]="recruiterDetails.recruiterName" class="form-control" type="text">
            </div>
            <div class="form-group">
                <label class="font-weight-bold">Recruiter Email</label>
                <input class="form-control" [(ngModel)]="recruiterDetails.emailId" type="email" disabled>
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Recruiter Address</label>
              <input class="form-control" [(ngModel)]="recruiterDetails.address" type="text">
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Recruiter Contact No</label>
              <input class="form-control" [(ngModel)]="recruiterDetails.contactNo" type="text">
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Recruiter Linkedin Id</label>
              <input class="form-control" [(ngModel)]="recruiterDetails.linkedinId" type="text">
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Recruiter Localtion</label>
              <input class="form-control" [(ngModel)]="recruiterDetails.location" type="text">
            </div>
            <button type="submit" (click)="onRecruiterUpdate()" class="btn submit-button float-right"> Update </button>
            <button type="submit" (click)="onRecruiterUpdateCancel()" class="btn submit-button float-right mr5"> Cancel </button>
          </div>  

          <form [ngClass]="{ 'hide': !addMode }" [formGroup]="recruiterForm" novalidate (ngSubmit)="onRecruiterFormSubmit(recruiterForm.value)">
            <div class="form-group">
                <label class="font-weight-bold">Recruiter Name*</label>
                <input class="form-control" formControlName="recruitername" type="text">
            </div>
            <div class="col-md-9 alert alert-danger" *ngIf="recruiterForm.controls['recruitername'].invalid && (recruiterForm.controls['recruitername'].dirty || recruiterForm.controls['recruitername'].touched)">
                <div *ngIf="recruiterForm.controls['recruitername'].errors.required">
                    Please Enter Recruiter Name
                </div>
            </div>
            <div class="form-group">
                <label class="font-weight-bold">Recruiter Email*</label>
                <input class="form-control" formControlName="emailId" type="email">
            </div>
            <div class="col-md-9 alert alert-danger" *ngIf="recruiterForm.controls['emailId'].invalid && (recruiterForm.controls['emailId'].dirty || recruiterForm.controls['emailId'].touched)">
                <div *ngIf="recruiterForm.controls['emailId'].errors.required">
                    Please Enter Recruiter Email
                </div>
            </div>
            <div class="form-group">
                <label class="font-weight-bold">Recruiter Password*</label>
                <input class="form-control" formControlName="password" type="password">
            </div>
            <div class="col-md-9 alert alert-danger" *ngIf="recruiterForm.controls['password'].invalid && (recruiterForm.controls['password'].dirty || recruiterForm.controls['password'].touched)">
                <div *ngIf="recruiterForm.controls['password'].errors.required">
                    Please Enter Recruiter Password
                </div>
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Address</label>
              <input class="form-control" formControlName="address" type="text">
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Contact No</label>
              <input class="form-control" formControlName="contactNo" type="tel">
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Linkedin Id</label>
              <input class="form-control" formControlName="linkedinId" type="text">
            </div>
            <div class="form-group">
              <label class="font-weight-bold">Location</label>
              <input class="form-control" formControlName="location" type="text">
            </div>
            <button type="submit"
                [disabled]="recruiterForm.pristine || recruiterForm.invalid" class="btn submit-button float-right">
                Submit
            </button>
            <button type="button" (click)="onCancelAdd()" class="btn submit-button float-right mr5"> Cancel </button>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-minimalistic-footer></app-minimalistic-footer>