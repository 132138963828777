import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  RegistrationForm: FormGroup
  submitted: boolean;
  loading: boolean;
  erroMsg: any;
  userData: any;
  formBuilder: any;
  constructor(private fb: FormBuilder, private authService: AuthServices, private router: Router) {
    this.RegistrationForm = fb.group({
      'companyName': [null, Validators.required],
      'address': [null, Validators.required],
      'contactNo': [null, Validators.required],
      'emailId': [null, Validators.required],
      'gstin': [null, Validators.required]


    })
  }
  get f() { return this.RegistrationForm.controls; }


  ngOnInit() {

  }



  // form validation
  register(formdata: any) {
    console.log(formdata)
    this.submitted = true;
    if (this.RegistrationForm.invalid) {
      return
    }
    this.loading = true;
    this.authService.partnerRegisterpartner(formdata).subscribe(
      data => {
        this.userData = (data);
        if ((this.userData.responseStatus === 200)) {
          console.log('200-Success');
          this.loading = false;
          this.erroMsg = '';
          this.submitted = false;
          swal({
            title: 'Success',
            text: data.message,
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#535BE2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK',
          }).then((confirm) => {
            if (confirm) {
              this.router.navigate(['/homenew']);
            }
          });
        }
      },
      error => {
        if (error.responseStatus === 401) {
          this.loading = false;
          this.erroMsg = error.message;
        } else if (error.responseStatus === 500) {
          this.loading = false;
          this.erroMsg = error.message;
        } else if (error.responseStatus === 400) {
          this.loading = false;
          this.erroMsg = error.message;
        }
      },
      () => console.log()
    );

  }
}
