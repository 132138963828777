<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
        <div class="cp_logo_wrapper index_2_logo">
            <a routerLink="/homenew">
                <img src="assets/sheImages/logo8.svg" class="img_view ml-3"  alt="logo"> 
            </a>
        </div>
    </div>
       <div class="jb_banner_wrapper jb_cover" id="banner_data">
            <div class="jb_banner_left" >
                <h2 id="header_font" style="line-height:57px;">With us, creating diversity is easy</h2><br/>
                <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="index3_form_box jb_cover">
                                    <div class="select_box select_box3 search_bar">
                                        <input #search type="text" name="name" [(ngModel)]="_id" class="search_data" placeholder="Search by title, skills or keyword" >
                                    </div>                  
                                    <div class="contect_form3 contct_form_new3">
                                        <input #location type="text" [(ngModel)]="_location"  name="location" placeholder="Location">
                                    </div>
                                    <div class="index3_form_search">
                                            <a [routerLink]="['/emp-search',_id,_location]"><i class="fas fa-search"></i></a>
                                    </div>
                                </div>
                            </div>
                </div> 
            </div>
            <div class="login_wrapper jb_cover pb-3">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12">
                                <form [formGroup]="SignupFormForEmployee" (ngSubmit)="signUp()">
                                    <div class="form-group icon_form comments_form"> 
                                        <input type="text" class="form-control require" formControlName="company"
                                            name="company" placeholder="Company Name*"
                                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
                                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                            <div *ngIf="f.company.errors.required" class="error-msg">company name 
                                                required.</div>
                                        </div>
                                        <div *ngIf="f.company.errors &&(f.company.touched || f.company.dirty)">
                                            <div [hidden]="!f.company.errors?.pattern" class="error-msg">
                                               .
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group icon_form comments_form">
                                        <input type="text" class="form-control require" formControlName="companyEmail"
                                            name="full_name" placeholder="Company Email Address* " pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            [ngClass]="{ 'is-invalid': submitted && f.companyEmail.errors }">
                                        <div *ngIf="submitted && f.companyEmail.errors" class="invalid-feedback">
                                            <div *ngIf="f.companyEmail.errors.required" class="error-msg">Email Id is
                                                required.</div>
                                        </div>
                                        <div *ngIf="submitted && f.companyEmail.errors && f.companyEmail.errors.pattern" style="color:brown;">
                                                Email must be a valid email address.
                                        </div>
                                    </div>
                                    <div class="form-group icon_form comments_form">
                                        <input type="password" formControlName="password" class="form-control require"
                                        id="password-field1" placeholder="Create Password *"
                                            [class.is-invalid]="f.submitted && password.invalid" required minlength="8"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                            <span toggle="#password-field1" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
                                            margin-right: 306px;
                                            margin-top: -34px;
                                             position: relative;
                                             z-index: 2;"></span>
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required" class="error-msg">Password is
                                                required.</div>
                                            <div *ngIf="f.password.errors.minlength" class="error-msg">Password must be
                                                at least 8 characters.</div>
                                        </div>
                                      
                                    </div>
                                    <div class="form-group icon_form comments_form">
                                        <input type="password" formControlName="confirmPass" class="form-control require"
                                        id="password-field"  placeholder="Confirm Password *"
                                             required minlength="8"
                                            [ngClass]="{ 'is-invalid': submitted && f.confirmPass.errors }">
                                            <span toggle="#password-field" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
                                            margin-right: 306px;
                                            margin-top: -34px;
                                             position: relative;
                                             z-index: 2;"></span>
                                        <div *ngIf="submitted && f.confirmPass.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmPass.errors.required" class="error-msg">confirm-password is
                                                required.</div>
                                            <div *ngIf="f.confirmPass.errors.minlength" class="error-msg">Password must be
                                                at least 8 characters.</div>
                                        </div> 
                                    </div>
                                    <p style="color:brown">{{password_error_msg}}</p>
                                    <div class="header_btn search_btn login_btn jb_cover text-center">
                                        <button  type="submit" class="btn login-botton" ><span style="color:#fff;font-weight:900;" >Register</span></button>
                                    </div>
                                </form> 
                                  <div class="header_btn search_btn login_btn jb_cover text-center ">
                                        <span style="font-size:12px;color:gray;">Already user?</span> <span class="Not-registered-SIGN"
                                        (click)="logiIn()"><b>Login</b></span>                                      
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           