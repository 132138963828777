<!-- <header>

  <nav class="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">
    <a class="navbar-brand" routerLink="/homenew">
      <img class="img-fluid" src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <div class="dropdown">
          <img class="dropbtn rounded mx-auto d-block img-fluid" src="assets/sheImages/nav_menu.svg"
            class="userPic_header" alt="img">
          <div class="dropdown-content">
            <a routerLink="/partners-admin"><i class="fas fa-oudent"></i>Dashboard</a>
            <a routerLink="/partners-addrecruiter"><i class="fa fa-users"></i>Add Associates</a>
            <a (click)="onLogout()"><i class="fas fa-sign-in-alt"></i> logout</a>
          </div>
        </div>
      </ul>
    </div>
  </nav>

</header> -->

<!-- <div *ngIf="showSpinner" class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
  <span class="sr-only">Loading...</span>
</div> -->

<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
  <div class="cp_logo_wrapper index_2_logo ">
    <a routerLink="/partners-admin">
      <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
    </a>
  </div>

  <div class="menu_btn_box jb_cover">
    <label class="dropdown">
      <p style="font-weight:400;">
        <!-- <img src="assets/sheImages/nav_menu.svg" class="nice-select userPic_header" alt="img" style="width: 62px;height: 60px;"> -->

        <img src="assets/sheImages/nav_menu.svg" alt="img" style="width: 62px;height: 60px; margin-left: 150px;border-radius: 50%;">
      </p>
      <input type="checkbox" class="dd-input" id="test">
      <ul class="dd-menu">
        <!-- Employee -->
        <!-- <li><a routerLink="/job-dashboard"><i class="fas fa-outdent"></i> Job Dashboard</a></li>
        <li><a routerLink="/user-profile"><i class="fas fa-edit"></i> View/Edit Profile</a></li> -->
        
    
        <!-- Employer -->
        <!-- <li><a routerLink="/recruiterprofile"><i class="fas fa-edit"></i> View /Edit </a></li>
        <li><a routerLink="/manage-recruiters"><i class="fas fa-user-edit"></i> Manage Recruiters </a></li>
        <li><a routerLink="/schedule-events"><i class="fas fa-tasks"></i> Schedule Events </a></li> -->

        <!-- Partner -->
        <!-- <li><a *ngIf="usertype" routerLink="/partners-addrecruiter"><i class="fa fa-users"></i>Add Associates</a></li> -->
        <!-- <li><a *ngIf="usertype" routerLink="/partners-subscription"><i class="fa fa-credit-card"></i>Payments Plan</a></li> -->
        <li><a routerLink="/partners-admin"><i class="fas fa-outdent"></i> Dashboard</a></li>
        <!-- Common -->
        <li><a (click)="onLogout()"><i class="fas fa-sign-in-alt"></i> Logout</a></li>
      </ul>
    </label>
  </div>
</div>
<!-- Body -->
<!-- Payment Details -->
<div class="grow_next_wrapper jb_cover">
  <div class="row">
    <div class="slider_small_shape44">
      <img src="assets/sheImages/jobseeker/c1.png" class="img-responsive " alt="img">
    </div>
    <div class="counter_jbbb2 jb_cover">
      <img src="assets/sheImages/jobseeker/c2.png" class="img-responsive" alt="img">
    </div>
  </div>
  <div class="container">
    <!-- Add Employer Button Ends-->
    <div class="row pt-4 pb-4">

      <!-- Employer List -->
      <div class="col-md-4 pb-3">
        <div class="card">
          <div class="pt-4">
            <h4 class="pl-md-4 pr-md-5 pb-1 pl-2 text-center">Subscription Details</h4>
            <hr />
          </div>
          <div class="card-view col-md-12">
            <!-- employer card chip -->
            <div class="pt-3 pb-1">
              <h5 class="">Company Name</h5>
              <p class="">{{companyName}}</p>
              <h5>Last Payment</h5>
              <p>{{lastPayment}}</p>
              <h5>Expiry Date</h5>
              <p>{{expiryDate}}</p>
              <h5>Plan Validity</h5>
              <p>{{planAvailed}}</p>
              <h5>Status</h5>
              <p>{{planStatus}}</p>
            </div>
          </div>

        </div>
      </div>

      <!-- Payment Cards -->
      <div class="col-md-8 pb-3">
        <div class="card cardHeight">
          <!-- <div class="card registration-card"> -->
          <div class="row pr-4">
            <div class="col-12 pr-2">
              <h4 class="pl-md-5 pr-md-5 pt-4 pb-4 pl-2 text-center">Last Transaction Details</h4>
              <!-- <hr/> -->
            </div>
          </div>

          <!-- <div class="card-body card-view pl-md-5 pr-md-5"> -->

          <section class="pricing py-5">
            <div class="container">
              <div class="row offset-2">
                <!-- Free Tier -->
                <div class="col-lg-8">
                  <div class="card mb-5 mb-lg-0">
                    <div class="card-body">
                      <!-- <h5 class="card-title text-muted text-uppercase text-center">6 Months</h5>
                      <h5 class="card-price text-center">50000</h5>
                      <h5 class="card-title text-muted text-uppercase text-center">INR</h5>
                      <hr> -->
                      <ul class="fa-ul">
                        <!-- <li><span class="fa-li"><i class="fas fa-check"></i></span>Single User</li> -->
                  
                        
                        <table>
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                          <tr>
                            <th>Order Id : </th>
                            <th>{{lasttxn.orderid}}</th>
                          </tr>
                          <tr>
                            <td>Order Amount (INR): </td>
                            <td>{{lasttxn.ordamt}}</td>
                          </tr>
                          <tr>
                            <td>Transaction Status : </td>
                            <td>{{lasttxn.txstatus}}</td>
                          </tr>
                          <tr>
                            <td>Transaction Message : </td>
                            <td>{{lasttxn.txmsg}}</td>
                          </tr>
                          <tr>
                            <td>Transaction Reference : </td>
                            <td>{{lasttxn.refid}}</td>
                          </tr>
                        </table>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- Plus Tier -->
                <!-- <div class="col-lg-5">
                  <div class="card mb-5 mb-lg-0">
                    <div class="card-body">
                      <h5 class="card-title text-muted text-uppercase text-center">1 Year</h5>
                      <h5 class="card-price text-center">100000</h5>
                      <h5 class="card-title text-muted text-uppercase text-center">INR</h5>
                      <hr>
                      <ul class="fa-ul">
                        <li><span class="fa-li"><i class="fas fa-check"></i></span><strong>5 Users</strong></li>
                      </ul>

                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </section>


          <!-- </div> -->
        </div>
      </div>
    </div>
    <!-- Payment Cards -->


  </div>
</div>
<!-- Payment Details -->



<!-- Body -->
<!-- Footer -->
<app-minimalistic-footer></app-minimalistic-footer>
<!-- Footer -->