<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<app-header></app-header>
<div class="main_slider_wrapper slider-area jb_cover" id="banner">
    <section class="careers-hero">
        <div class="careers-container">
            <div class="careers-hero__inner">
                <div id="header" itemscope="" itemtype="http://schema.org/WPHeader">
                    <h1 itemprop="name" class="careers-hero__title"
                        style="color:#393939;font-size:48px;padding-top:20px;">Join the Team</h1>
                </div>
                <p class="careers-hero__subtitle" style="color:#393939;"> We are constantly looking for rock stars with
                    brilliant minds and endless passion to join our team! Is it your turn?</p>
                <a class="swui-button swui-button--primary js-careersHeroBtn" fragment="opportunities"
                    (click)="navigateToSection('opportunities')" data-analytics-category="Button"
                    data-analytics-label="See open position">
                    See open positions
                </a>
            </div>
        </div>
    </section>
    <section class="careers-about">
        <div class="careers-container">
            <article class="careers-about__article careers-about__article--center">
                <div class="careers-about__info">
                    <h2 class="section-title careers-about__title" style="color:#a234b2"><b>Who we are</b></h2>
                    <h1 id="header_font" style="font-size:48px;">Unravel hurdles of career path </h1>
                    <p class="careers-about__text" style="padding-top:10px;">(n) <b style="font-style:#000">Shenzyn;</b>
                        /she'engine/<br />An exclusive platform for the women to find the most suitable opportunities
                        and ready themselves to advance their career, faster. Shenzyn makes smart use of AI and
                        Blockchain to deliver the best match between your search and the opportunities to create,
                        design, reshape your career.</p>
                </div>

                <div class="careers-about__video-wrapper careers-about__video-wrapper--blue-figure">
                    <div class="careers-about__video-object">
                        <div class="video">
                            <video poster="../../assets/video/img_video2.png" style="width:100%;" controls>
                                <source
                                    src="https://shenzyn-uploads.s3.ap-south-1.amazonaws.com/Assets/Shenzyn%2BVideo.mp4"
                                    type="video/mp4" />
                                Browser not supported
                            </video>
                        </div>
                    </div>
                </div>
            </article>

            <article class="careers-about__article careers-about__article--left">
                <div class="careers-about__info">
                    <h2 class="section-title careers-about__title" style="color:#a234b2"><b>What We do</b></h2>
                    <h1 id="header_font" style="font-size:48px;">A marketplace to drive better diversity</h1>
                    <p class="careers-about__text" style="padding-top:10px;">Success for any company depends on
                        acquiring the best talents to meet their business objectives. Shenzyn is the AI powered Next
                        Generation Marketplace that streamlines the process to deliver that by bringing together the
                        demands, the head-hunters, the trainers, and the women talent in one place.</p>
                </div>

                <div class="careers-about__img-wrapper careers-about__img-wrapper--yellow-figure">
                    <div class="careers-about__img-object">
                        <img class="careers-about__img" src="../../assets/sheImages/career/Why_new.svg" width="560px"
                            height="360px" alt=" What We do">
                    </div>
                </div>
            </article>

            <article class="careers-about__article careers-about__article--right">
                <div class="careers-about__info">
                    <h2 class="section-title careers-about__title" style="color:#a234b2"><b>How we do it</b></h2>
                    <h1 id="header_font" style="font-size:48px;">AI & Blockchain based platform</h1>
                    <p class="careers-about__text" style="padding-top:10px;">Shenzyn platform is powered by an industry
                        leading AI engine that, like a human, performs deep research on the profiles vs demands to
                        deliver the most accurate match. Additionally, we passionately target the most time consuming
                        activities such as duplication of verification, validation of certification processes etc for
                        optimization by leveraging smart contracts/ Blockchain.</p>
                </div>
                <div class="careers-about__img-wrapper careers-about__img-wrapper--orange-figure">
                    <div class="careers-about__img-object">
                        <img class="careers-about__img" src="../../assets/sheImages/career/HowWeDo.svg" width="560px"
                            height="360px" alt=" ">
                    </div>
                </div>
            </article>
        </div>
    </section>
    <section class="careers-opps">
        <div class="careers-container">
            <div id="opportunities" class="careers-opps__positions">
                <header class="careers-opps__header">
                    <div class="careers-opps__headings">
                        <h2 class="section-title careers-opps__title" style="color:#393939"><b>Career Opportunities</b>
                        </h2>
                        <!-- <p class="careers-opps__subtitle">5 Open Positions</p> -->
                    </div>
                    <!-- <div class="careers-opps__filters">
                        Filter by:
                        <div class="careers-opps__select-wrapper">
                            <select id="js-careersLocationSelect"  style="display: none; max-width:100% !important;">
                                <option value="all" class="careers-opps-select__option--all" >All Locations</option>
                                                                <option class="careers-opps-select__option--disabled" disabled="">Bangalore</option>
                                                                        <option class="" value="USWest">Hyderabad</option>
                                                                        <option class="careers-opps-select__option--remote" value="remoteWest">Pune</option>
                                                                        <option class="careers-opps-select__option--disabled" disabled="">Kolkata</option>
                                                                        <option class="" value="US">Bhubaneswar</option>
                                                                        <option class="" value="USMA">Kolkata</option>
                                                                        <option class="careers-opps-select__option--remote" value="remoteEast">Chenai</option>
                                                                        <option class="careers-opps-select__option--disabled" disabled="">Gurgao</option>
                                                                        <option class="" value="UK">Uttarakhand</option>
                                       
                        </select>
     
                        </div>
                        <div class="careers-opps__select-wrapper">
                            <select id="js-careersDepartmentSelect " class ="nice-select" style="display: none;max-width:100% !important;">
                                <option value="all">All Departments</option>
                                                                <option value="dev">Software </option>
                                                                <option value="business">General and Administration</option>
                                                                <option value="product">Marketing</option>
                                                                <option value="sales">Accounting</option>
                                                                <option value="marketing">R&D</option>
                        </select>
                        </div>
                    </div> -->
                </header>

                <!-- Careers grid -->
                <div id="js-careersGrid" class="careers-grid"
                    style="position: relative; height: 350px; min-height: 181.2px;">
                    <div id="js-careerItem-20518" class="careers-grid__item js-careersGridItem"
                        style="position: absolute; left: 0%; top: 0px;">
                        <a href="#" class="careers-card" target="_blank" data-toggle="modal" data-target="#myModal"
                            rel="noopener" data-analytics-category="External link"
                            data-analytics-label="Market Intelligence Representative">
                            <h3 class="careers-card__title" title="Market Intelligence Representative">
                                Digital marketing </h3>
                            <p class="careers-card__location">Min-4 years</p>
                            <p class="careers-card__location">Bangalore, India</p>
                            <div class="careers-card__footer">
                                <span class="careers-card__label careers-card__label--marketing">Digital </span>
                                <span class="careers-card__icon"></span>
                            </div>
                        </a>
                    </div>
                    <div id="js-careerItem-20517" class="careers-grid__item js-careersGridItem"
                        style="position: absolute; left: 25%; top: 0px;">
                        <a href="#" class="careers-card" target="_blank" data-toggle="modal" data-target="#myModal1"
                            rel="noopener" data-analytics-category="External link"
                            data-analytics-label="Market Intelligence Representative- Russian Speaker">
                            <h3 class="careers-card__title" title="Market Intelligence Representative- Russian Speaker">
                                Sales </h3>
                            <p class="careers-card__location">Min-5 years</p>
                            <p class="careers-card__location">Bangalore, India</p>
                            <div class="careers-card__footer">
                                <span class="careers-card__label careers-card__label--marketing">Sales</span>
                                <span class="careers-card__icon"></span>
                            </div>
                        </a>
                    </div>
                    <div id="js-careerItem-20516" class="careers-grid__item js-careersGridItem"
                        style="position: absolute; left: 50%; top: 0px;">
                        <a href="#" class="careers-card" target="_blank" data-toggle="modal" data-target="#myModal2"
                            rel="noopener" data-analytics-category="External link"
                            data-analytics-label="Product Manager – Data Collection &amp; Privacy">
                            <h3 class="careers-card__title" title="Product Manager – Data Collection &amp; Privacy">
                                Python Developer </h3>
                            <p class="careers-card__location">Min-1 years</p>
                            <p class="careers-card__location">Bangalore, India</p>
                            <div class="careers-card__footer">
                                <span class="careers-card__label careers-card__label--product">Developer </span>
                                <span class="careers-card__icon"></span>
                            </div>
                        </a>
                    </div>
                    <div id="js-careerItem-20515" class="careers-grid__item js-careersGridItem"
                        style="position: absolute; left: 75%; top: 0px;">
                        <a href="#" class="careers-card" target="_blank" data-toggle="modal" data-target="#myModal3"
                            rel="noopener" data-analytics-category="External link"
                            data-analytics-label="Product Marketing Manager">
                            <h3 class="careers-card__title" title="Product Marketing Manager">
                                Content Writer </h3>
                            <p class="careers-card__location">3-5 years</p>
                            <p class="careers-card__location">Bangalore, India</p>
                            <div class="careers-card__footer">
                                <span class="careers-card__label careers-card__label--sales">Writer </span>
                                <span class="careers-card__icon"></span>
                            </div>
                        </a>
                    </div>
                    <div id="js-careerItem-20509" class="careers-grid__item js-careersGridItem"
                        style="position: absolute; left: 0%; top: 181.2px;">
                        <a href="#" class="careers-card" target="_blank" rel="noopener" data-toggle="modal"
                            data-target="#myModal4" data-analytics-category="External link"
                            data-analytics-label="Developer, Product Engineer ">
                            <h3 class="careers-card__title" title="Developer, Product Engineer ">
                                Full Stack Developer</h3>
                            <p class="careers-card__location">2+ years</p>
                            <p class="careers-card__location">Bangalore, India</p>
                            <div class="careers-card__footer">
                                <span class="careers-card__label careers-card__label--product">Developer</span>
                                <span class="careers-card__icon"></span>
                            </div>
                        </a>
                    </div>
                    <div id="js-careerItem-20517" class="careers-grid__item js-careersGridItem"
                        style="position: absolute; left: 25%; top: 181.2px;">
                        <a href="#" class="careers-card" target="_blank" data-toggle="modal" data-target="#myModal5"
                            rel="noopener" data-analytics-category="External link"
                            data-analytics-label="Product Manager – Data Collection &amp; Privacy">
                            <h3 class="careers-card__title" title="Product Manager – Data Collection &amp; Privacy">
                                Graphic Designer </h3>
                            <p class="careers-card__location">0-1 years</p>
                            <p class="careers-card__location">Bangalore, India</p>
                            <div class="careers-card__footer">
                                <span class="careers-card__label careers-card__label--marketing">Graphics
                                    Designer</span>
                                <span class="careers-card__icon"></span>
                            </div>
                        </a>
                    </div>
                    <!-- <div id="js-careerItem-20508" class="careers-grid__item js-careersGridItem" style="position: absolute; left: 25%; top: 181.2px;">
                            <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Product Marketing Manager">
                                <h3 class="careers-card__title" title="Product Marketing Manager">
                                    Graphics Designer                         </h3>
                                <p class="careers-card__location">3-5 years</p>
                                <p class="careers-card__location">Kolkata, India</p>
                                <div class="careers-card__footer">
                                    <span class="careers-card__label careers-card__label--marketing">Designer</span>
                                    <span class="careers-card__icon"></span>
                                </div>
                            </a>
                        </div>
                        <div id="js-careerItem-20496" class="careers-grid__item js-careersGridItem" style="position: absolute; left: 50%; top: 181.2px;">
                            <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Solution Implementation Manager">
                                <h3 class="careers-card__title" title="Solution Implementation Manager">
                                   Angular Developer </h3>
                                   <p class="careers-card__location">3-5 years</p>
                                <p class="careers-card__location">Bhubaneswar, India</p>
                                <div class="careers-card__footer">
                                    <span class="careers-card__label careers-card__label--sales">Developer</span>
                                    <span class="careers-card__icon"></span>
                                </div>
                            </a>
                        </div>
                        <div id="js-careerItem-20495" class="careers-grid__item js-careersGridItem" style="position: absolute; left: 75%; top: 181.2px;">
                            <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Product Marketing Manager">
                                <h3 class="careers-card__title" title="Product Marketing Manager">
                                    React Developer                          </h3>
                                <p class="careers-card__location">0-5 years</p>
                                <p class="careers-card__location">Bhubaneswar, India</p>
                                <div class="careers-card__footer">
                                    <span class="careers-card__label careers-card__label--marketing">Developer</span>
                                    <span class="careers-card__icon"></span>
                                </div>
                            </a>
                        </div>
                        <div id="js-careerItem-20494" class="careers-grid__item js-careersGridItem" style="position: absolute; left: 0%; top: 362.4px;">
                            <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="DevOps Engineer">
                                <h3 class="careers-card__title" title="DevOps Engineer">
                                    DevOps Engineer                            </h3>
                                <p class="careers-card__location">5-7 years</p>
                                <p class="careers-card__location">Pune, India</p>
                                <div class="careers-card__footer">
                                    <span class="careers-card__label careers-card__label--dev">R&amp;D</span>
                                    <span class="careers-card__icon"></span>
                                </div>
                            </a>
                        </div>
                        <div id="js-careerItem-20492" class="careers-grid__item js-careersGridItem" style="position: absolute; left: 25%; top: 362.4px;">
                            <a href="" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Talent Acquisition Specialist">
                                <h3 class="careers-card__title" title="Talent Acquisition Specialist">
                                    Data Science Engineer                            </h3>
                                <p class="careers-card__location">3-5 years</p>
                                <p class="careers-card__location">Chenai, India</p>
                                <div class="careers-card__footer">
                                    <span class="careers-card__label careers-card__label--business">Engineer</span>
                                    <span class="careers-card__icon"></span>
                                </div>
                            </a>
                        </div>
                        <div id="js-careerItem-20491" class="careers-grid__item js-careersGridItem" style="position: absolute; left: 50%; top: 362.4px;">
                            <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Account Manager – SMB">
                                <h3 class="careers-card__title" title="Account Manager – SMB">
                                    Account Manager                           </h3>
                                <p class="careers-card__location">3-5 years</p>
                                <p class="careers-card__location">Raipur, India</p>
                                <div class="careers-card__footer">
                                    <span class="careers-card__label careers-card__label--sales">Manager</span>
                                    <span class="careers-card__icon"></span>
                                </div>
                            </a>
                        </div>
                        <div id="js-careerItem-20481" class="careers-grid__item js-careersGridItem" style="position: absolute; left: 75%; top: 362.4px;">
                            <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Facility Lead &amp; Office Admin">
                                <h3 class="careers-card__title" title="Facility Lead &amp; Office Admin">
                                   Java Developer                           </h3>
                                <p class="careers-card__location">3-5 years</p>
                                <p class="careers-card__location">Raipur, India</p>
                                <div class="careers-card__footer">
                                    <span class="careers-card__label careers-card__label--business">Developer</span>
                                    <span class="careers-card__icon"></span>
                                </div>
                            </a>
                        </div>    -->
                    <div id="js-careersGridSizer" class="careers-grid__sizer"></div>
                </div>
            </div>
            <!-- / Open positions -->
        </div>
    </section>
</div>
<app-footer></app-footer>
<div class="modal fade delete_popup" id="myModal" role="dialog" style="margin-top:-120px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                    <div class="jb_listing_single_overview jb_cover">
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Digital marketing (Job Description)</b></h2>

                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Drive the performance in digital
                                    marketing managing SEM & SEO & Define best in-class practice for each Digital
                                    marketing channels & run the marketing campaign.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Generate traffic & leads through Google
                                    & other Search engine.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Self-driven marketing all-rounder with
                                    the flair to Collaborate with product, design and technology team to optimize user
                                    experience.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Create weekly, monthly and quarterly
                                    digital marketing plans and strategies to drive revenue and brand awareness.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Build marketing & advertising
                                    strategy, campaign planning, implementation, management & optimization.</li>
                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Qualification</b></h2>
                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;4 yrs of experience from IT marketing
                                    or digital media company.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Strong understanding of Digital
                                    advertising and Digital lead generation.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Experience with Webanalytice tools .
                                </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Strong familiarity with the mobile and
                                    digital content domains.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Strong analytical and quantitative
                                    skills; strong bias towards data-based decision making, and comfort with data
                                    analysis.</li>
                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover">
                            <h2 class="job_description_heading"><b>how to apply</b></h2>
                            <span><b>To apply, please upload your CV/Resume, here</b>

                                <span data-animation="animated fadeInUp"
                                    class="career--upload-button swui-button--career">
                                    <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                        <a style="margin: 2px;"> upload
                                        </a>
                                        <!-- TODO add a wait indicator-->
                                    </label>
                                    <input id="file-upload" (change)="uploadCareerProfile($event, 'digital_marketing')"
                                        type="file" />
                                </span>
                            </span>

                            <!-- <span><b>To apply, please mail your CV along with a cover letter to</b><a
                                    href="mailto:career@shenzyn.com?Subject=Hello Shenzyn" target="_blank">
                                    career@shenzyn.com</a></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade delete_popup" id="myModal1" role="dialog" style="margin-top:-120px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                    <div class="jb_listing_single_overview jb_cover">
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Sales (Job Description)</b></h2>

                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Create a long-term strategy for
                                    business development and sales in B2B through partnerships and alliances for Device.
                                </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Negotiate long-term deal on commercial
                                    & contractual terms and create simplified solutions for integration with partner’s
                                    products and services while managing ongoing performance.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Provide input to Product Management
                                    teams on creating optimal product features and purchase experience for customers.
                                    Understand competitive market, need to be aware with competition product suites &
                                    services.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Product sales and monitoring
                                    Operations. Managing relationship with clients and major stakeholders.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; To generate leads & converting leads
                                    to sales. Formulate strategies and action plans to maximize sales.</li>
                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Qualification</b></h2>
                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;At least 5 years in Business
                                    Development, Sales and/or Product Management in B2B customer-facing roles in India.
                                </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Strong familiarity with the mobile and
                                    digital content domains.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Strong analytical and quantitative
                                    skills; strong bias towards data-based decision making, and comfort with data
                                    analysis and presentation to leadership.</li>
                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover">
                            <h2 class="job_description_heading"><b>how to apply</b></h2>

                            <span><b>To apply, please upload your CV/Resume, here</b>
                                <span data-animation="animated fadeInUp"
                                    class="career--upload-button swui-button--career">
                                    <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                        <a style="margin: 2px;"> upload
                                        </a>
                                        <!-- TODO add a wait indicator-->
                                    </label>
                                    <input id="file-upload" (change)="uploadCareerProfile($event,'sales')"
                                        type="file" />
                                </span>
                            </span>
                            <!-- <span><b>To apply, please mail your CV along with a cover letter to</b><a
                                    href="mailto:career@shenzyn.com?Subject=Hello Shenzyn" target="_blank">
                                    career@shenzyn.com</a></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade delete_popup" id="myModal2" role="dialog" style="margin-top:-120px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                    <div class="jb_listing_single_overview jb_cover">
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Python Developer (Job Description)</b></h2>

                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Strong experience in specialized
                                    analytics tools and technologies Python, R, SQL.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Experience with object-oriented Python
                                    development.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Understanding of database technologies
                                    and data storage approaches including but not limited to MySQL, Postgres, MongoDB,
                                    HBase, and Redi.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Experience in Agile software
                                    development methodologies.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Identify the right modeling
                                    approach(es) for given scenario and articulate why the approach fits.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Assess data availability and modeling
                                    feasibility.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Evaluate model fit and based on
                                    business / function scenario.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Good interpersonal communication
                                    skills and influencing skills.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Eagerness to learn and ability to work
                                    with limited supervision.  </li>
                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Qualification</b></h2>
                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;1 yrs of experience in Python
                                    development using Algorithm and data analysis.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Strong understanding of product life
                                    cycle is preferred.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Strong analytical and quantitative
                                    skills; strong bias towards data-based decision making, and comfort with data
                                    analysis.</li>
                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover">
                            <h2 class="job_description_heading"><b>how to apply</b></h2>
                            <span><b>To apply, please upload your CV/Resume, here</b>
                                <span data-animation="animated fadeInUp"
                                    class="career--upload-button swui-button--career">
                                    <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                        <a style="margin: 2px;"> upload
                                        </a>
                                        <!-- TODO add a wait indicator-->
                                    </label>
                                    <input id="file-upload" (change)="uploadCareerProfile($event,'python_developer')"
                                        type="file" />
                                </span>
                            </span>
                            <!-- <span><b>To apply, please mail your CV along with a cover letter to</b><a
                                    href="mailto:career@shenzyn.com?Subject=Hello Shenzyn" target="_blank">
                                    career@shenzyn.com</a></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade delete_popup" id="myModal3" role="dialog" style="margin-top:-120px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                    <div class="jb_listing_single_overview jb_cover">
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Content Writer (Job Description)</b></h2>

                            <p>We are looking for a Content Writer to join our Gender Diversity Product team who can
                                enrich our websites with new blog posts, and guides marketing team with related content.
                                Ultimately, you’ll deliver quality writing pieces that appeal to our audiences, attract
                                customers and boost brand awareness.</p>
                        </div>
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Responsibilities</b></h2>
                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;She will handle the ideation and
                                    creation of compelling content including website copy, blog posts, social
                                    media content, and product/service descriptions.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;She will be responsible for adding
                                    value by actively engaging customers/stakeholders and maintaining a cohesive brand
                                    voice.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Need to research industry-related
                                    topics (combining online sources, interviews and studies).</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Write clear marketing copy to promote
                                    our product.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Prepare well-structured drafts using
                                    Content Management Systems.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Proofread and edit blog posts before
                                    publication.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Coordinate with marketing and design
                                    teams to illustrate articles.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Conduct simple keyword research and
                                    use SEO guidelines to increase web traffic.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Promote content on social media.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Identify customers’ needs and gaps in
                                    our content and recommend new topics.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Update website content as needed.</li>

                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Qualification</b></h2>
                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Proven work experience as a Content
                                    Writer, or similar role.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Portfolio of published articles.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Experience doing research using
                                    multiple sources.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Familiarity with web publications &
                                    web tools.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Excellent writing and editing skills
                                    in English.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Masters in Marketing, English,
                                    Journalism or related field.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Coordinate with marketing and design
                                    teams to illustrate articles.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Conduct simple keyword research and
                                    use SEO guidelines to increase web traffic.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Promote content on social media.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Identify customers’ needs and gaps in
                                    our content and recommend new topics.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Update website content as needed.</li>

                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover">
                            <h2 class="job_description_heading"><b>how to apply</b></h2>
                            <span><b>To apply, please upload your CV/Resume, here</b>

                                <span data-animation="animated fadeInUp"
                                    class="career--upload-button swui-button--career">
                                    <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                        <a style="margin: 2px;"> upload
                                        </a>
                                        <!-- TODO add a wait indicator-->
                                    </label>
                                    <input id="file-upload" (change)="uploadCareerProfile($event,'content_writer')"
                                        type="file" />
                                </span>
                            </span>

                            <!-- <span><b>To apply, please mail your CV along with a cover letter to</b><a
                                    href="mailto:career@shenzyn.com?Subject=Hello Shenzyn" target="_blank">
                                    career@shenzyn.com</a></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade delete_popup" id="myModal4" role="dialog" style="margin-top:-120px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                    <div class="jb_listing_single_overview jb_cover">

                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Full Stack Developer(Job Description)</b></h2>
                            <ul>

                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Proven experience as a Javascript
                                    based Full Stack Development </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Prior experience developing web
                                    application using MERN Stack </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Familiarity with react/redux. </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Knowledge of multiple front-end
                                    languages and libraries (HTML5/ CSS, JavaScript, XML, jQuery, bootstrap) </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Knowledge of multiple JavaScript
                                    frameworks (e.g. Angular, React, Node.js) </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Familiarity with NO SQL databases
                                    (preferably MongoDB), web servers (preferably nginx) </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Good understanding of UI/UX design,
                                    should be able work UI designs to fit devices of various form factors. </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Excellent communication and teamwork
                                    skills </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; Great attention to detail </li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp; An analytical mind </li>

                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Qualification</b></h2>
                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Any Graduate Degree.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;2+ yrs of experience in Javascript full
                                    stack development.</li>
                            </ul>
                        </div>
                        <div class="jp_job_res jb_cover">
                            <h2 class="job_description_heading"><b>how to apply</b></h2>

                            <span><b>To apply, please upload your CV/Resume, here</b>
                                <span data-animation="animated fadeInUp"
                                    class="career--upload-button swui-button--career">
                                    <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                        <a style="margin: 2px;"> upload
                                        </a>
                                        <!-- TODO add a wait indicator-->
                                    </label>
                                    <input id="file-upload"
                                        (change)="uploadCareerProfile($event,'full_stack_developer')" type="file" />
                                </span>
                            </span>

                            <!-- <span><b>To apply, please mail your CV along with a cover letter to</b><a
                                    href="mailto:career@shenzyn.com?Subject=Hello Shenzyn" target="_blank">
                                    career@shenzyn.com</a></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade delete_popup" id="myModal5" role="dialog" style="margin-top:-120px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                    <div class="jb_listing_single_overview jb_cover">
                        <div class="jp_job_res jb_cover minimum_cover">
                            <h2 class="job_description_heading"><b>Graphic Designer (Job Description)</b></h2>

                            <p>
                                We are looking for a Graphic Designer to join our Design team who can enrich our social
                                and digital media with new
                                posts, and work alongside our marketing team. Ultimately, you’ll deliver quality
                                creatives that appeals to our
                                audiences, attract customers and boost brand awareness.
                            </p>

                            <ul>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;A strong portfolio of relevant
                                    illustrations and other graphic designs.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;0-1 years of experience in digital
                                    marketing designs.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Degree in Design, Fine Arts,
                                    self-taught designers are also welcome.</li>
                                <li><i class="fa fa-caret-right"></i>&nbsp;&nbsp;Has in-depth knowledge of Adobe
                                    Photoshop and Illustrator. Any other software can be a bonus.</li>
                            </ul>

                            <p>
                                <b>Note: </b>Add the link to your portfolio on your CV itself, before uploading.
                            </p>
                        </div>
                        <div class="jp_job_res jb_cover">
                            <h2 class="job_description_heading"><b>how to apply</b></h2>
                            <span><b>To apply, please upload your CV/Resume, here</b>
                                <span data-animation="animated fadeInUp"
                                    class="career--upload-button swui-button--career">
                                    <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                        <a style="margin: 2px;"> upload
                                        </a>
                                        <!-- TODO add a wait indicator-->
                                    </label>
                                    <input id="file-upload" (change)="uploadCareerProfile($event,'graphics_designer')"
                                        type="file" />
                                </span>
                            </span>
                            <!-- <span><b>To apply, please mail your CV along with a cover letter to</b><a
                                    href="mailto:career@shenzyn.com?Subject=Hello Shenzyn" target="_blank">
                                    career@shenzyn.com</a></span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>