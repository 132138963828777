import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(@Inject(DOCUMENT) document, private modalService: NgbModal, private modalService2: NgbModal, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }
  jobsUrlLink: String;
  employerUrlLink: String;
  partnersUrlLink: String;

  ngOnInit() {
    this.jobsUrlLink = environment.jobsWebUrl;
    this.employerUrlLink = environment.employersWebUrl;
    this.partnersUrlLink = environment.partnersWebUrl;

  }
  onLogout() {
    console.log('inside logout');
    localStorage.removeItem('name');
    localStorage.removeItem('authtoken');
    localStorage.removeItem('emailId');
    this.router.navigate(['/login']);
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('header_data');
      element.style.background = "rgba(255,255,255,0.9)";
      element.style.position = "fixed";
      element.style.color = " rgba(0, 0, 0, 0.6)";
      element.style.marginTop = "-2px";
      element.style.transitionDuration = "0.6s";
      element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
    } else {
      let element = document.getElementById('header_data');
      element.style.background = "";
      element.style.position = "";
      element.style.marginTop = "";
      element.style.boxShadow = "";
      element.style.color = "#fff";
    }
  }

}
