import { Component, OnInit, Directive, Input, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators ,FormsModule } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { JobseekerService } from '../../services/jobseeker.operations';
import swal from 'sweetalert2';


@Component({
  selector: 'app-campaign-register',
  templateUrl: './campaign-register.component.html',
  styleUrls: ['./campaign-register.component-purified.css']
})

export class CampaignRegisterComponent implements OnInit {
  RegistrationForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  erroMsg: any;
  userData: any;
  formBuilder: any;
  contactNo;
  jobRoles : any;
  email: any;
  uploadErr: any;
  fileToUpload: File = null;
  File = null;
  resumeName: any;
  resumeStatus: string;
  source: string;
  showUploadWait: boolean=false;

  constructor(private fb: FormBuilder, private jobSeekerService: JobseekerService, private route:ActivatedRoute, private router: Router) {
      this.RegistrationForm = fb.group({
        'contactNo': [null, Validators.required],
        'emailId': [null, Validators.required],
        'resumeUrl': [null, Validators.required],
        // 'jobRole': [null, Validators.required],
        'submittedBy': [null, Validators.required],
        'termsandconditions': [false, Validators.pattern('true')],
        'source': [, ]
      });

    }
    get f() { return this.RegistrationForm.controls; }
    ngOnInit() {
      this.route.queryParams
      .filter(params => params.source)
      .subscribe(params => {
        console.log(params);
        this.source = params.source;
      });
    }

    // form validation
    register(formdata: any) {
      this.submitted = true;
        if (this.RegistrationForm.invalid) {
          this.loading = false;
        console.warn('its inside tere');
        return;
      }
      //this.loading = true;
      this.jobSeekerService.campaignRegister(formdata).subscribe(
        data => {
          this.userData = (data);
          if ((this.userData.responseStatus === 200)) {
            console.log('200-Success');
            this.loading = false;
            this.erroMsg = '';
            this.submitted = false;
            swal({
              title: 'Success',
              text: data.message+data.emailId,
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#535BE2',
              cancelButtonColor: '#FF7E39',
              confirmButtonText: 'OK',
            }).then((confirm) => {
              if (confirm) {
                this.router.navigate(['/homenew']);
              }
            });
          }
        },
        error => {
          if (error.responseStatus === 401) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 500) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 400) {
            this.loading = false;
            this.erroMsg = error.message;
          }
        },
        () => console.log()
      );
    }

    onCheckboxChange(event:any){
      
      this.jobRoles.push(event.target.value)
      console.log(this.jobRoles)
    }
//   // upload resume
  fileChangeEvent(event: any) {
    this.uploadErr = "";
    const file = event.target.files[0];
    if(file.type.match(/pdf\/*/) || file.type.match(/msword\/*/) || file.type.match(/vnd.openxmlformats-officedocument.wordprocessingml.document\/*/)) 
    {
      this.uploadErr = "";
      //this.resumeName = file.name
      this.showUploadWait = true;
      this.fileToUpload = file
      this.jobSeekerService.resumeUpload(this.fileToUpload).subscribe(data => {
        this.showUploadWait = false;
        this.userData = (data);
        if ((this.userData.responseStatus === 200)) {
          this.resumeStatus = "File uploaded successfully!";
            setTimeout(() => {
            this.resumeStatus = "";
            }, 2000)
            if (data.data.phone && typeof (data.data.phone) === 'object' && data.data.phone.length > 0) {
              this.contactNo = data.data.phone[0].replace('+91','').replace('-','').replace(' ','');
            }
            if (data.data.email && typeof (data.data.email) === 'object' && data.data.email.length > 0) {
              this.email = data.data.email[0];
            }
            //if (data.location && data.location.length > 0) {
              this.resumeName = data.location;
            //}
        }
      }, error => {
        this.resumeStatus = error.message;
      });
    } else {
      this.uploadErr = 'File should be either pdf / doc / docx format!';
      return false;
    }
  }
}
