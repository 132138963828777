<!-- Desktop view -->
<div id="desktop-view">
  <div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
    <div class="cp_logo_wrapper index_2_logo">
        <a routerLink="/job-dashboard">
            <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
        </a>
    </div>
</div>
<div class="row row-height">
    <div class="col-lg-5 content-left" id="content-left" style="background-color: #a06df2; ">
      <div class="content-left-wrapper">
        <div>
          <figure>
            <img id='imgsrc' src="/assets/sheImages/others/graphic6.svg" alt="" class="img-fluid">
          </figure>
          <p style="color: #ffff;font-weight:900;">Itinerary, without a stopover, for a beautiful career is right here.
          </p>
        </div>
      </div>
</div>
<!-- /content-left -->
<div class="col-lg-7 content-right" id="start">
      <div id="wizard_container">
        <!-- /top-wizard -->
          <form id="wrapped" [formGroup]="RegistrationForm"  ngNativeValidate (submit)="register(RegistrationForm.value)">
            <div>
              <h1 class="head-title">Wait Not, Procrastinate Not, Just Start</h1><br />
              <h3 class="main_question"><strong></strong> Create your roadmap to destination SUCCESS by uploading your profile.</h3>

              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="field">
                    <div class="file  has-name is-boxed">
                      <label class="file-label ">
                        <input class="file-input" #file (change)="fileChangeEvent($event)" type="file" name="resume"
                          accept=".doc, .docx,.pdf">
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-cloud-upload-alt"></i>
                            <i *ngIf="showUploadWait" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
                          </span>
                          <span class="file-label">
                            Upload resume
                          </span>
                        </span>
                        <br />
                        <span class="text-success">{{resumeStatus}}</span>
                        <span class="text-danger">{{uploadErr}}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group">
                <input type="hidden" class="form-control " name="src" id="src" [(ngModel)]="source"
                 [formControl]="RegistrationForm.controls['source']"
                [ngClass]="{ 'is-invalid': submitted && f.source.errors }">
                <div *ngIf="submitted && f.source.errors" class="invalid-feedback">
                <div *ngIf="f.resumeUrl.errors.required">Invalid Registration Source</div>
                </div>
                </div>
              </div>
              
              <div class="form-group">
                  <input type="hidden" class="form-control " name="name" id="name" [(ngModel)]="resumeName"
                   [formControl]="RegistrationForm.controls['resumeUrl']"
                  [ngClass]="{ 'is-invalid': submitted && f.resumeUrl.errors }">
                <div *ngIf="submitted && f.resumeUrl.errors" class="invalid-feedback">
                  <div *ngIf="f.resumeUrl.errors.required">Resume upload is required</div>
                </div>
              </div>

              <div class="form-group">
                <label class="custom-label">Email Id</label>
                <div class="styled-select clearfix">
                    <input type="email" class="form-control " placeholder="Email id *" id="emailId" name="emailId"
                    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" [formControl]="RegistrationForm.controls['emailId']"
                    [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" [(ngModel)]="email" >
                    <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                    <div *ngIf="f.emailId.errors.required">Email Id is required</div>
                    <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">Email must be a valid email address.</div> 
                    </div>
                </div>
              </div>

              <div class="form-group">
                <label class="custom-label">Contact No</label>
                <input type="number" class="form-control" name="contactNo" id="contactNo"
                pattern="^((\\+91-?)|0)?[0-9]{10}$" placeholder="Contact No*"
                [formControl]="RegistrationForm.controls['contactNo']" [(ngModel)]="contactNo"
                [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }">
                    <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                        <div *ngIf="f.contactNo.errors.required">Contact no required</div>
                        <div *ngIf="submitted && f.contactNo.errors && f.contactNo.errors.pattern">It is not a valid mobile number.</div> 
                    </div>
              </div>

              <div class="form-group">
                <label class="custom-label">Position Applied for </label>
                <br>
                <input (change)="onCheckboxChange($event)" type="checkbox" name="role" value="dnps"/>.Net Production support &nbsp; 
                <input (change)="onCheckboxChange($event)"type="checkbox" name="role" value="dsdev"/>Datastage Developers &nbsp; <br>
                <input type="checkbox" name="role" value="dsps"/>Datastage Production Support &nbsp; 
                <input type="checkbox" name="role" value="dsact"/>Datastage Actimize &nbsp; <br>
                <input type="checkbox" name="role" value="javbd"/>Java + Bigdata &nbsp; 
                <input type="checkbox" name="role" value="javsbor"/>Java + Springboot + Oracle &nbsp; <br>
                <input type="checkbox" name="role" value="javsbms"/>Java + Springboot + Microservices &nbsp; 
                <input type="checkbox" name="role" value="manqa"/>Manual QA &nbsp; <br>
                <input type="checkbox" name="role" value="autqa"/>Automation QA&nbsp; 
                <input type="checkbox" name="role" value="fsjav"/>Fullstack Java (HackerEarth)&nbsp; <br>
                <input type="checkbox" name="role" value="oth"/>Others&nbsp; 
                      
              </div>

             
              <div class="login_remember_box">
              <label class="control control--checkbox "> By submitting profile, I agree to the <a routerLink="/termsandconditions"  >Terms and Condtions</a> 
                  <input type="checkbox"class="form-control email"  id="termsandconditions" name="termsandconditions" 
                  [formControl]="RegistrationForm.controls['termsandconditions']" [ngClass]="{ 'is-invalid': submitted && f.termsandconditions.errors }">
                  <span class="control__indicator"></span>
                  <div *ngIf="submitted && f.termsandconditions.errors" class="invalid-feedback">
                      <div *ngIf="f.termsandconditions.errors.required"> required</div>
                      <div  *ngIf="submitted && f.termsandconditions.errors ">
                          required
                      </div>
                  </div>
              </label>
            </div>
          </div>
           <p style="color:red;">{{erroMsg}}</p>
           <div class="form-button">
             <button id="submit" type="submit" [disabled]="loading" class="submit " style="background-color: #d80075">Register</button>
             <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
           </div>
      <!-- </div> -->
        </form>
      </div>
    </div>
  </div>
</div>