/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recruiter-email-verify.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./recruiter-email-verify.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/auth.service";
var styles_RecruiterEmailVerifyComponent = [i0.styles];
var RenderType_RecruiterEmailVerifyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecruiterEmailVerifyComponent, data: {} });
export { RenderType_RecruiterEmailVerifyComponent as RenderType_RecruiterEmailVerifyComponent };
export function View_RecruiterEmailVerifyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "logo-size"], ["src", "../../assets/sheImages/logo8.svg"], ["style", " width: 163px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h4", [["style", "text-align: center; margin-top: 20%"]], null, null, null, null, null))], null, null); }
export function View_RecruiterEmailVerifyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recruiter-email-verify", [], null, null, null, View_RecruiterEmailVerifyComponent_0, RenderType_RecruiterEmailVerifyComponent)), i1.ɵdid(1, 114688, null, 0, i2.RecruiterEmailVerifyComponent, [i3.ActivatedRoute, i3.Router, i4.AuthServices], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecruiterEmailVerifyComponentNgFactory = i1.ɵccf("app-recruiter-email-verify", i2.RecruiterEmailVerifyComponent, View_RecruiterEmailVerifyComponent_Host_0, {}, {}, []);
export { RecruiterEmailVerifyComponentNgFactory as RecruiterEmailVerifyComponentNgFactory };
