import { Component, Injectable, OnInit, Pipe } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import Swal from "sweetalert2";
import { AuthServices } from "../../services/auth.service";
import { JobseekerService } from "../../services/jobseeker.operations";
import { environment } from "../../../environments/environment";

declare var $: any;
interface JobDetails {
  id: number;
  companyType: any;
  Freshness: any;
  Salary: any;
  ExperienceLevel: any;
}
@Component({
  selector: "app-job-search-result",
  templateUrl: "./job-search-result.component.html",
  styleUrls: ["./job-search-result.component.css"],
})
@Pipe({ name: "jsonFilterBy" })
@Pipe({ name: "result" })
@Injectable()
export class SearchResultComponent implements OnInit {
  experiance = [
    "Fresher",
    "0-1 years",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "10-50 years",
    "undefined",
  ];
  selectedDevice = "Experiance level";
  _id;
  _location;
  recommend_job: boolean = false;
  applied_job: boolean = false;
  saved_job: boolean = false;
  search_view: boolean = true;
  data: any;
  value: any;
  location: any;
  dataValue: any = [];
  finalDAtes: any = [];
  experience;
  dataValues;
  loading = true;
  public contact;
  sortExp: any;
  sortSalary: any;
  sortFreshness: any;
  Freshness = "";
  Salary = 0;
  error;
  userData: any;
  loadingValue: any = false;
  companyType: any = "";
  ExperienceLevel: any = "";
  searchCompanyType;
  loadAPI: Promise<any>;
  ExperienceDetails = [
    "Fresher",
    "0-1 Years",
    "1-3 Years",
    "More than 3 Years",
    "More than 5 years",
    "10 years and more",
  ];
  jobId: any;
  jobDescription: any;
  jdVideo: any;
  skills: any;
  exp: any;
  companyName: any;
  jobTitle: any;
  employeeStrength: any;
  logo: any;
  loc: any;
  website: any;
  selectedFilter;
  date: any = [];
  uploadbtntxt:String;
  dateValues: any;
  dbDate: any = [];
  resulDate: any;
  temp: any;
  filterExperienceValue: any = "any";
  filterSalaryValue: any = "any";
  filterFreshnessValue: any = "any";
  filterCompanyValue: any = "any";
  companylogo: any = "any";
  Finalvalue: any = [];
  result: any = [];
  empData: JobDetails[] = [];
  jobLocation: string;
  minexperience: any;
  maxexperience: any;
  minannualCTC: any;
  maxannualCTC: any;
  jobsUrlLink: String;
  employerUrlLink: String;
  partnersUrlLink: String;
  fileToUpload: File = null;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthServices,
    private router: Router,
    private JobseekerService: JobseekerService
  ) {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }
  ngOnInit(): void {
    this.jobsUrlLink = environment.jobsWebUrl;
    this.uploadbtntxt  = 'Upload Resume & Apply';
    this.route.params.subscribe((params) => {
      this.value = params.id ? params.id : ""; // --> Name must match wanted parameter
      this.location = params.location ? params.location : "";
      if (this.value === "undefined") {
        this.value = "";
      }
      if (this.location === "undefined") {
        this.location = "";
      }
    });

    let datas: "" = this.value;
    let locations: "" = this.location;
    this.authService.search(datas, locations).subscribe(
      (data) => {
        const dataVal = data.data;
        //hide salary where applicaple
        //console.log(dataVal.length);
        for (var i = 0; i < dataVal.length; i++) {
          if (dataVal[i].salaryHide) {
            // console.log(dataVal[i].salaryHide);
            dataVal[i].maxannualCTC = "";
            dataVal[i].minannualCTC = "";
          }
        }

        this.Finalvalue = dataVal;
        this.dataValue = dataVal;
        this.empData = dataVal;
        this.finalDAtes = this.dataValue;
        this.companylogo = data.data.logo;
      },
      (error) => {
        if (error.responseStatus === 401) {
        } else if (error.responseStatus === 500) {
        } else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );
  }

  filterCompany(selectedValue) {
    this.filterCompanyValue = selectedValue;
  }
  filterFreshness(selectedValue) {
    this.filterFreshnessValue = selectedValue;
  }
  filterSalary(selectedValue) {
    this.filterSalaryValue = selectedValue;
  }
  filterExperience(selectedValue) {
    this.filterExperienceValue = selectedValue;
  }
  goToSearch() {
    this.router.navigate(["/search-view", this.value, this.location]);
  }
  filteredSet(ctFilter, freshnessFilter, salaryFilter, expFilter) {
    var i;
    var resultSet = [];
    var currDate = moment.utc();
    var type, freshness, temp;
    var jobPostedDate;
    var value;
    var minex;
    var maxex;
    var minexI;
    var maxexI;
    var splitStr;
    var dbminexI, dbmaxexI, dbmaxctcI, salaryFilterI;
    minexI = +expFilter;
    if (freshnessFilter == "1") {
      type = "days";
      freshness = 0;
    } else if (freshnessFilter == "2") {
      type = "days";
      freshness = 7;
    } else if (freshnessFilter == "3") {
      type = "months";
      freshness = 0;
    }
    if (
      ctFilter == "any" &&
      freshnessFilter == "any" &&
      salaryFilter == "any" &&
      expFilter == "any"
    ) {
      resultSet = this.dataValue;
    } else {
      for (i = 0; i < this.dataValue.length; i++) {
        temp = this.dataValue[i].jobPostedDate;
        if (temp == "undefined") {
          continue;
        }
        jobPostedDate = moment(temp).utc();
        value = currDate.diff(jobPostedDate, type);
        dbmaxctcI = +this.dataValue[i].maxannualCTC;
        dbminexI = +this.dataValue[i].minexperience;
        dbmaxexI = +this.dataValue[i].maxexperience;
        salaryFilterI = +salaryFilter;
        if (
          (ctFilter == this.dataValue[i].companyType || ctFilter == "any") &&
          (dbmaxctcI >= salaryFilterI || salaryFilter == "any") &&
          ((dbminexI <= minexI && dbmaxexI >= minexI) || expFilter == "any") &&
          (value <= freshness || freshnessFilter == "any")
        ) {
          if (resultSet.indexOf(this.dataValue[i]) == -1) {
            resultSet.push(this.dataValue[i]);
          }
        }
      }
      console.log("In result set: ", resultSet, " :: Length", resultSet.length);
    }
    return resultSet;
  }
  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
      if (
        scripts[i].getAttribute("src") != null &&
        scripts[i].getAttribute("src").includes("loader")
      ) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        "../assets/js/jquery.nice-select.min.js",
        "../assets/js/jquery-3.2.1.min.js",
        "../assets/js/bootstrap.min.js",
        "../assets/js/selectBnfit.js",
        "../assets/js/plugin.js",
        "../assets/js/imagesloaded.pkgd.min.js",
        "../assets/js/isotope.pkgd.min.js",
        "../assets/js/custom.js",
        "../assets/js/functions.js",
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement("script");
        node.src = dynamicScripts[i];
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
      }
    }
  }

  myFunc(num1, num2) {
    this.authService.getJobs(num1).subscribe(
      (data) => {
        this.loading = false;
        const dataValue = data.data;
        this.dataValues = dataValue;
        this.jdVideo = dataValue.jdVideo;
        this.skills = dataValue.skills;
        this.jobTitle = dataValue.jobTitle;
        this.jobDescription = dataValue.jobDescription;
        this.jobLocation = dataValue.jobLocation;
        this.companyName = dataValue.companyName;
        this.minexperience = dataValue.minexperience;
        this.maxexperience = dataValue.maxexperience;
        if (dataValue.salaryHide) {
          this.minannualCTC = "";
          this.maxannualCTC = "";
        } else {
          this.minannualCTC = "₹ " + dataValue.minannualCTC;
          this.maxannualCTC = "₹ " + dataValue.maxannualCTC + " " + "a year";
        }
      },
      (error) => {
        // console.log('error', error.message);

        if (error.responseStatus === 401) {
          // console.log('inside if');
        } else if (error.responseStatus === 500) {
          // console.log('inside if');
        } else if (error.responseStatus === 400) {
          // console.log('inside if');
        }
      },
      () => console.log()
    );
    //
    this.authService.getCompany(num2).subscribe(
      (data) => {
        this.loading = false;
        const dataValue = data.data;
        this.companyName = dataValue.companyName;
        this.website = dataValue.website;
        this.employeeStrength = dataValue.employeeStrength;
        this.logo = dataValue.logo;
        this.loc = dataValue.location;
      },
      (error) => {
        if (error.responseStatus === 401) {
        } else if (error.responseStatus === 500) {
        } else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );
  }
  fileChangeEvent(event: any) {
    var file = event.target.files[0];
    this.uploadbtntxt  = 'Uploading... please wait.';
    this.fileToUpload = file;
    console.log(this.fileToUpload);
    this.JobseekerService.resumeUpload(this.fileToUpload).subscribe((data) => {
      this.uploadbtntxt  = 'Upload Resume & Apply';
      this.userData = data;
      if (this.userData.responseStatus === 200) {
        var email = data.email;
        if (data.newuser) {
          
          if(email === undefined){
            alert("Unable to parse email id, will be redirected to registration page.");
            document.location.href = this.jobsUrlLink + "/#/user/sign-up"; 
          }
          else{
                localStorage.setItem("email", email);
                Swal({
                  title: "Registration Confirmation",
                  html:
                    "You will be registerd on Shenzyn with the email id : " +
                    email +
                    ", and you agree to shenzyn <a href='https://www.shenzyn.com/#/termsandconditions' target=”_blank”> T&C</a>",
                  type: "info",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Register Please!",
                }).then(
                  (result) => {
                    if (result.value) {
                      document.location.href =
                        this.jobsUrlLink + "/#/user/upload-register/" + email;
                    }
                  },
                  (error) => {
                    //cancel action nothing to do
                  }
                );
              }
        } else {
          alert("Account with email id "+ email +" exists, will be redirected to login page.");
          document.location.href = this.jobsUrlLink + "/#/user/login/" + email;
        }
      } else {
        alert("File upload failed, will be redirected to registration page.");
        document.location.href = this.jobsUrlLink + "/#/user/sign-up";
      }
    });
  }

  onChange(newValue) {
    this.exp = newValue;
    this.router.navigate(["/search-view", newValue, ""]);
    this.selectedDevice = newValue;
  }
  finalFilter() {
    const result = this.filteredSet(
      this.filterCompanyValue,
      this.filterFreshnessValue,
      this.filterSalaryValue,
      this.filterExperienceValue
    );
    this.Finalvalue = result;
  }
}
