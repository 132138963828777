
import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';
import { JobseekerService } from '../../services/jobseeker.operations';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  loadAPI: Promise<any>;
  // fileToUpload: any;
  fileToUpload: File = null;
  closeResult: string;
  constructor(@Inject(DOCUMENT) document, private modalService: NgbModal, private modalService2: NgbModal, private toastr: ToastrService, private JobseekerService: JobseekerService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }
  @HostListener('window:scroll', ['$event'])

  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('header_data');
      element.style.background = "rgba(255,255,255,0.9)";
      element.style.position = "fixed";
      element.style.marginTop = "-2px";
      element.style.transitionDuration = "0.6s";
      element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
    } else {
      let element = document.getElementById('header_data');
      element.style.background = "";
      element.style.position = "";
      element.style.marginTop = "";
      element.style.boxShadow = "";
    }
  }
  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        "../assets/js/jquery-3.2.1.min.js",
        "../assets/js/bootstrap.min.js",
        "../assets/js/selectBnfit.js",
        "../assets/js/plugin.js",
        "../assets/js/owl.carousel.js",
        "../assets/js/jquery.countTo.js",
        "../assets/js/jquery.magnific-popup.js",
        "../assets/js/dropify.min.js",
        "../assets/js/jquery.inview.min.js",
        "../assets/js/jquery.nice-select.min.js",
        "../assets/js/imagesloaded.pkgd.min.js",
        "../assets/js/isotope.pkgd.min.js",
        "../assets/js/custom.js",
        "../assets/js/functions.js",
      ];
      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }
  navigateToSection(anchorHash) {
    setTimeout(() => {
      const anchor = document.getElementById(anchorHash);
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView();
      }
    });
  }

  uploadCareerProfile(event: any, jobtitle) {
    var file = event.target.files[0];
    console.log(file);
    this.fileToUpload = file
    console.log(this.fileToUpload)
    this.JobseekerService.uploadShenzynJob(this.fileToUpload,jobtitle).subscribe(data => {
      if ((data.responseStatus === 200)) {
        var email = data.email;
          Swal({
            title: 'Upload Confirmation',
            html: "You have applied for job posting with Shenzyn with the email id : " + email ,
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
          })
      }
      else {
        Swal({
          title: 'Upload Failed',
          html: "Unable to upload the profile, please email to : wehearyou@shenzyn.com",
          type: 'error',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        })
      }
    })
  }

}
