/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./not-found.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap/modal/modal";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["style", "width:100%;height:14%;text-align:center;position:relative;top:40%;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["style", "width:100%;height:10%;text-align:center;position:relative;top:40%;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not sure how you reached here, the page you are looking for is not here."])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["align", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://www.shenzyn.com"], ["text-align", "center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go Back to Shenzyn "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["alt", "404-Page not found"], ["display", "block"], ["margin-left", "auto"], ["margin-right", "auto"], ["src", "../../assets/sheImages/others/404Error.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["align", "center"]], null, null, null, null, null))], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i2.NotFoundComponent, [i3.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found", i2.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
