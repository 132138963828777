import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
var PartnersSignupComponent = /** @class */ (function () {
    function PartnersSignupComponent(fb, authService, router) {
        this.fb = fb;
        this.authService = authService;
        this.router = router;
        this.dropdownList = [];
        this.selectedItems = [];
        this.dropdownSettings = {};
        this.RegistrationForm = fb.group({
            'companyName': [null, Validators.required],
            'address': [null, Validators.required],
            'contactNo': [null, Validators.required],
            'emailId': [null, Validators.required],
            // 'password': [null, Validators.required],
            'gstin': [null, Validators.required]
        });
    }
    Object.defineProperty(PartnersSignupComponent.prototype, "f", {
        get: function () { return this.RegistrationForm.controls; },
        enumerable: true,
        configurable: true
    });
    PartnersSignupComponent.prototype.ngOnInit = function () {
        // $(".toggle-password").click(function() {
        //   $(this).toggleClass("fa-eye fa-eye-slash");
        //   var input = $($(this).attr("toggle"));
        //   if (input.attr("type") == "password") {
        //     input.attr("type", "text");
        //   } else {
        //     input.attr("type", "password");
        //   }
        // });
        this.dropdownList = [
            { item_id: 1, item_text: 'IT' },
            { item_id: 2, item_text: 'BPO/KPO' },
            { item_id: 3, item_text: 'BFSI' },
            { item_id: 4, item_text: 'Manufacturing' },
            { item_id: 5, item_text: 'Pharma' }
        ];
        // this.selectedItems = [
        //   { item_id: 3, item_text: 'Pune' },
        //   { item_id: 4, item_text: 'Navsari' }
        // ];
        this.dropdownSettings = {
            limitSelection: 3,
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    };
    // custom specs selection
    PartnersSignupComponent.prototype.onItemSelect = function (e) {
        // console.log(e,'its event');
        this.selectedItems.push(e);
        console.warn(this.selectedItems);
    };
    PartnersSignupComponent.prototype.onSelectAll = function (e) {
        console.warn(e, ' its event');
    };
    // form validation
    PartnersSignupComponent.prototype.register = function (formdata) {
        var _this = this;
        this.submitted = true;
        formdata.selectedItems = this.selectedItems;
        console.log(formdata);
        // if (this.RegistrationForm.invalid) {
        //   console.warn('its inside tere');
        //   return
        // }
        this.loading = true;
        this.authService.partnerRegisterpartner(formdata).subscribe(function (data) {
            _this.userData = (data);
            if ((_this.userData.responseStatus === 200)) {
                console.log('200-Success');
                _this.loading = false;
                _this.erroMsg = '';
                _this.submitted = false;
                swal({
                    title: 'Success',
                    text: data.message,
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['/homenew']);
                    }
                });
            }
        }, function (error) {
            if (error.responseStatus === 401) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 500) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 400) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
        }, function () { return console.log(); });
    };
    return PartnersSignupComponent;
}());
export { PartnersSignupComponent };
