<!-- Desktop View -->

<div id="desktop-view">
  <div class="form-body">
    <div class="website-logo">
      <a routerLink="/homenew">
        <img class="logo-size" src="assets/sheImages/logo56.svg" alt="" style="width:170px;height:47px;">
      </a>
    </div>
    <div class="row">
      <div class="img-holder">
        <div class="bg"></div>
        <div class="info-holder">
          <img src="/assets/sheImages/graphic1.svg" alt="">
        </div>
      </div>
      <div class="form-holder">
        <div class="form-content">
          <div class="form-items" style="max-width: 596px;">
            <h1 id="header_font" style="font-size:44px;line-height:57px;"> Partner’s Login </h1>
            <br />
            <p class="p">Welcome to the Shenzyn Partner Extranet Portal. By inserting
              your data below you can register as member for Shenzyn.</p>
            <div class="page-links">
              <a routerLink="/partners-login" class="active">Login</a>
              <a routerLink="/partners-signup">Register</a>
            </div>
            <form [formGroup]="loginForm" novalidate (submit)="login(loginForm.value)">
              <input type="text" class="form-control email" placeholder="Email id *" id="emailId" name="emailId"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [formControl]="loginForm.controls['emailId']"
                [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
              <div *ngIf="submitted && f.emailId.errors && (f.emailId.touched || f.emailId.dirty) "
                class="invalid-feedback">
                <div *ngIf="f.emailId.errors.required && (f.emailId.touched || f.emailId.dirty)">Email Id is required
                </div>
                <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
                  Email must be a valid email address.
                </div>
              </div>
              <input type="password" class="form-control email" id="password-field" placeholder=" Password *"
                name="password" [formControl]="loginForm.controls['password']"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <span toggle="#password-field" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
                              margin-right: 20px;
                              margin-top: -42px;
                              position: relative;
                              z-index: 2;"></span>
              <div *ngIf="submitted && f.password.errors && (f.password.touched || f.password.dirty) "
                class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
              <p style="color:red;">{{erroMsg}}</p>
              <div class="form-button">
                <button type="submit" [disabled]="loading" class="ibtn "
                  style="background-color: #d80075">Login</button>
                  <a routerLink="/partners-forget">Forgot password?</a>
                <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
              </div>
            </form>
            <!-- <div class="other-links">
              <span>Or login with</span>
              <a><i class="fab fa-google" (click)="socialSignIn('google')"></i></a>
              <a><i class="fab fa-linkedin-in" (click)="socialSignIn('linkedin')"></i></a>
            </div> -->
            <div class="other-links">
              <p style="font-size:13px;margin-top:10px;">New to shenzyn? Please <a id="reg_txt"
                  routerLink="/partners-signup">Register</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile View -->
<div id="mobile-view">
  <div class="container">
    <div class="row form-content">
      <div class="col-sm-12 fullpage">
        <div class="col-sm-3 form-items logo-login" style="max-width: 596px;">
          <a routerLink="/homenew">
            <img class="logo-size" src="assets/sheImages/logo8.svg" alt="">
          </a>

        </div>
        <h5 class="animation"></h5>
        <h3 class="test" id="header_font">Partner’s Login </h3>
        <br />
        <p class="p">Welcome to the Shenzyn Partner Extranet Portal. By inserting
          your data below you can register as member for Shenzyn.</p>
        <div class="col page-links">
          <a routerLink="/partners-login" class="active">Login</a>
          <a routerLink="/partners-signup">Register</a>
        </div>
        <form [formGroup]="loginForm" novalidate (submit)="login(loginForm.value)">
          <input type="text" class="form-control email" placeholder="Email id *" id="emailId" name="emailId"
            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [formControl]="loginForm.controls['emailId']"
            [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
          <div *ngIf="submitted && f.emailId.errors && (f.emailId.touched || f.emailId.dirty) "
            class="invalid-feedback">
            <div *ngIf="f.emailId.errors.required && (f.emailId.touched || f.emailId.dirty)">Email Id is required</div>
            <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
              Email is invalid
            </div>
          </div>
          <input type="password" class="form-control email" placeholder=" Password *" id="password" name="password"
            [formControl]="loginForm.controls['password']" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
          <div *ngIf="submitted && f.password.errors && (f.password.touched || f.password.dirty) "
            class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
          <p style="color:red;">{{erroMsg}}</p>
          <div class="form-button">
            <!-- <button type="submit" [disabled]="!loginForm.valid"  [disabled]="!ngForm.valid">Submit</button>     -->
            <button type="submit" [disabled]="loading" class="ibtn " style="background-color: #d80075">Login</button> <a
              routerLink="/partners-forget">Forgot password?</a>
            <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
          </div>
        </form>

        <!-- <div class="col other-links">
          <span>Or login with</span>
          <a><i class="fab fa-google" (click)="socialSignIn('google')"></i></a>
          <a><i class="fab fa-linkedin-in" (click)="socialSignIn('linkedin')"></i></a>
        </div> -->

        <div class="col other-links">
          <p style="font-size:13px;margin-top:10px;">New to shenzyn? Please <a id="reg_txt"
              routerLink="/partners-signup">Register</a></p>
        </div>
      </div>
    </div>
  </div>
</div>