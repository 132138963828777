import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers, Response } from "@angular/http";
import { AuthServices } from '../../services/auth.service';

@Component({
  selector: 'partners-login',
  templateUrl: './partners-login.component.html',
  styleUrls: ['./partners-login.component.css']
})
export class PartnersLoginComponent implements OnInit {
  loginForm: FormGroup;
  userData: any;
  submitted = false;
  erroMsg: string;
  loading: boolean;
  disableLogin: boolean = false;
  isPartnerAdmin:string = "isPartnerAdmin";
  isPartner:string = "true";

  constructor(private ngZone: NgZone, private route: ActivatedRoute, private http: Http, private fb: FormBuilder, private authService: AuthServices, private router: Router) {
    this.loginForm = fb.group({
      'emailId': [null, Validators.required],
      'password': [null, Validators.required],
    });
  }
  get f() { return this.loginForm.controls; }


  ngOnInit() {
    $(".toggle-password").click(function() {

      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  login(formdata: any) {
    this.submitted = true;
    if ((formdata.emailId !== null) && (formdata.password !== null)) {
      this.loading = true;
      this.authService.Partnerlogin(formdata).subscribe(
        data => {
          console.log(data,' its data');
          
          this.userData = (data);
          if ((this.userData.responseStatus === 200)) {
            localStorage.setItem("authtoken", JSON.stringify(data.authtoken));
            localStorage.setItem("emailId", JSON.stringify(data.emailId));
            localStorage.setItem("name", JSON.stringify(data.name));
            localStorage.setItem("companyName",JSON.stringify(data.companyName));
            localStorage.setItem("isPartner",data.isPartner);
            localStorage.setItem("contactNo",data.contactNo);
            // localStorage.setItem(this.isPartnerAdmin,this.isPartner);
            if(data.isPartner && data.isPartner == true)
            {
              this.router.navigate(['partners-admin'])
            }
            else{
              localStorage.setItem("partnerEmail",JSON.stringify(data.partnerEmail))
              this.router.navigate(['partners-admin'])
            }
            
          }
          else if (data.responseStatus === 201) {
            this.loading = false;
            this.erroMsg = data.message;
          }
        },
        error => {
         if (error.responseStatus === 401) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 500) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 400) { 
            this.loading = false;
            this.erroMsg = error.message; 
          }
        },
        () => console.log()
      );
    }
  } 

}
