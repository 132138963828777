import { Http, Headers, Response } from "@angular/http";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { PlatformLocation } from "@angular/common";
import { environment } from "../../environments/environment";
import { AuthServices } from "./auth.service";
import { Router } from "@angular/router";
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { from } from 'rxjs';


@Injectable()
export class JobseekerService {
  name: string;
  token: string;
  email: string;
  Bearer: any = "Bearer ";
  public redirectUrl: string;
  constructor(private http: Http, private authService: AuthServices, private router: Router) {
  }
  // jobseeker personal details update 
  registration(formdata) {
    {
      this.name = localStorage.getItem('name').replace(/['"]+/g, '')
      let data = {
        "name": this.name,
        "profilePicture": formdata.profilePicture,
        "coverPage": formdata.coverPage,
        "DOB": formdata.dob,
        "nationality": formdata.nationality,
        "currentLocation": formdata.currentLocation,
        "contactNo": formdata.contactNo,
        "coverLetter": formdata.coverLetter,
        "videoCv": formdata.videoCv,
        "resume": formdata.resume,
        "workExperiences": [
          {
            "currentDesignation": formdata.currentDesignation,
            "currentCompany": formdata.currentCompany,
            "currentCtc": formdata.currentCtc,
            "joiningDate": formdata.joiningDate,
            "lastDate": formdata.lastDate,
            "employmentDesignation": formdata.employmentDesignation,
            "noticePeriod": formdata.noticePeriod,
            "servingNoticePeriod": formdata.servingNoticePeriod
          }
        ],
        "educationDetails": [
          {
            "highestQualification": formdata.highestQualification,
            "degree": formdata.degree,
            "specialization": formdata.specialization,
            "institute": formdata.institute,
            "educationType": formdata.educationType,
            "passingYear": formdata.passingYear
          }
        ],
        "certifications": [{
          "certificationName": formdata.certificationName,
          "certificationBody": formdata.certificationBody
        }
        ],
        "projects": [
          {
            "projectTitle": formdata.projectTitle,
            "projectDescription": formdata.projectDescription,
            "skillsUtilized": [formdata.skillsUtilized]
          }
        ],
        "technicalSkills": [{
          "skillName": formdata.skillName,
          "skillLevel": formdata.skillLevel
        }],
        "languagesKnown": [{
          "language": formdata.language,
          "proficiency": formdata.proficiency
        }],
        "publications": [{
          "publicationTitle": formdata.publicationTitle,
          "publicationLink": formdata.publicationLink
        }],
        "patents": [{
          "patentTitle": formdata.patentTitle,
          "patentIssuedAuthority": formdata.patentIssuedAuthority
        }],
        "preferredLocation": [formdata.preferredLocation],
        "preferredCompanies": [
          {
            "companySize": formdata.companySize,
            "companyName": formdata.companyName,
            "desiredCTC": formdata.desiredCTC,
            "benefits": [formdata.benefits

            ]
          }
        ],
        "briefBio": formdata.briefBio,
        "savedJobs": [formdata.savedJobs],
        "appliedJobs": [formdata.appliedJobs]
      }
      this.email = localStorage.getItem('emailId') 
      var url = environment.endPointApi + "jobseekers/updatepersonaldetails/"+this.email.replace(/['"]+/g, '')
      var headers = new Headers();
      this.token = localStorage.getItem('authtoken')
      headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
      headers.append('Content-Type', 'application/json');
      var body = formdata;
      this.http.put(url, body, { headers: headers }).subscribe(
        (r) => {
          var data = r.json();
          Swal({
            title: 'Success',
            html: data.message,
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#1976d2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK!',
          }).then((confirm) => {
            if (confirm) {
              this.router.navigateByUrl('/job-dashboard');
            }
          })
        },
        (e) => {
          var data = e.json();
          Swal({
            title: 'Unsuccessfull',
            html: data.message,
            type: 'error',
            showCancelButton: false,
            confirmButtonColor: '#1976d2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK!',
          }).then((confirm) => {
            if (confirm) {
              this.router.navigateByUrl('/user-profile');
            }
          });
        }
      );
    }
  }
  public userDetails(formdata) {
      this.email = localStorage.getItem('emailId') ;
      this.token = localStorage.getItem('authtoken');
      let data = {
        "name": this.name,
        "profilePicture": formdata.profilePicture,
        "coverPage": formdata.coverPage,
        "DOB": formdata.dob,
        "nationality": formdata.nationality,
        "currentLocation": formdata.currentLocation,
        "contactNo": formdata.contactNo,
        "coverLetter": formdata.coverLetter,
        "videoCv": formdata.videoCv,
        "resume": formdata.resume,
        "workExperiences": [
          {
            "currentDesignation": formdata.currentDesignation,
            "currentCompany": formdata.currentCompany,
            "currentCtc": formdata.currentCtc,
            "joiningDate": formdata.joiningDate,
            "lastDate": formdata.lastDate,
            "employmentDesignation": formdata.employmentDesignation,
            "noticePeriod": formdata.noticePeriod,
            "servingNoticePeriod": formdata.servingNoticePeriod
          }
        ],
        "educationDetails": [
          {
            "highestQualification": formdata.highestQualification,
            "degree": formdata.degree,
            "specialization": formdata.specialization,
            "institute": formdata.institute,
            "educationType": formdata.educationType,
            "passingYear": formdata.passingYear
          }
        ],
        "certifications": [{
          "certificationName": formdata.certificationName,
          "certificationBody": formdata.certificationBody
        }
        ],
        "projects": [
          {
            "projectTitle": formdata.projectTitle,
            "projectDescription": formdata.projectDescription,
            "skillsUtilized": [formdata.skillsUtilized]
          }
        ],
        "technicalSkills": [{
          "skillName": formdata.skillName,
          "skillLevel": formdata.skillLevel
        }],
        "languagesKnown": [{
          "language": formdata.language,
          "proficiency": formdata.proficiency
        }],
        "publications": [{
          "publicationTitle": formdata.publicationTitle,
          "publicationLink": formdata.publicationLink
        }],
        "patents": [{
          "patentTitle": formdata.patentTitle,
          "patentIssuedAuthority": formdata.patentIssuedAuthority
        }],
        "preferredLocation": [formdata.preferredLocation],
        "preferredCompanies": [
          {
            "companySize": formdata.companySize,
            "companyName": formdata.companyName,
            "desiredCTC": formdata.desiredCTC,
            "benefits": [formdata.benefits

            ]
          }
        ],
        "briefBio": formdata.briefBio,
        "savedJobs": [formdata.savedJobs],
        "appliedJobs": [formdata.appliedJobs]
      }
      var headers = new Headers();
      headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
      headers.append('Content-Type', 'application/json');
      var body = formdata
      return this.http.put(environment.endPointApi + 'jobseekers/updatepersonaldetails/'+this.email.replace(/['"]+/g, ''), body,{headers:headers}).map((response: Response) => {
        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
        } else {
          const user = response.json();
          user.responseStatus = response.status;
          return user;
        } 
      }).catch((error) => {
        if (error.status === 500) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 400) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 401) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        }
    });
    } 
  // update basic details
  public basicDetails(formdata) {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    let data = {
      "dateofbirth": formdata.DOB,
      "contactNo": formdata.contactNo,
      "currentLocation": formdata.currentLocation,
      "nationality": formdata.nationality
    }
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'jobseekers/updatebasicdetails/'+this.email.replace(/['"]+/g, ''), data,{headers:headers}).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  } 
  // update achievements
  public achievements(formdata) {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'jobseekers/updateachievements/'+this.email.replace(/['"]+/g, ''), formdata,{headers:headers}).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  // update company preferences
  public companypreferences(formdata) {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'jobseekers/updatecompanypreferences/'+this.email.replace(/['"]+/g, ''), formdata,{headers:headers}).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  // update educational details
  public educationalDetails(formdata) {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'jobseekers/updateeducationaldetails/'+this.email.replace(/['"]+/g, ''), formdata,{headers:headers}).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  // update skills
  public updateskills(formdata) {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'jobseekers/updateskills/'+this.email.replace(/['"]+/g, ''), formdata,{headers:headers}).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  // update work experiences
  public workExperiences(formdata) {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'jobseekers/updateworkexperiences/'+this.email.replace(/['"]+/g, ''), formdata,{ headers: headers }).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  postFile(fileToUpload: File) {
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(environment.endPointApi + 'jobseekers/resumeparser', formData, {headers: headers }).map((response: Response) => { 
        if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
            this.redirectUrl = null;
        } else {
            const user = response.json();
            user.responseStatus = response.status;
            return user;
        }
      }).catch((error) => {
            if (error.status === 500) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err); 
            } else if (error.status === 400) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
            } else if (error.status === 401) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
            }
      });
  }
          
  //without login resume upload begin
  resumeUpload(fileToUpload: File) {
      var headers = new Headers();
      //  headers.append('Content-Type', 'application/json');
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      return this.http.post(environment.endPointApi + 'jobseekers/resumeparserprelogin', formData, {headers: headers }).map((response: Response) => { 
      if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
      } else {
          const user = response.json();
          user.responseStatus = response.status;
          return user;
      }
      }).catch((error) => {
          if (error.status === 500) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
            } else if (error.status === 400) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
            } else if (error.status === 401) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
            }
      });
  }


//upload profile for shenzyn job
uploadShenzynJob(fileToUpload: File, jobtitle) {
  var headers = new Headers();
  //  headers.append('Content-Type', 'application/json');
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload);
  return this.http.post(environment.endPointApi + 'jobseekers/resumeuloadshenzynjob/'+jobtitle, formData, {headers: headers }).map((response: Response) => { 
  if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
  } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
  }
  }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
        } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
        } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
        }
  });
}


     //without login resume upload end
    // upload profilePic
    profilePic(fileToUpload: File) {
      this.token = localStorage.getItem('authtoken')
        var headers = new Headers();
        headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
      const formData: FormData = new FormData();
      formData.append('image', fileToUpload, fileToUpload.name);
      return this.http
        .post(environment.endPointApi + 'jobseekers/gap', formData, {headers: headers }).map((response: Response) => { 
          if (this.redirectUrl) {
                  this.router.navigate([this.redirectUrl]);
                  this.redirectUrl = null;
                } else {
                  const user = response.json();
                  user.responseStatus = response.status;
                  return user;
                } 
              })
              .catch((error) => {
                if (error.status === 500) {
                  const err = error.json();
                  err.responseStatus = error.status;
                  return Observable.throw(err); 
                } else if (error.status === 400) {
                  const err = error.json();
                  err.responseStatus = error.status;
                  return Observable.throw(err);
                } else if (error.status === 401) {
                  const err = error.json();
                  err.responseStatus = error.status;
                  return Observable.throw(err);
                }
              });
            }
  // get user profile
  public userProfile() {
    this.email = localStorage.getItem('emailId')
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.get(environment.endPointApi + 'jobseekers/profile/'+this.email.replace(/['"]+/g, ''),
    { headers: headers } ).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
}
// apply job
public applyjob(id) {
  this.token = localStorage.getItem('authtoken')
  let data = {
    "id": id
  }
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi + 'jobseekers/applyjob', data,{headers:headers}).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// save job
public savejob(id) {
  this.token = localStorage.getItem('authtoken')
  let data = {
    "id": id
  }
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi + 'jobseekers/savejob', data,{headers:headers}).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
} 
  // get Count
  public getCount() {
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken');
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.get(environment.endPointApi + 'jobseekers/getsavedandappliedjobscount',
    { headers: headers } ).map((response: Response) => {
      const userJobsCount = response.json();
      userJobsCount.responseStatus = response.status;
      return userJobsCount;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  //  upload cover image api
  coverImage (fileToUpload: File) {
    this.token = localStorage.getItem('authtoken')
      var headers = new Headers();
      headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http
      .post(environment.endPointApi + 'jobseekers/updatecoverphoto', formData, {headers: headers }).map((response: Response) => { 
        if (this.redirectUrl) {
                this.router.navigate([this.redirectUrl]);
                this.redirectUrl = null;
              } else {
                const user = response.json();
                user.responseStatus = response.status;
                return user;
              } 
            })
            .catch((error) => {
              if (error.status === 500) {
                const err = error.json();
                err.responseStatus = error.status;
                return Observable.throw(err); 
              } else if (error.status === 400) {
                const err = error.json();
                err.responseStatus = error.status;
                return Observable.throw(err);
              } else if (error.status === 401) {
                const err = error.json();
                err.responseStatus = error.status;
                return Observable.throw(err);
              }
            });
          }
          //Get Basic Details
  public getBasicDetails() {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization':this.Bearer + this.token.replace(/['"]+/g, '')
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new Headers(headerDict), 
    };
    return this.http.get(environment.endPointApi + 'jobseekers/getbasicdetails',requestOptions).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  } 
  //Get Company Preferences
  public getPreferences() {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization':this.Bearer + this.token.replace(/['"]+/g, '')
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new Headers(headerDict), 
    };
    return this.http.get(environment.endPointApi + 'jobseekers/getCompanyPreferences',requestOptions).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  } 
  //Get Work experience Details
  public getWorkExperienceDetails() {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization':this.Bearer + this.token.replace(/['"]+/g, '')
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new Headers(headerDict), 
    };
    return this.http.get(environment.endPointApi + 'jobseekers/getworkexperiences',requestOptions).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  } 
  getEducationDetails(){
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization':this.Bearer + this.token.replace(/['"]+/g, '')
    }
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new Headers(headerDict), 
    };
    return this.http.get(environment.endPointApi + 'jobseekers/geteducationaldetails',requestOptions).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  getAchievementDetails(){
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization':this.Bearer + this.token.replace(/['"]+/g, '')
    }
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new Headers(headerDict), 
    };
    return this.http.get(environment.endPointApi + 'jobseekers/getachievements',requestOptions).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }  
  getSkillDetails(){
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization':this.Bearer + this.token.replace(/['"]+/g, '')
    }
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new Headers(headerDict), 
    };
    return this.http.get(environment.endPointApi + 'jobseekers/getskills',requestOptions).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
// applied jobs
public appliedJobs() {
  const emailId = localStorage.getItem('emailId');
  var headers = new Headers();
  this.token = localStorage.getItem('authtoken');
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'jobseekers/getappliedjobs/'+emailId.replace(/['"]+/g, ''),  
  { headers: headers } ).map((response: Response) => {
    const userJobsCount = response.json();
    userJobsCount.responseStatus = response.status;
    return userJobsCount;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// saved jobs
public savedJobs() {
  const emailId = localStorage.getItem('emailId')
  var headers = new Headers();
  this.token = localStorage.getItem('authtoken')
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'jobseekers/getsavedjobs/'+emailId.replace(/['"]+/g, ''),  
  { headers: headers } ).map((response: Response) => {
    const userJobsCount = response.json();
    userJobsCount.responseStatus = response.status;
    return userJobsCount;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// get count getrecommendationcount
public getRecommendationCount() {
  var headers = new Headers();
  this.token = localStorage.getItem('authtoken')
  this.email = localStorage.getItem('emailId')
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'recommendation/getrecommendationcount/'+this.email.replace(/['"]+/g, ''),
  { headers: headers } ).map((response: Response) => {
    const userJobsCount = response.json();
    userJobsCount.responseStatus = response.status;
    return userJobsCount;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
 // get count getrecommendation data
public getrecommendationdata() {
  var headers = new Headers();
  this.token = localStorage.getItem('authtoken')
  this.email = localStorage.getItem('emailId')
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'recommendation/getrecommendationdata/'+this.email.replace(/['"]+/g, ''),
  { headers: headers } ).map((response: Response) => {
    const userJobsCount = response.json();
    userJobsCount.responseStatus = response.status;
    return userJobsCount;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

// jobseeker campaign registration
public campaignRegister(formdata) {
  return this.http.post(environment.endPointApi + 'jobseekers/campaignregister', formdata).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}




}