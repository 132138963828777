import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRouteSnapshot} from '@angular/router';
import { PaymentServices } from '../../services/payment.operations';


@Component({
  selector: 'app-partners-pymt-callback',
  templateUrl: './partners-pymt-callback.component.html',
  styleUrls: ['./partners-pymt-callback.component.css']
})
export class PartnersPaymentCallbackComponent implements OnInit {
  companyName: any;
  lastPayment: any ="NA";
  expiryDate: any = "NA";
  planAvailed: any = "NA";
  planStatus: any = "NA";

  lasttxn = {
    "orderid":"NA",
		"txstatus" : "NA",
		"status" : "NA",
		"refid" : "NA",
		"txmsg" : "NA",
		"ordamt" : "NA"
	}

  constructor(private router: Router, private PaymentService: PaymentServices ) { }

  ngOnInit() {
    this.companyName = localStorage.getItem('companyName');
    this.getSubscriptionDetails();
    this.getLastTransactionDetails();
  }


  

  getSubscriptionDetails(){
    this.PaymentService.getPartnerSubscriptionDetails().subscribe(
      data => {
        if(data.orderStatus == 'COMPLETED')
        {
        this.lastPayment = data.subscriptionDate;
        this.expiryDate =  data.subscriptionExpiryDate;
        this.planAvailed = data.subscriptionTerminMonths + " months subscription.";
        this.planStatus = (new Date(this.expiryDate) > new Date())? "Active" : "Inactive";
        }
        
        //  this.lasttxn.txstatus = data.txStatus;
        //  this.lasttxn.orderid = data.orderId;
        //  this.lasttxn.status = data.orderStatus;
        //  this.lasttxn.refid = data.referenceId;
        //  this.lasttxn.txmsg = data.txMsg;
        //  this.lasttxn.ordamt = data.orderAmount;
      },
      error => {
        if (error.responseStatus === 401) {
        }
        else if (error.responseStatus === 500) {
        } 
        else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );
  }
  getLastTransactionDetails(){
    this.PaymentService.getPartnerLastTxnDetails().subscribe(
      data => {
         this.lasttxn.txstatus = data.txStatus;
         this.lasttxn.orderid = data.orderId;
         this.lasttxn.status = data.orderStatus;
         this.lasttxn.refid = data.referenceId;
         this.lasttxn.txmsg = data.txMsg;
         this.lasttxn.ordamt = data.orderAmount;
      },
      error => {
        if (error.responseStatus === 401) {
        }
        else if (error.responseStatus === 500) {
        } 
        else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );
  }


  onLogout() {
    localStorage.clear()
    this.router.navigate(['/partners-login']);
  }

}
