<a id="return-to-top" class="return_top3"><i class="fa fa-angle-double-up"></i></a>
<nav class="cd-dropdown cd_dropdown_index2 cd_dropdown_index3 d-block d-sm-block d-md-block d-lg-none d-xl-none"
    style="margin-top: 120px;">
    <br /> <br /> <br />
    <a href="#0" class="cd-close">Close</a>
    <ul class="cd-dropdown-content">

        <!-- <li><a routerLink="/login">login</a></li> -->
        <li><a href="https://jobs.shenzyn.com/#/user/login">login</a></li>
        <li><a href="https://employer.shenzyn.com/#/user/login">employer</a></li>
        <!-- <li><a href="javascript:void(0);">employer</a></li> -->
    </ul>
</nav>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
    <div class="cp_logo_wrapper index_2_logo">
        <a routerLink="/homenew">
            <img src="assets/sheImages/logo8.svg"  alt="logo" style="width:110%;height:auto;"> 
        </a>
    </div>
</div>
<div class="container">
    <div class="row" >
        <div class="ffht">
            <div class="registration_1">
                <div class="clear1"></div>
            </div>
            <div class="faq_holder" ><a name="h1" id="h1"></a>
                <div class="sort_searchmid1 sa_head">Privacy Policy </div>
                <p style="text-align:center;margin-top:70px;" >This Application collects some Personal Data from its Users.</p>
                <hr  style="margin-left:auto !important; margin-right:auto !important; border-color: #57D38C;"  >
                <div class="clear"></div>
             </div>
            <div class="faq_container" >
                    <div class="sub_head_new"><span></span>Owner and Data Controller</div> 
                     <p style="margin-top: -1px;"><a href="//www.shenzyn.com"><u>www.shenzyn.com</u></a></p>
                     <p style="margin-top: -20px;">#1192, 2nd Sector, HSR Layout </p>
                     <p style="margin-top: -20px;">Bangalore, 560102 India.</p>
                     <p style="margin-top: -20px;">Owner contact email:  <a href="mailto: wehearyou@shenzyn.com"> wehearyou@shenzyn.com</a> </p>
                     
                <div class="sub_head_new"><span></span>Types of Data collected</div>
                <p>When you visit/surf our website, certain personal information about you such as your IP Address, etc.
                    may be automatically stored with us. However, if you choose to avail of certain services on our
                    website, you shall be required to provide certain personal information for the registration and/or
                    access to such services/web pages. Such information may include, without limitation, your name,
                    email address, contact address, mobile/telephone number(s), sex, age, occupation, interests, credit
                    card information, billing information, financial information, content, IP address, standard web log
                    information, information about your computer hardware and software and such other information as may
                    be required for your interaction with the services and from which your identity is discernible. We
                    may also collect demographic information that is not unique to you such as code, preferences,
                    favorites, etc. Further, sometimes you may be asked to provide descriptive, cultural, preferential
                    and social &amp; life style information.</p>
                    <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: first name; last name; date of birth; phone number; profession; address; country; state; email address; ZIP/Postal code; city; Calendar permission; Camera permission; Microphone permission; Social media accounts permission; Photo Library permission; geographic position.</p>
                    <p> Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection. 
                            Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application. </p>
                            <p>Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service. </p>
                <div class="sub_head_new"><span></span>Cookies and Other Tracking Technologies </div>
                <p>Some of our Web pages utilize "cookies" and other tracking technologies. A "cookie" is a small text
                    file that may be used, for example, to collect information about Web site activity. Some cookies and
                    other technologies may serve to recall Personal Information previously indicated by a Web user. Most
                    browsers allow you to control cookies, including whether or not to accept them and how to remove
                    them.</p>
                <p>You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies
                    with your browser, but please note that if you choose to erase or block your cookies, you will need
                    to re-enter your original user ID and password to gain access to certain parts of the Web site and
                    App.</p>
                <p>Tracking technologies may record information such as Internet domain and host names; Internet
                    protocol (IP) addresses; browser software and operating system types; clickstream patterns; and
                    dates and times that our site is accessed. Our use of cookies and other tracking technologies allows
                    us to improve our Web site and your Web experience. We may also analyze information that does not
                    contain Personal Information for trends and statistics.</p>
                <div class="sub_head_new"><span></span> <b>Mode and place of processing the Data</b> </div>
                <div class="sub_head_new"><span></span>Methods of processing </div>
                <p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data. </p>
                <p>Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time. </p>
                <div class="sub_head_new"><span></span>Legal basis of processing  </div>
                <p>The Owner may process Personal Data relating to Users if one of the following applies: </p>
                <p>• Users have given their consent for one or more specific purposes. Note: Under some legislations, the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law; </p>
                <p>• provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof; </p>
                <p>• processing is necessary for compliance with a legal obligation to which the Owner is subject; </p>
                <p>• processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner; </p>
                <p>•processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party. </p>
            <p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract. </p>

            <div class="sub_head_new"><span></span>Place  </div>
            <p>The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located. </p>

            <p>Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data. </p>
            <p>Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data. </p>
            
            <p>If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section. </p>
            
            <div class="sub_head_new"><span></span>Device permissions for Personal Data access  </div> 
            <p> Depending on the User's specific device, this Application may request certain permissions that allow it to access the User's device Data as described below. </p>
            <p>By default, these permissions must be granted by the User before the respective information can be accessed. Once the permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings or contact the Owner for support at the contact details provided in the present document. </p>
            <p>The exact procedure for controlling app permissions may be dependent on the User's device and software. </p>
            <p>Please note that the revoking of such permissions might impact the proper functioning of this Application. </p>
            <p>If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by this Application. </p>

            <div class="sub_head_new"><span></span>Calendar permission</div>
            <p>Used for accessing the calendar on the User's device, including the reading, adding and removing of entries. </p>

            <div class="sub_head_new"><span></span>Camera permission</div>
            <p>Used for accessing the camera or capturing images and video from the device. </p>

            <div class="sub_head_new"><span></span>Microphone permission</div>
            <p>Allows accessing and recording microphone audio from the User's device. </p>

            <div class="sub_head_new"><span></span>Photo Library permission</div>
            <p>Allows access to the User's Photo Library. </p>

            <div class="sub_head_new"><span></span>Social media accounts permission</div>
            <p>Used for accessing the User's social media account profiles, such as Facebook and Twitter. </p>
            
<div class="sub_head_new"><span></span> Detailed information on the processing of Personal Data </div>
        <p> Personal Data is collected for the following purposes and using the following services: </p>
          <div class="sub_head_new"><span></span>Contacting the User</div>
        <p>Contact form (this Application) </p>
        <p>By filling in the contact form with their Data, the User authorizes this Application to use these details to
            reply to requests for information, quotes or any other kind of request as indicated by the form’s header.
        </p>
        <p>Personal Data collected: address; city; country; date of birth; email address; first name; last name; phone
            number; profession; state; ZIP/Postal code. </p>
          <div class="sub_head_new"><span></span>Device permissions for Personal Data access</div>
        <p>This Application requests certain permissions from Users that allow it to access the User's device Data as
            described below. </p>
        <p>Device permissions for Personal Data access (this Application) </p>
                <p>This Application requests certain permissions from Users that allow it to access the User's device
                    Data as summarized here and described within this document.</p>
                  <p>  Personal Data collected: Calendar permission; Camera permission; Microphone permission; Photo
                    Library permission; Social media accounts permission. </p>
                  <div class="sub_head_new"><span></span>Location-based interactions</div>
                <div class="sub_head_new"><span></span>Geolocation (this Application) </div>
            <p>This Application may collect, use, and share User location Data in order to provide location-based
            services. </p>
        <p>Most browsers and devices provide tools to opt-out from this feature by default. If explicit authorization
            has been provided, the User’s location data may be tracked by this Application.
            Personal Data collected: geographic position. </p>
         <div class="sub_head_new"><span></span> The rights of Users </div>
        <p> Users may exercise certain rights regarding their Data processed by the Owner.
            In particular, Users have the right to do the following: </p>
         <p>Withdraw their consent at any time. Users have the right to withdraw consent where they have previously
            given their consent to the processing of their Personal Data. </p>
         <p>Object to processing of their Data. Users have the right to object to the processing of their Data if the
            processing is carried out on a legal basis other than consent. Further details are provided in the dedicated
            section below. </p>
         <p>Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure
            regarding certain aspects of the processing and obtain a copy of the Data undergoing processing. </p>
         <p>Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to
            be updated or corrected. </p>
         <p>Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the
            processing of their Data. In this case, the Owner will not process their Data for any purpose other than
            storing it. </p>
         <p>Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances,
            to obtain the erasure of their Data from the Owner. </p>
         <p>Receive their Data and have it transferred to another controller. Users have the right to receive their
            Data in a structured, commonly used and machine-readable format and, if technically feasible, to have it
            transmitted to another controller without any hindrance. This provision is applicable provided that the Data
            is processed by automated means and that the processing is based on the User's consent, on a contract which
            the User is part of or on pre-contractual obligations thereof. </p>
         <p>Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.
        </p>

         <div class="sub_head_new"><span></span> Details about the right to object to processing </div>
        <p> Where Personal Data is processed for a public interest, in the exercise of an official authority vested in
            the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such
            processing by providing a ground related to their particular situation to justify the objection. </p>
        <p>Users must know that, however, should their Personal Data be processed for direct marketing purposes, they
            can object to that processing at any time without providing any justification. To learn, whether the Owner
            is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this
            document. </p>
         <div class="sub_head_new"><span></span> How to exercise these rights </div>
        <p>Any requests to exercise User rights can be directed to the Owner through the contact details provided in
            this document. These requests can be exercised free of charge and will be addressed by the Owner as early as
            possible and always within one month. </p>

         <div class="sub_head_new"><span></span> Additional information about Data collection and processing </div>
        <p> Legal action</p>
        <p>The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to
            possible legal action arising from improper use of this Application or the related Services.
            The User declares to be aware that the Owner may be required to reveal personal data upon request of public
            authorities. </p>

        <p>Additional information about User's Personal Data</p>
        <p>In addition to the information contained in this privacy policy, this Application may provide the User with
            additional and contextual information concerning particular Services or the collection and processing of
            Personal Data upon request. </p>
        <p>System logs and maintenance</p>
        <p>For operation and maintenance purposes, this Application and any third-party services may collect files that
            record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for
            this purpose. </p>
        <p>Information not contained in this policy</p>
        <p>More details concerning the collection or processing of Personal Data may be requested from the Owner at any
            time. Please see the contact information at the beginning of this document. </p>
        <p>How “Do Not Track” requests are handled</p>
        <p>This Application does not support “Do Not Track” requests.
            To determine whether any of the third-party services it uses honour the “Do Not Track” requests, please read
            their privacy policies. </p>
        <p>Changes to this privacy policy</p>
        <p>The Owner reserves the right to make changes to this privacy policy at any time by giving notice to its Users
            on this page and possibly within this Application and/or - as far as technically and legally feasible -
            sending a notice to Users via any contact information available to the Owner. It is strongly recommended to
            check this page often, referring to the date of the last modification listed at the bottom. </p>

        <p>Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall
            collect new consent from the User, where required. </p>

         <div class="sub_head_new"><span></span> Definitions and legal references </div>
        <p> Personal Data (or Data) </p>
        <p>Any information that directly, indirectly, or in connection with other information — including a personal
            identification number — allows for the identification or identifiability of a natural person. </p>
        <p>Usage Data</p>
        <p>Information collected automatically through this Application (or third-party services employed in this
            Application), which can include: the IP addresses or domain names of the computers utilized by the Users who
            use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method
            utilized to submit the request to the server, the size of the file received in response, the numerical code
            indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the
            features of the browser and the operating system utilized by the User, the various time details per visit
            (e.g., the time spent on each page within the Application) and the details about the path followed within
            the Application with special reference to the sequence of pages visited, and other parameters about the
            device operating system and/or the User's IT environment. </p>
        <p>User</p>
        <p>The individual using this Application who, unless otherwise specified, coincides with the Data Subject. </p>
        <p>Data Subject</p>
        <p>The natural person to whom the Personal Data refers. </p>
        <p>Data Processor (or Data Supervisor) </p>
        <p>The natural or legal person, public authority, agency or other bodies which processes Personal Data on behalf
            of the Controller, as described in this privacy policy. </p>
        <p>Data Controller (or Owner) </p>
        <p>The natural or legal person, public authority, agency or other bodies which, alone or jointly with others,
            determines the purposes and means of the processing of Personal Data, including the security measures
            concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is
            the Owner of this Application. </p>
        <p>This Application</p>
        <p>The means by which the Personal Data of the User is collected and processed. </p>
        <p>Service</p>
        <p>The service provided by this Application as described in the relative terms (if available) and on this
            site/application. </p>
        <p>European Union (or EU) </p>
        <p>Unless otherwise specified, all references made within this document to the European Union include all
            current member states to the European Union and the European Economic Area. </p>

          <div class="sub_head_new"><span></span>Legal information</div>
        <p>This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of
        Regulation (EU) 2016/679 (General Data Protection Regulation).
        This privacy policy relates solely to this Application, if not stated otherwise within this document.
    </p>
                <div class="static_txt">
                    <div class="sub_head_new"><span></span>Changing Your Personal Information</div>
                    <p>You can access and modify your personal information by signing on to the website. We will not
                        modify the
                        information provided by you. However, we recommend that you update your personal information as
                        soon as
                        such changes are necessitated.</p>
                    <p>Where you make a public posting, you may not be able to change or remove it nor shall you be able
                        to close your account. Upon your request, we will close your account and remove your personal
                        information from view as soon as reasonably possible, based on your account activity and in
                        accordance
                        with applicable law(s). However, we will retain your personal information from closed accounts
                        to comply
                        with law, Avoid/check illegal and/or potentially prohibited activities and to enforce User
                        Agreements;
                        Comply with any court judgment / decree / order / directive / legal &amp; government authority
                        /applicable law;
                        Investigate potential violations or applicable national &amp; international laws; Investigate
                        deliberate
                        damage to the website/services or its legitimate operation; Detect, prevent, or otherwise
                        address
                        security and/or technical issues; Protect the rights, property or safety of HTML and/or its
                        Directors,
                        employees and the general public at large; Respond to Claims of third parties; and take such
                        other actions
                        as may be permitted by law. </p>
                    <div class="sub_head_new"><span></span>Account Protection</div>
                    <p>Your password is the key to your account. You shall be solely responsible for all the activities
                        happening under your username and you shall be solely responsible for keeping your password
                        secure. Do not
                        disclose your password to anyone. If you share your password or your personal information with
                        others,
                        you shall be solely responsible for all actions taken under your username and you may lose
                        substantial
                        control over your personal information and may be subject to legally binding actions taken on
                        your behalf.
                        Therefore, if your password has been compromised for any reason, you should immediately change
                        your password.
                    </p>
                    <div class="sub_head_new"><span></span>Business Transaction:</div>
                    <p>In the event HTML goes through a business transition, such as a merger, acquisition by another
                        company,
                        or sale of all or a portion of its assets, your personally identifiable information will likely
                        be among
                        the assets transferred. Where your information is transferred you will be notified via
                        email/prominent
                        notice on our website for 30 days of any such change in ownership or control of your personal
                        information.</p>
                    <div class="sub_head_new"><span></span>Security:</div>
                    <p>The security of your personal information is important to us. When you enter your personal
                        information
                        we treat the data as an asset that must be protected and use tools (encryption, passwords,
                        physical security,
                        etc.) to protect your personal information against unauthorized access and disclosure. However,
                        no method of transmission over the Internet, or method of electronic storage, is 100% secure,
                        therefore,
                        while we strive to use commercially acceptable means to protect your personal information,
                        we cannot guarantee its absolute security nor can we guarantee that third parties shall
                        not unlawfully intercept or access transmissions or private communications, and that other
                        users may abuse or misuse your personal information that you provide. Therefore, although we
                        work hard to protect your information, we do not promise, and you should not expect, that your
                        personal information or private communications will always remain private. </p>
                    <div class="sub_head_new"><span></span>Links to Other Sites:</div>
                    <p>The website contains links to other sites that are not owned or controlled by HT Media Limited.
                        Please be aware that we, HT Media Limited and/or the website, are not responsible for the
                        privacy practices of such other sites.</p>
                    <p>We encourage you to be aware when you leave our site and to read the privacy statements of
                        each and every Web site that collects personally identifiable information.</p>
                    <p>This privacy statement applies only to information collected by the website or to our other
                        related websites provided it appears at the footer of the page therein. It does not apply to
                        third
                        party advertisements which appear on our websites and we suggest you read the privacy statements
                        of
                        such advertisers. </p>
                    <div class="sub_head_new"><span></span>Changes in this Privacy Statement</div>
                    <p>If we decide to change our privacy policy, we will post those changes to this privacy statement
                        and such other places we deem appropriate so that you are updated about the manner we collect
                        information,
                        what information we collect, how we use it and under what circumstances, if any, we disclose it.
                    </p>
                    <div class="sub_head_new"><span></span>Contact Us</div>
                    <p>If you have any questions or suggestions regarding our privacy policy,
                        please contact us at:<a href="mailto:wehearyou@shenzyn.com">wehearyou@shenzyn.com</a></p>
                        <hr  style="margin-left:37% !important; margin-right:45% !important; border-color: #57D38C;"  width="20%">
                    </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</div>
    <app-footer ></app-footer>
      