import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
var SignupEmployerComponent = /** @class */ (function () {
    function SignupEmployerComponent(authService, router, formBuilder, HttpClient) {
        this.authService = authService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.HttpClient = HttpClient;
        this.error_message = null;
        this.errorFromApi = false;
        this.formInvalid = false;
        this.formErrors = false;
        this.passwordMismatch = false;
        this.submitted = false;
        this.mobilePatern = false;
    }
    SignupEmployerComponent.prototype.ngOnInit = function () {
        document.location.href = "https://employer.shenzyn.com/#/user/sign-up";
        // this.formvalidation();
        // $(".toggle-password").click(function() {
        //   $(this).toggleClass("fa-eye fa-eye-slash");
        //   var input = $($(this).attr("toggle"));
        //   if (input.attr("type") == "password") {
        //     input.attr("type", "text");
        //   } else {
        //     input.attr("type", "password");
        //   }
        // });
        // $(".toggle-password1").click(function() {
        //   $(this).toggleClass("fa-eye fa-eye-slash");
        //   var input = $($(this).attr("toggle"));
        //   if (input.attr("type") == "password") {
        //     input.attr("type", "text");
        //   } else {
        //     input.attr("type", "password");
        //   }
        // });
    };
    SignupEmployerComponent.prototype.onWindowScroll = function (e) {
        if (window.pageYOffset > 50) {
            var element = document.getElementById('header_data');
            element.style.background = "rgba(255,255,255,0.9)";
            element.style.position = "fixed";
            element.style.marginTop = "-2px";
            element.style.transitionDuration = "0.6s";
            element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
        }
        else {
            var element = document.getElementById('header_data');
            element.style.background = "";
            element.style.position = "";
            element.style.marginTop = "";
            element.style.boxShadow = "";
        }
    };
    // form validation
    SignupEmployerComponent.prototype.formvalidation = function () {
        this.SignupFormForEmployee = this.formBuilder.group({
            company: ['', Validators.required],
            companyEmail: ['', Validators.required],
            password: ['', [Validators.required]],
            confirmPass: ['', [Validators.required]],
        });
    };
    Object.defineProperty(SignupEmployerComponent.prototype, "f", {
        get: function () {
            return this.SignupFormForEmployee.controls;
        },
        enumerable: true,
        configurable: true
    });
    // api integration
    SignupEmployerComponent.prototype.signUp = function () {
        this.submitted = true;
        if (this.SignupFormForEmployee.value.password && this.SignupFormForEmployee.value.confirmPass) {
            if (this.SignupFormForEmployee.value.password == this.SignupFormForEmployee.value.confirmPass) {
                this.passwordMismatch = false;
            }
            else {
                this.passwordMismatch = true;
                this.password_error_msg = "Password & confirmpassword should be match.";
                return false;
            }
        }
        else {
            this.formErrors = true;
            return;
        }
        if (this.SignupFormForEmployee.invalid) {
            this.formInvalid = true;
            return;
        }
        this.formInvalid = false;
        console.log(this.req_signup);
        this.emailId = this.SignupFormForEmployee.value.companyEmail,
            this.password = this.SignupFormForEmployee.value.password,
            this.companyName = this.SignupFormForEmployee.value.company,
            this.authService.signInEmploye(this.companyName, this.emailId, this.password);
    };
    SignupEmployerComponent.prototype.logiIn = function () {
        this.router.navigate(['/employe-login']);
    };
    return SignupEmployerComponent;
}());
export { SignupEmployerComponent };
