
import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  NgbModal,

} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component-purified.css']
})
export class AboutUsComponent implements OnInit {

  loadAPI: Promise<any>;
  fileToUpload: any;
  closeResult: string;

  constructor(@Inject(DOCUMENT) document, private modalService: NgbModal, private modalService2: NgbModal, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    // AOS.init();  
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
    // scroll
    const anchor = document.getElementById('header_font');
    if (anchor) {
      anchor.focus();
      anchor.scrollIntoView();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 250) {
      let element = document.getElementById('header_data');
      element.style.background = "rgba(255,255,255,0.9)";
      element.style.position = "fixed";
      element.style.marginTop = "-2px";
      element.style.transitionDuration = "0.6s";
      element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
    } else {
      let element = document.getElementById('header_data');
      element.style.background = "";
      element.style.position = "";
      element.style.marginTop = "";
      element.style.boxShadow = "";
    }
  }
  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }
    if (!isFound) {
      var dynamicScripts = [
        "../assets/js/jquery-3.2.1.min.js",
        "../assets/js/bootstrap.min.js",
        "../assets/js/selectBnfit.js",
        "../assets/js/jquery.menu-aim.js",
        "../assets/js/plugin.js",
        "../assets/js/owl.carousel.js",
        "../assets/js/jquery.countTo.js",
        "../assets/js/jquery.magnific-popup.js",
        "../assets/js/dropify.min.js",
        "../assets/js/jquery.inview.min.js",
        "../assets/js/jquery.nice-select.min.js",
        "../assets/js/imagesloaded.pkgd.min.js",
        "../assets/js/isotope.pkgd.min.js",
        "../assets/js/custom.js",
        "../assets/js/functions.js",
        "../assets/js/modernizr.js"

      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }

}