<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
  <div class="cp_logo_wrapper index_2_logo ">
    <a routerLink="/employe-dashboard">
      <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
    </a>
  </div>

  <div class="menu_btn_box jb_cover">
    <label class="dropdown">
      <p style="font-weight:400;">
        <!-- <img src="assets/sheImages/nav_menu.svg" class="nice-select userPic_header" alt="img" style="width: 62px;height: 60px;"> -->

        <img src="assets/sheImages/nav_menu.svg" alt="img" style="width: 62px;height: 60px; margin-left: 150px;border-radius: 50%;">
      </p>
      <input type="checkbox" class="dd-input" id="test">
      <ul class="dd-menu">
        <!-- Employee -->
        <li><a routerLink="/job-dashboard"><i class="fas fa-outdent"></i> Job Dashboard</a></li>
        <li><a routerLink="/user-profile"><i class="fas fa-edit"></i> View/Edit Profile</a></li>
        
    
        <!-- Employer -->
        <li><a routerLink="/recruiterprofile"><i class="fas fa-edit"></i> View /Edit </a></li>
        <li><a routerLink="/manage-recruiters"><i class="fas fa-user-edit"></i> Manage Recruiters </a></li>
        <li><a routerLink="/schedule-events"><i class="fas fa-tasks"></i> Schedule Events </a></li>

        <!-- Partner -->
        <li><a routerLink="/partners-addrecruiter"><i class="fa fa-user-edit"></i> Add Associates</a></li>
        <li><a routerLink="/partners-subscription"><i class="fa fa-credit-card"></i> Payments Plan</a></li>

        <!-- Common -->
        <li><a (click)="onLogout()"><i class="fas fa-sign-in-alt"></i> Logout</a></li>
      </ul>
    </label>
  </div>
</div>