<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
  <div class="cp_logo_wrapper index_2_logo">
      <a routerLink="/homenew">
          <img src="assets/sheImages/logo8.svg"  alt="logo" style="width:110%;height:auto;"> 
      </a>
  </div>
</div>
 <div class="jb_banner_wrapper jb_cover" id="banner_data">
      <div class="jb_banner_left" >
          <!-- <br/> -->
          <h2 id="header_font" style="line-height:57px;">Creating diversity is easy</h2><br/>
      </div>
      <div class="login_wrapper jb_cover">
      <div class="container">
          <div class="row">
              <div class="col-lg-8 col-md-12 col-sm-12">
                                                  <h2>Reset Password </h2>
                                                  <form [formGroup]="forgotForm" novalidate (ngSubmit)="onSubmit()" >
                                                      <div class="form-group">
                                                        <label>Create new password</label>
                                                        <input type="password"  id="password-field" formControlName="password" class="form-control" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d].{8,}" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                                        <span toggle="#password-field" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
                                                        margin-right: 306px;
                                                        margin-top: -34px;
                                                         position: relative;
                                                         z-index: 2;"></span>
                                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                                            <div  *ngIf="submitted && f.password.errors && f.password.errors.pattern">
                                                              At least 8 characters in length,
                                                              Password should have at least 1 Number and at least 1  letter in capital
                                                          </div></div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Confirm password</label>
                                                        <input type="password" id="password-field1" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                                                        <span toggle="#password-field1" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
                                                        margin-right: 306px;
                                                        margin-top: -34px;
                                                         position: relative;
                                                         z-index: 2;"></span>
                                                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                                            <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                                        </div>
                                                    </div>
                                                          <p style="color:red;">{{erroMsg}}</p>
                                                    <div class="header_btn search_btn login_btn jb_cover text-center">
                                                          <button id="submit" type="submit" [disabled]="loading" class="btn login-botton"><span style="color:#fff;font-weight:900;" > Reset Password  </span></button>
                                                              <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
                                                          </div>
                                                    </form>
                                                  </div>
                                             
                                              </div>
                                          </div>
                                      </div>
                                  <div style="height:200px;">
                                  </div>
                                  </div>
                                  <!-- <div class="footer index2_footer_wrapper footer_index3 shaa jb_cover" style="overflow: hidden;">
                                  <div class="container">
                                  <div class="row">
                                  <div class="copyright_left"><i class="fa fa-copyright"></i> 2019 <a href="javascript:void(0);">  4GEN Technologies Pvt. Ltd </a> All Rights Reserved.
                                  </div>
                                  <div class="clearfix"></div>
                                  </div>
                                  </div>
                                  <div class="waveWrapper waveAnimation">
                                  <div class="waveWrapperInner bgTop gradient-color">
                                  <div class="wave waveTop wavetop_1 wavetop_3"></div>
                                  </div>
                                  <div class="waveWrapperInner bgMiddle">
                                  <div class="wave waveMiddle"></div>
                                  </div>
                                  <div class="waveWrapperInner bgBottom">
                                  <div class="wave waveBottom wavebottom_1 wavebottom_3"></div>
                                  </div>
                                  </div>
                                  <div class="ft_shape2 bubble-190">
                                  <img src="../../assets/sheImages/bubble2.png" class="img-responsive " alt="img">
                                  </div>
                                  <div class="ft_shape1 bubble-19">
                                  <img src="../../assets/sheImages/bubble2.png" class="img-responsive " alt="img">
                                  </div>
                                  </div> -->