import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WebinarService } from '../../services/webinar.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css']
})

export class WebinarComponent implements OnInit {
  RegistrationForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  erroMsg: any;
  userData: any;
  formBuilder: any;
  contactNo;
  email: any;
  uploadErr: any;
  fileToUpload: File = null;
  File = null;
  resumeName: any;
  resumeStatus: string;
  source: string;
  showUploadWait: boolean = false;
  webData: any[];
  allWebinars: any;
  completedWebinars: any;
  completedWebinarData: any[];
  modalData: any;
  videoModalData: any;
  success: boolean;
  failure: boolean;
  tileCouter: Number = 0;
  addWebinarParticipantsForm: FormGroup;
  participantFirstName: String;
  participantLastName: String;
  participantEmailId: String;
  participantPhone: Number;
  selectedModalData: any = {
    webinarTitle: "",
    webinarDate: "",
    webinarLink: "",
    webinarApp: "",
    webinarId: "",
    webinarTime: "",
    webinarDescription: "",
    webinarKeyTakeAways: "",
    preseneterTitle: "",
    preseneterBio: "",
    preseneterName: "",
    acceptFutureWebinar: false
  };
  selectedVideoModal: any = {
    webinarVideoLink: "",
    webinarId: "",
    webinarTitle: ""
  };
  // Variables for upcoming webinar pagination
  sortedWebinarArray: Array<Object> = [];
  sortedCompletedWebinarArray: Array<Object> = [];
  currentPage: number = 0;
  nextPage: number = 2;
  previousPage: number = 0;
  pageCount: number;
  tilesPerPage: number = 6;
  start: number = 0;
  end: number;
  // Variables for past webinar carousal
  completedWebinarCount: number;
  carousalleftindex: number = 0;
  carousalrightindex: number = 1;
  carousalItemsPerPage: number = 2;
  wId = '';

  createForm() {
    this.addWebinarParticipantsForm = this.fb.group({
      participantFirstName: [null, Validators.required],
      participantLastName: [null, Validators.required],
      participantEmailId: [null, [Validators.required]],
      participantPhone: [null, [Validators.required]]
    });
  }

  constructor(private fb: FormBuilder,
    private webinarService: WebinarService,
    private route: ActivatedRoute, private router: Router) {
    this.addWebinarParticipantsForm = fb.group({
      'contactNo': [null, Validators.required],
      'emailId': [null, Validators.required],
      'resumeUrl': [null, Validators.required],
      'termsandconditions': [false, Validators.pattern('true')],
      'source': [,]
    });
    this.createForm();
  }
  webinars(array, size) {
    let results: any = [];

    results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  }

  get f() { return this.RegistrationForm.controls; }

  ngOnInit() {

    var queryParams = this.route.snapshot.queryParams;
    this.wId = queryParams ? queryParams.id : '';
    // console.log(this.wId);
    // var ImageMap = function (map, img) {
    //   var n,
    //     areas = map.getElementsByTagName('area'),
    //     len = areas.length,
    //     coords = [],
    //     previousWidth = 2820;
    //   for (n = 0; n < len; n++) {
    //     coords[n] = areas[n].coords.split(',');
    //   }
    //   this.resize = function () {
    //     var n, m, clen,
    //       x = img.offsetWidth / previousWidth;
    //     for (n = 0; n < len; n++) {
    //       clen = coords[n].length;
    //       for (m = 0; m < clen; m++) {
    //         coords[n][m] *= x;
    //       }
    //       areas[n].coords = coords[n].join(',');
    //     }
    //     previousWidth = document.body.clientWidth;
    //     return true;
    //   };
    //   window.onresize = this.resize;
    // },
    //   imageMap = new ImageMap(document.getElementById('map_ID'), document.getElementById('img_ID'));
    // imageMap.resize();
    // return;

    // $('#publicisSpaientModal').modal('show');

    var screenWidth = window.innerWidth;
    this.tileCouter = 0;
    //backend call to get all webinar data
    this.webinarService.getWebinarDetails().subscribe(
      data => {
        //sort tht data based on seq id.
        data.data.sort(function (x, y) { if (!x["seqId"] && !y["seqId"]) { return 0 } else { return x["seqId"] - y["seqId"] } });
        this.sortedWebinarArray = data.data;
        this.pageCount = Math.ceil(this.sortedWebinarArray.length / this.tilesPerPage);
        this.end = this.start + this.tilesPerPage;
        this.allWebinars = this.sortedWebinarArray.slice(this.start, this.end);
        // console.log(this.allWebinars);
        if (this.wId !== '') {
          this.allWebinars.forEach(element => {
            if (element.webinarId === this.wId) {
              this.showModalByWebinarID(this.wId);
            }
          });
          // console.log(this.allWebinars);
        }
      },
      error => {
        if (error.responseStatus === 401) {
        } else if (error.responseStatus === 500) {
        } else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );

    this.webinarService.getCompletedWebinars().subscribe(
      data => {
        data.data.sort(function (x, y) { if (!x["seqId"] && !y["seqId"]) { return 0 } else { return y["seqId"] - x["seqId"] } });
        this.sortedCompletedWebinarArray = data.data;
        this.completedWebinarCount = this.sortedCompletedWebinarArray.length;
        this.carousalItemsPerPage = Math.ceil(screenWidth / 750);
        // console.log(this.carousalItemsPerPage);
        this.carousalrightindex = this.carousalleftindex + this.carousalItemsPerPage;
        this.completedWebinars = this.sortedCompletedWebinarArray.slice(this.carousalleftindex, this.carousalrightindex);
      },
      error => {
        if (error.responseStatus === 401) {
        } else if (error.responseStatus === 500) {
        } else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );

    this.route.queryParams
      .filter(params => params.source)
      .subscribe(params => {
        console.log(params);
        this.source = params.source;
      });



  }

  //Cancel validation
  remove() {
    this.addWebinarParticipantsForm.reset();
  }

  loadWebinarTiles(pageNumber) {
    if ((this.pageCount - pageNumber) <= 0) {
      return;
    }
    this.start = pageNumber * this.tilesPerPage;
    this.end = this.start + this.tilesPerPage;
    if (this.start > this.sortedWebinarArray.length) {
      return;
    }
    this.allWebinars = this.sortedWebinarArray.slice(this.start, this.end);
    this.currentPage = pageNumber;
  }

  loadPrevious() {
    if ((this.currentPage - 1) < 0) {
      return;
    }
    this.start = (this.currentPage - 1) * this.tilesPerPage;
    this.end = this.start + this.tilesPerPage;
    this.allWebinars = this.sortedWebinarArray.slice(this.start, this.end);
    this.currentPage = this.currentPage - 1;
  }

  loadNext() {
    if ((this.currentPage + 1) >= this.pageCount) {
      return;
    }
    this.start = (this.currentPage + 1) * this.tilesPerPage;
    this.end = this.start + this.tilesPerPage;
    this.allWebinars = this.sortedWebinarArray.slice(this.start, this.end);
    this.currentPage = this.currentPage + 1;
  }

  carousalScroll(direction) {
    if (direction == "left") {
      if (this.carousalleftindex != 0) {
        this.carousalleftindex--;
        this.carousalrightindex = this.carousalleftindex + this.carousalItemsPerPage;
      }
    }
    else if (direction == "right") {
      if (this.carousalrightindex < this.completedWebinarCount) {
        this.carousalrightindex++;
        this.carousalleftindex = this.carousalrightindex - this.carousalItemsPerPage;;
      }
    }
    this.completedWebinars = this.sortedCompletedWebinarArray.slice(this.carousalleftindex, this.carousalrightindex);
    //  console.log(this.completedWebinars);
  }

  public showModal(webData) {
    this.modalData = webData;
    this.success = false;
    this.failure = false;
    this.selectedModalData.webinarLink = webData.webinarLink;
    this.selectedModalData.webinarId = webData.webinarId;
    this.selectedModalData.webinarApp = webData.webinarApp;
    this.selectedModalData.webinarDate = webData.webinarDate;
    this.selectedModalData.webinarTitle = webData.webinarTitle;
    this.selectedModalData.webinarTime = webData.webinarTime;
    this.selectedModalData.preseneterName = webData.preseneterName;
    this.selectedModalData.preseneterBio = webData.preseneterBio;
    this.selectedModalData.preseneterTitle = webData.preseneterTitle;
    this.selectedModalData.webinarDescription = webData.webinarDescription;
    this.selectedModalData.webinarKeyTakeAways = webData.webinarKeyTakeAways;
    this.selectedModalData.acceptFutureWebinar = false;

    $('#registrationModal').modal('show');
  }

  showModalByWebinarID(webinarId) {
    if (this.allWebinars.length > 0) {
      this.allWebinars.forEach(element => {
        if (webinarId === element.webinarId) {
          this.showModal(element);
        }
      });
    }
  }

  showVideoModal(completedWebinarData) {
    // console.log(completedWebinarData);
    this.videoModalData = completedWebinarData;
    this.selectedVideoModal.webinarId = completedWebinarData.webinarId;
    this.selectedVideoModal.webinarTitle = completedWebinarData.webinarTitle;
    this.selectedVideoModal.webinarVideoLink = completedWebinarData.webinarVideoLink;
    $('#completedWebinarModal').modal('show');
  }

  addParticipants() {
    var formData = this.addWebinarParticipantsForm.value;
    console.log(this.addWebinarParticipantsForm.value)
    formData.webinarId = this.selectedModalData.webinarId;
    formData.webinarLink = this.selectedModalData.webinarLink;
    formData.webinarApp = this.selectedModalData.webinarApp;
    formData.webinarDate = this.selectedModalData.webinarDate;
    formData.webinarTitle = this.selectedModalData.webinarTitle;
    formData.webinarTime = this.selectedModalData.webinarTime;
    formData.webinarKeyTakeAways = this.selectedModalData.webinarKeyTakeAways;
    formData.preseneterName = this.selectedModalData.preseneterName;
    formData.preseneterBio = this.selectedModalData.preseneterBio;
    formData.preseneterTitle = this.selectedModalData.preseneterTitle;
    formData.acceptFutureWebinar = this.selectedModalData.acceptFutureWebinar;
    console.log(formData);
    this.webinarService.AddParticipants(formData).subscribe((res: any) => {
      console.log(res);

      if (res.status == true) {
        this.success = true;
        this.failure = false;
      } else {
        this.success = false;
        this.failure = true;
      }
      this.addWebinarParticipantsForm.reset();

    }, (error: any) => {
      console.log(this.success);
      this.success = false;
      this.failure = true;
      this.alertMsg(error.message, 'warning');
    })
  }

  alertMsg(msg: string, type: string) {
    if (type == 'success') {
      Swal({
        title: 'Success',
        text: msg,
        type: 'success',
        showCancelButton: false,
        confirmButtonColor: '#535BE2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK',
      });
    }
    else if (type == 'warning') {
      Swal({
        title: 'Warning',
        html: msg,
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1976d2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK!',
      });
    }
  }

  navigateToSection(anchorHash) {
    setTimeout(() => {
      const anchor = document.getElementById(anchorHash);
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView();
      }
    });
  }
  // closePublisModal() {
  //   $('#publicisSpaientModal').modal('hide');
  // }
}
