/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./minimal-employer-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./minimal-employer-header.component";
import * as i5 from "../../services/recruiter.operations";
var styles_MinimalEmployerHeaderComponent = [i0.styles];
var RenderType_MinimalEmployerHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MinimalEmployerHeaderComponent, data: {} });
export { RenderType_MinimalEmployerHeaderComponent as RenderType_MinimalEmployerHeaderComponent };
function View_MinimalEmployerHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["routerLink", "/recruiterprofile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-user-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" View /Edit "]))], function (_ck, _v) { var currVal_2 = "/recruiterprofile"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MinimalEmployerHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["routerLink", "/manage-recruiters"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-user"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Manage Recruiters "]))], function (_ck, _v) { var currVal_2 = "/manage-recruiters"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_MinimalEmployerHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "return_top3"], ["href", "javascript:"], ["id", "return-to-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-angle-double-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 23, "div", [["class", "cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover"], ["id", "header_data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "cp_logo_wrapper index_2_logo "]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["routerLink", "/employe-dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "logo"], ["src", "assets/sheImages/logo8.svg"], ["style", "width:110%;height:auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 18, "div", [["class", "menu_btn_box jb_cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 17, "label", [["class", "dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["style", "font-weight:400;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "img"], ["src", "assets/sheImages/nav_menu.svg"], ["style", "width: 62px;height: 60px; margin-left: 150px;border-radius: 50%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["class", "dd-input"], ["id", "test"], ["type", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 13, "ul", [["class", "dd-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinimalEmployerHeaderComponent_1)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MinimalEmployerHeaderComponent_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [["routerLink", "/schedule-events"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fas fa-user"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Schedule Events "])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "i", [["class", "fas fa-sign-in-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/employe-dashboard"; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isRecruiter; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.isRecruiter; _ck(_v, 16, 0, currVal_4); var currVal_7 = "/schedule-events"; _ck(_v, 19, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵnov(_v, 19).target; var currVal_6 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_5, currVal_6); }); }
export function View_MinimalEmployerHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "minimal-employer-header", [], null, null, null, View_MinimalEmployerHeaderComponent_0, RenderType_MinimalEmployerHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.MinimalEmployerHeaderComponent, [i5.RecruiterService, i2.Router, i5.RecruiterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MinimalEmployerHeaderComponentNgFactory = i1.ɵccf("minimal-employer-header", i4.MinimalEmployerHeaderComponent, View_MinimalEmployerHeaderComponent_Host_0, {}, {}, []);
export { MinimalEmployerHeaderComponentNgFactory as MinimalEmployerHeaderComponentNgFactory };
