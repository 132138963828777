<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="description" content="" />
  <meta name="author" content="TechiePriyaRanjan" />
  <title>Shenzyn's Webinar 2020</title>

  <link rel="canonical" href="https://www.shenzyn.com/" />

  <!-- Bootstrap core CSS -->
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
  <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
    integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous" />
  <!-- Video player css -->
  <link href="https://vjs.zencdn.net/7.7.5/video-js.css" rel="stylesheet" />
  <link href="https://unpkg.com/@videojs/themes@1.0.0/dist/fantasy/index.css" rel="stylesheet" />

  <!-- Favicons -->
  <link rel="apple-touch-icon" sizes="180x180" href="assets/sheImages/webinar/favicons/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="assets/sheImages/webinar/favicons/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="assets/sheImages/webinar/favicons/favicon-16x16.png" />
  <link rel="manifest" href="assets/sheImages/webinar/favicons/site.webmanifest" />
  <link rel="mask-icon" href="assets/sheImages/webinar/favicons/safari-pinned-tab.svg" color="#5bbad5" />
  <meta name="msapplication-TileColor" content="#da532c" />
  <meta name="theme-color" content="#ffffff" />

  <!-- Custom styles for this page -->
  <!-- <link rel="stylesheet" href="css/style.css"> -->
</head>

<body>
  <div
    class="d-flex header__navigation flex-column flex-md-row align-items-center px-md-1 mb-3 bg-white border-bottom shadow-sm">
    <!-- <a href="https://www.shenzyn.com/" class="my-0 mr-md-auto font-weight-normal logo-shenzyn">
      <img src="assets/sheImages/webinar/shenzyn_light_logo.svg">
    </a> -->
    <div class="cp_logo_wrapper index_2_logo my-0 mr-md-auto font-weight-normal">
      <a routerlink="/homenew" ng-reflect-router-link="/homenew" href="#/homenew">
        <img alt="logo" src="assets/sheImages/logo8.svg" style="width: 110%; height: auto;" /></a>
    </div>
    <nav class="my-2 my-md-0 mr-md-3">
      <ul class="nav_socialLinks_text">
        <li>
          Webinars <i class="fa fa-angle-down ml-2"></i>
          <ul>
            <li>
              <a class="p-2 text-top-nav" fragment="webinars" (click)="navigateToSection('webinars')">Upcoming
                Webinars</a>
            </li>
            <li>
              <a class="p-2 text-top-nav" fragment="past-webinar" (click)="navigateToSection('past-webinar')">Past
                Webinars</a>
            </li>
          </ul>
        </li>
        <li>
          <a class="p-2 text-top-nav" fragment="schedule" (click)="navigateToSection('schedule')">Schedule</a>
        </li>
        <!-- <li><a class="p-2 text-top-nav" href="https://www.shenzyn.com/#/aboutus">About us</a></li> -->
        <li>
          <a class="p-2 text-top-nav margin-text-right" href="https://jobs.shenzyn.com/#/user/sign-up">Register with
            Shenzyn</a>
        </li>
      </ul>
      <ul class="nav_socialLinks">
        <li>
          <a href="https://www.linkedin.com/company/shenzyn/" target="_blank"><i class="fa fa-linkedin"></i></a>
        </li>
        <li>
          <a href="https://www.instagram.com/we_shenzyn/" target="_blank"><i class="fa fa-instagram"></i></a>
        </li>
        <li>
          <a href="https://www.facebook.com/WeShenzyn/" target="_blank"><i class="fa fa-facebook-f"></i></a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCJ3hSpePirYqXCAJo75OIhQ" target="_blank"><i
              class="fa fa-youtube-play"></i></a>
        </li>
      </ul>
    </nav>
    <!-- <a class="btn btn-outline-primary" href="#">Sign up</a> -->
  </div>

  <div class="container-fluid">
    <!-- <section class="section-shaped-hero py-sm">
      <div class="container py-lg-md d-flex">
        <div class="col px-0">
          <div class="row">
            <div class="col-lg-6 col-md-pull-6 mt-3">
              <img class="landing-page-hero img-fluid" src="assets/sheImages/webinar/banner_image.svg"
                alt="Conversational UI with Multilingual Chatbots for Business">
            </div>

            <div id="desktop-header" class="col-lg-6 col-md-push-6">
              <div class="hero-content-wrapper-white mb-4 pt-5">
                <div class="text-center">
                  <img class="mt-4" src="assets/sheImages/webinar/shenzyn_light_logo.svg" height="30px">
                  <h1 class="she-converse-title pt-2">Sh<span style="color: #ED3A4E; font-weight: 700;">e</span>
                    Converse</h1>
                  <h1 class="webinar-title pt-1">WEBINARS</h1>
                </div>
                <p class="pt-4 banner-title">
                  This too shall pass. Let this temporary difficult time not hamper with your long
                  term career. Presenting She Converse Webinars. Be a part of a weekly webinar series
                  and interact with industry leaders and experienced HR professionals. Get advice,
                  tips, hacks, suggestions & more!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="section-shaped-hero py-sm">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="content">
              <div id="carouselContent" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner" role="listbox">
                  <!-- <div class="carousel-item active text-center">
                    <img id="img_ID" (click)="showModalByWebinarID('S_3454117')" class="img-fluid" width="700px"
                      height="700px" src="assets/sheImages/webinar/bank2-min.png" style="cursor: pointer;" alt="" /> -->
                    <!-- <map id="map_ID" name="webeventmap">
                      <area target="" (click)="showModalByWebinarID('S_1765259')" alt="Gautam Shetty"
                        title="Gautam Shetty" shape="poly" coords="1023,5,1604,0,1609,561,1031,563" />
                      <area target="" (click)="showModalByWebinarID('S_728357')" alt="Gopal Saha" title="Gopal Saha"
                        coords="2238,2,2814,7,2816,647,2238,642" shape="poly">
                      <area target="" (click)="showModalByWebinarID('S_364514')" alt="Jitender Panihar"
                        title="Jitender Panihar" coords="1037,1162,1613,1167,1615,1807,1037,1802" shape="poly">
                      <area target="" (click)="showModalByWebinarID('S_676552')" alt="Shanoo Singh" title="Shanoo Singh"
                        coords="1620,536,2238,538,2243,1248,1611,1251" shape="poly">
                      <area target="" (click)="showModalByWebinarID('S_3314354')" alt="Charles Godwin P"
                        title="Charles Godwin P" coords="2236,1251,2819,1246,2816,1819,2226,1824" shape="poly">
                    </map> -->
                  <!-- </div> -->

                  <div class="carousel-item active text-align" style="height: 500px;">
                    <div class="col px-0">
                      <div class="row">
                        <div class="col-lg-6 col-md-pull-6 mt-3">
                          <img class="landing-page-hero img-fluid" src="assets/sheImages/webinar/banner_image.svg"
                            alt="Webinar Banner" />
                        </div>

                        <div id="desktop-header" class="col-lg-6 col-md-push-6">
                          <div class="hero-content-wrapper-white mb-4 pt-5">
                            <div class="text-center">
                              <img class="mt-4" src="assets/sheImages/webinar/shenzyn_light_logo.svg" height="30px" />
                              <h1 class="she-converse-title pt-2">
                                Sh<span style="color: #ed3a4e; font-weight: 700;">e</span>
                                Converse
                              </h1>
                              <h1 class="webinar-title pt-1">WEBINARS</h1>
                            </div>
                            <p class="pt-4 banner-title">
                              This too shall pass. Let this temporary
                              difficult time not hamper with your long term
                              career. Presenting She Converse Webinars. Be a
                              part of a weekly webinar series and interact
                              with industry leaders and experienced HR
                              professionals. Get advice, tips, hacks,
                              suggestions & more!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="carousel-item active text-center">
                    <img id="img_ID" class="img-fluid" width="100%"
                      src="assets/sheImages/webinar/SheDialogues@31July.jpg"
                      alt="She Converse 15th July Banner Image" />
                  </div> -->
                  <!-- <li class="nav-dots">
                    <a href="#carouselContent" data-slide="next"><label for="img-1" class="nav-dot"></label></a>
                    <a href="#carouselContent" data-slide="next"><label for="img-2" class="nav-dot"></label></a>
                    <a href="#carouselContent" data-slide="next"><label for="img-3" class="nav-dot"></label></a>
                  </li> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-light-pink" id="webinars">
      <div class="container py-lg-md d-flex">
        <div class="col px-0">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="section-title"><span>Webinars</span></h2>
            </div>
          </div>

          <div class="row">
            <div class="container text-center">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" (click)="loadPrevious()" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  <div *ngFor="
                        let item of [].constructor(pageCount);
                        let i = index
                      ">
                    <li class="page-item">
                      <a (click)="loadWebinarTiles(i)" class="page-link">{{
                          i + 1
                        }}</a>
                    </li>
                  </div>

                  <li class="page-item">
                    <a class="page-link" (click)="loadNext()" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4" *ngFor="let row of allWebinars">
              <div class="card my-4">
                <!-- <span class="p-3">
                          <img src="assets/sheImages/webinar/coupon.svg" height="20px" class="pt-1 float-left mr-1" alt="...">
                          <p>Free</p>
                      </span> 
                    -->
                <div class="card-body">
                  <h5 class="card-title">{{ row.webinarTitle }}</h5>
                  <!-- <p class="card-small-description">{{row.webinarTargetAudience}}</p> -->
                  <div class="row">
                    <div class="col-md-7">
                      <span>
                        <h6 class="event-date">
                          <i class="fa fa-calendar-o" aria-hidden="true"></i>
                          {{ row.webinarDate }}
                        </h6>
                      </span>
                    </div>
                    <div class="col-md-5">
                      <span>
                        <h6 class="event-date">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>{{ row.webinarTime }}
                        </h6>
                      </span>
                    </div>
                  </div>
                  <div class="speaker-image py-3">
                    <img src="{{ row.preseneterPhoto }}" />
                  </div>
                  <div>
                    <p class="speaker-name">{{ row.preseneterName }}</p>
                    <p class="speaker-designation">
                      {{ row.preseneterTitle }}
                    </p>
                  </div>
                </div>
                <div class="card-footer text-center mb-2">
                  <a class="know-more btn btn-outline-primary showitman" (click)="showModal(row)"
                    data-toggle="modal">Register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Past Webinars -->
    <section class="section-shaped-hero py-md" id="past-webinar">
      <div class="container pt-4">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="section-schedule"><span>Past Webinars</span></h2>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="col px-0 pt-2">
          <div id="pwSlider">
            <!-- Give wrapper ID to target with jQuery & CSS -->
            <div class="MS-content">
              <div class="item p-2" *ngFor="let webinar of completedWebinars">
                <div class="row pb-2">
                  <div class="col-md-5 text-center">
                    <div class="speaker-image py-3">
                      <img src="{{ webinar.preseneterPhoto }}" />
                      <p class="speaker-name pt-1">
                        {{ webinar.preseneterName }}
                      </p>
                      <p class="speaker-designation text-center">
                        {{ webinar.preseneterTitle }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-7 text-center">
                    <p class="webinarTitle">{{ webinar.webinarTitle }}</p>
                    <p class="event-date" style="font-weight: 600; font-size: 14px;">
                      <i class="fa fa-calendar-o" aria-hidden="true"></i>
                      {{ webinar.webinarDate }}
                    </p>
                    <!-- <button (click)="showVideoModal(webinar)" data-toggle="modal" data-target="#completedWebinarModal" class="btn view-button"><i class="fa fa-video-camera"
                        aria-hidden="true" style="color: #fff;font-size: 15px; margin-right: 10px;"></i>View
                      Video</button> -->
                    <a href="{{ webinar.webinarVideoLink }}" target="_blank"
                      class="know-more btn btn-outline-primary showitman"><i class="fa fa-video-camera"
                        aria-hidden="true" style="
                            color: #fff;
                            font-size: 15px;
                            margin-right: 10px;
                          "></i>View now</a>
                    <!-- <a href="{{webinar.webinarVideoLink}}" target="_blank" class="know-more btn btn-outline-primary "><i
                        class="fa fa-video-camera" aria-hidden="true"
                        style="color: #fff;font-size: 15px; margin-right: 10px;"></i>See Recording</a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="MS-controls">
              <button class="MS-left">
                <i class="fa fa-chevron-left blink" (click)="carousalScroll('left')" aria-hidden="true"></i>
              </button>
              <button class="MS-right">
                <i class="fa fa-chevron-right blink" (click)="carousalScroll('right')" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Schedule -->
    <section class="section-shaped-hero py-md" id="schedule">
      <div class="container py-lg-md d-flex">
        <div class="col px-0 pt-5">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="section-schedule"><span>Schedule</span></h2>
            </div>
          </div>

          <div class="row pt-5">
            <div id="desktop-header" class="col-lg-6 col-md-push-6">
              <div class="hero-content-wrapper-white mb-4 pt-5">
                <p class="schedule-text pb-4">
                  A webinar series to hone your professional skills, add new
                  perspectives and understand new ideas. With more than 50
                  webinars, SheConverse webinars is proving to be an effective
                  learning method, few hours in a week. Join us where we bring
                  the best of the industries to share their knowledge bank
                  with you. Have a speaker in mind? Or what to appear on the
                  webinars as a speaker? Share the details with
                  <a href="mailto:wehearyou@shenzyn.com">wehearyou@shenzyn.com</a>
                </p>
                <!-- <p class="schedule-text pb-4">
                  All the webinars will take place in the month of April and May. Please check the individual webinars
                  to check the final schedule.
                </p>
                <h4 class="pb-4" style="font-size: 28px; font-weight: 800;color: #5D5B5F;">Session breakdown</h4>
                <p class="schedule-time-details"><b>4.00 p.m:</b> Overview and introduction of Panelist & topic</p>
                <p class="schedule-time-details"><b>4.10-4.30 p.m:</b> Topic coverage over video </p>
                <p class="schedule-time-details"><b>4.30-4.45 p.m:</b> Q&A through chat by the audience</p> -->
              </div>
            </div>

            <div class="col-lg-6 col-md-pull-6 mt-3 text-center">
              <img class="landing-page-hero img-fluid" style="max-height: 333px;"
                src="assets/sheImages/webinar/Laptop.png" alt="She Converse Webinars" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="registrationModal" tabindex="-1" role="dialog" aria-labelledby="speakerModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-md-5">
                <h5 class="modal-speaker-title pl-2" id="speakerModalCenterTitle">
                  {{ modalData.preseneterName }}
                </h5>
              </div>
              <div class="col-md-7">
                <p class="modal-speaker-designation">
                  {{ modalData.preseneterTitle }}
                </p>
              </div>
              <hr />
            </div>

            <div class="row pt-4">
              <div class="col-md-5">
                <h5 class="modal-speaker-image">
                  <img src="{{ modalData.preseneterPhoto }}" alt="" />
                </h5>
              </div>
              <div class="col-md-7">
                <!-- <div *ngFor="let i of selectedModalData.webinarDescription.length">
                  <p>{{selectedModalData.webinarDescription[i]}}</p>
                </div> -->

                <p *ngIf="selectedModalData.webinarDescription[0]">
                  {{ selectedModalData.webinarDescription[0] }}
                </p>
                <p *ngIf="selectedModalData.webinarDescription[1]">
                  {{ selectedModalData.webinarDescription[1] }}
                </p>
                <p *ngIf="selectedModalData.webinarDescription[2]">
                  {{ selectedModalData.webinarDescription[2] }}
                </p>
                <p *ngIf="selectedModalData.webinarDescription[3]">
                  {{ selectedModalData.webinarDescription[3] }}
                </p>
                <p *ngIf="selectedModalData.webinarDescription[4]">
                  {{ selectedModalData.webinarDescription[4] }}
                </p>
                <p *ngIf="selectedModalData.webinarDescription[5]">
                  {{ selectedModalData.webinarDescription[5] }}
                </p>

                <!-- <p>{{selectedModalData.webinarDescription[1]}}</p>
                <p>{{selectedModalData.webinarDescription[2]}}</p> -->
              </div>
              <hr />
            </div>

            <div class="row pt-1">
              <div class="col-md-5">
                <div class="container">
                  <h5 style="color: #5d5b5f; font-size: 24px;">Webinar</h5>
                  <h4 style="font-size: 28px; color: #734099; font-weight: 800;">
                    {{ modalData.webinarTitle }}
                  </h4>
                  <div class="row pt-2">
                    <div class="col-md-6">
                      <span>
                        <h6 style="font-size: 20px;" class="event-date">
                          <i class="fa fa-calendar-o" aria-hidden="true"></i>
                          {{ modalData.webinarDate }}
                        </h6>
                      </span>
                    </div>
                    <div class="col-md-6">
                      <span>
                        <h6 style="font-size: 20px;" class="event-date float-left">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>{{ modalData.webinarTime }}
                        </h6>
                      </span>
                    </div>
                  </div>

                  <h5 class="modal-headings-small">Key takeaways</h5>
                  <!-- <p class="modal-subheading-texts">{{modalData.webinarKeyTakeAways}}</p> -->
                  <li class="modal-subheading-texts" *ngIf="selectedModalData.webinarKeyTakeAways[0]">
                    {{ selectedModalData.webinarKeyTakeAways[0] }}
                  </li>
                  <li class="modal-subheading-texts" *ngIf="selectedModalData.webinarKeyTakeAways[1]">
                    {{ selectedModalData.webinarKeyTakeAways[1] }}
                  </li>
                  <li class="modal-subheading-texts" *ngIf="selectedModalData.webinarKeyTakeAways[2]">
                    {{ selectedModalData.webinarKeyTakeAways[2] }}
                  </li>
                  <li class="modal-subheading-texts" *ngIf="selectedModalData.webinarKeyTakeAways[3]">
                    {{ selectedModalData.webinarKeyTakeAways[3] }}
                  </li>
                  <li class="modal-subheading-texts" *ngIf="selectedModalData.webinarKeyTakeAways[4]">
                    {{ selectedModalData.webinarKeyTakeAways[4] }}
                  </li>

                  <h5 class="modal-headings-small">
                    Who is this session for?
                  </h5>
                  <p class="modal-subheading-texts">
                    {{ modalData.webinarTargetAudience }}
                  </p>
                  <h5 class="modal-headings-small">Duration</h5>
                  <p class="modal-subheading-texts">
                    {{ modalData.webinarDuration }}
                  </p>
                </div>
              </div>
              <div class="col-md-7">
                <div class="registration-wrapper container py-4 px-2">
                  <h5 style="
                        color: #5d5b5f;
                        font-size: 28px;
                        font-weight: 600;
                        padding-bottom: 15px;
                        font-style: italic;
                      ">
                    Register Here
                  </h5>
                  <form [formGroup]="addWebinarParticipantsForm" ngNativeValidate (ngSubmit)="addParticipants()">
                    <div class="form-group">
                      <input type="text" class="form-control" formControlName="participantFirstName"
                        placeholder="First Name" required />
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" formControlName="participantLastName"
                        placeholder="Last Name" required />
                    </div>
                    <div class="form-group">
                      <input type="email" class="form-control" formControlName="participantEmailId"
                        placeholder="Email ID" required />
                    </div>
                    <div class="form-group">
                      <input type="tel" class="form-control" formControlName="participantPhone"
                        placeholder="Contact Number" required />
                    </div>
                    <div class="form-group">
                      <div class="form-check">
                        <!-- <input class="form-check-input" type="checkbox" (change)="acceptFutWebinar($event)"
                          id="acceptFutWebinar" checked={selectedModalData.acceptFutureWebinar}
                          formControlName="acceptFutureWebinar" required> -->
                        <input type="checkbox" class="form-check-input"
                          [(ngModel)]="selectedModalData.acceptFutureWebinar" [ngModelOptions]="{ standalone: true }" />
                        <label class="form-check-label" for="acceptFutWebinar">
                          Register me for all webinars.
                        </label>
                      </div>
                    </div>
                    <span *ngIf="success == true" class="text-success">Successfully registered for webinar.</span>
                    <span *ngIf="failure == true" class="text-danger">Failed to register for webinar, please contact
                      wehearyou@shenzyn.com</span>

                    <div class="form-group">
                      <p style="
                            color: #747373;
                            font-size: 14px;
                            font-style: italic;
                            padding-left: 10px;
                          ">
                        On Registration you will receive a mail with the link
                        to the webinar
                      </p>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        <button type="submit" class="btn btn-primary registration-button">
                          Register
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Completed Webinar Video Modal -->
  <div class="modal fade" id="completedWebinarModal" tabindex="0" role="dialog" aria-labelledby="completedWebinar"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ videoModalData.webinarTitle }}</h5>
          <!-- <h5 class="modal-title" id="completedWebinarModa">{{webinarTitle}}</h5> -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <video id="my-video" class="video-js vjs-theme-fantasy" controls preload="auto" height="420px" width="auto"
            data-setup="{}">
            <!-- <source src="https://shenzyn-uploads.s3.ap-south-1.amazonaws.com/Webinars/Sheconverse_Sonam_Gupta.mp4" type="video/mp4" /> -->
            <source src="{{ videoModalData.webinarVideoLink }}" type="video/mp4" />
            <!-- <source src="MY_VIDEO.webm" type="video/webm" /> -->
            <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
            </p>
          </video>
        </div>
      </div>
    </div>
  </div>

  <!-- Publicis Spaient Modal -->
  <!-- <div class="modal fade" id="publicisSpaientModal" tabindex="0" role="dialog" aria-labelledby="publicisSpaient"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" style="position: absolute; top: 10px; right: 10px;" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <a (click)="closePublisModal()" href="https://diversitymeetup.splashthat.com/" target="_blank">
            <img class="img-fluid" src="assets/sheImages/PublicisSapientCampaign.jpeg">
          </a>
        </div>
      </div>
    </div>
  </div> -->

  <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
    integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
    crossorigin="anonymous"></script>
  <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
    integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
    crossorigin="anonymous"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
    integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
    crossorigin="anonymous"></script>
  <!-- If you'd like to support IE8 (for Video.js versions prior to v7) -->
  <script src="https://vjs.zencdn.net/ie8/1.1.2/videojs-ie8.min.js"></script>
  <script src="https://vjs.zencdn.net/7.7.5/video.js"></script>
  <script src="assets/js/multislider.min.js"></script>

  <app-minimalistic-footer></app-minimalistic-footer>
</body>

</html>