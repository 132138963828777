import { Component, OnInit, AfterViewInit,OnDestroy, ViewEncapsulation, HostListener, ViewChild } from '@angular/core';
import {Location} from '@angular/common'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import { timeThursday } from 'd3';
import swal from 'sweetalert2';
import {ExcelService} from '../../services/excel.service';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-partners-admin-dashboard',
  templateUrl: './partners-admin-dashboard.component.html',
  styleUrls: ['./partners-admin-dashboard.component.css']
})
export class PartnersAdminDashboardComponent implements OnInit, AfterViewInit,OnDestroy {
  showDialog: boolean = false;
  showEditModal:Boolean = false;
  upcomingEventSelected: Object = {};
  companySrc: any;
  usertype:Boolean;
  checked: boolean = false;
  EventDetail: any = {};
  filteredProfileList:any = [];
  UpcomingEventData: any;
  moment: any = moment;
  showSpinner:boolean=true;
  listofPastEvent:any;
  partnerEmail:String;
  companyName:String;
  confirmDisabled:Boolean;
  addProfileForm:FormGroup;
  editProfileForm:FormGroup;
  buttonStatus:Boolean;
  applicant:any={
    applicantName:"",
    applicantEmailId:"",
    applicantPhone:"",
    applicantExperience:"",
    applicantExpectedCTC:"",
    applicantNoticePeriod:"",
    unavailable:true
  };
  uploadErr:any;
  resumeName:any;
  fileToUpload:any;
  userData:any;
  resumeStatus:any;
  contactNo:any;
  email:any;
  matchscore:any='--';
  validContactNo:any;
  location: Location;
  profSubmitter:any;
  parsingResume:Boolean;
  ClikedEvent:Boolean;
  SuccessparsingResume:Boolean;
  UploadNotPossible:Boolean;
  viewContent:String;
  isSubscribedEmail:String;
  UserSubscribed:Boolean;
  hideForPastEvent:Boolean;
  dataValue: any[];
  updateData:any ={};
  Condition:String;
  data: any =[];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtInstance: Promise<DataTables.Api>;
  dtOptions: DataTables.Settings = {};
  
  dtTrigger: Subject<any> = new Subject();

  constructor(private excelService:ExcelService,private _location:Location, private modalService: NgbModal, private router: Router,private formBuilder: FormBuilder, private authService: AuthServices) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    const tokenVerify = localStorage.getItem('authtoken')
    if (!tokenVerify) {
      this.router.navigate(['partners-login']);
      return;
    }
    this.formvalidation();
    this.editFormvalidation();
    localStorage.setItem('defaultcompanylogo', '/assets/sheImages/partners/company_default.png');
    this.companySrc = localStorage.getItem('defaultcompanylogo');
    this.usertype = JSON.parse(localStorage.getItem("isPartner"));
    this.partnerEmail = JSON.parse(localStorage.getItem("emailId"));
    //console.warn(this.usertype,' its type of User');
    this.companyName = JSON.parse(localStorage.getItem("companyName"));
    if(this.usertype)
    {
      this.confirmDisabled = true;
      this.authService.GetAllUpcomingEvents().subscribe((Eventdata: any)=>{
        this.UpcomingEventData = Eventdata.data;
        this.dataValue = this.companies(this.UpcomingEventData, 4);
        $('#addProfilebtn').addClass('disabled');
        $('#editProfilebtn').addClass('disabled');
        $('#exportFile').addClass('disabled');
        this.Condition = 'disabled';
        this.showSpinner = false;
        
        
      }, (error: any) => {
        this.alertMsg(error.message, 'warning');
      });
    }
    else{
      //console.warn('its false');
      this.partnerEmail = JSON.parse(localStorage.getItem("partnerEmail"));
      this.confirmDisabled = false;
      // this.companyName = JSON.parse(localStorage.getItem("companyName"));
      this.authService.getAllSubscribedEvents(this.partnerEmail).subscribe((Eventdata: any) =>{
        this.UpcomingEventData = Eventdata.data;
        this.dataValue = this.companies(this.UpcomingEventData, 4);
        this.showSpinner = false;
        $('#addProfilebtn').addClass('disabled');
        $('#editProfilebtn').addClass('disabled');
        $('#exportFile').addClass('disabled');
        this.Condition = 'disabled';
      },(error:any) =>{
        this.alertMsg(error.message, 'warning');
      }
      )
    }
    
    this.getPastEvents(this.partnerEmail);
  }
  // get all Past Events
  getPastEvents(partnerEmail){
    this.authService.GetAllPastEvents(partnerEmail).subscribe((e: any) => {
      this.listofPastEvent = e.data;
      if(this.listofPastEvent.length > 0)
      {
        this.EventDetail = this.listofPastEvent[0];
      } else {
        this.EventDetail = {};
      }
    }, (error: any) => {
        this.alertMsg(error.message, 'warning');
    });
  }

  companies(array, size) {
    let results: any = [];

    results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  }

  // Download Excel
  exportAsXLSX():void {
    
    if(this.ClikedEvent == true && this.EventDetail.listofApplicants.length > 0)
    {
      this.excelService.exportAsExcelFile(this.data, 'Profiles');
      this.data = [];
    }
    
  }
  // Disable Btn before Filling Form
  disableAddbtn(){
    $('#addmodal').addClass('disabled');
  }

  // Check Partner Subscription
  checkSubscribtion(eve:any){
    if(eve.eventEnrolledBy.length !== 0)
    {
      this.isSubscribedEmail = JSON.parse(localStorage.getItem('emailId'));
      eve.eventEnrolledBy.forEach(el => {
        this.UserSubscribed = el == this.isSubscribedEmail ? true:false;
      });
    }
    else{
      this.UserSubscribed = false;
    }
    return this.UserSubscribed;
  }

  // Add Associte Page
  addAssociate() {
    localStorage.setItem('userpicUrl', "/assets/sheImages/aboutus/murali.jpg");
    localStorage.setItem('userType', 'PartnerAdmin');
    this.router.navigate(['partners-addrecruiter']);
  }

  openModal(data: any, i: number) {
    this.upcomingEventSelected = data;
    if (Object.keys(this.upcomingEventSelected).length !== 0) {
      this.showDialog = !this.showDialog;
      this.companySrc = data.src ? data.src : localStorage.getItem('defaultcompanylogo');
    }
  }
  CloseModal() {
    this.showDialog = !this.showDialog;
  }
  
  Confirm(data: any) {
    var SubscribedBy = JSON.parse(localStorage.getItem('emailId'));
    this.upcomingEventSelected["isSubscribed"] = true;
    this.upcomingEventSelected["SubscribedBy"] = SubscribedBy;
    this.checked = true;
    this.authService.PartnerSubscribed(this.upcomingEventSelected).subscribe((res: any)=>{
      if(res){
        this.router.navigate(['partners-admin']);
        this.CloseModal();
      } 
      this.alertMsg(res.message, 'success');
    }, (error: any) => {
      console.log(error);
      this.alertMsg(error.message, 'warning');
    });
  }

  // Edit Profile
  EditProfile(e:any){
    console.warn(e,' its clicked profile');
    this.applicant = e;
  } 
  // data Table Re-Render
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  // Selected Past Events
  showDetails(eve: any) {
    //console.warn(eve,' its clicked data');
    this.EventDetail = eve;
    //filter the event.list of Applicants
    this.filteredProfileList=[];
    eve.listofApplicants.forEach( (el) =>{
      if(this.companyName == el.CompanySubmittedBy || this.partnerEmail == el.profileSubmittedBy){
        this.filteredProfileList.push(el);
      }
    },this);  
    eve['listofApplicants'] = this.filteredProfileList;

    this.ClikedEvent = true;
    localStorage.setItem("ClickedEventId",eve._id);
    localStorage.setItem("eventId",eve.eventId);
    // this.dtTrigger.next();
    // this.rerender();
    if(eve.listofApplicants.length > 0)
    {
      $('.datatable').DataTable().destroy();
      this.dtTrigger.next();
    }
    else{
      $('.datatable').DataTable()
   .rows().invalidate('data')
   .draw(false);
    }
    if(moment() >= moment(eve.eventDate)){
      $('#addProfilebtn').addClass('disabled');
      $('#editProfilebtn').addClass('disabled');
      $('#exportFile').addClass('disabled');
      this.Condition = 'disabled';
      // $("#editProfilebtn").removeAttr("href");
      this.hideForPastEvent = false;
      this.UploadNotPossible = true;
      this.viewContent = "This Events Already Completed"
      
    }
    else{
      this.UploadNotPossible = false;
      this.hideForPastEvent = true;
      $('#addProfilebtn').removeClass('disabled');
      $('#editProfilebtn').removeClass('disabled');
      $('#exportFile').removeClass('disabled');
      this.Condition = 'enable';
      // $("#editProfilebtn").attr("href","#editEmployeeModal");
    }

    if(eve.listofApplicants.length >0)
    {
      if(this.data.length > 0){
        this.data = []
      }
      
      eve.listofApplicants.forEach((el) =>{
        this.data.push({
          Status:el.status[0],
          Name:el.applicantName,
          EmailId:el.applicantEmailId,
          Phone:el.applicantPhone,
          ExpectedCTC:el.applicantExpectedCTC,
          Experience:el.applicantExperience,
          Noticeperiod:el.applicantNoticePeriod,
          ProfileSubmittedBy:el.profileSubmittedBy,
          CompanySubmittedBy:el.CompanySubmittedBy,
          ResumeUrl:el.resumeUplodedUrl
        })
      });
      
    }
    



  }

 //Cancel validation
 remove(){
  this.addProfileForm.reset();
 } 

  // badge  color
  getBadgeClass(status){
    var badgeClass = "";
    switch(status) {
      case 'SUBMITTED':
        badgeClass = "badge badge-info badge2";
        break;
      case 'SHORTLISTED':
        badgeClass = "badge badge-small badge-success badge2";
        break; 
      case 'SELECTED':
        badgeClass = "badge badge-small badge-success badge2";
      break;
      case 'OFFERED':
        badgeClass = "badge badge-small badge-primary badge2";
      break;
      case 'JOINED':
        badgeClass = "badge badge-small badge-success badge2";
      break;
      case 'UNAVAILABLE':
        badgeClass = "badge badge-small badge-danger badge2";
        break;
      default:
        badgeClass = "badge badge-small badge-success badge2";
    }
    return badgeClass;
  }

  map(mapAdd: any) {
    var map = "https://www.google.com/maps/search/?api=1&query=" + mapAdd;
    window.open(map, '_blank');
  }
  // Logout
  onLogout() {
    localStorage.clear()
    this.router.navigate(['/partners-login']);
  }

  // form validation
  formvalidation() 
  {
    this.addProfileForm=this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      phone:[null,[Validators.required]],
      experience:[null,[Validators.required]],
      ctc:[null, [Validators.required]],
      notice:[null, [Validators.required]],
      files:[null,[Validators.required]],
      //matchPercentage:['',Validators.required]
  });

  }
  editFormvalidation(){
    this.editProfileForm = this.formBuilder.group({
      applicantName: [null, Validators.required],
      applicantEmailId: [null, Validators.required],
      applicantPhone:[null,[Validators.required]],
      applicantExperience:[null,[Validators.required]],
      applicantExpectedCTC:[null, [Validators.required]],
      applicantNoticePeriod:[null, [Validators.required]],
      unavailable:['',Validators.required]
    })
  }

  get f(){  
    return this.addProfileForm.controls;
  }
  
  async addProfile(){
    var partnerCheck = JSON.parse(localStorage.getItem("isPartner"));
    switch(partnerCheck){
      case false:
        this.addProfileForm.value.profileSubmittedBy= JSON.parse(localStorage.getItem('emailId'));
      case true:
        this.addProfileForm.value.profileSubmittedBy=JSON.parse(localStorage.getItem('emailId'));
    }
    this.addProfileForm.value.ObjectId = localStorage.getItem("ClickedEventId");
    this.addProfileForm.value.companyName = JSON.parse(localStorage.getItem("companyName"));
    this.addProfileForm.value.isPartner = JSON.parse(localStorage.getItem("isPartner"));
    this.addProfileForm.value.eventId = localStorage.getItem("eventId");
    this.addProfileForm.value.resumeUrl = this.userData.location;
    
   await this.authService.listOfApplicant(this.addProfileForm.value);
   $('#addmodal').click(function(){
     $('#addProfileModal').modal('hide');
   });   
  }
  // Edit Profile For Event Without Resume Upload
  public editProfiles(){
    var partnerCheck = JSON.parse(localStorage.getItem("isPartner"));
    switch(partnerCheck){
      case false:
        this.editProfileForm.value.profileSubmittedBy= JSON.parse(localStorage.getItem('emailId'));
      case true:
        this.editProfileForm.value.profileSubmittedBy=JSON.parse(localStorage.getItem('emailId'));
    }
    this.editProfileForm.value.ObjectId = localStorage.getItem("ClickedEventId");
    this.editProfileForm.value.companyName = JSON.parse(localStorage.getItem("companyName"));
    this.editProfileForm.value.isPartner = JSON.parse(localStorage.getItem("isPartner"));
    this.editProfileForm.value.eventId = localStorage.getItem("eventId");
    this.editProfileForm.value.ClickedApplicantId = localStorage.getItem("ClickedApplicantId");
    this.authService.EditApplicant(this.editProfileForm.value);
   $('#addmodal').click(function(){
     $('#editEmployeeModal').modal('hide');
   })  
  }

  EditProfileApplicant(applicant){
    localStorage.setItem("ClickedApplicantId",applicant._id);
    var checkingEmail = JSON.parse(localStorage.getItem("emailId"));
    if(this.hideForPastEvent && checkingEmail == applicant.profileSubmittedBy)
    {
      this.editProfileForm.setValue({
        applicantName:applicant.applicantName,
        applicantEmailId:applicant.applicantEmailId,
        applicantPhone:applicant.applicantPhone,
        applicantExperience:applicant.applicantExperience,
        applicantExpectedCTC:applicant.applicantExpectedCTC,
        applicantNoticePeriod:applicant.applicantNoticePeriod,
        unavailable: applicant.status[0] =="UNAVAILABLE" ? true:false, 
      });
      $("#editEmployeeModal").modal('show');
    }
    else if(this.hideForPastEvent && checkingEmail !== applicant.profileSubmittedBy){
      
      Swal({
        title: 'Warning',
        html:"Sorry ! You Cannot Edit This Profile",
        type: 'info',
        showCancelButton: false,
        confirmButtonColor: '#1976d2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK!',
      });
    }
    
    
  }
  // Closing Popup Modal
  close(){
    $("#editEmployeeModal").removeClass("show");
    $("#editEmployeeModal").hide(0);
  }

   // upload resume
   fileChangeEvent(event: any) {
    this.uploadErr = "";
    var file = event.target.files[0];
    this.parsingResume = true;
    this.SuccessparsingResume = false;
    if (file.type.match(/pdf\/*/) || file.type.match(/msword\/*/) || file.type.match(/vnd.openxmlformats-officedocument.wordprocessingml.document\/*/)) {
      this.uploadErr = "";
      this.resumeName = file.name
      this.fileToUpload = file;
      this.profSubmitter = {
        eventId:localStorage.getItem('eventId'),
        emailId:JSON.parse(localStorage.getItem('emailId'))
      }

      this.authService.postFile(this.fileToUpload,this.profSubmitter).subscribe(data => {
        this.userData = (data);
        if ((this.userData.responseStatus === 200)) {
          this.resumeStatus = "File uploaded successfully!";
          this.parsingResume = false;
          this.SuccessparsingResume = true;
          $('#addmodal').removeClass('disabled');
          setTimeout(() => {
            this.resumeStatus = "";
          }, 2000)
          if (data.data.phone && typeof (data.data.phone) === 'object' && data.data.phone.length > 0) {
            // this.contactNo = this.validContactNo = data.data.phone[0];
            this.contactNo = this.validContactNo = data.data.phone[0].replace('+91','').replace('-','').replace(' ','');
          } else {
            this.contactNo = ""
          }
          if (data.data.email && typeof (data.data.email) === 'object' && data.data.email.length > 0) {
            this.email = data.data.email[0];
          }
          this.resumeName = data.location;
          this.matchscore=data.profilematch;
        }
        if ((this.userData.responseStatus === 400)) {
          this.resumeStatus = this.userData.message;
          this.parsingResume = false;
          this.SuccessparsingResume = false;
          $('#addmodal').removeClass('disabled');
          setTimeout(() => {
            this.resumeStatus = "";
          }, 2000)
        }
      }, error => {
        this.resumeStatus = error.message;
      });
    }
    else {
      this.uploadErr = "File should be either pdf / doc / docx format!"
      return false;
    }
  }

  // Upcoming Events Subscription
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.showDialog = !this.showDialog;
  }

  ngAfterViewInit() {
      $('.dataTables_length').addClass('bs-select');
      this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }

  alertMsg(msg: string, type: string){
    if(type == 'success')
    {
      Swal({
        title: 'Success',
        text: msg,
        type: 'success',
        showCancelButton: false,
        confirmButtonColor: '#535BE2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK',
      });
    } 
    else if(type == 'warning')
    {
      Swal({
        title: 'Warning',
        html: msg,
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1976d2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK!',
      });
    }
  }
}
