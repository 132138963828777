import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router, ActivatedRoute } from '@angular/router';
@Injectable()
export class AuthenticationService {
  constructor(public jwtHelper: JwtHelperService, private router: Router) {

  }


  isAuthenticated() {
    const user = localStorage.getItem('authtoken');
    console.log('user', user);
    //  return user != null && user.length > 0;
     return !this.jwtHelper.isTokenExpired(user);
  }
}
 