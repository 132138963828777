<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<app-header></app-header>
<div class="section is-header bg-accent position-relative" style="margin-top: -2px;background:#e3eefe;">
    <div class="container flex-horizontal">
        <div class="col lg-5 flexv-justify-center md-12">
            <div>
                <h1 id="header_font">Our story</h1>
                <div class="text-medium" style="font-size: 14px;margin-top:20px;"><b> “Create the highest, grandest
                        vision possible for your life because you become what you believe” </b> – Oprah Winfrey <br><br>
                    Our founders have set grand vision to let every woman dream and achieve big. They believe that women
                    have the power to honor her choices and accomplish them. Everybody talks about career for women but
                    Muralidhar & Samir not just talked or thought about it, they have created grand career channel for
                    women with their unrivalled experience
                    <br><br>With over 2 decades of experience in Human Resources Function with Fortune 100 leading
                    companies, they believe hi tech applications and artificial intelligence in people function are the
                    key success factor in coming competitive environment. Their tech driven platform shall let the women
                    thrive with growth and success in career aspect
                    <br><br>Together, Muralidhar and Samir dream to break the age-old prejudices that women fit into few
                    roles and not all. They envision women breaking those stereotypes or traditional perceptions and
                    embark on a journey to soar high with success and inspire the world</div>
            </div>
        </div>
        <div class="col lg-1"></div>
        <div class="col lg-6 no-margin-bottom md-12"><img src="/assets/sheImages/aboutus/team.svg" alt=""></div>
    </div>
</div>
<div class="section our-team-styleOne" style="background-color: #faf5ff">
    <div class="container">
        <div class="col">
            <!-- <h2 id="header_font">Team with a common vision</h2> -->
            <div class="separator" style="margin-left:0px;margin-right:0px;">Team with a common vision</div>
        </div>
    </div>
    <div class="container wrapped ">
        <div class="row">
            <div class="col-lg-8 offset-lg-10 col-md-12 col-sm-12">
                <div class="single-team-member text-center">
                    <div class="image">
                        <span class="font_size1">P<br>R<br />A<br />C<br />H<br />I</span>
                        <img src="/assets/sheImages/aboutus/Prachi.jpg">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <p>Prachi is passionate about nurturing culture in people , cares about increasing
                                    happiness quotient.</p>
                                <li><a class="tran3s"><i class="fab fa-facebook-f" style="color:gray;"
                                            aria-hidden="true"></i></a></li>
                                <li><a class="tran3s"><i class="fab fa-twitter" style="color:gray;"
                                            aria-hidden="true"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/prachi-tiwari-5957216" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div><br>
                    <p style="color:#a234b2;"><b>Chief of People and Culture</b></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size3">M<br>U<br>R<br>A<br>L<br>I</span>
                        <img src="/assets/sheImages/aboutus/murali.jpg">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <p> Muralidhar dream to break the age old prejudices that women may not fit in to
                                    certain career roles. </p>
                                <li><a href="https://www.facebook.com/mteppala" target="_blank" class="tran3s"> <i
                                            class="fab fa-facebook-f" aria-hidden="true"></i> </a></li>
                                <li><a href="https://twitter.com/mteppala?s=08" target="_blank" class="tran3s"><i
                                            class="fab fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="https://in.linkedin.com/in/muralidharteppala" target="_blank"
                                        class="tran3s"> <i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br>
                    <p style="color:#a234b2;"><b>Founder</b></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size3">S<br>A<br>M<br>I<br>R</span>
                        <img src="/assets/sheImages/aboutus/Samir.jpg">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <p> Samir, driven by people and technology is confident that the future lies in right
                                    blend of these two.</p>
                                <li><a href="https://www.facebook.com/ghoshsmr29" target="_blank" class="tran3s"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                                <li><a href="https://twitter.com/SamirGhosh29" target="_blank" class="tran3s"><i
                                            class="fab fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/samir-ghosh-%E2%98%81-9871969" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Co-Founder</b> </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size3">A<br>R<br>U<br>N</span>
                        <img src="/assets/sheImages/aboutus/Arun.jpg" style="">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <p> Arun, driven by people and technology is confident that the future lies in right
                                    blend of these two.</p>
                                <!-- <li><a href="https://www.facebook.com/ghoshsmr29" target="_blank" class="tran3s"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a></li> -->
                                <!-- <li><a href="https://twitter.com/SamirGhosh29" target="_blank" class="tran3s"><i
                                            class="fab fa-twitter" aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/arungiyer/" target="_blank" class="tran3s"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>CTO</b> </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size4">R<br>A<br>N<br>J<br>A<br>N</span>
                        <img src="/assets/sheImages/aboutus/Priyaranjan.png" style="width:450px;height:300px">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <!-- <p>Samir, driven by people and technology is confident that the future lies in right blend of these two.</p> -->
                                <li><a href="https://github.com/techiepriyaranjan/" target="_blank" class="tran3s"><i
                                            class="fab fa-github"></i></a></li>
                                <!-- <li><a target="_blank" class="tran3s" style="color:gray;"><i class="fab fa-twitter"
                                            aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/techiepriyaranjan/" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Tech Innovator</b> </p>
                </div>
            </div>
            <div class="col-lg-3  col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size4">M<br>E<br>G<br>H<br>A</span>
                        <img src="/assets/sheImages/aboutus/MeghaN.png" style="width:450px;height:300px">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <!-- <p>Samir, driven by people and technology is confident that the future lies in right blend of these two.</p> -->
                                <li><a href="https://www.facebook.com/megha.mayuri.984" target="_blank"
                                        class="tran3s"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                                <!-- <li><a style="color:gray;" target="_blank" class="tran3s"><i class="fab fa-twitter"
                                            aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/megha-nimbargimath-63a119152/" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>

                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Quality Checker</b> </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size4">K<br>U<br>L<br>D<br>E<br>E<br>P</span>
                        <img src="/assets/sheImages/aboutus/Kuldeep.jpg" style="width:450px;height:300px;">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <!-- <p>Samir, driven by people and technology is confident that the future lies in right blend of these two.</p> -->
                                <li><a href="https://medium.com/@kuldeepnpatel" target="_blank" class="tran3s"><i
                                            class="fab fa-medium" aria-hidden="true"></i></a></li>
                                <!-- <li><a style="color:gray;" href="https://www.kaggle.com/kuldeepnpatel" target="_blank"
                                        class="tran3s">
                                        <i class="fab fa-kaggle" aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/kuldeepnpatel/" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Data Scientist</b> </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size4">A<br>J<br>I<br>T<br>H</span>
                        <img src="/assets/sheImages/aboutus/Ajith.jpg" style="width:450px;height:300px;">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <!-- <p>Samir, driven by people and technology is confident that the future lies in right blend of these two.</p> -->
                                <!-- <li><a href="https://www.linkedin.com/in/ajith-a-10040a175" target="_blank"
                                        class="tran3s"><i class="fab fa-medium" aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/ajith-a-10040a175" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Full-Stack Developer</b> </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size4">B<br>A<br>L<br>A</span>
                        <img src="/assets/sheImages/aboutus/BalaAditya..jpg" style="width:450px;height:300px;">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <!-- <p>Samir, driven by people and technology is confident that the future lies in right blend of these two.</p> -->
                                <!-- <li><a href="https://www.linkedin.com/in/ajith-a-10040a175" target="_blank"
                                                    class="tran3s"><i class="fab fa-medium" aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/bala-aditya-586008169" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Account Lead Traniee</b> </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size4">D<br>I<br>P<br>A<br>N<br>J<br>A<br>N</span>
                        <img src="/assets/sheImages/aboutus/DipanjanDas.jpg" style="width:450px;height:300px;">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <!-- <p>Samir, driven by people and technology is confident that the future lies in right blend of these two.</p> -->
                                <!-- <li><a href="https://www.linkedin.com/in/ajith-a-10040a175" target="_blank"
                                                                class="tran3s"><i class="fab fa-medium" aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/dipanjan-das-29951319b/" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Team Lead</b> </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div class="single-team-member">
                    <div class="image">
                        <span class="font_size4">A<br>N<br>K<br>I<br>T<br>A</span>
                        <img src="/assets/sheImages/aboutus/ankita.jpg" style="width:450px;height:300px;">
                        <div class="opacity tran3s">
                            <ul class="tran3s">
                                <!-- <p>Samir, driven by people and technology is confident that the future lies in right blend of these two.</p> -->
                                <!-- <li><a href="https://www.linkedin.com/in/ajith-a-10040a175" target="_blank"
                                                                class="tran3s"><i class="fab fa-medium" aria-hidden="true"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/in/ankita-joshi-89457b17/" target="_blank"
                                        class="tran3s"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <br />
                    <p style="color:#a234b2;"><b>Communications Manager</b> </p>
                </div>
            </div>
        </div>
    </div><br />

    <div class="separator">Advisors</div><br />
    <div class="container wrapped">
        <div class="row">
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive" src="/assets/sheImages/aboutus/CKMohan.png">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a href="https://www.facebook.com/chakasaril.k.mohan" target="_blank"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" aria-hidden="true" style="color:gray;"></i></a>
                                    <a href=" https://www.linkedin.com/in/c-k-mohan-1b8b274/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">C K Mohan</p>
                    <p class="social_text">Advisor to the Board & Chair Nomination, THP Group</p>
                </div>
            </div>
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive" src="/assets/sheImages/aboutus/MargaretAnn.jpeg">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a href="https://www.facebook.com/margaretann.cole" target="_blank"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a href=" https://www.linkedin.com/in/margaretanncole/" target="_blank"><i
                                            class="fab fa-twitter" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/margaretanncole/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Margaret Ann cole</p>
                    <p class="social_text">EVP, Global Talent Leader, Porter Novelli </p>
                </div>
            </div>
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive" src="/assets/sheImages/aboutus/NamitaKutty.png"
                                width="250px" height="250px">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a href=" https://www.facebook.com/namita.kutty" target="_blank"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a href="https://twitter.com/search?q=namita%20kutty&src=typed_query"
                                        target="_blank"><i class="fab fa-twitter" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/namitakutty/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Namita Kutty </p>
                    <p class="social_text">Executive Coach, Orange County</p>
                </div>
            </div>
            <div class="col-md-3 hidden-sm">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive" src="/assets/sheImages/aboutus/TanujaGairola.png"
                                width="250px" height="250px">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a href="https://www.facebook.com/tanuja.gairola" target="_blank"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" aria-hidden="true" style="color:gray;"></i></a>
                                    <a href="https://www.linkedin.com/in/tanuja-gairola-474528/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Tanuja Gairola</p>
                    <p class="social_text">Talent Acquisition Leader, Clearwater Analytics </p>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive" src="/assets/sheImages/aboutus/Ramasiva.jpeg">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a href="https://www.facebook.com/rama.siva.3958" target="_blank"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" style="color:gray;" aria-hidden="true"
                                            target="_blank"></i></a>
                                    <a href="https://www.linkedin.com/in/rama-siva-21000a4/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Rama Siva</p>
                    <p class="social_text">Head- Workforce Transformation, Agile HR & Human Capital Strategy, U.S. Bank
                    </p>
                </div>
            </div>
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive"
                                src="/assets/sheImages/aboutus/RuchiraBharadwaja.png" width="300px">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a href="https://www.facebook.com/ruchirab" target="_blank"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" style="color:gray;" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/ruchirab/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Ruchira Bharadwaja</p>
                    <p class="social_text">CHRO, Future Generali India Pvt ltd </p>
                </div>
            </div>
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive"
                                src="/assets/sheImages/aboutus/ElizabethSantanakumar.png" width="250px" height="250px">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a href="https://www.facebook.com/elizabeth.santanakumar" target="_blank"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" style="color:gray;" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/elizabeth-santana-kumar-b661933/"
                                        target="_blank"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Elizabeth Santanakumar</p>
                    <p class="social_text">Head of Organizational Effectiveness, Shell </p>

                </div>
            </div>
            <div class="col-md-3 hidden-sm">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive" src="assets/sheImages/aboutus/RuchiraDudani.png">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" style="color:gray;" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/ruchiradudani/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Ruchira Dudani</p>
                    <p class="social_text">Senior Director, Sales & Relationship Management, S&P Global </p>

                </div>
            </div>
        </div>
        <div class="row mt-4" style="width:100%;">
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">

                </div>
            </div>
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-30">
                            <img alt="team4" class="img-responsive" src="/assets/sheImages/aboutus/TracyGallery">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a><i class="fab fa-facebook-f" style="color:gray;" aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" style="color:gray;" aria-hidden="true"
                                            target="_blank"></i></a>
                                    <a href="https://www.linkedin.com/in/tracygallery5/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Tracy Gallery</p>
                    <p class="social_text">VP, CHRO Grantham University</p>
                </div>
            </div>
            <div class="col-md-3 col-sm-4">
                <div class="item-team text-center text-capitalize">
                    <div class="team-info">
                        <div class="team-img mb-10">
                            <img alt="team4" class="img-responsive" src="/assets/sheImages/aboutus/MonikaChaurasia.jpeg"
                                width="300px">
                            <div class="mask">
                                <div class="mask-inner">
                                    <a target="_blank"><i class="fab fa-facebook-f" style="color:gray;"
                                            aria-hidden="true"></i></a>
                                    <a><i class="fab fa-twitter" style="color:gray;" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/monika-chourasia/" target="_blank"><i
                                            class="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="social_media">Monika Chourasia</p>
                    <p class="social_text">SVP & Head of IT Operations, Vodafone </p>
                </div>
            </div>

            <div class="col-md-3 hidden-sm">
                <div class="item-team text-center text-capitalize">

                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>