<!-- Desktop View -->
<div id="desktop-view">
<div class="form-body">
  <div class="website-logo">
      <a routerLink="/homenew">
            <img class="logo-size" src="/assets/sheImages/logo56.svg" alt="" style="width: 172px; height: 43;" >
      </a>
  </div>
  <div class="row">
      <div class="img-holder">
          <div class="bg"></div>
          <div class="info-holder">
              <img src="/assets/sheImages/others/graphic3.svg" alt="">
          </div>
      </div>
      <div class="form-holder">
          <div class="form-content">
              <div class="form-items" style="max-width: 596px;">
                  <h1 id="header_font" style="font-size:44px;line-height:57px;">Wait Not, Procrastinate Not, Just Start</h1>
                  <br/>
                  <p class="p"> Itinerary, without a stopover, for a beautiful career is right here.</p>
                  <div class="page-links">
                      <a routerLink="/login" >Login</a><a routerLink="/user-signup"  class="active">Register</a>
                  </div> 
                  <form [formGroup]="SignupForm" novalidate (submit)="signUp(SignupForm.value)">
                        <input type="text" class="form-control " name="name" id="name"  placeholder="Full Name*" pattern="[a-zA-Z ]*"
                        [formControl]="SignupForm.controls['name']" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Full name is required</div>
                            <div  *ngIf="submitted && f.name.errors && f.name.errors.pattern">
                                Full Name should conatin only alphabets (a/A-z/Z) 
                            </div> 
                        </div>
                        <input type="email" class="form-control "  [(ngModel)]="email"  placeholder="Email id *" id="emailId" name="emailId"  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        [formControl]="SignupForm.controls['emailId']" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
                        <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                            <div *ngIf="f.emailId.errors.required">Email Id is required</div>
                            <div  *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
                                Email must be a valid email address.
                            </div>  
                        </div>
                        <input type="password" class="form-control email" placeholder=" Password *" id="password-field" name="password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{8,}"
                            [formControl]="SignupForm.controls['password']" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <span toggle="#password-field" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
                            margin-right: 20px;
                            margin-top: -42px;
                            position: relative;
                            z-index: 2;"></span>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div  *ngIf="submitted && f.password.errors && f.password.errors.pattern">
                                    At least 8 characters in length,
                                    Password should have at least 1 Number and at least 1  letter in capital
                                </div>
                            </div>
                            <div class="login_remember_box">
                                <label class="control control--checkbox "> By signing up, I agree to the <a routerLink="/termsandconditions"  >Terms and Condtions</a> and certify that I am a woman
                                    <input type="checkbox"class="form-control email"  id="termsandconditions" name="termsandconditions" 
                                    [formControl]="SignupForm.controls['termsandconditions']" [ngClass]="{ 'is-invalid': submitted && f.termsandconditions.errors }">
                                    <span class="control__indicator"></span>
                                    <div *ngIf="submitted && f.termsandconditions.errors" class="invalid-feedback">
                                        <div *ngIf="f.termsandconditions.errors.required"> required</div>
                                        <div  *ngIf="submitted && f.termsandconditions.errors ">
                                            required
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <p style="color:red;">{{erroMsg}}</p>                                                                                    
                      <div class="form-button">
                            <button id="submit" type="submit" [disabled]="loading"   class="ibtn " style="background-color: #d80075">Register</button>
                            <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
                      </div>
                  </form> 
                  <div class="other-links"> 
                    <span>Or login with</span>
                    <a><i class="fab fa-google" (click)="socialSignIn('google')"></i></a>
                    <a><i class="fab fa-linkedin-in" (click)="socialSignIn('linkedin')"></i></a>
                </div>
                <div class="other-links">
                    <p style="font-size:13px;margin-top:10px;">Already a user? Please <a id="reg_txt" routerLink="/login">Login</a></p>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
</div>
<!-- Mobile View -->
<div id="mobile-view">
    <div class="container">
       <div class="row form-content">
           <div class="col-sm-12">
               <div class="col-sm-3 form-items logo-login" style="max-width: 596px;">
                <a routerLink="/homenew">
                    <img class="logo-size" src="assets/sheImages/logo8.svg" alt="" >
                </a>
                   
               </div>
               <div class="form-items" style="max-width: 596px;">
                   <h3 id="header_font">Wait Not, Procrastinate Not, Just Start</h3>
                   <br/>
                   <!-- <p>Wait Not, Procrastinate Not, Just Start</p> -->
                   <p class="p"> Itinerary, without a stopover, for a beautiful career is right here.</p>
                   <div class="page-links">
                       <a routerLink="/login" >Login</a><a routerLink="/user-signup"  class="active">Register</a>
                   </div> 
                   <form [formGroup]="SignupForm" novalidate (submit)="signUp(SignupForm.value)">
                         <input type="text" class="form-control " name="name" id="name"  placeholder="Full Name*" pattern="[a-zA-Z ]*"
                         [formControl]="SignupForm.controls['name']" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                         <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                             <div *ngIf="f.name.errors.required">Full name is required</div>
                             <div  *ngIf="submitted && f.name.errors && f.name.errors.pattern">
                                 Full Name should conatin only alphabets (a/A-z/Z)
                                 
                             </div> 
                         </div>
                         <input type="email" class="form-control "  [(ngModel)]="email"  placeholder="Email id *" id="emailId" name="emailId"  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                         [formControl]="SignupForm.controls['emailId']" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
                         <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                             <div *ngIf="f.emailId.errors.required">Email Id is required</div>
                             <div  *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
                                 Email is invalid
                             </div>  
                         </div>
                         <input type="password" class="form-control email" placeholder=" Password *" id="password" name="password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{8,}"
                             [formControl]="SignupForm.controls['password']" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                             <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                 <div *ngIf="f.password.errors.required">Password is required</div>
                                 <div  *ngIf="submitted && f.password.errors && f.password.errors.pattern">
                                     At least 8 characters in length,
                                     Password should have at least 1 Number and at least 1  letter in capital
                                 </div>
                             </div>
                             <div class="login_remember_box">
                                 <label class="control control--checkbox "> By signing up, I agree to the <a routerLink="/termsandconditions"  >Terms and Condtions</a> and certify that I am a woman
                                     <input type="checkbox"class="form-control email"  id="termsandconditions" name="termsandconditions" 
                                     [formControl]="SignupForm.controls['termsandconditions']" [ngClass]="{ 'is-invalid': submitted && f.termsandconditions.errors }">
                                     <span class="control__indicator"></span>
                                     <div *ngIf="submitted && f.termsandconditions.errors" class="invalid-feedback">
                                         <div *ngIf="f.termsandconditions.errors.required"> required</div>
                                         <div  *ngIf="submitted && f.termsandconditions.errors ">
                                             required
                                         </div>
                                     </div>
                                 </label>
                                 <!-- <div *ngIf="submitted && f.termsandconditions.errors" class="invalid-feedback">
                                     <div *ngIf="f.termsandconditions.errors.required"> required</div>
                                    
                                 </div> -->
                             </div>
                             <!-- <input type="checkbox" name="vehicle1" value="Bike"> I have <br> -->
                             <p style="color:red;">{{erroMsg}}</p>                                                                                    
                       <div class="form-button">
                             <button id="submit" type="submit" [disabled]="loading"   class="ibtn " style="background-color: #d80075">Register</button>
                             <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
                       </div>
                   </form> 
                      
                   <div class="other-links"> 
                     <span>Or login with</span>
                    <a><i class="fab fa-google" (click)="socialSignIn('google')"></i></a>
                    <a><i class="fab fa-linkedin-in" (click)="socialSignIn('linkedin')"></i></a>

                 </div>
                 <div class="other-links">
                     <p style="font-size:13px;margin-top:10px;">Already a user? Please <a id="reg_txt" routerLink="/login">Login</a></p>
                 </div>
               </div>
           </div>
       </div>
    </div>
   </div>