import { Component, OnInit } from '@angular/core';
import { RecruiterService } from '../../services/recruiter.operations';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'minimal-employer-header',
  templateUrl: './minimal-employer-header.component.html',
  styleUrls: ['./minimal-employer-header.component.css']
})
export class MinimalEmployerHeaderComponent implements OnInit {
  companylogo: any;
  loading: boolean;
  erroMsg: string;
  logo: any;
  companyName: any;
  isRecruiter:boolean;
  loadAPI: Promise<any>;

  constructor(
    public apiService: RecruiterService,
    private router: Router,
    private RecruiterService: RecruiterService) {
  }

  ngOnInit() {
    if (localStorage.getItem('isRecruiter')) {
      // console.log(' its inside local' , JSON.parse(localStorage.getItem('isRecruiter')));
      this.isRecruiter = JSON.parse(localStorage.getItem('isRecruiter')) == true ? false : true;
    }

    this.RecruiterService.getBasicDetails().subscribe((response: any) => {
      this.logo = response.data.logo;
      this.companyName = response.data.companyName
    }, (error: any) => {
      console.log(error);
    })
    this.companylogo;
  }
  // Logout Button
  onLogout() {
    localStorage.removeItem('companyName')
    localStorage.removeItem('authtoken');
    localStorage.removeItem('emailId');
    this.router.navigate(['/employe-login']);
  }
}
