export class RegistrationModal {
    constructor(
      public logofile: any,
      public website: any,
      public addressline1: any,
      public pincode: any,
      public state: any,
      public country: any,
      public gstin: any,
      public sachac: any,
      public contact: any,
      public companyindustry: any,
      public location: any,
      public aboutus: any,
      public culture: any,
      public employeestrength: any,
      public genderdiversity: any,
      public additionalmaternitybenifits: any,
      public benifits: any,
      public otherBenefits: any,
    ) {
  
    }
  }
  
  export class RegistrationPhase1Modal {
    constructor(
      public website: any,
      public websiteerror: any,
      public addressline1: any,
      public addressline1error: any,
      public city: any,
      public cityerror: any,
      public pincode: any,
      public pincodeerror: any,
      public state: any,
      public stateerror: any,
      public country: any,
      public countryerror: any,
      public gstin: any,
      public gstinerror: any,
      public sachac: any,
      public sachacerror: any,
      public contact: any,
      public contacterror: any,
      public companyname: any,
      public companynameerror: any,
      public companyindustry: any,
      public companyindustryerror: any,
      public location: any,
      public locationerror: any,
      public logofile: any,
      public logofileerror: any,
    ) {
  
    }
  }
  
  export class RegistrationPhase2Modal {
    constructor(
      public aboutus: any,
      public aboutuserror: any,
      public culture: any,
      public cultureerror: any,
      public employeestrength: any,
      public employeestrengtherror: any,
      public genderdiversity: any,
      public genderdiversityerror: any,
      public additionalbenifits: any,
      public additionalbenifitserror: any,
      public benifits: any,
      public benifitserror: any,
      public otherBenefits: any,
      public otherBenefitserror: any,
      public coverPhoto: any,
      public coverPhotoerror: any
    ) {
  
    }
  }
  
  export class PostRegistrationPhase1Modal {
    constructor(
      public companyName: any,
      public address: any,
      public website: any,
      public location: any,
      public gstin: any,
      public sacorhac: any,
      public logo: any,
      public contactNo: any,
      public industry: any
    ) {
  
    }
  }
  
  export class PostRegistrationPhase2Modal {
    constructor(
      public aboutUs: any,
      public culture: any,
      public employeeStrength: any,
      public genderDiversity: any,
      public additionalMaternityBenefits: any,
      public womenCentricPolicies: any,
    
      public otherBenefits: any
    ) {
  
    }
    
  }
  export const IMAGE_URL ='https://angular.io/assets/images/logos/angular/angular.svg';

  