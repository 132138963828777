<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover">
    <div class="cp_logo_wrapper index_2_logo ">
        <a routerLink="/homenew">
            <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
        </a>
    </div>
    <div class="jb_navigation_wrapper index_2_right_menu index_3_right_menu">
        <div class="row" style="float:left;width:75%;height:auto;margin-top:10px;">
            <div class="container">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <form class="navbar-form" role="search">
                        <div class="input-group add-on">
                            <input type="text" class="form-control" #search [(ngModel)]="value"
                                placeholder="Search by title, skills or keyword" name="name" id="srch-term1">
                            <input type="text" class="form-control" [(ngModel)]="location" placeholder="Location"
                                name="location" id="srch-term2">
                            <div class="input-group-btn">
                                <button class="btn btn-default" type="submit" (click)="goToSearch()"><i
                                        class="fas fa-search"></i></button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
        <div class="posting_job employer_gap" style="float:right;">
            <ul>
                <li class="btn_hover">
                    <a routerLink="/employe-login"><b>employer</b></a>
                </li>
            </ul>
        </div>
        <div class="mainmenu green_main_menu blue_main_menu d-xl-block d-lg-block d-md-none d-sm-none d-none">
            <ul class="main_nav_ul menu_2_ul" style="margin:0px !important;">
                <li class="has-mega gc_main_navigation"><a routerLink="/login"
                        class="gc_main_navigation active_class active_class2 active_class3">Login</a>
                </li>
            </ul>
        </div>
    </div>

</div>
<div class="grow_next_wrapper jb_cover">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="index3_form_box jb_cover">
                    <div class="select_box3">
                        <select id="filter_dropdown" name="Select Experience" aria-placeholder="Select Experince"
                            (change)="filterExperience($event.target.value);finalFilter()">
                            <option value="any" selected data-default>Experience Level</option>
                            <option value="0">Fresher</option>
                            <option value="1">1 Year</option>
                            <option value="2">2 Years</option>
                            <option value="3">3 Years</option>
                            <option value="4">4 Years</option>
                            <option value="5">5 Years</option>
                            <option value="6">6 Years</option>
                            <option value="7">7 Years</option>
                            <option value="8">8 Years</option>
                            <option value="9">9 Years</option>
                            <option value="10">10+ Years</option>
                            <option value="15">15+ Years</option>
                            <option value="20">20+ Years</option>
                            <option value="any">Any</option>
                        </select>
                    </div>
                    <div class="select_box3">
                        <select id="filter_dropdown1" name="Select Salary" aria-placeholder="Select Salary"
                            (change)="filterSalary($event.target.value);finalFilter()">
                            <option value="" selected data-default>Salary (L/A)</option>
                            <option value="100000">Above 1 </option>
                            <option value="200000">2 </option>
                            <option value="400000">4 </option>
                            <option value="600000">6 </option>
                            <option value="800000">8 </option>
                            <option value="1000000">10 + </option>
                            <option value="2000000">20 + </option>
                            <option value="any">Any </option>
                        </select>
                    </div>
                    <div class="select_box3">
                        <select id="filter_dropdown2" name="Industry" aria-placeholder="Industry"
                            (change)="filterFa($event.target.value);finalFilter()">
                            <option value="any" selected data-default>Functional Area</option>
                            <option value="Administrative/Support">Administrative/Support</option>
                            <option value="Art/Design/Media">Art/Design/Media</option>
                            <option value="Buisness">Buisness</option>
                            <option value="Child Care">Child Care</option>
                            <option value="Education">Education</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Finance/Accounting">Finance/Accounting</option>
                            <option value="Health Care">Health Care</option>
                            <option value="Human Resources">Human Resources</option>
                            <option value="Insurance">Insurance</option>
                            <option value="Legal and Low Enforcement">Legal and Low Enforcement</option>
                            <option value="Manufaturing">Manufaturing</option>
                            <option value="Marketing/Public Relations">Marketing/Public Relations</option>
                            <option value="Nursing">Nursing</option>
                            <option value="Real Estate">Real Estate</option>
                            <option value="Restaurant and Hospitality">Restaurant and Hospitality</option>
                            <option value="Retails">Retails</option>
                            <option value="any">Any</option>
                        </select>
                    </div>
                    <div class="select_box3">
                        <select id="filter_dropdown3" name="Notice period" aria-placeholder="Notice period"
                            (change)="filterNP($event.target.value);finalFilter()">
                            <option value="any" selected data-default>Notice period</option>
                            <option value="15">15 Days</option>
                            <option value="30">30 Days</option>
                            <option value="45">45 Days</option>
                            <option value="60">60 days</option>
                            <option value="75">75 Days</option>
                            <option value="90">90 Days</option>
                            <option value="any">Any</option>

                        </select>
                    </div>
                    <div class="select_box3">
                        <select id="filter_dropdown4" name="Education" aria-placeholder="Education"
                            (change)="filterEd($event.target.value);finalFilter()">
                            <option value="" selected data-default>Education</option>
                            <option value="B.Tech">BTech</option>
                            <option value="BCA">BCA</option>
                            <option value="BSc">BSc</option>
                            <option value="MTech">MTech</option>
                            <option value="MCA">MCA</option>
                            <option value="MBA">MBA</option>
                            <option value="any">Any</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
        <div class="jb_heading_wraper">
            <h3 class="separator">Best candidates</h3>
        </div>
    </div>
</div>
<div class="candidate_dashboard_wrapper jb_cover">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 col-12" style="height:600px;overflow-y: scroll !important;">
                <div class="job_listing_left_fullwidth job_listing_grid_wrapper index2_listing_jobs index3_listing_jobs jb_cover"
                    *ngFor="let row of Finalvalue ">
                    <div class="row" (click)="myFunc(row.emailId)">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="jp_job_post_side_img">
                                <img src="{{row.profilePicture}}" style="width: 80px;
                                height: 106px;
                                padding: 10px 0 10px 0;
                                border-radius: 50%" alt="post_img" />

                            </div>
                            <div class="jp_job_post_right_cont">
                                <ul>
                                        <li style="width:100%;font-size:15px !important;">
                                                <p hidden> <input type="text" value="jobId" [(ngModel)]="row.jobId" />
                                                    <input type="text" value="companyName" name="companyName"
                                                        [(ngModel)]="row.companyName"> {{row.emailId}} </p>
                                                <p  class="social_media">{{row.name}}</p>
                                        </li>
                                    <li class="social_media" style="width:100%;font-size:15px !important;">
                                        <p>{{row.workExperiences[0].currentDesignation}}</p>
                                    </li>
                                    <li style="width:100%;font-size:15px !important;">
                                            <p><i class="fa fa-map-marker" 
                                                    style="color:#797979!important;font-size:12px;"></i>&nbsp;{{row.currentLocation}}</p>
                                        </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 col-12 mt-3" *ngIf="!loading">
                <div class="job_listing_left_fullwidth1 job_listing_grid_wrapper jb_cover">
                    <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="jp_job_post_side_img">
                                    <img src="{{profilePicture}}" style="margin-top: -6px;
                                                border-radius: 50%;
                                                width: 91px;
                                                height: 82px;" alt="post_img" />
                                </div>
                                <div class="jp_job_post_right_cont" id="jp_job_post_right_cont1">
                                    <ul>
                                        <li>
                                            <h4  class="designation"style="font-weight:900;font-size:15px;color:#393939">
                                                {{name}}</h4>
                                                
                                        </li>
                                        
                                        <li>
                                            <h6 class="com_name" style="font-size:15px;color:#797979;">
                                                {{currentDesignation}} </h6>
                                        </li>
                                        <li> <span class="location" style="font-size:15px;color:#797979; "><i class="fa fa-map-marker" 
                                                    style="color:#797979;font-size:12px;"></i>&nbsp; {{currentLocation}}</span></li>
                                    </ul>
                                </div><br/>
                        
                                <div class="jp_job_des jb_cover" style="padding-bottom: 0px; ">
                                        <h6 class="com_name" style="font-weight:900;color:#393939">About &nbsp;{{name}}</h6>
                                        <div class="row">
                                          <p style="padding:10px;">{{briefBio}}</p>
                                        </div>
                                      </div>
                                <div class="jp_job_des jb_cover" style="padding-bottom: 0px;">
                                        <h6 class="com_name" style="font-weight:900;color:#393939">Experience</h6>
                                        <div class="jp_job_post_right_cont" >
                                          <ul>
                                        
                                            <li>
                                              <h6 class="com_name" style="font-size:15px;"><i class="fa fa-industry" aria-hidden="true" style="font-size:12px;color:#797979;margin-top:-4px;"></i>&nbsp; {{currentCompany}} </h6>
                                            </li>
                                            <li>
                                              <h6 class="com_name" style="font-size:15px;">{{currentDesignation}}</h6>
                                            </li>
                                            <li>
                                                <h6 class="com_name" style="font-size:15px;">{{joiningDate}}- {{lastDate}}</h6>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12 ">
                                <div class="jp_job_post_right_cont text-right">
                                    <ul>
                                        <li>
                                            <h4  style="color:#797979;font-size:15px;">Work Exp : {{totalWorkExperience}} Yrs </h4>
                                        </li><br/>
                                        <li>
                                            <h4  style="color:#797979;font-size:15px;">CTC : <span> ₹ {{currentCtc}} PA
                                                </span></h4>
                                        </li>
                                    </ul>
                                </div><br/>
                                <div class="jp_job_des jb_cover" style="margin-top:55px;">
                                        <h6 class="com_name" style="font-weight:900;color:#393939">Education Details</h6>
                                        <div class="jp_job_post_right_cont">
                                          <ul>
                                            <li>
                                              <h4  style="color:#797979;font-weight:none;font-size:15px;"> <i class="fa fa-university" aria-hidden="true" style="font-size:12px;color:#797979;margin-top:-4px;"></i>&nbsp; {{institute}} </h4>
                                            </li>
                                            <li>
                                              <h4  style="color:#797979;font-weight:none;font-size:15px;"> <i class="fas fa-graduation-cap" aria-hidden="true" style="font-size:12px;color:#797979;margin-top:-4px;"></i>&nbsp; {{degree}} ({{specialization}}) ,{{passingYear}}</h4>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <h6 class="com_name" style="font-weight:900;color:#393939; padding: inherit;">Technical skills</h6>
                                      <div class="jp_job_post_right_cont">
                                        <ul>
                                          <li>
                                            <h4 *ngFor="let technicalSkill of technicalSkill " style="color:#797979;font-weight:none; font-size: 15px; margin: 8px;"> &nbsp; {{technicalSkill.skillName}} </h4>
                                          </li>
                                        
                                        </ul>
                                      </div>
       
                                <div class="jp_job_res jb_cover text-left" style="margin-top:200px;">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="jp_job_post_right_btn_wrapper jb_cover">
                                            <ul>
                                                    <li>
                                                            <div class="job_adds_right mt-2">
                                                                <i class="fas fa-file-download" routerLink="/employe-signup" style="font-size:20px;" ></i>
                                                            </div>
                                                        </li>
                                                   
                                                        <li> <a routerLink="/employe-signup" id="job-apply">Message</a>
                                                        </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
         </div>
     </div>
 </div>
 </div> 
  <app-footer ></app-footer>

 