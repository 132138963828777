var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { FormBuilder, Validators } from '@angular/forms';
import { ExcelService } from '../../services/excel.service';
import { DataTableDirective } from 'angular-datatables';
var PartnersAdminDashboardComponent = /** @class */ (function () {
    function PartnersAdminDashboardComponent(excelService, _location, modalService, router, formBuilder, authService) {
        this.excelService = excelService;
        this._location = _location;
        this.modalService = modalService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.showDialog = false;
        this.showEditModal = false;
        this.upcomingEventSelected = {};
        this.checked = false;
        this.EventDetail = {};
        this.filteredProfileList = [];
        this.moment = moment;
        this.showSpinner = true;
        this.applicant = {
            applicantName: "",
            applicantEmailId: "",
            applicantPhone: "",
            applicantExperience: "",
            applicantExpectedCTC: "",
            applicantNoticePeriod: "",
            unavailable: true
        };
        this.matchscore = '--';
        this.updateData = {};
        this.data = [];
        this.dtOptions = {};
        this.dtTrigger = new Subject();
    }
    PartnersAdminDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10
        };
        var tokenVerify = localStorage.getItem('authtoken');
        if (!tokenVerify) {
            this.router.navigate(['partners-login']);
            return;
        }
        this.formvalidation();
        this.editFormvalidation();
        localStorage.setItem('defaultcompanylogo', '/assets/sheImages/partners/company_default.png');
        this.companySrc = localStorage.getItem('defaultcompanylogo');
        this.usertype = JSON.parse(localStorage.getItem("isPartner"));
        this.partnerEmail = JSON.parse(localStorage.getItem("emailId"));
        //console.warn(this.usertype,' its type of User');
        this.companyName = JSON.parse(localStorage.getItem("companyName"));
        if (this.usertype) {
            this.confirmDisabled = true;
            this.authService.GetAllUpcomingEvents().subscribe(function (Eventdata) {
                _this.UpcomingEventData = Eventdata.data;
                _this.dataValue = _this.companies(_this.UpcomingEventData, 4);
                $('#addProfilebtn').addClass('disabled');
                $('#editProfilebtn').addClass('disabled');
                $('#exportFile').addClass('disabled');
                _this.Condition = 'disabled';
                _this.showSpinner = false;
            }, function (error) {
                _this.alertMsg(error.message, 'warning');
            });
        }
        else {
            //console.warn('its false');
            this.partnerEmail = JSON.parse(localStorage.getItem("partnerEmail"));
            this.confirmDisabled = false;
            // this.companyName = JSON.parse(localStorage.getItem("companyName"));
            this.authService.getAllSubscribedEvents(this.partnerEmail).subscribe(function (Eventdata) {
                _this.UpcomingEventData = Eventdata.data;
                _this.dataValue = _this.companies(_this.UpcomingEventData, 4);
                _this.showSpinner = false;
                $('#addProfilebtn').addClass('disabled');
                $('#editProfilebtn').addClass('disabled');
                $('#exportFile').addClass('disabled');
                _this.Condition = 'disabled';
            }, function (error) {
                _this.alertMsg(error.message, 'warning');
            });
        }
        this.getPastEvents(this.partnerEmail);
    };
    // get all Past Events
    PartnersAdminDashboardComponent.prototype.getPastEvents = function (partnerEmail) {
        var _this = this;
        this.authService.GetAllPastEvents(partnerEmail).subscribe(function (e) {
            _this.listofPastEvent = e.data;
            if (_this.listofPastEvent.length > 0) {
                _this.EventDetail = _this.listofPastEvent[0];
            }
            else {
                _this.EventDetail = {};
            }
        }, function (error) {
            _this.alertMsg(error.message, 'warning');
        });
    };
    PartnersAdminDashboardComponent.prototype.companies = function (array, size) {
        var results = [];
        results = [];
        while (array.length) {
            results.push(array.splice(0, size));
        }
        return results;
    };
    // Download Excel
    PartnersAdminDashboardComponent.prototype.exportAsXLSX = function () {
        if (this.ClikedEvent == true && this.EventDetail.listofApplicants.length > 0) {
            this.excelService.exportAsExcelFile(this.data, 'Profiles');
            this.data = [];
        }
    };
    // Disable Btn before Filling Form
    PartnersAdminDashboardComponent.prototype.disableAddbtn = function () {
        $('#addmodal').addClass('disabled');
    };
    // Check Partner Subscription
    PartnersAdminDashboardComponent.prototype.checkSubscribtion = function (eve) {
        var _this = this;
        if (eve.eventEnrolledBy.length !== 0) {
            this.isSubscribedEmail = JSON.parse(localStorage.getItem('emailId'));
            eve.eventEnrolledBy.forEach(function (el) {
                _this.UserSubscribed = el == _this.isSubscribedEmail ? true : false;
            });
        }
        else {
            this.UserSubscribed = false;
        }
        return this.UserSubscribed;
    };
    // Add Associte Page
    PartnersAdminDashboardComponent.prototype.addAssociate = function () {
        localStorage.setItem('userpicUrl', "/assets/sheImages/aboutus/murali.jpg");
        localStorage.setItem('userType', 'PartnerAdmin');
        this.router.navigate(['partners-addrecruiter']);
    };
    PartnersAdminDashboardComponent.prototype.openModal = function (data, i) {
        this.upcomingEventSelected = data;
        if (Object.keys(this.upcomingEventSelected).length !== 0) {
            this.showDialog = !this.showDialog;
            this.companySrc = data.src ? data.src : localStorage.getItem('defaultcompanylogo');
        }
    };
    PartnersAdminDashboardComponent.prototype.CloseModal = function () {
        this.showDialog = !this.showDialog;
    };
    PartnersAdminDashboardComponent.prototype.Confirm = function (data) {
        var _this = this;
        var SubscribedBy = JSON.parse(localStorage.getItem('emailId'));
        this.upcomingEventSelected["isSubscribed"] = true;
        this.upcomingEventSelected["SubscribedBy"] = SubscribedBy;
        this.checked = true;
        this.authService.PartnerSubscribed(this.upcomingEventSelected).subscribe(function (res) {
            if (res) {
                _this.router.navigate(['partners-admin']);
                _this.CloseModal();
            }
            _this.alertMsg(res.message, 'success');
        }, function (error) {
            console.log(error);
            _this.alertMsg(error.message, 'warning');
        });
    };
    // Edit Profile
    PartnersAdminDashboardComponent.prototype.EditProfile = function (e) {
        console.warn(e, ' its clicked profile');
        this.applicant = e;
    };
    // data Table Re-Render
    PartnersAdminDashboardComponent.prototype.rerender = function () {
        var _this = this;
        this.dtElement.dtInstance.then(function (dtInstance) {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            _this.dtTrigger.next();
        });
    };
    // Selected Past Events
    PartnersAdminDashboardComponent.prototype.showDetails = function (eve) {
        var _this = this;
        //console.warn(eve,' its clicked data');
        this.EventDetail = eve;
        //filter the event.list of Applicants
        this.filteredProfileList = [];
        eve.listofApplicants.forEach(function (el) {
            if (_this.companyName == el.CompanySubmittedBy || _this.partnerEmail == el.profileSubmittedBy) {
                _this.filteredProfileList.push(el);
            }
        }, this);
        eve['listofApplicants'] = this.filteredProfileList;
        this.ClikedEvent = true;
        localStorage.setItem("ClickedEventId", eve._id);
        localStorage.setItem("eventId", eve.eventId);
        // this.dtTrigger.next();
        // this.rerender();
        if (eve.listofApplicants.length > 0) {
            $('.datatable').DataTable().destroy();
            this.dtTrigger.next();
        }
        else {
            $('.datatable').DataTable()
                .rows().invalidate('data')
                .draw(false);
        }
        if (moment() >= moment(eve.eventDate)) {
            $('#addProfilebtn').addClass('disabled');
            $('#editProfilebtn').addClass('disabled');
            $('#exportFile').addClass('disabled');
            this.Condition = 'disabled';
            // $("#editProfilebtn").removeAttr("href");
            this.hideForPastEvent = false;
            this.UploadNotPossible = true;
            this.viewContent = "This Events Already Completed";
        }
        else {
            this.UploadNotPossible = false;
            this.hideForPastEvent = true;
            $('#addProfilebtn').removeClass('disabled');
            $('#editProfilebtn').removeClass('disabled');
            $('#exportFile').removeClass('disabled');
            this.Condition = 'enable';
            // $("#editProfilebtn").attr("href","#editEmployeeModal");
        }
        if (eve.listofApplicants.length > 0) {
            if (this.data.length > 0) {
                this.data = [];
            }
            eve.listofApplicants.forEach(function (el) {
                _this.data.push({
                    Status: el.status[0],
                    Name: el.applicantName,
                    EmailId: el.applicantEmailId,
                    Phone: el.applicantPhone,
                    ExpectedCTC: el.applicantExpectedCTC,
                    Experience: el.applicantExperience,
                    Noticeperiod: el.applicantNoticePeriod,
                    ProfileSubmittedBy: el.profileSubmittedBy,
                    CompanySubmittedBy: el.CompanySubmittedBy,
                    ResumeUrl: el.resumeUplodedUrl
                });
            });
        }
    };
    //Cancel validation
    PartnersAdminDashboardComponent.prototype.remove = function () {
        this.addProfileForm.reset();
    };
    // badge  color
    PartnersAdminDashboardComponent.prototype.getBadgeClass = function (status) {
        var badgeClass = "";
        switch (status) {
            case 'SUBMITTED':
                badgeClass = "badge badge-info badge2";
                break;
            case 'SHORTLISTED':
                badgeClass = "badge badge-small badge-success badge2";
                break;
            case 'SELECTED':
                badgeClass = "badge badge-small badge-success badge2";
                break;
            case 'OFFERED':
                badgeClass = "badge badge-small badge-primary badge2";
                break;
            case 'JOINED':
                badgeClass = "badge badge-small badge-success badge2";
                break;
            case 'UNAVAILABLE':
                badgeClass = "badge badge-small badge-danger badge2";
                break;
            default:
                badgeClass = "badge badge-small badge-success badge2";
        }
        return badgeClass;
    };
    PartnersAdminDashboardComponent.prototype.map = function (mapAdd) {
        var map = "https://www.google.com/maps/search/?api=1&query=" + mapAdd;
        window.open(map, '_blank');
    };
    // Logout
    PartnersAdminDashboardComponent.prototype.onLogout = function () {
        localStorage.clear();
        this.router.navigate(['/partners-login']);
    };
    // form validation
    PartnersAdminDashboardComponent.prototype.formvalidation = function () {
        this.addProfileForm = this.formBuilder.group({
            name: [null, Validators.required],
            email: [null, Validators.required],
            phone: [null, [Validators.required]],
            experience: [null, [Validators.required]],
            ctc: [null, [Validators.required]],
            notice: [null, [Validators.required]],
            files: [null, [Validators.required]],
        });
    };
    PartnersAdminDashboardComponent.prototype.editFormvalidation = function () {
        this.editProfileForm = this.formBuilder.group({
            applicantName: [null, Validators.required],
            applicantEmailId: [null, Validators.required],
            applicantPhone: [null, [Validators.required]],
            applicantExperience: [null, [Validators.required]],
            applicantExpectedCTC: [null, [Validators.required]],
            applicantNoticePeriod: [null, [Validators.required]],
            unavailable: ['', Validators.required]
        });
    };
    Object.defineProperty(PartnersAdminDashboardComponent.prototype, "f", {
        get: function () {
            return this.addProfileForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    PartnersAdminDashboardComponent.prototype.addProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var partnerCheck;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        partnerCheck = JSON.parse(localStorage.getItem("isPartner"));
                        switch (partnerCheck) {
                            case false:
                                this.addProfileForm.value.profileSubmittedBy = JSON.parse(localStorage.getItem('emailId'));
                            case true:
                                this.addProfileForm.value.profileSubmittedBy = JSON.parse(localStorage.getItem('emailId'));
                        }
                        this.addProfileForm.value.ObjectId = localStorage.getItem("ClickedEventId");
                        this.addProfileForm.value.companyName = JSON.parse(localStorage.getItem("companyName"));
                        this.addProfileForm.value.isPartner = JSON.parse(localStorage.getItem("isPartner"));
                        this.addProfileForm.value.eventId = localStorage.getItem("eventId");
                        this.addProfileForm.value.resumeUrl = this.userData.location;
                        return [4 /*yield*/, this.authService.listOfApplicant(this.addProfileForm.value)];
                    case 1:
                        _a.sent();
                        $('#addmodal').click(function () {
                            $('#addProfileModal').modal('hide');
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    // Edit Profile For Event Without Resume Upload
    PartnersAdminDashboardComponent.prototype.editProfiles = function () {
        var partnerCheck = JSON.parse(localStorage.getItem("isPartner"));
        switch (partnerCheck) {
            case false:
                this.editProfileForm.value.profileSubmittedBy = JSON.parse(localStorage.getItem('emailId'));
            case true:
                this.editProfileForm.value.profileSubmittedBy = JSON.parse(localStorage.getItem('emailId'));
        }
        this.editProfileForm.value.ObjectId = localStorage.getItem("ClickedEventId");
        this.editProfileForm.value.companyName = JSON.parse(localStorage.getItem("companyName"));
        this.editProfileForm.value.isPartner = JSON.parse(localStorage.getItem("isPartner"));
        this.editProfileForm.value.eventId = localStorage.getItem("eventId");
        this.editProfileForm.value.ClickedApplicantId = localStorage.getItem("ClickedApplicantId");
        this.authService.EditApplicant(this.editProfileForm.value);
        $('#addmodal').click(function () {
            $('#editEmployeeModal').modal('hide');
        });
    };
    PartnersAdminDashboardComponent.prototype.EditProfileApplicant = function (applicant) {
        localStorage.setItem("ClickedApplicantId", applicant._id);
        var checkingEmail = JSON.parse(localStorage.getItem("emailId"));
        if (this.hideForPastEvent && checkingEmail == applicant.profileSubmittedBy) {
            this.editProfileForm.setValue({
                applicantName: applicant.applicantName,
                applicantEmailId: applicant.applicantEmailId,
                applicantPhone: applicant.applicantPhone,
                applicantExperience: applicant.applicantExperience,
                applicantExpectedCTC: applicant.applicantExpectedCTC,
                applicantNoticePeriod: applicant.applicantNoticePeriod,
                unavailable: applicant.status[0] == "UNAVAILABLE" ? true : false,
            });
            $("#editEmployeeModal").modal('show');
        }
        else if (this.hideForPastEvent && checkingEmail !== applicant.profileSubmittedBy) {
            Swal({
                title: 'Warning',
                html: "Sorry ! You Cannot Edit This Profile",
                type: 'info',
                showCancelButton: false,
                confirmButtonColor: '#1976d2',
                cancelButtonColor: '#FF7E39',
                confirmButtonText: 'OK!',
            });
        }
    };
    // Closing Popup Modal
    PartnersAdminDashboardComponent.prototype.close = function () {
        $("#editEmployeeModal").removeClass("show");
        $("#editEmployeeModal").hide(0);
    };
    // upload resume
    PartnersAdminDashboardComponent.prototype.fileChangeEvent = function (event) {
        var _this = this;
        this.uploadErr = "";
        var file = event.target.files[0];
        this.parsingResume = true;
        this.SuccessparsingResume = false;
        if (file.type.match(/pdf\/*/) || file.type.match(/msword\/*/) || file.type.match(/vnd.openxmlformats-officedocument.wordprocessingml.document\/*/)) {
            this.uploadErr = "";
            this.resumeName = file.name;
            this.fileToUpload = file;
            this.profSubmitter = {
                eventId: localStorage.getItem('eventId'),
                emailId: JSON.parse(localStorage.getItem('emailId'))
            };
            this.authService.postFile(this.fileToUpload, this.profSubmitter).subscribe(function (data) {
                _this.userData = (data);
                if ((_this.userData.responseStatus === 200)) {
                    _this.resumeStatus = "File uploaded successfully!";
                    _this.parsingResume = false;
                    _this.SuccessparsingResume = true;
                    $('#addmodal').removeClass('disabled');
                    setTimeout(function () {
                        _this.resumeStatus = "";
                    }, 2000);
                    if (data.data.phone && typeof (data.data.phone) === 'object' && data.data.phone.length > 0) {
                        // this.contactNo = this.validContactNo = data.data.phone[0];
                        _this.contactNo = _this.validContactNo = data.data.phone[0].replace('+91', '').replace('-', '').replace(' ', '');
                    }
                    else {
                        _this.contactNo = "";
                    }
                    if (data.data.email && typeof (data.data.email) === 'object' && data.data.email.length > 0) {
                        _this.email = data.data.email[0];
                    }
                    _this.resumeName = data.location;
                    _this.matchscore = data.profilematch;
                }
                if ((_this.userData.responseStatus === 400)) {
                    _this.resumeStatus = _this.userData.message;
                    _this.parsingResume = false;
                    _this.SuccessparsingResume = false;
                    $('#addmodal').removeClass('disabled');
                    setTimeout(function () {
                        _this.resumeStatus = "";
                    }, 2000);
                }
            }, function (error) {
                _this.resumeStatus = error.message;
            });
        }
        else {
            this.uploadErr = "File should be either pdf / doc / docx format!";
            return false;
        }
    };
    // Upcoming Events Subscription
    PartnersAdminDashboardComponent.prototype.onKeydownHandler = function (event) {
        this.showDialog = !this.showDialog;
    };
    PartnersAdminDashboardComponent.prototype.ngAfterViewInit = function () {
        $('.dataTables_length').addClass('bs-select');
        this.dtTrigger.next();
    };
    PartnersAdminDashboardComponent.prototype.ngOnDestroy = function () {
        // Do not forget to unsubscribe the event
        // this.dtTrigger.unsubscribe();
    };
    PartnersAdminDashboardComponent.prototype.alertMsg = function (msg, type) {
        if (type == 'success') {
            Swal({
                title: 'Success',
                text: msg,
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#535BE2',
                cancelButtonColor: '#FF7E39',
                confirmButtonText: 'OK',
            });
        }
        else if (type == 'warning') {
            Swal({
                title: 'Warning',
                html: msg,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1976d2',
                cancelButtonColor: '#FF7E39',
                confirmButtonText: 'OK!',
            });
        }
    };
    return PartnersAdminDashboardComponent;
}());
export { PartnersAdminDashboardComponent };
