import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';

import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedinLoginProvider
} from 'angular-6-social-login';

@Component({
  selector: 'app-jobseeker-signup',
  templateUrl: './job-signup.component.html',
  styleUrls: ['./job-signup.component.css']
})
export class JobseekerSignupComponent implements OnInit {


  error_message=null;
  errorFromApi=false;
  SignupFormForEmployee: FormGroup;
  formInvalid=false;
  formErrors=false;
  passwordMismatch=false;
  submitted = false;
  mobilePatern=false;
  emailId:any;
  password:any;
  req_signup:any;
  companyName: any;
  invalid: any;
  userData: any;
  erroMsg: string;
  loading: boolean;
  SignupForm: FormGroup;
  disableLogin: boolean = false;
  email: any;
  emails: string;
  constructor(private fb: FormBuilder,private authService: AuthServices,private socialAuthService: AuthService, private router: Router,private formBuilder: FormBuilder,private HttpClient:HttpClient) { 
    this.SignupForm = fb.group({
      'emailId': [null, Validators.required],
      'name': [null, Validators.required],
      'password': [null, Validators.required],
      'termsandconditions': [false, Validators.pattern('true')],
      'resume': ['']
    });
  }
  get f() { return this.SignupForm.controls; } 
  ngOnInit() {
    document.location.href = "https://jobs.shenzyn.com/#/user/sign-up";
    // $(".toggle-password").click(function() {

    //   $(this).toggleClass("fa-eye fa-eye-slash");
    //   var input = $($(this).attr("toggle"));
    //   if (input.attr("type") == "password") {
    //     input.attr("type", "text");
    //   } else {
    //     input.attr("type", "password");
    //   }
    // });
    //  this.emails = localStorage.getItem('email'.replace(/['"]+/g, '')) 
    //  this.email=this.emails.replace(/['["[]]+/g, '')
  }
  // form validation
  signUp(formdata:any) {
    this.submitted = true; 
   if (this.SignupForm.invalid)
    {
      return
    }
    this.loading = true;
      this.authService.signIn(formdata).subscribe(
        data => {
          this.userData = (data);
          if ((this.userData.responseStatus === 200)) {
            console.log('200-Success');
            this.loading = false;
            this.erroMsg = '';
            this.submitted = false;
            swal({
              title: 'Success',
              text: data.message,
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#535BE2',
              cancelButtonColor: '#FF7E39',
              confirmButtonText: 'OK',
            }).then((confirm) => {
                  if (confirm) {
                    this.router.navigate(['/login']);
                  }
                });
          }
        },
        error => {
          if (error.responseStatus === 401) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 500) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 400) { 
            this.loading = false;
            this.erroMsg = error.message; 
          }
        },
        () => console.log()
      );
    }
    public socialSignIn(socialPlatform : string) {
      let socialPlatformProvider;
      if(socialPlatform == "google"){
        socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      this.socialAuthService.signIn(socialPlatformProvider).then(
        (userData) => {
          let idTokenData={"code":userData.idToken};
            this.authService.gmail(idTokenData).subscribe(
              data => {
                this.userData = (data);
                if ((this.userData.responseStatus === 200)) {
                  localStorage.setItem("authtoken", JSON.stringify(data.authToken));
                  localStorage.setItem("emailId", JSON.stringify(data.data.email));
                  localStorage.setItem("name", JSON.stringify(data.data.name));
                  this.router.navigate(['/user-profile']);
                }
                else if (data.responseStatus === 201) {
                  this.loading = false;
                  this.erroMsg = data.message;
                }
              },
              error => {
               if (error.responseStatus === 401) {
                  this.loading = false;
                  this.erroMsg = error.message;
                } else if (error.responseStatus === 500) {
                  this.loading = false;
                  this.erroMsg = error.message;
                } else if (error.responseStatus === 400) { 
                  this.loading = false;
                  this.erroMsg = error.message; 
                }
              },
              () => console.log()
            );
        }
      );
      } else if(socialPlatform == "linkedin"){
        this.authService.linkedin();
        // socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID
        // this.socialAuthService.signIn(socialPlatformProvider).then(
        //   (userData) => {
        //   }
        // );
      }
    }
  }
