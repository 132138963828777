import { Component, HostListener, OnInit } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import * as c3 from 'c3';

import { JobseekerService } from '../../services/jobseeker.operations';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-companysingle',
  templateUrl: './companysingle.component.html',
  styleUrls: ['./companysingle.component.css']
})
export class CompanysingleComponent implements OnInit {

  public pieChartLabels: string[] = ["This week", "Last 2 months", "Last month", "Last week"];
  // public pieChartData:number[] = [21, 39, 10, 14];
  acticeJobsTemp = 0;
  appRecievedTemp = 0;
  public pieChartData: number[] = [];
  public pieChartType: string = 'pie';
  public pieChartOptions: any = {
    'backgroundColor': [
      "#FF6384",
      "#4BC0C0",
      "#FFCE56",
      "#E7E9ED",
      "#36A2EB"
    ]
  };
  public rj_ChartLabels: string[] = ["1", "2", "3", "4", "5", "6", "7"];
  public rajesh_chart_here: string = 'line';
  public rj_ChartData: number[] = [21, 39, 10, 14, 20, 11, 4, 3, 2, 1];
  public rj_chart_opt: any = {
    'backgroundColor': [
      "#36A2EB"
    ]
  };


  mydata: [[
    { y: 450 },
    { y: 414 },
    { y: 520, indexLabel: "highest", markerColor: "red", markerType: "triangle" },
    { y: 460 },
    { y: 450 },
    { y: 500 },
    { y: 480 },
    { y: 480 },
    { y: 410, indexLabel: "lowest", markerColor: "DarkSlateGrey", markerType: "cross" },
    { y: 500 },
    { y: 480 },
    { y: 510 }
  ]
  ];
  single: any[];
  multi: any[];
  dateData: any[];
  dateDataWithRange: any[];
  range = false;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  tooltipDisabled = false;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'GDP Per Capita';
  showGridLines = true;
  innerPadding = 0;
  autoScale = true;
  timeline = false;
  barPadding = 8;
  groupPadding = 0;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  view = '';
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;
  rangeFillOpacity = 0.15;
  public imagePath;
  imgURL: any = '';
  public message: string;

  colorScheme = {
    domain: ['#835fff', '#ff5d6d', '#5d3cff', '#4728f3']
  };
  schemeType = 'ordinal';
  name: string;
  url: any = '';
  email: string;
  currentCompany: any;
  currentDesignation: any;
  currentLocation: any;
  institute: any;
  constructor(private router: Router,private JobseekerService: JobseekerService,) {
    // Object.assign(this, {
    //   single

    // });

  }
  ngOnInit() {
    // 
    this.email = localStorage.getItem('emailId').replace(/['"]+/g, '')
    // this.name = localStorage.getItem('name').replace(/['"]+/g, '')
    console.log(this.name)

    this.JobseekerService.userProfile().subscribe(
      data => {

        const dataVal = data
        this.name = dataVal.data.name
        this.currentCompany = dataVal.data.workExperiences[0].currentCompany
        this.currentDesignation = dataVal.data.workExperiences[0].currentDesignation
        this.currentLocation = dataVal.data.currentLocation
        this.institute = dataVal.data.educationDetails[0].institute
        console.log(this.currentCompany)
        console.log(data)

      },

      error => console.log('fetched error', error));
    // console.log(message)



    this.name = localStorage.getItem('name').replace(/['"]+/g, '')
    console.log(this.name)

    var min = 4;
    var max = 20;
    for (let x = 0; x < 5; x++) {
      var random =
        Math.floor(Math.random() * (+max - +min)) + +min;
      this.pieChartData.push(random);
    }
    this.acticeJobsTemp = this.pieChartData[2] + 100;
    this.appRecievedTemp = this.pieChartData[1] + 60;
 
  }


  public chartClicked(e: any): void {
    console.log(e);
  }

  // event on pie chart slice hover
  public chartHovered(e: any): void {
    console.log(e);
  }

  // event on pie chart slice hover
  public rj_chartHovered(e: any): void {

    console.log(e);
  }
  public rj_chartClicked(e: any): void {

    console.log(e.active[0]._chart);
    console.log(e.active[0]._chart.tooltip._data.datasets[0].label);
    // e.active[0]._chart.tooltip._data.datasets[0].label="rajesh";
    e.active[0]._chart.tooltip._data.datasets[0].label = "job posted";
    console.log(e);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('header_data');
      element.style.background = "rgba(255,255,255,0.9)";
      element.style.position = "fixed";
      element.style.marginTop = "-2px";
      element.style.transitionDuration = "0.6s";
      element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
      let log_txt = document.getElementById('login_txt');
      //  log_txt.style.color="rgb(180, 70, 255)";


    } else {
      let element = document.getElementById('header_data');
      element.style.background = "";
      element.style.position = "";
      element.style.marginTop = "";
      element.style.boxShadow = "";
      // let log_txt = document.getElementById('login_txt');
      // log_txt.style.color="";

    }
  }
  // 
  previewPic(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }
// 
onSelectFile(event) {
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => { // called once readAsDataURL is completed
      // this.url = event.target.result;
    }
  }
}
  onLogout() {
    console.log('inside logout');
    // localStorage.setItem('buyertoken', null);
    localStorage.removeItem('authtoken');
    localStorage.removeItem('emailId');
    this.router.navigate(['/login']);
  }
}
