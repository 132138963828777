import { Http, Headers, Response } from "@angular/http";
import {HttpErrorResponse} from "@angular/common/http";
import { Injectable, EventEmitter, OnInit } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { PlatformLocation } from "@angular/common";
import { environment } from "../../environments/environment";
import {PastEvent} from './events'
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable,throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { from } from 'rxjs';
import { catchError } from "rxjs/operators";

@Injectable()
export class AuthServices {

  static USER_KEY: string;
  profile: any;
  data: any;
  loginProgress: EventEmitter<string>;
  loginSuccess: EventEmitter<string>;
  loginFailed: EventEmitter<string>;
  loginEmployeSuccess: EventEmitter<string>;
  Bearer: any = "Bearer ";
  token: string;
  email: string;
  name: string;
  allBooks$: Observable<PastEvent[]> 
  searchLoaded: EventEmitter<any>;
  public redirectUrl: string;
  value: any;
  constructor(private route: ActivatedRoute,toastar: ToastrService, private http: Http, private platformLocation: PlatformLocation, private router: Router) {
    this.loginProgress = new EventEmitter<string>();
    this.loginSuccess = new EventEmitter<string>();
    this.loginFailed = new EventEmitter<string>();
    this.loginEmployeSuccess = new EventEmitter<string>();
    this.searchLoaded= new EventEmitter<any>();
  }
  // jobseeker registrartion
  
  public signIn(formdata) {
    return this.http.post(environment.endPointApi + 'jobseekers/register', formdata).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
// jobseeker forgot
  
public forgot(formdata) {
  return this.http.post(environment.endPointApi + 'jobseekers/forgot', formdata).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

// Partner/recruiter Forgot password
public Partnerforgot(formdata) {
  return this.http.post(environment.endPointApi + 'partner/forgot', formdata).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}



  // jobseeker verifying email address
  secret(secret: string) {
    var url = environment.endPointApi + "jobseekers/verifyemail/"+secret;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(url, { headers: headers }).subscribe(
      (r) => {
        var data = r.json();
        Swal({
          title: 'Success',
          html: data.message,
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/login');
          }
        });

      },
      (e) => {
        var data = e.json();
        Swal({
          title: 'Unsuccessful',
          html: data.message,
          type: 'error',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/homenew');
          }
        });
      }
    );
  }

  /** 
  * @desc this  functions for partner admin confirmation 
  * @author ajith.a@shenzyn.com
  * @required valid Otp Token
  * @function PartnerEmailVerify
  * @params secret:string
  * @modifiedDate 30-01-2020
*/
// PartnerEmailVerify(secret: String){
//   var url = environment.endPointApi + "partner/confirm-partner/"+secret;
//     var headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     this.http.post(url, { headers: headers }).subscribe(
//       (r) => {
//         var data = r.json();
//         Swal({
//           title: 'Success',
//           html: data.message,
//           type: 'success',
//           showCancelButton: false,
//           confirmButtonColor: '#1976d2',
//           cancelButtonColor: '#FF7E39',
//           confirmButtonText: 'OK!',
//         }).then((confirm) => {
//           if (confirm) {
//             this.router.navigateByUrl('/partners-login');
//           }
//         });

//       },
//       (e) => {
//         var data = e.json();
//         Swal({
//           title: 'Unsuccessful',
//           html: data.message,
//           type: 'error',
//           showCancelButton: false,
//           confirmButtonColor: '#1976d2',
//           cancelButtonColor: '#FF7E39',
//           confirmButtonText: 'OK!',
//         }).then((confirm) => {
//           if (confirm) {
//             this.router.navigateByUrl('/homenew');
//           }
//         });
//       }
//     );
// }

/** 
  * @desc this  functions for partner admin Email Verification and Recruiter Email Verify 
  * @author ajith.a@shenzyn.com
  * @required valid Otp Token
  * @function PartnerEmailVerify
  * @params secret:string
  * @modifiedDate 30-01-2020
*/
public PartnerEmailVerify(secret: String,partnerUrl:String){
  
  
  var url = environment.endPointApi + partnerUrl+secret;

  
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(url, { headers: headers }).subscribe(
      (r) => {
        var data = r.json();
        Swal({
          title: 'Success',
          html: data.message,
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/homenew');
          }
        });

      },
      (e) => {
        var data = e.json();
        Swal({
          title: 'Unsuccessful',
          html: data.message,
          type: 'error',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/homenew');
          }
        });
      }
    );
}

 /** 
  * @desc this  functions for partner Confirmed Events
  * @author ajith.a@shenzyn.com
  * @function PartnerSubscribed
  * @modifiedDate 05-02-2020
*/
public PartnerSubscribed(SubData){
  return this.http.post(environment.endPointApi + 'events/subscribed', SubData).map((res) =>{
    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
  });
}
 /** 
  * @desc this  functions for partner Confirmed Events
  * @author ajith.a@shenzyn.com
  * @function listOfApplicant
  * @modifiedDate 13-02-2020
*/
public listOfApplicant(formdata){
    var url = environment.endPointApi +'events/addprofiles';
    this.http.post(url,formdata).subscribe(
      (r) => {
        var data = r.json();
        Swal({
          title: 'Success',
          html: data.message,
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('partners-admin');
          }
        });

      },
      (e) => {
        var data = e.json();
        Swal({
          title: 'Unsuccessful',
          html: data.message,
          type: 'error',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('partners-admin');
          }
        });
      }
    );
}

 /** 
  * @desc this  functions for Edit Added Profiles
  * @author ajith.a@shenzyn.com
  * @function EditApplicant
  * @modifiedDate 24-02-2020
*/
public EditApplicant(formdata){
  var url = environment.endPointApi +'events/editprofiles';
  this.http.post(url,formdata).subscribe(
    (r) => {
      var data = r.json();
      Swal({
        title: 'Success',
        html: data.message,
        type: 'success',
        showCancelButton: false,
        confirmButtonColor: '#1976d2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK!',
      }).then((confirm) => {
        if (confirm) {
          this.router.navigateByUrl('partners-admin');
        }
      });

    },
    (e) => {
      var data = e.json();
      Swal({
        title: 'Unsuccessful',
        html: data.message,
        type: 'error',
        showCancelButton: false,
        confirmButtonColor: '#1976d2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK!',
      }).then((confirm) => {
        if (confirm) {
          this.router.navigateByUrl('partners-admin');
        }
      });
    }
  );
}



/** 
  * @desc this  functions for recruiter resume Upload for Applicant
  * @author ajith.a@shenzyn.com
  * @function postFile
  * @modifiedDate 14-02-2020
*/
public postFile(fileToUpload: File,profSubmitter:any) {
  this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  formData.append('emailId',profSubmitter.emailId);
  formData.append('eventId',profSubmitter.eventId);
  return this.http
    .post(environment.endPointApi + 'events/AddApplicant/resumeparser', formData, {headers: headers }).map((response: Response) => { 
      if (this.redirectUrl) {
              this.router.navigate([this.redirectUrl]);
              this.redirectUrl = null;
            } else {
              const user = response.json();
              user.responseStatus = response.status;
              return user;
            } 
          })
          .catch((error) => {
            if (error.status === 500) {
              const err = error.json();
              err.responseStatus = error.status;
              return Observable.throw(err); 
            } else if (error.status === 400) {
              const err = error.json();
              err.responseStatus = error.status;
              return Observable.throw(err);
            } else if (error.status === 401) {
              const err = error.json();
              err.responseStatus = error.status;
              return Observable.throw(err);
            }
          });
        }



 /** 
  * @desc this  functions for partner admin confirmation 
  * @author ajith.a@shenzyn.com
  * @required valid Otp Token
  * @function PartnerRegisterEmailVerify
  * @params secret:string
  * @modifiedDate 31-01-2020
*/
PartnerRegisterEmailVerify(secret: String){
  var url = environment.endPointApi + "partner/partner-register-verify/"+secret;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(url, { headers: headers }).subscribe(
      (r) => {
        var data = r.json();
        Swal({
          title: 'Success',
          html: data.message,
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('partner-reset-pwd');
          }
        });

      },
      (e) => {
        var data = e.json();
        Swal({
          title: 'Unsuccessful',
          html: data.message,
          type: 'error',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/homenew');
          }
        });
      }
    );
}

/** 
  * @desc this  functions for partner admin confirmation 
  * @author ajith.a@shenzyn.com
  * @required valid Otp Token
  * @function Partnerlogin
  * @params secret:string
  * @modifiedDate 31-01-2020
*/
public Partnerlogin(formdata){
  return this.http.post(environment.endPointApi + 'partner/login', formdata).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

/** 
  * @desc this  functions for getting All Future Events
  * @author ajith.a@shenzyn.com
  * @required valid Otp Token
  * @function GetAllEvents
  * @params secret:string
  * @modifiedDate 04-02-2020
*/
public GetAllUpcomingEvents(){
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  return this.http.post(environment.endPointApi + 'events/getAllEvents',{headers: headers}).map((res) =>{
    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
  });
}
/** 
  * @desc this  functions for getting All Future Events Subscribed by Partner Admin
  * @author ajith.a@shenzyn.com
  * @required CompanyName,Admin MailId
  * @function getAllSubscribedEvents
  * @params CompanyName:string,partnerEmail:string
  * @modifiedDate 11-02-2020
*/

public getAllSubscribedEvents(partnerEmail:String){
  var headers = new Headers();
  const body = JSON.stringify({"partnerEmail":partnerEmail});
  headers.append('Content-Type', 'application/json');
  return this.http.post(environment.endPointApi + 'events/getAllSubscribedEvents',body,{headers: headers}).map((res) =>{
    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
  });
}


/** 
  * @desc this  functions for partner in getting All past events 
  * @author ajith.a@shenzyn.com
  * @required valid Otp Token
  * @function GetAllPastEvents
  * @params secret:string
  * @modifiedDate 05-02-2020
*/
public GetAllPastEvents(partnerEmail:String):Observable<any>{
  var Partnerdetails ={
    emailId:partnerEmail
  }
  return this.http.post(environment.endPointApi + 'events/getAllPastEvents',Partnerdetails).map((response: Response) => {
      return response.json();
  }).catch((error) => {
    const err = error.json();
    err.responseStatus = error.status;
    return Observable.throw(err);
  });
}

private handleError(errorResponse:HttpErrorResponse){
  if(errorResponse.error instanceof ErrorEvent){
    console.error('Client Side Error: '+errorResponse.error);    
  }
  else{
    console.error('Server side error: '+errorResponse)
  }
  return throwError('There is Problem with the  Service');
}



  public login(formdata) {
    return this.http.post(environment.endPointApi + 'jobseekers/login', formdata).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }

  
//jobseeker forgot password 
jobseekerForgot(emailId: string) {
  var url = environment.endPointApi + "jobseekers/forgot";
  var body = JSON.stringify({'emailId': emailId });
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const options = { headers: headers };
  this.http.post(url, body, options).subscribe(
    (r) => {
      var data = r.json();
  //  this.toastr.success( );
      swal(data.message)
    },
    (e) => {
      var data = e.json();
      var msg = data["message"];
      swal("", data.message);
     
    }
  );
}

//jobseeker reset password
public jobseekerReset(secret:string,formdata) {
 const data = {
 
  'password': formdata 
};

const body = JSON.stringify(data);

const head = new Headers({
  'Content-Type': 'application/json'
});
  return this.http.post(environment.endPointApi + 'jobseekers/reset/'+secret, body, {headers: head}).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

  public otpSend(contactNo) {
    this.email = localStorage.getItem('emailId') 
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    let data ={
      "phoneNo": '+91'+contactNo
    }
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(environment.endPointApi + 'jobseekers/sendphoneotp', data,{ headers: headers }).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }

  // Verifying mobile number 
  

  public otpGet(phoneNo:any,otp:any) {
   
    this.token = localStorage.getItem('authtoken')
    let data = {
      "phoneNo": '+91'+phoneNo,
      "otp": otp
    }
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(environment.endPointApi + 'jobseekers/verifyphoneotp', data, { headers: headers }).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }

  //recruiter registration 
  signInEmploye(companyName: string, emailId: string, password: string) {
    var url = environment.endPointApi + "company/register";
    var body = JSON.stringify({ 'companyName': companyName, 'password': password, 'emailId': emailId });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = { headers: headers };
    this.loginProgress.emit("");
    this.http.post(url, body, options).subscribe(
      (r) => {
        var data = r.json()


        Swal({
          title: 'Success',
          //   html: true, 
          html: data.message,

          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/employe-login');
          }
        });

      },
      (e) => {
        var data = e.json();

        Swal({
          title: 'Unsuccessful',
          html:  data.message,
          type: 'error',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',

          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/employe-signup');
          }
        });

      }
    );
  }

  // recruiter verifying email address
  companyVerify(secret: string) {

    var url = environment.endPointApi + "recruiter/verifyemail/" + secret;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(url, { headers: headers }).subscribe(
      (r) => {
        var data = r.json();
        Swal({
          title: 'Success',
          html: data.message,
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',
          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/employe-login');
          }
        });
      },
      (e) => {
        var data = e.json();
        Swal({
          title: 'Unsuccessful',
          html:  data.message,
          type: 'error',
          showCancelButton: false,
          confirmButtonColor: '#1976d2',
          cancelButtonColor: '#FF7E39',

          confirmButtonText: 'OK!',
        }).then((confirm) => {
          if (confirm) {
            this.router.navigateByUrl('/homenew');
          }
        });
      }
    );
  }
  // recruiter login
    public loginRecruiter(formdata) {
    var loginRoute =  'recruiter/login';
    return this.http.post(environment.endPointApi + loginRoute, formdata).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  //recruiter forgot password 
  public recruiterForgot(formdata) {
    return this.http.post(environment.endPointApi + 'recruiter/forgot', formdata).map((response: Response) => {
      if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      } else {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
//recruiter reset password
public recruiterReset(secret:string,formdata) {
  const data = {
   'password': formdata
 };
 const body = JSON.stringify(data);
 const head = new Headers({
   'Content-Type': 'application/json'
 });
  return this.http.post(environment.endPointApi + 'recruiter/reset/'+secret, body, {headers: head}).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
public gmail(formdata) {
  return this.http.post(environment.endPointApi + 'auth/google', formdata).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
public linkedin() {
  window.location.href=environment.endPointApi + 'auth/linkedin';
}
public gitHub(formdata) {
  return this.http.post(environment.endPointApi + 'auth/github', formdata).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// jobs by company
companyJob(datas) {
  {
  if(datas==="undefined"){
    datas= "";
   
  }else if(datas==="undefined"){
    datas= "";
  }
 
  // var fd= datas.split(" ");
  
  const data = {
    "companyName": datas,
   
  }
  const body = JSON.stringify(data);
  const head = new Headers({
    'Content-Type': 'application/json'
  });
   return this.http.post(environment.endPointApi + 'search/companydetails', body, {headers: head}).map((response: Response) => {
     if (this.redirectUrl) {
       this.router.navigate([this.redirectUrl]);
       this.redirectUrl = null;
     } else {
       const user = response.json();
       user.responseStatus = response.status;
       return user;
     } 
   }).catch((error) => {
     if (error.status === 500) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     } else if (error.status === 400) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     } else if (error.status === 401) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     }
 });
}
} 


// job search user
search(datas,locations) {
  {
  if(datas==="undefined" && locations==="undefined"){
    datas= "";
    locations= "";
  }else if(datas==="undefined"){
    datas= "";
  }
  else if(locations==="undefined"){
    locations="";
  }
  var fd= datas.split(" ");
  var lc = locations.split(" ")
  const data = {
    "searchString": fd,
    "location": lc
  }
  const body = JSON.stringify(data);
  const head = new Headers({
    'Content-Type': 'application/json'
  });
   return this.http.post(environment.endPointApi + 'search/jobsearch', body, {headers: head}).map((response: Response) => {
     if (this.redirectUrl) {
       this.router.navigate([this.redirectUrl]);
       this.redirectUrl = null;
     } else {
       const user = response.json();
       user.responseStatus = response.status;
       return user;
     } 
   }).catch((error) => {
     if (error.status === 500) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     } else if (error.status === 400) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     } else if (error.status === 401) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     }
 });
}
}
// profile search
profileSearch(datas,locations) {
  {
  if(datas==="undefined" && locations==="undefined"){
    datas= "";
    locations= "";
  }else if(datas==="undefined"){
    datas= "";
  }
  else if(locations==="undefined"){
    locations="";
  }
  var fd= datas.split(" ");
  var lc = locations.split(" ")
  const data = {
    "searchString": fd,
    "location":lc
  }
  const body = JSON.stringify(data);
  const head = new Headers({
    'Content-Type': 'application/json'
  });
   return this.http.post(environment.endPointApi + 'search/profilesearchregex', body, {headers: head}).map((response: Response) => {
     if (this.redirectUrl) {
       this.router.navigate([this.redirectUrl]);
       this.redirectUrl = null;
     } else {
       const user = response.json();
       user.responseStatus = response.status;
       return user;
     } 
   }).catch((error) => {
     if (error.status === 500) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     } else if (error.status === 400) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     } else if (error.status === 401) {
       const err = error.json();
       err.responseStatus = error.status;
       return Observable.throw(err);
     }
 });
}
}

public getJobs(num1:any) { 
  this.token = localStorage.getItem('authtoken');
  var headers = new Headers();
  if(this.token!=null){
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));}
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'recruiter/getalljobdetails/'+num1, { headers: headers }).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
public getCompany(num2:any) {
  this.token = localStorage.getItem('authtoken')
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'company/getcompanybyname/'+num2, { headers: headers }).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

public subscribe(emailId) {
  let data ={
    "emailId": emailId 
  }
  return this.http.post(environment.endPointApi + 'subscriptions/subscribe', data).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
public contact(formdata) {
  let data ={
    "emailId":  formdata.email,
    "message":formdata.text_area
  }
  return this.http.post(environment.endPointApi + 'contactus/contact', data).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// get all companies
public getAllCompanies() {
  return this.http.get  (environment.endPointApi + 'company/getcompanies', ).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// parnter registration
public partnerRegisterpartner(formdata) {
  return this.http.post(environment.endPointApi + 'partner/registerpartner', formdata).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

// partner Reset Password
public PartnerReset(secret:string,formdata) {
  const data = {
   'password': formdata
 };
 const body = JSON.stringify(data);
 const head = new Headers({
   'Content-Type': 'application/json'
 });
  return this.http.post(environment.endPointApi + 'partner/reset/'+secret, body, {headers: head}).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

// Partner Recruiter Reset pwd
public PartnerRecruiterReset(secret:string,formdata) {
  const data = {
   'password': formdata
 };
 const body = JSON.stringify(data);
 const head = new Headers({
   'Content-Type': 'application/json'
 });
  return this.http.post(environment.endPointApi + 'partner/reset/'+secret, body, {headers: head}).map((response: Response) => {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    } else {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    } 
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// Recruiter Services

public RecruiterRegister(formdata,Url){
  return this.http.post(environment.endPointApi+Url,formdata).map((res) =>{

    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
});
}

public RecruiterUpdate(formdata: any){
  const emailId = formdata.emailId;
  this.token = localStorage.getItem('authtoken');
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi+'recruiter/updatebasicdetails/'+emailId,formdata,{ headers: headers }).map((res) =>{
    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
});
}

// Partner Recruiter Edit
public PartnerRecruiterUpdate(formdata: any){
  const emailId = formdata.emailId;
  this.token = localStorage.getItem('authtoken');
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi+'partner/updatebasicdetails/'+emailId,formdata,{ headers: headers }).map((res) =>{
    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
});
}

public DeactivateRecruiter(emailId: any){
  this.token = localStorage.getItem('authtoken');
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi+'recruiter/deactivateRecruiter/'+emailId,{},{ headers: headers }).map((res) =>{
    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
});
}

public DeactivatePartnerRecruiter(emailId: any){
  this.token = localStorage.getItem('authtoken');
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi+'partner/deactivateRecruiter/'+emailId,{},{ headers: headers }).map((res) =>{
    return res.json();
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
});
}


}

