import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
var PartnersComponent = /** @class */ (function () {
    function PartnersComponent(fb, authService, router) {
        this.fb = fb;
        this.authService = authService;
        this.router = router;
        this.RegistrationForm = fb.group({
            'companyName': [null, Validators.required],
            'address': [null, Validators.required],
            'contactNo': [null, Validators.required],
            'emailId': [null, Validators.required],
            'gstin': [null, Validators.required]
        });
    }
    Object.defineProperty(PartnersComponent.prototype, "f", {
        get: function () { return this.RegistrationForm.controls; },
        enumerable: true,
        configurable: true
    });
    PartnersComponent.prototype.ngOnInit = function () {
    };
    // form validation
    PartnersComponent.prototype.register = function (formdata) {
        var _this = this;
        console.log(formdata);
        this.submitted = true;
        if (this.RegistrationForm.invalid) {
            return;
        }
        this.loading = true;
        this.authService.partnerRegisterpartner(formdata).subscribe(function (data) {
            _this.userData = (data);
            if ((_this.userData.responseStatus === 200)) {
                console.log('200-Success');
                _this.loading = false;
                _this.erroMsg = '';
                _this.submitted = false;
                swal({
                    title: 'Success',
                    text: data.message,
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['/homenew']);
                    }
                });
            }
        }, function (error) {
            if (error.responseStatus === 401) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 500) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 400) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
        }, function () { return console.log(); });
    };
    return PartnersComponent;
}());
export { PartnersComponent };
