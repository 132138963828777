import { Component, OnInit,HostListener, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import { /*FormControl, FormGroupDirective, NgForm,*/ Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';
import swal from 'sweetalert2';


@Component({
  selector: 'app-partners-reset-pwd',
  templateUrl: './partners-reset-pwd.component.html',
  styleUrls: ['./partners-reset-pwd.component.css']
})
export class PartnersResetPwdComponent implements OnInit {
  secret:any;
  token: any;
  forgotForm: FormGroup;
  userData: any;
  submitted = false;
  erroMsg: string;
  loading: boolean;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authService: AuthServices ) {
    this.forgotForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
  }, {
      validator: MustMatch('password', 'confirmPassword')
  });
   }
   get f() { return this.forgotForm.controls; }

   ngOnInit() {
    $(".toggle-password").click(function() {

      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
    $(".toggle-password1").click(function() {

      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
    const id = this.route.snapshot.paramMap.get('token'); 
    this.secret=id
    this.token=id;
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 50) {
       let element = document.getElementById('header_data');
       element.style.background="rgba(255,255,255,0.9)";
       element.style.position="fixed";  
       element.style.marginTop="-2px";
       element.style.transitionDuration="0.6s";
       element.style.boxShadow="0px 1px 10px rgba(0,0,0,0.4)";
      
     } else {
      let element = document.getElementById('header_data');
      element.style.background="";
      element.style.position="";
      element.style.marginTop=""; 
      element.style.boxShadow="";
     }
  }
 
  onSubmit() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
        return; 
    }
    const secret = this.token;
    const formdata = this.forgotForm.value.password
    this.authService.PartnerReset(secret,formdata).subscribe(
      data => {
        this.userData = (data);
        if ((this.userData.responseStatus === 200)) {
          console.log('200-Success');
          this.loading = false;
          this.erroMsg = '';
         
          this.submitted = false;
          swal({
            title: 'Success',
            text: data.message,
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#535BE2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK',
          }).then((confirm) => {
                if (confirm) {
                  this.router.navigate(['partners-login']);
                }
              });
        }

      },
      error => {
        if (error.responseStatus === 401) {
          this.loading = false;
          swal({
            title: 'Unsuccessful',
            text: error.message,
            type: 'error',
            showCancelButton: false,
            confirmButtonColor: '#535BE2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK',
          }).then((confirm) => {
                if (confirm) {
                  this.router.navigate(['/homenew']);
                }
              });
        
        
        } else if (error.responseStatus === 500) {
          this.loading = false;
          this.erroMsg = error.message;
          swal({
            title: 'Unsuccessful',
            text: error.message,
            type: 'error',
            showCancelButton: false,
            confirmButtonColor: '#535BE2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK',
          }).then((confirm) => {
                if (confirm) {
                  this.router.navigate(['/homenew']);
                }
              });
        } else if (error.responseStatus === 400) {
          this.loading = false;
          this.erroMsg = error.message; 
          swal({
            title: 'Unsuccessful',
            text: error.message,
            type: 'error',
            showCancelButton: false,
            confirmButtonColor: '#535BE2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK',
          }).then((confirm) => {
                if (confirm) {
                  this.router.navigate(['/homenew']);
                }
              });
        }
      },
      () => console.log()
    );
}

}
