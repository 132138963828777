import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import { /*FormControl, FormGroupDirective, NgForm,*/ Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';
import swal from 'sweetalert2';
var PartnerRecruiterResetComponent = /** @class */ (function () {
    function PartnerRecruiterResetComponent(formBuilder, route, router, authService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.submitted = false;
        this.forgotForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }
    Object.defineProperty(PartnerRecruiterResetComponent.prototype, "f", {
        get: function () { return this.forgotForm.controls; },
        enumerable: true,
        configurable: true
    });
    PartnerRecruiterResetComponent.prototype.ngOnInit = function () {
        $(".toggle-password").click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            }
            else {
                input.attr("type", "password");
            }
        });
        $(".toggle-password1").click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            }
            else {
                input.attr("type", "password");
            }
        });
        var id = this.route.snapshot.paramMap.get('token');
        this.secret = id;
        this.token = id;
    };
    PartnerRecruiterResetComponent.prototype.onWindowScroll = function (e) {
        if (window.pageYOffset > 50) {
            var element = document.getElementById('header_data');
            element.style.background = "rgba(255,255,255,0.9)";
            element.style.position = "fixed";
            element.style.marginTop = "-2px";
            element.style.transitionDuration = "0.6s";
            element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
        }
        else {
            var element = document.getElementById('header_data');
            element.style.background = "";
            element.style.position = "";
            element.style.marginTop = "";
            element.style.boxShadow = "";
        }
    };
    PartnerRecruiterResetComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.forgotForm.invalid) {
            return;
        }
        var secret = this.token;
        var formdata = this.forgotForm.value.password;
        this.authService.PartnerRecruiterReset(secret, formdata).subscribe(function (data) {
            _this.userData = (data);
            if ((_this.userData.responseStatus === 200)) {
                console.log('200-Success');
                _this.loading = false;
                _this.erroMsg = '';
                _this.submitted = false;
                swal({
                    title: 'Success',
                    text: data.message,
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['partners-login']);
                    }
                });
            }
        }, function (error) {
            if (error.responseStatus === 401) {
                _this.loading = false;
                swal({
                    title: 'Unsuccessful',
                    text: error.message,
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['/homenew']);
                    }
                });
            }
            else if (error.responseStatus === 500) {
                _this.loading = false;
                _this.erroMsg = error.message;
                swal({
                    title: 'Unsuccessful',
                    text: error.message,
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['/homenew']);
                    }
                });
            }
            else if (error.responseStatus === 400) {
                _this.loading = false;
                _this.erroMsg = error.message;
                swal({
                    title: 'Unsuccessful',
                    text: error.message,
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['/homenew']);
                    }
                });
            }
        }, function () { return console.log(); });
    };
    return PartnerRecruiterResetComponent;
}());
export { PartnerRecruiterResetComponent };
