import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';

@Component({
  selector: 'app-recruiter-email-verify',
  templateUrl: './recruiter-email-verify.component.html',
  styleUrls: ['./recruiter-email-verify.component.css']
})
export class RecruiterEmailVerifyComponent implements OnInit {
  secret:any;
  token: any;
  url:String;
  constructor(private route: ActivatedRoute,private _router:Router,private authService: AuthServices) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('token'); 
    this.secret=id;
    this.url = "partner/recruiter-register-verify/";
    // console.log(this.url,' its inside url');
    
    this.authService.PartnerEmailVerify(this.secret,this.url);
  }

}
