import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from "../../../environments/environment";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {

  show: boolean = false;
  constructor(@Inject(DOCUMENT) document, private modalService: NgbModal, private modalService2: NgbModal, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }
  jobsUrlLink: String;
  employerUrlLink: String;
  partnersUrlLink: String;

  ngOnInit() {
    this.jobsUrlLink = environment.jobsWebUrl;
    this.employerUrlLink = environment.employersWebUrl;
    this.partnersUrlLink = environment.partnersWebUrl;
  }

  toggleCollapse() {
    console.log(this.show, ' its clicked');
    // document.getElementById("mySidenav").style.width = "250px";

    this.show = !this.show
    if (this.show) {
      console.log('inside if');

      document.getElementById("mySidenav").style.width = "250px";
      document.body.style.overflowY = "hidden";
    }
    else {
      console.log('inside else');
      document.getElementById("mySidenav").style.width = "0px";
      document.body.style.overflowY = 'visible';
    }
  }

  closeNav() {
    alert('its close button');
    console.log(' its inside close nav');
    document.getElementById("mySidenav").style.width = "0px";
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('header_data');
      element.style.background = "rgba(255,255,255,0.9)";
      element.style.position = "fixed";
      element.style.color = " rgba(0, 0, 0, 0.6)";
      element.style.marginTop = "-2px";
      element.style.transitionDuration = "0.6s";
      element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
    } else {
      let element = document.getElementById('header_data');
      element.style.background = "";
      element.style.position = "";
      element.style.marginTop = "";
      element.style.boxShadow = "";
      element.style.color = "#fff";
    }
  }

}
