<!-- Footer Style #01 -->

<!-- <div class="container-fluid py-3">
    <div class="container">
        <div class="row py-3">
            <div class="col-md-9">
                <a routerLink="/homenew"> <img src="assets/sheImages/logo8.svg" alt="logo"
                        style="width:180px;height:auto;"></a>
            </div>
            <div class="col-md-3">
                <div class="d-inline-block">
                    <ul class="icon_list_news index2_icon_list jb_cover">
                        <li><a href="https://www.facebook.com/Shenzyn-108397103967073/" target="_blank"><i
                                    class="fab fa-facebook-f"></i></a></li>
                        <li>
                            <a href="https://twitter.com/Shenzyn_EW" target="_blank"><i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li><a href=" https://www.linkedin.com/company/28714426/admin/" target="_blank"><i
                                    class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="mailto:wehearyou@shenzyn.com?Subject=Hello Shenzyn" target="_blank"><i
                                    class="fab fa-google-plus-g"></i></a></li>
                        <li> <a href="https://www.instagram.com/we_shenzyn/" target="_blank">
                                <i class="fab fa-instagram" style="font-weight:900;"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row py-3">
            <div class="col-md-12">
                <div class=" text-center">
                    <i class="fa fa-copyright"></i> 2019
                    <a href="javascript:void(0);"> 4GEN Technologies Pvt. Ltd. </a> All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Footer Style #01 Ends-->


<!-- Footer Style #02 -->
<div class="container-fluid py-5 mt-5">
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="job_newsletter_wrapper footer_hr">
                    <hr class="style-three">
                </div>
            </div>
        </div>
    </div> -->
    <div class="container">
        <div class="row py-3">
            <div class="col-md-2">
                <a routerLink="/homenew"> <img src="assets/sheImages/logo8.svg" alt="logo"
                        style="width:180px;height:auto;"></a>
            </div>
            <div class="col-md-7 text-center">
                <ul class="footer--navigation float-right">
                    <li class="abt1"><a href="https://www.shenzyn.com/#/aboutus"> About Us</a></li>
                    <li class="abt1"><a href="https://www.shenzyn.com/#/contact"> Contact Us</a></li>
                    <li class="abt1"><a href="https://blog.shenzyn.com//" target="_blank">Blogs</a></li>
                    <li class="abt1"><a href="https://www.shenzyn.com/#/termsandconditions"> Terms &amp; Conditions </a>
                    </li>
                    <li class="abt1"><a href="https://www.shenzyn.com/#/privacypolicy"> Privacy Policy</a></li>
                </ul>
            </div>
            <div class="col-md-3">
                <div class="d-inline-block">
                    <ul class="icon_list_news index2_icon_list jb_cover">
                        <li><a href="https://www.facebook.com/Shenzyn-108397103967073/" target="_blank"><i
                                    class="fab fa-facebook-f"></i></a></li>
                        <li>
                            <a href="https://twitter.com/Shenzyn_EW" target="_blank"><i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li><a href=" https://www.linkedin.com/company/28714426/admin/" target="_blank"><i
                                    class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCJ3hSpePirYqXCAJo75OIhQ" target="_blank"><i
                                    class="fab fa-youtube"></i></a></li>
                        <li> <a href="https://www.instagram.com/we_shenzyn/" target="_blank">
                                <i class="fab fa-instagram" style="font-weight:900;"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row py-3">
            <div class="col-md-12">
                <div class=" text-center">
                    <i class="fa fa-copyright"></i> 2020
                    <a href="javascript:void(0);"> 4GEN Technologies Pvt. Ltd. </a> All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Footer Style #02 Ends-->