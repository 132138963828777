import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';

@Component({
  selector: 'app-partners-confirm-email',
  templateUrl: './partners-confirm-email.component.html',
  styleUrls: ['./partners-confirm-email.component.css']
})
export class PartnersConfirmEmailComponent implements OnInit {
  secret:any;
  token: any;
  constructor(private route: ActivatedRoute,private _router:Router,private authService: AuthServices) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('token'); 
    this.secret=id;
    this.authService.PartnerRegisterEmailVerify(this.secret)
  }

}
