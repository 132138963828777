import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentServices } from '../../services/payment.operations';


@Component({
  selector: 'app-partners-subscription',
  templateUrl: './partners-subscription.component.html',
  styleUrls: ['./partners-subscription.component.css']
})
export class PartnersSubscriptionComponent implements OnInit {
  companyName: string;
  lastPayment: any;
  expiryDate: any;
  planAvailed: any;
  planStatus: any;
  hideSubscriptionPlans : boolean = false;
  postData = {
    "postUrl":"",
		"appId" : "",
		"customerName" : "",
		"customerEmail" : "",
		"customerPhone" : "",
		"returnUrl" : "",
    "notifyUrl" : "",
    "orderId" : "",
    "orderAmount6mSubscription":"",
    "orderAmount12mSubscription": "",
    "orderNote" : "",
		"orderCurrency" : "",
    "signature6mSubscription":"",
    "signature12mSubscription":""
	}
  
  lasttxn = {
    "orderid":"NA",
		"txstatus" : "NA",
		"status" : "NA",
		"refid" : "NA",
		"txmsg" : "NA",
		"ordamt" : "NA"
  }
  
  constructor(private router: Router,private PaymentService: PaymentServices ) { }

  ngOnInit() {
    this.companyName = localStorage.getItem('companyName');
    this.getSubscriptionDetails();
    this.getPaymentDetails();
  }


  getPaymentDetails(){
    this.PaymentService.getPartnerPaymentDetails().subscribe(
      data => {
        this.postData.postUrl = data.postUrl;
        this.postData.appId = data.appId;
        this.postData.customerName = data.customerName;
        this.postData.customerEmail = data.customerEmail;
        this.postData.customerPhone = data.customerPhone;
        this.postData.returnUrl = data.returnUrl;
        this.postData.notifyUrl = data.notifyUrl;
        this.postData.orderId = data.orderId;
        this.postData.orderAmount6mSubscription = data.orderAmount6mSubscription;
        this.postData.orderAmount12mSubscription = data.orderAmount12mSubscription;
        this.postData.orderCurrency = data.orderCurrency;
        this.postData.orderNote = data.orderNote;
        this.postData.signature6mSubscription = data.signature6mSubscription;
        this.postData.signature12mSubscription = data.signature12mSubscription;
      },
      error => {
        if (error.responseStatus === 401) {
        }
        else if (error.responseStatus === 500) {
        } 
        else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );
  }


 getSubscriptionDetails(){
    this.PaymentService.getPartnerSubscriptionDetails().subscribe(
      data => {
        if(data.orderStatus == 'COMPLETED')
        {
        this.lastPayment = data.subscriptionDate;
        this.expiryDate =  data.subscriptionExpiryDate;
        this.planAvailed = data.subscriptionTerminMonths + " months subscription.";
        this.planStatus = (new Date(this.expiryDate) > new Date())? "Active" : "Inactive";
        }
        if(this.planStatus=='Active')
        {
          // redirect to payment result plan page this.hideSubscriptionPlans = true;
          this.router.navigate(['/partners-payment-callback']);
        }
        this.lasttxn.txstatus = data.txstatus;
        this.lasttxn.orderid = data.orderid;
        this.lasttxn.status = data.status;
        this.lasttxn.refid = data.refid;
        this.lasttxn.txmsg = data.txmsg;
        this.lasttxn.ordamt = data.ordamt;
      },
      error => {
        if (error.responseStatus === 401) {
        }
        else if (error.responseStatus === 500) {
        } 
        else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );
  }

  onLogout() {
    localStorage.clear()
    this.router.navigate(['/partners-login']);
  }

}
