<div *ngIf="showSpinner" class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
  <span class="sr-only">Loading...</span>
</div>

<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
  <div class="cp_logo_wrapper index_2_logo ">
    <a routerLink="/partners-admin">
      <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
    </a>
  </div>

  <div class="menu_btn_box jb_cover">
    <label class="dropdown">
      <p style="font-weight:400;">
        <!-- <img src="assets/sheImages/nav_menu.svg" class="nice-select userPic_header" alt="img" style="width: 62px;height: 60px;"> -->

        <img src="assets/sheImages/nav_menu.svg" alt="img" style="width: 62px;height: 60px; margin-left: 150px;border-radius: 50%;">
      </p>
      <input type="checkbox" class="dd-input" id="test">
      <ul class="dd-menu">
        <!-- Employee -->
        <!-- <li><a routerLink="/job-dashboard"><i class="fas fa-outdent"></i> Job Dashboard</a></li>
        <li><a routerLink="/user-profile"><i class="fas fa-edit"></i> View/Edit Profile</a></li> -->
        
    
        <!-- Employer -->
        <!-- <li><a routerLink="/recruiterprofile"><i class="fas fa-edit"></i> View /Edit </a></li>
        <li><a routerLink="/manage-recruiters"><i class="fas fa-user-edit"></i> Manage Recruiters </a></li>
        <li><a routerLink="/schedule-events"><i class="fas fa-tasks"></i> Schedule Events </a></li> -->

        <!-- Partner -->
        <li><a *ngIf="usertype" routerLink="/partners-addrecruiter"><i class="fa fa-users"></i>Add Associates</a></li>
        <li><a *ngIf="usertype" routerLink="/partners-subscription"><i class="fa fa-credit-card"></i> Payments Plan</a></li>

        <!-- Common -->
        <li><a (click)="onLogout()"><i class="fas fa-sign-in-alt"></i> Logout</a></li>
      </ul>
    </label>
  </div>
</div>
<!-- <div id="eve container" class="hero text-center">
  <div class="row up-events"> -->
    <!-- <div class="container back-image col-md-2">
      <img src="assets/sheImages/partners/partner_admin.png">
    </div> -->
    <!-- <div class="col-md-12">
      <h1 class="mb-20">Upcoming Events</h1> -->
      <!-- Slide Bar -->
      <!-- <div class="col-md-12">
        <div id="blogSlider" class="">
          <div class="MS-content">
            <div class="item bg-light border  w-20 rounded" *ngFor="let image of UpcomingEventData;let i = index">
              <div class="imgTitle">
                <h2 class="blogTitle">{{image.eventName}}</h2>
                <i *ngIf="checked && image.isSubscribed || checkSubscribtion(image)" class="fa fa-check-circle"
                  aria-hidden="true" style="font-size:20px;color:greenyellow"></i>
                <img data-toggle="modal" data-target="#exampleModalCenter" (click)="openModal(image,i)"
                  data-effect="mfp-zoom-in" class="card-img-top img-fluid" src="{{companySrc}}" alt="">
                <h4 class="card-title mt-3">{{moment(image.eventDate).format('DD-MM-YYYY')}}</h4>
              </div>
              <div class="card-footer" style="padding: 0;">
                <small><i *ngIf="checked && image.isSubscribed || image.eventEnrolledBy"
                    class="fas fa-user-friends d-flex justify-content-end disabled" aria-hidden="true"
                    style="font-size:20px;color: black;" ngbPopover="Coming Soon" placement="right"
                    triggers="mouseenter:mouseleave" ngbPopover-append-to-body="true"></i></small>
                <button data-toggle="modal" data-target="#exampleModalCenter" (click)="openModal(image,i)"
                  class="btn btn-secondary btn-sm">show</button>

              </div>
            </div>
          </div>
          <div class="MS-controls">
            <button class="MS-left"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
            <button class="MS-right"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
          </div>
        </div>
      </div> -->

      <div class="sh-upcoming">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                    <div class="jb_heading_wraper">
                        <h3 class="separator">Upcoming Events</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="container mt-4">
                        <div id="carousel-example-1z" class="carousel slide carousel-slide mb-5" data-ride="carousel">
                            <div class="carousel-inner" role="listbox">
                                <div class="row row-equal carousel-item {{ i == 0 ? 'active' : '' }}"
                                    *ngFor="let allcompanies of dataValue;let i =index;">
                                    <div id="company_wrap" class="col-lg-3 col-md-3 col-sm-12 col-xs-12"
                                        *ngFor="let image of allcompanies;let i = index" style="display:inline-flex;">
                                        <!-- *ngIf="row.jobsPosted?.length > 0" -->
                                        <div id="company_wrap1" class="company_main_wrapper"
                                        data-toggle="modal" data-target="#exampleModalCenter" (click)="openModal(image,i)">
                                            <div id="company_wrap2" class="company_img_wrapper">
                                              <i *ngIf="checked && image.isSubscribed || checkSubscribtion(image)" class="fa fa-check-circle"
                                                      aria-hidden="true" style="font-size:20px;color:greenyellow"></i>
                                                <div class="logo_res">
                                                    <img [src]="image.companyLogo ? image.companyLogo:companySrc" class="logo_img" alt="team_img1">
                                                </div>
                                                
                                            </div>
                                            <div id="company_wrap4" class="opening_job">
                                                <h1>{{image.eventName}}</h1>
                                            </div>
                                            <div id="company_wrap5" class="company_img_cont_wrapper">
                                                <p class="social_media">{{image.companyName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="carousel-control-prev">
                                <a class="carousel-control-prev" href="#carousel-example-1z" role="button"
                                    data-slide="prev">
                                    <span aria-hidden="true"><i class="fa fa-angle-left"
                                            style="font-size:48px;color:#393939"></i></span>

                                </a>
                            </div>
                            <div id="carousel-control-next">
                                <a class="carousel-control-next" href="#carousel-example-1z" role="button"
                                    data-slide="next">
                                    <span aria-hidden="true"><i class="fa fa-angle-right"
                                            style="font-size:48px;color:#393939"></i></span>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>



      <!-- SlideBar -->


      <!-- </div> -->
    <!-- </div>
  </div>
</div> -->
<!-- 2nd -->
<!-- <div class="typewriter hero text-center">
  <h1 id="demo"></h1>
</div> -->
<!--  -->




<!-- Event Card -->
<div *ngIf="showDialog" class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="container-fluid">
    <div class="row d-flex justify-content-center modal-dialog-centered">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 animated zoomIn">
        <div class="tile">
          <div class="wrapper">
            <div class="header">{{upcomingEventSelected.companyName}}</div>

            <div class="banner-img row">
              <div class="col-md-6 mt-3">
                <!-- <div class="spinner-border" id="spinner" role="status">
                  <span class="sr-only">Loading...</span>
                </div> -->
                <img class="example" src="{{companySrc}}" alt="Image 1">
              </div>

              <div class="col-md-6 mt-4">
                <h6 class="types"><strong><i class="fa fa-users"></i> {{upcomingEventSelected.eventName}}</strong></h6>
                <h6 class="types"><strong><i
                      class="fa fa-briefcase"></i>{{upcomingEventSelected.minexperience}}-{{upcomingEventSelected.maxexperience}}
                    yrs</strong></h6>
                <h6 class="types"><strong><i class="fa fa-map-marker"></i>
                    {{upcomingEventSelected.eventLocation}}</strong></h6>
                <h6 class="types"><strong><i
                      class="fa fa-calendar"></i>{{moment(upcomingEventSelected.eventDate).format('DD-MM-YYYY')}}</strong>
                </h6>
              </div>
            </div>

            <div class="dates">
              <div class="start w-100" ngbPopover="Click To Check Location" placement="top"
                triggers="mouseenter:mouseleave" (click)="map(upcomingEventSelected.eventAddress)">
                <strong>ADDRESS</strong>{{upcomingEventSelected.eventAddress}}
                <span></span>
              </div>
            </div>

            <div class="stats">
              <div class="col-md-4">
                <strong>CUTOFF</strong><span class="badge badge-primary badge-pill">50%</span>
              </div>
              <div class="col-md-4">
                <strong>FOOTFALL</strong> <span
                  class="badge badge-success badge-pill">{{upcomingEventSelected.minimumFootfall}}</span>
              </div>
              <div class="col-md-4">
                <strong>MAX-FOOTFALL</strong> <span
                  class="badge badge-danger badge-pill">{{upcomingEventSelected.maximumFootfall}}</span>
              </div>
            </div>
            <div class="footer1">
              <a *ngIf="confirmDisabled" (click)="Confirm(upcomingEventSelected)" class="Cbtn Cbtn-primary">Confirm</a>
              <!-- <a (click)="Remove(upcomingEventSelected)"  class="Cbtn Cbtn-primary">Remove</a> -->
              <a (click)="CloseModal()" class="Cbtn Cbtn-danger">Cancel</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Past Events -->
<!-- <div id="eve" class="mt-20 hero text-center">
  <h1 class="mb-20">Past Events</h1>
</div> -->

<div class="container" style="max-width: 95%">
  <div class="row pt-4 pb-4" id="eve-summary">
    <div class="col-md-4 pb-3">
      <div class="card registration-card">
        <div class="pt-4">
          <h4 class="pl-md-4 pr-md-5 pb-4 pl-2">All Events</h4>
          <hr>
        </div>
        <div class="card-view">
          <div class="media pt-3 pb-1 cursor-pointer" *ngFor="let event of listofPastEvent;let i = index"
            (click)="showDetails(event)">
            <!-- <img src="/assets/sheImages/recruiter/user_pic.png" alt="img"
              style="width: 64px;height: 64px;border-radius: 50%;margin-right: 10px;"> -->
            <div *ngIf="moment() <= moment(event.eventDate)" class="media-body m-1">
              <h5 class="mb-2">{{event.companyName}}</h5>
              <p>{{event.eventName}}</p> <span class="float-right"><p class="text-success">Upcoming</p></span>
              <p> {{moment(event.eventDate).format('DD-MM-YYYY')}} </p>
            </div>
            <div *ngIf="moment() > moment(event.eventDate)" class="media-body m-1">
              <h5 class="mb-2">{{event.companyName}}</h5>
              <p>{{event.eventName}}</p> <span class="float-right"><p class="text-danger">Past</p></span>
              <p> {{moment(event.eventDate).format('DD-MM-YYYY')}} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Old Event Summary -->
    <div class="col-md-8 pb-3">
      <div class="card registration-card">
        <!-- <div class="row pr-4">
          <div class="col-6 pr-2">
            <h4 class="pl-md-5 pr-md-5 pt-4 pb-4 pl-2">Event Summary</h4>
          </div>
          <div class="col-6 text-right pr-md-4 pt-4 pb-4" id="event-edit-panel">
            <a (click)="exportAsXLSX()" class="">
              <i class="fa fa-download"></i>
            </a> 
            <a href="#addProfileModal" id="addProfilebtn" (click)="disableAddbtn()" class="btn btn-success" data-toggle="modal">
              <i class="fa fa-address-card" aria-hidden="true"></i>
              <span> Add profile</span></a>
            
          </div>
        </div> -->

        <div class="row card-header">
          <h4 class="pl-md-5 pr-md-5 pt-4 pb-4 pl-2 col-md-9 col-9">Event Summary</h4>
          <a (click)="exportAsXLSX()" id="exportFile" class="pt-4 pr-4 text-success" data-toggle="tooltip" data-placement="right" title="Export">
            <i class="fa fa-download fa-2x"></i>
          </a> 
          <a href="#addProfileModal" id="addProfilebtn" (click)="disableAddbtn()" class="btn btn-success ml-4 pl-4 pb-4 mb-4" data-toggle="modal">
            <i class="fa fa-address-card" aria-hidden="true"></i>
            <span> Add profile</span></a>
        </div>


        <hr>
        <div class="card-body card-view pl-md-5 pr-md-5">
          <div> 
            <div class="row">
              <div class="col-md-12">
                <h4 *ngIf="!EventDetail.listofApplicants || EventDetail.listofApplicants.length == 0" class="text-center">There are no applicants for this event</h4>
                <!-- <h4 *ngIf="UploadNotPossible" class="text-center">{{viewContent}} <i class='far fa-frown'></i></h4> -->
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" *ngIf="EventDetail && EventDetail.listofApplicants && EventDetail.listofApplicants.length > 0" class="table table-hover borderless table-fixed datatable">
                  <thead class="">
                    <tr class="heading-color">
                      <th scope="col">Email</th>
                      <th scope="col">Experience - Yrs</th>
                      <th scope="col">Notice-Period - Days</th>
                      <th scope="col">Profile Status</th>
                      <th scope="col">Profile Submitted By</th>
                      <!-- <th scope="col">Profile Match Score</th> -->
                    </tr>
                  </thead>
                  <tbody *ngIf="ClikedEvent" class="t-body">
                    <tr id="editProfilebtn"  (click)="EditProfileApplicant(applicant)" data-toggle="modal" *ngFor="let applicant of EventDetail.listofApplicants">
                      <td>{{applicant.applicantEmailId}}</td>
                      <td>{{applicant.applicantExperience}} </td>
                      <td>{{applicant.applicantNoticePeriod}}</td>
                      <td [ngClass]="getBadgeClass(applicant.status[0])" >{{applicant.status}}</td>
                      <td>{{applicant.profileSubmittedBy}}</td>
                      <!-- <td></td> -->
                      <!-- <td>{{applicant.matchscore}}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Modal HTML -->
    <div id="addProfileModal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <form [formGroup]="addProfileForm" ngNativeValidate (ngSubmit)="addProfile()">
            <div class="modal-header">
              <h4 class="modal-title">Add Profile</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
              <div class="">
                <span>Choose file * (Doc/Docx Format)</span>
                <input type="file" #file id="file-id" (change)="fileChangeEvent($event)" type="file" name="resume"
                accept=".doc, .docx,.pdf">
              </div>
              <div class="" *ngIf="parsingResume">
                <span><p class="text-danger">Please Wait For Resume Upload</p></span>
              </div>
              <div class="" *ngIf="SuccessparsingResume">
                <span><p class="text-success">Resume Uploaded Successfully <i class="fas fa-check"></i></p></span>
              </div>
              <div class="form-group">
                <label>Profile Match Score : {{matchscore}} %</label>
                <!-- <input type="text" readonly [(ngModel)]="matchscore" class="form-control" formControlName="matchPercentage" name="matchPercentage" placeholder="Profile Match %*"
                  required> -->
              </div>
              <div class="form-group">
                <label>Candidate Name*</label>
                <input type="text" class="form-control" formControlName="name" name="name" placeholder="Candidate Name*"
                  required>
              </div>
              <div class="form-group">
                <label>Email*</label>
                <input type="email" class="form-control" formControlName="email" name="email" placeholder="Email*"
                  required [(ngModel)]="email">
              </div>
              <div class="form-group">
                <label>Phone*</label>
                <input type="number" id="phone" name="phone" formControlName="phone" placeholder="1234567890"
                  pattern="^\d{10}$" required [(ngModel)]="contactNo">
              </div>
              <div class="form-group">
                <label>Experience*</label>
                <input type="number" id="experience" name="experience" formControlName="experience" placeholder="5"
                  pattern="^\d{10}$" required>
              </div>
              <div class="form-group">
                <label>Expected CTC*</label>
                <input type="number" class="form-control" formControlName="ctc" name="ctc" placeholder="CTC(lac)*"
                  required>
              </div>
              <div class="form-group">
                <label>Notice Period*</label>
                <input type="number" class="form-control" formControlName="notice" name="notice"
                  placeholder="Notice Period*" required>
              </div>
            </div>
            <div class="modal-footer">
              <input type="button" class="btn btn-default" (click)="remove()" data-dismiss="modal" value="Cancel">
              <input type="submit" id="addmodal" class="btn btn-success" value="Add">
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Edit Modal HTML -->
    <div *ngIf="hideForPastEvent" id="editEmployeeModal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <form [formGroup]="editProfileForm"  ngNativeValidate (ngSubmit)="editProfiles()">
            <div class="modal-header">
              <h4 class="modal-title">Edit Profile</h4>
              <button type="button" (click)="close()" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Candidate Name*</label>
                <input type="text" class="form-control"    formControlName="applicantName" name="applicantName" placeholder="Candidate Name*"
                  required>
              </div>
              <div class="form-group">
                <label>Email*</label>
                <input type="email" class="form-control"  [readonly]="true"   formControlName="applicantEmailId" name="applicantEmailId" placeholder="Email*"
                  >
              </div>
              <div class="form-group">
                <label>Phone*</label>
                <input type="number" id="phone" name="phone"   formControlName="applicantPhone" placeholder="1234567890"
                  pattern="^\d{10}$" required>
              </div>
              <div class="form-group">
                <label>Experience*</label>
                <input type="number" id="experience" name="experience"  formControlName="applicantExperience" placeholder="5"
                  pattern="^\d{10}$" required>
              </div>
              <div class="form-group">
                <label>Expected CTC*</label>
                <input type="number" class="form-control"   formControlName="applicantExpectedCTC" name="applicantExpectedCTC" placeholder="CTC(lac)*"
                  required>
              </div>
              <div class="form-group">
                <label>Notice Period*</label>
                <input type="number" class="form-control"  formControlName="applicantNoticePeriod" name="applicantNoticePeriod"
                  placeholder="Notice Period*" required>
              </div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" [(ngModel)]="editProfileForm.unavailable" id="exampleCheck1" formControlName="unavailable" name="unavailable">
                <label class="form-check-label" for="exampleCheck1">Unavailable</label>
              </div>
            </div>

            <div class="modal-footer">
              <input type="button" (click)="close()" class="btn btn-default" data-dismiss="modal" value="Cancel">
              <input type="submit" id="addmodal" class="btn btn-success" value="Edit">
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Delete Modal HTML -->
    <div id="deleteEmployeeModal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <form>
            <div class="modal-header">
              <h4 class="modal-title">Delete Employee</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete these Records?</p>
              <p class="text-warning"><small>This action cannot be undone.</small></p>
            </div>
            <div class="modal-footer">
              <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel">
              <input type="submit" class="btn btn-danger" value="Delete">
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Editable Table -->


  </div>
</div>
<app-minimalistic-footer></app-minimalistic-footer>