import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { AuthService, GoogleLoginProvider } from 'angular-6-social-login';
var JobseekerSignupComponent = /** @class */ (function () {
    function JobseekerSignupComponent(fb, authService, socialAuthService, router, formBuilder, HttpClient) {
        this.fb = fb;
        this.authService = authService;
        this.socialAuthService = socialAuthService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.HttpClient = HttpClient;
        this.error_message = null;
        this.errorFromApi = false;
        this.formInvalid = false;
        this.formErrors = false;
        this.passwordMismatch = false;
        this.submitted = false;
        this.mobilePatern = false;
        this.disableLogin = false;
        this.SignupForm = fb.group({
            'emailId': [null, Validators.required],
            'name': [null, Validators.required],
            'password': [null, Validators.required],
            'termsandconditions': [false, Validators.pattern('true')],
            'resume': ['']
        });
    }
    Object.defineProperty(JobseekerSignupComponent.prototype, "f", {
        get: function () { return this.SignupForm.controls; },
        enumerable: true,
        configurable: true
    });
    JobseekerSignupComponent.prototype.ngOnInit = function () {
        document.location.href = "https://jobs.shenzyn.com/#/user/sign-up";
        // $(".toggle-password").click(function() {
        //   $(this).toggleClass("fa-eye fa-eye-slash");
        //   var input = $($(this).attr("toggle"));
        //   if (input.attr("type") == "password") {
        //     input.attr("type", "text");
        //   } else {
        //     input.attr("type", "password");
        //   }
        // });
        //  this.emails = localStorage.getItem('email'.replace(/['"]+/g, '')) 
        //  this.email=this.emails.replace(/['["[]]+/g, '')
    };
    // form validation
    JobseekerSignupComponent.prototype.signUp = function (formdata) {
        var _this = this;
        this.submitted = true;
        if (this.SignupForm.invalid) {
            return;
        }
        this.loading = true;
        this.authService.signIn(formdata).subscribe(function (data) {
            _this.userData = (data);
            if ((_this.userData.responseStatus === 200)) {
                console.log('200-Success');
                _this.loading = false;
                _this.erroMsg = '';
                _this.submitted = false;
                swal({
                    title: 'Success',
                    text: data.message,
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['/login']);
                    }
                });
            }
        }, function (error) {
            if (error.responseStatus === 401) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 500) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 400) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
        }, function () { return console.log(); });
    };
    JobseekerSignupComponent.prototype.socialSignIn = function (socialPlatform) {
        var _this = this;
        var socialPlatformProvider;
        if (socialPlatform == "google") {
            socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
            this.socialAuthService.signIn(socialPlatformProvider).then(function (userData) {
                var idTokenData = { "code": userData.idToken };
                _this.authService.gmail(idTokenData).subscribe(function (data) {
                    _this.userData = (data);
                    if ((_this.userData.responseStatus === 200)) {
                        localStorage.setItem("authtoken", JSON.stringify(data.authToken));
                        localStorage.setItem("emailId", JSON.stringify(data.data.email));
                        localStorage.setItem("name", JSON.stringify(data.data.name));
                        _this.router.navigate(['/user-profile']);
                    }
                    else if (data.responseStatus === 201) {
                        _this.loading = false;
                        _this.erroMsg = data.message;
                    }
                }, function (error) {
                    if (error.responseStatus === 401) {
                        _this.loading = false;
                        _this.erroMsg = error.message;
                    }
                    else if (error.responseStatus === 500) {
                        _this.loading = false;
                        _this.erroMsg = error.message;
                    }
                    else if (error.responseStatus === 400) {
                        _this.loading = false;
                        _this.erroMsg = error.message;
                    }
                }, function () { return console.log(); });
            });
        }
        else if (socialPlatform == "linkedin") {
            this.authService.linkedin();
            // socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID
            // this.socialAuthService.signIn(socialPlatformProvider).then(
            //   (userData) => {
            //   }
            // );
        }
    };
    return JobseekerSignupComponent;
}());
export { JobseekerSignupComponent };
