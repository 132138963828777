import { OnInit, } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as AOS from "aos";
import { AuthServices } from "../services/auth.service";
import { JobseekerService } from "../services/jobseeker.operations";
import { FormGroup, FormBuilder } from "@angular/forms";
import { environment } from "../../environments/environment";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceDetectorService } from "ngx-device-detector";
var Home1Component = /** @class */ (function () {
    function Home1Component(document, fb, modalService, modalService2, toastr, router, route, authService, JobseekerService, deviceService) {
        this.fb = fb;
        this.modalService = modalService;
        this.modalService2 = modalService2;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this.authService = authService;
        this.JobseekerService = JobseekerService;
        this.deviceService = deviceService;
        this.subscriptions = [];
        this.fileToUpload = null;
        this.disableLogin = false;
        this.show = false;
        this.deviceInfo = null;
        this.isMobile = false;
        this.isDesktopDevice = false;
        this.prev = true;
        this.next = true;
        this.SerachForm = fb.group({
            _id: [""],
            _location: [""],
        });
    }
    Object.defineProperty(Home1Component.prototype, "f", {
        get: function () {
            return this.SerachForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    // Onclick function
    Home1Component.prototype.myClickFunction = function (event) {
        this.show = !this.show;
        console.log(event);
    };
    Home1Component.prototype.goToSearch = function (formdata) {
        this.router.navigate(["/search-view", this._id, this._location]);
    };
    Home1Component.prototype.companies = function (array, size) {
        var results = [];
        results = [];
        while (array.length) {
            results.push(array.splice(0, size));
        }
        return results;
    };
    Home1Component.prototype.ngOnInit = function () {
        // $("#GroupWebinarModal").modal("show");
        var _this = this;
        this.jobsUrlLink = environment.jobsWebUrl;
        this.employerUrlLink = environment.employersWebUrl;
        this.partnersUrlLink = environment.partnersWebUrl;
        this.loadAPI = new Promise(function (resolve) {
            _this.loadScript();
            resolve(true);
        });
        AOS.init();
        this.authService.getAllCompanies().subscribe(function (data) {
            var allCom = data.data;
            _this.dataValue = _this.companies(allCom, 4);
            console.log(_this.dataValue.length);
        }, function (error) {
            if (error.responseStatus === 401) {
            }
            else if (error.responseStatus === 500) {
            }
            else if (error.responseStatus === 400) {
            }
        }, function () { return console.log(); });
        // Device Detector
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        var isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
        if (this.isMobile) {
            document.body.style.overflow = "visible";
            // document.body.style.maxWidth="100%";
            if (this.deviceInfo.device == "iPhone") {
                this.prev = false;
                this.next = false;
            }
        }
        // show Beyond DiversityAd
        // this.showBeyondDiversityAd();
    };
    Home1Component.prototype.getJob = function (comName) {
        this.router.navigate(["/jobs-by-comapny", comName]);
    };
    Home1Component.prototype.fileChangeEvent = function (event) {
        var _this = this;
        var file = event.target.files[0];
        console.log(file);
        this.fileToUpload = file;
        console.log(this.fileToUpload);
        this.JobseekerService.resumeUpload(this.fileToUpload).subscribe(function (data) {
            _this.userData = data;
            if (_this.userData.responseStatus === 200) {
                var email = data.email;
                if (data.newuser) {
                    //ToDO : check if email is valid, else redirect to register
                    localStorage.setItem("email", email);
                    Swal({
                        title: "Registration Confirmation",
                        html: "You will be registerd on Shenzyn with the email id : " +
                            email +
                            ", and you agree to shenzyn <a href='https://www.shenzyn.com/#/termsandconditions' target=”_blank”> T&C</a>",
                        type: "info",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Register Please!",
                    }).then(function (result) {
                        if (result.value) {
                            document.location.href =
                                _this.jobsUrlLink + "/#/user/upload-register/" + email;
                        }
                    }, function (error) {
                        //cancel action nothing to do
                    });
                }
                else {
                    document.location.href = _this.jobsUrlLink + "/#/user/login/" + email;
                }
            }
            else {
                document.location.href = _this.jobsUrlLink + "/#/user/sign-up";
            }
        });
    };
    Home1Component.prototype.loadScript = function () {
        var isFound = false;
        var scripts = document.getElementsByTagName("script");
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute("src") != null &&
                scripts[i].getAttribute("src").includes("loader")) {
                isFound = true;
            }
        }
        if (!isFound) {
            var dynamicScripts = ["../assets/js/custom.js"];
            for (var i = 0; i < dynamicScripts.length; i++) {
                var node = document.createElement("script");
                node.src = dynamicScripts[i];
                node.type = "text/javascript";
                node.async = false;
                node.charset = "utf-8";
                document.getElementsByTagName("head")[0].appendChild(node);
            }
        }
    };
    Home1Component.prototype.closePublisModal = function () {
        $("#GroupWebinarModal").modal("hide");
        $(".modal-backdrop").remove();
    };
    Home1Component.prototype.showBeyondDiversityAd = function () {
        this.toastr.show("<a href='https://beyondiversity.shenzyn.com/#/home?ref=Shenzyn' target='_blank'><img height='250px' src='../assets/sheImages/I - L.E.A.P-min.png'></a>", "", {
            timeOut: 15000,
            extendedTimeOut: 5000,
            positionClass: "toast-bottom-right",
            enableHtml: true,
            closeButton: true,
        }
        //       {
        //   timeOut: 150000,
        //   extendedTimeOut: 50000,
        //   positionClass: "toast-bottom-right",
        //   enableHtml: true,
        //   closeButton: true,
        //   tapToDismiss: false,
        // }
        );
    };
    return Home1Component;
}());
export { Home1Component };
