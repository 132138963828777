import { Component, OnInit,HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-employer-signup',
  templateUrl: './emp-signup.component.html',
  styleUrls: ['./emp-signup.component.css']
})
export class SignupEmployerComponent implements OnInit {
  _id
  _location
  error_message=null;
  errorFromApi=false;
  SignupFormForEmployee: FormGroup;
  formInvalid=false;
  formErrors=false;
  passwordMismatch=false;
  submitted = false;
  mobilePatern=false;
  emailId:any;
  password:any;
  req_signup:any;
  companyName: any;
  invalid: any;
  password_error_msg:any;
  constructor(private authService: AuthServices, private router: Router,private formBuilder: FormBuilder,private HttpClient:HttpClient) { 
   
  }
  ngOnInit(){
    document.location.href = "https://employer.shenzyn.com/#/user/sign-up";

    // this.formvalidation();
    // $(".toggle-password").click(function() {

    //   $(this).toggleClass("fa-eye fa-eye-slash");
    //   var input = $($(this).attr("toggle"));
    //   if (input.attr("type") == "password") {
    //     input.attr("type", "text");
    //   } else {
    //     input.attr("type", "password");
    //   }
    // });
    // $(".toggle-password1").click(function() {

    //   $(this).toggleClass("fa-eye fa-eye-slash");
    //   var input = $($(this).attr("toggle"));
    //   if (input.attr("type") == "password") {
    //     input.attr("type", "text");
    //   } else {
    //     input.attr("type", "password");
    //   }
    // });
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 50) {
       let element = document.getElementById('header_data');
       element.style.background="rgba(255,255,255,0.9)";
       element.style.position="fixed";  
       element.style.marginTop="-2px";
       element.style.transitionDuration="0.6s";
       element.style.boxShadow="0px 1px 10px rgba(0,0,0,0.4)";
      
     } else {
      let element = document.getElementById('header_data');
      element.style.background="";
      element.style.position="";
      element.style.marginTop=""; 
      element.style.boxShadow="";
     }
  }
// form validation
  formvalidation() 
  {
    this.SignupFormForEmployee=this.formBuilder.group({
      company: ['', Validators.required],
      companyEmail: ['', Validators.required],
      password:['',[Validators.required]],
      confirmPass:['', [Validators.required]],
  });
  }

  get f(){  
    return this.SignupFormForEmployee.controls;
  }
// api integration
  signUp() {
    this.submitted = true;
    if(this.SignupFormForEmployee.value.password &&this.SignupFormForEmployee.value.confirmPass ){
      if(this.SignupFormForEmployee.value.password == this.SignupFormForEmployee.value.confirmPass){
        this.passwordMismatch=false;
      }else{
        this.passwordMismatch=true;
        this.password_error_msg="Password & confirmpassword should be match."
        return false;
      }
    }
    else{
      this.formErrors=true;
      return;
    }
    if(this.SignupFormForEmployee.invalid){
      this.formInvalid=true;
      return;
    }
    this.formInvalid=false;
   
    console.log(this.req_signup)
      this.emailId=this.SignupFormForEmployee.value.companyEmail,
      this.password=this.SignupFormForEmployee.value.password,
      this.companyName= this.SignupFormForEmployee.value.company,
    this.authService.signInEmploye(this.companyName, this.emailId, this.password)
   

  }
  logiIn()
  {
    this.router.navigate(['/employe-login']); 
  }
  
}