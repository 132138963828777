import {
  Component,
  OnInit,
  HostListener,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import * as AOS from "aos";
import { AuthServices } from "../services/auth.service";
import { JobseekerService } from "../services/jobseeker.operations";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { environment } from "../../environments/environment";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceDetectorService } from "ngx-device-detector";
declare var $: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component-purified.css"],
  encapsulation: ViewEncapsulation.None,
})
export class Home1Component implements OnInit {
  closeResult: string;
  _id;
  _location;
  subscriptions = [];
  fileToUpload: File = null;
  userData: any;
  submitted: boolean;
  loadAPI: Promise<any>;
  disableLogin: boolean = false;
  dataValue: any[];
  SerachForm: FormGroup;
  loading: boolean;
  show: boolean = false;
  deviceInfo = null;
  isMobile: boolean = false;
  isDesktopDevice: boolean = false;
  username: any;
  email: any;
  authtoken: any;
  prev: boolean = true;
  next: boolean = true;
  jobsUrlLink: String;
  employerUrlLink: String;
  partnersUrlLink: String;
  constructor(
    @Inject(DOCUMENT) document,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private modalService2: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthServices,
    private JobseekerService: JobseekerService,
    private deviceService: DeviceDetectorService
  ) {
    this.SerachForm = fb.group({
      _id: [""],
      _location: [""],
    });
  }
  get f() {
    return this.SerachForm.controls;
  }

  // Onclick function
  myClickFunction(event) {
    this.show = !this.show;
    console.log(event);
  }

  goToSearch(formdata: any) {
    this.router.navigate(["/search-view", this._id, this._location]);
  }
  companies(array, size) {
    let results: any = [];

    results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  }
  ngOnInit() {
    // $("#GroupWebinarModal").modal("show");

    this.jobsUrlLink = environment.jobsWebUrl;
    this.employerUrlLink = environment.employersWebUrl;
    this.partnersUrlLink = environment.partnersWebUrl;

    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
    AOS.init();

    this.authService.getAllCompanies().subscribe(
      (data) => {
        const allCom = data.data;
        this.dataValue = this.companies(allCom, 4);
        console.log(this.dataValue.length);
      },
      (error) => {
        if (error.responseStatus === 401) {
        } else if (error.responseStatus === 500) {
        } else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );

    // Device Detector

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    if (this.isMobile) {
      document.body.style.overflow = "visible";
      // document.body.style.maxWidth="100%";
      if (this.deviceInfo.device == "iPhone") {
        this.prev = false;
        this.next = false;
      }
    }

    // show Beyond DiversityAd
    // this.showBeyondDiversityAd();
  }

  getJob(comName) {
    this.router.navigate(["/jobs-by-comapny", comName]);
  }
  fileChangeEvent(event: any) {
    var file = event.target.files[0];
    console.log(file);
    this.fileToUpload = file;
    console.log(this.fileToUpload);
    this.JobseekerService.resumeUpload(this.fileToUpload).subscribe((data) => {
      this.userData = data;
      if (this.userData.responseStatus === 200) {
        var email = data.email;
        if (data.newuser) {
          //ToDO : check if email is valid, else redirect to register
          localStorage.setItem("email", email);
          Swal({
            title: "Registration Confirmation",
            html:
              "You will be registerd on Shenzyn with the email id : " +
              email +
              ", and you agree to shenzyn <a href='https://www.shenzyn.com/#/termsandconditions' target=”_blank”> T&C</a>",
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Register Please!",
          }).then(
            (result) => {
              if (result.value) {
                document.location.href =
                  this.jobsUrlLink + "/#/user/upload-register/" + email;
              }
            },
            (error) => {
              //cancel action nothing to do
            }
          );
        } else {
          document.location.href = this.jobsUrlLink + "/#/user/login/" + email;
        }
      } else {
        document.location.href = this.jobsUrlLink + "/#/user/sign-up";
      }
    });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; ++i) {
      if (
        scripts[i].getAttribute("src") != null &&
        scripts[i].getAttribute("src").includes("loader")
      ) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = ["../assets/js/custom.js"];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement("script");
        node.src = dynamicScripts[i];
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
      }
    }
  }
  closePublisModal() {
    $("#GroupWebinarModal").modal("hide");
    $(".modal-backdrop").remove();
  }

  showBeyondDiversityAd() {
    this.toastr.show(
      "<a href='https://beyondiversity.shenzyn.com/#/home?ref=Shenzyn' target='_blank'><img height='250px' src='../assets/sheImages/I - L.E.A.P-min.png'></a>",
      "",
      {
        timeOut: 15000,
        extendedTimeOut: 5000,
        positionClass: "toast-bottom-right",
        enableHtml: true,
        closeButton: true,
      }

      //       {
      //   timeOut: 150000,
      //   extendedTimeOut: 50000,
      //   positionClass: "toast-bottom-right",
      //   enableHtml: true,
      //   closeButton: true,
      //   tapToDismiss: false,
      // }
    );
  }
}
