<!-- <nav [class.show]="show" class="sidenav cd-dropdown cd_dropdown_index2 cd_dropdown_index3 d-block d-sm-block d-md-block d-lg-none d-xl-none"
    style="margin-top: 120px;">
    <br /> <br /> <br />
    <a href="#" class="cd-close">Close</a>
    <ul class="cd-dropdown-content">
        <li><a routerLink="/login">login</a></li>
        <li><a routerLink="/employe-login">Employer</a></li>
    </ul>
</nav> -->

<nav id="mySidenav" class="sidenav" [class.show]="show">
    <div class="sidebar-rotater">
        <!-- <a href="#" class="closebtn" (click)="closeNav()">&times;</a> -->
        <ul>
            <!-- <li><a class="login-sidenav" routerLink="/login"><i class="fas fa-sign-in-alt"></i>JOBSEEKER</a></li> -->
            <li><a class="login-sidenav" href={{partnersUrlLink}}><i class="fas fa-sign-in-alt"></i>PARTNER</a></li>
            <!-- <li><a class="employe-sidenav" routerLink="/employe-login"><i class="fas fa-user-circle"></i>EMPLOYER</a></li> -->
            <li><a class="employe-sidenav" href={{employerUrlLink}}><i class="fas fa-user-circle"></i>EMPLOYER</a></li>
            <li><a class="employe-sidenav" routerLink="/webinar"><i class="fas fa-users mr-2"></i>WEBINAR</a></li>
        </ul>
    </div>
</nav>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
    <div class="cp_logo_wrapper index_2_logo">
        <a routerLink="/homenew">
            <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
        </a>
    </div>
    <header class="mobail_menu d-block d-sm-block d-md-block d-lg-none d-xl-none" id="navbar">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="cd-dropdown-wrapper drop-position">
                        <a class="house_toggle" (click)="toggleCollapse()">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 31.177 31.177"
                                style="enable-background:new 0 0 31.177 31.177;" xml:space="preserve" width="25px"
                                height="25px">
                                <g>
                                    <g>
                                        <path class="menubar"
                                            d="M30.23,1.775H0.946c-0.489,0-0.887-0.398-0.887-0.888S0.457,0,0.946,0H30.23    c0.49,0,0.888,0.398,0.888,0.888S30.72,1.775,30.23,1.775z"
                                            fill="#004165" />
                                    </g>
                                    <g>
                                        <path class="menubar"
                                            d="M30.23,9.126H12.069c-0.49,0-0.888-0.398-0.888-0.888c0-0.49,0.398-0.888,0.888-0.888H30.23    c0.49,0,0.888,0.397,0.888,0.888C31.118,8.729,30.72,9.126,30.23,9.126z"
                                            fill="#004165" />
                                    </g>
                                    <g>
                                        <path class="menubar"
                                            d="M30.23,16.477H0.946c-0.489,0-0.887-0.398-0.887-0.888c0-0.49,0.398-0.888,0.887-0.888H30.23    c0.49,0,0.888,0.397,0.888,0.888C31.118,16.079,30.72,16.477,30.23,16.477z"
                                            fill="#004165" />
                                    </g>
                                    <g>
                                        <path class="menubar"
                                            d="M30.23,23.826H12.069c-0.49,0-0.888-0.396-0.888-0.887c0-0.49,0.398-0.888,0.888-0.888H30.23    c0.49,0,0.888,0.397,0.888,0.888C31.118,23.43,30.72,23.826,30.23,23.826z"
                                            fill="#004165" />
                                    </g>
                                    <g>
                                        <path class="menubar"
                                            d="M30.23,31.177H0.946c-0.489,0-0.887-0.396-0.887-0.887c0-0.49,0.398-0.888,0.887-0.888H30.23    c0.49,0,0.888,0.398,0.888,0.888C31.118,30.78,30.72,31.177,30.23,31.177z"
                                            fill="#004165" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="jb_navigation_wrapper index_2_right_menu index_3_right_menu">
        <div class="posting_job employer_gap">
            <ul>
                <li class="btn_hover_new fadeInDown">
                    <a routerLink="/webinar">Webinar</a>
                </li>
                <li class="btn_hover fadeInDown">
                    <a href={{employerUrlLink}}>Employer</a>
                </li>
                <li class="btn_hover fadeInDown">
                    <a href={{partnersUrlLink}}>Partner</a>
                </li>
            </ul>
        </div>
    </div>
</div>