<!-- New Header for Responsive function -->
<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>

<app-home-header></app-home-header>
<div id="main">
    <div class="main_slider_wrapper slider-area jb_cover" id="banner">
        <div class="mains_slider_shaper fadeInDown">
            <img src="/assets/sheImages/slider_bg.png" class="img-responsive" alt="img">
        </div>
        <div id="carousel-example-generic" class="carousel slide" data-ride="carousel" data-interval="false">
            <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                    <div class="carousel-captions caption-1">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div class="content">
                                        <div class="slider_shape_smt1 bubble-2">
                                            <img src="/assets/sheImages/bubble.png" class="img-responsive " alt="img">
                                        </div>
                                        <div id="carouselContent" class="carousel slide" data-ride="carousel">
                                            <div class="carousel-inner" role="listbox">
                                                <div class="carousel-item active text-align" style="height:410px;">
                                                    <span style="color:#a234b2;"><b>Who we are</b> </span>
                                                    <h2 data-animation="animated fadeInUp"
                                                        class="top_company_slider_wrapper jb_cover">Unravel hurdles of
                                                        career path </h2>
                                                    <p style="text-align:left;" data-animation="animated fadeInUp">(n)
                                                        <b style="font-style:black">Shenzyn;</b> /she'engine/<br />An
                                                        exclusive platform for the women to find the most suitable
                                                        opportunities and ready themselves to advance their career,
                                                        faster.
                                                        Shenzyn makes smart use of AI and Blockchain to deliver the best
                                                        match between your search and the opportunities to create,
                                                        design,
                                                        reshape your career. </p>
                                                </div>
                                                <div class="carousel-item text-align" style="height:410px;">
                                                    <span style="color:#a234b2;"><b>What We do</b> </span>
                                                    <h2 data-animation="animated fadeInUp"
                                                        class="top_company_slider_wrapper jb_cover">A marketplace to
                                                        drive
                                                        better diversity</h2>
                                                    <p style="text-align:left;" data-animation="animated fadeInUp">
                                                        Success
                                                        for any company depends on acquiring the best talents to meet
                                                        their
                                                        business objectives. Shenzyn is the AI powered Next Generation
                                                        Marketplace that streamlines the process to deliver that by
                                                        bringing
                                                        together the demands, the head-hunters, the trainers, and the
                                                        women
                                                        talent in one place. </p>
                                                </div>
                                                <div class="carousel-item text-align" style="height:410px;">
                                                    <span style="color:#a234b2;"><b>How we do it</b> </span>
                                                    <h2 data-animation="animated fadeInUp"
                                                        class="top_company_slider_wrapper jb_cover">AI & Blockchain
                                                        based
                                                        platform </h2>
                                                    <p style="text-align:left;" data-animation="animated fadeInUp">
                                                        Shenzyn
                                                        platform is powered by an industry leading AI engine that, like
                                                        a
                                                        human, performs deep research on the profiles vs demands to
                                                        deliver
                                                        the most accurate match. Additionally, we passionately target
                                                        the
                                                        most time consuming activities such as duplication of
                                                        verification,
                                                        validation of certification processes etc for optimization by
                                                        leveraging smart contracts/ Blockchain. </p>
                                                </div>
                                                <li class="nav-dots">
                                                    <a href="#carouselContent" data-slide="next"><label for="img-1"
                                                            class="nav-dot"></label></a>
                                                    <a href="#carouselContent" data-slide="next"><label for="img-2"
                                                            class="nav-dot"></label></a>
                                                    <a href="#carouselContent" data-slide="next"><label for="img-3"
                                                            class="nav-dot"></label></a>
                                                </li>
                                            </div>
                                        </div>
                                        <div data-animation="animated fadeInUp" class="btn_hover slider_btn">
                                            <!-- <a routerLink="/user-signup">join us</a> -->
                                            <a style="color: #fff;" href="https://jobs.shenzyn.com/#/user/login">Log
                                                In</a>

                                        </div>
                                        <div data-animation="animated fadeInUp" class="btn_hover slider_btn">
                                            <!-- <a routerLink="/user-signup">join us</a> -->
                                            <a style="color: #fff;"
                                                href="https://jobs.shenzyn.com/#/user/sign-up">Register
                                            </a>

                                        </div>
                                        <!-- <div data-animation="animated fadeInUp" class="btn_hover slider_btn upload-btn">
                                            <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                                <a style="margin: 2px;"> upload resume
                                                </a> -->
                                        <!-- TODO add a wait indicator-->
                                        <!-- </label>
                                            <input id="file-upload" (change)="fileChangeEvent($event)" type="file" />
                                        </div> -->
                                        <div class="clear"></div>
                                    </div>
                                </div>
                                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                                    <div class="slider_shape_sm3 bubble-3">
                                        <img src="/assets/sheImages/bubble.png" class="img-responsive " alt="img">
                                    </div>
                                    <div class="slider_side_img jb_cover girl_align">
                                        <img src="/assets/sheImages/imgbg.png" class="img-responsive" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider_small_shape">
            <img src="/assets/sheImages/shape4.png" class="img-responsive" alt="img">
        </div>
    </div>
    <div class="index3_form_wrapper jb_cover">
        <div class="slider_small3_shape">
            <img src="/assets/sheImages/shape4.png" class="img-responsive" alt="img">
        </div>

        <div class="container">
            <div class="">
                <div class="col-lg-12 col-md-12 col-sm-12" data-aos="fade-up">
                    <div class="index3_form_box jb_cover">
                        <form>
                            <div class="select_box select_box3 search_bar" style="position:relative;">
                                <input type="text" name="name" [(ngModel)]="_id" class="search_data form-control"
                                    placeholder="Search by title, skills or keyword">

                            </div>
                            <div class="contect_form3 contct_form_new3" style="position:relative;">
                                <input type="text" [(ngModel)]="_location" class="form-control" name="location"
                                    placeholder="Location">

                            </div>
                            <a [routerLink]="['/search-view',_id,_location]">
                                <div class="index3_form_search">
                                    <i class="fas fa-search" style="color:#fff;"></i>
                                </div>
                            </a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="top_company_wrapper jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12" data-aos="fade-up">
                    <div class="jb_heading_wraper">
                        <h3 class="separator">Partners in Diversity</h3>
                    </div>
                </div>
                <div *ngIf="dataValue && dataValue.length !== 0" class="row">
                    <div class="container mt-4">
                        <div id="carousel-example-1z" class="carousel slide carousel-slide mb-5" data-ride="carousel">
                            <div class="carousel-inner" role="listbox">
                                <div class="row row-equal carousel-item {{ i == 0 ? 'active' : '' }}"
                                    *ngFor="let allcompanies of dataValue;let i =index;">
                                    <div id="company_wrap" class="col-lg-3 col-md-3 col-sm-12 col-xs-12"
                                        *ngFor="let row of allcompanies;" style="display:inline-flex;">
                                        <!-- *ngIf="row.jobsPosted?.length > 0" -->
                                        <div id="company_wrap1" class="company_main_wrapper"
                                            (click)=getJob(row.companyName)>
                                            <div id="company_wrap2" class="company_img_wrapper">
                                                <div class="logo_res">
                                                    <img src={{row.logo}} class="logo_img" alt="team_img1">
                                                </div>
                                            </div>
                                            <div id="company_wrap4" class="opening_job">
                                                <h1>{{row.length}} job open</h1>
                                            </div>
                                            <div id="company_wrap5" class="company_img_cont_wrapper">
                                                <p class="social_media">{{row.companyName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="carousel-control-prev" *ngIf="prev">
                                <a class="carousel-control-prev" href="#carousel-example-1z" role="button"
                                    data-slide="prev">
                                    <span aria-hidden="true"><i class="fa fa-angle-left"
                                            style="font-size:48px;color:#393939"></i></span>

                                </a>
                            </div>
                            <div id="carousel-control-next" *ngIf="next">
                                <a class="carousel-control-next" href="#carousel-example-1z" role="button"
                                    data-slide="next">
                                    <span aria-hidden="true"><i class="fa fa-angle-right"
                                            style="font-size:48px;color:#393939"></i></span>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="client_wrapper_top jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                    <div class="jb_heading_wraper">
                        <h3 style="text-transform: none;" data-aos="fade-up" class="separator">Testimonials</h3>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="client_wrapper jb_cover ">
                        <div class="owl-carousel owl-theme">
                            <div class="item">
                                <div class="row">
                                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                        <div class="client_wrapper_cntnt jb_cover">
                                            <div class="client_shap1 bubble-8">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <div class="client_shap2 bubble-9">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <img src="/assets/sheImages/home_pic/quote1.png" alt="img">
                                            <h1><a>Apurva Mitra</a> <span> (Technology lead)</span></h1>
                                            <p>“It’s a nice mixture of technology valuing diversity and Shenzyn
                                                promoting
                                                women inclusion is a great initiative. This will be a highly impactful
                                                for
                                                our self-sureness and give us power to standout with all features
                                                Shenzyn
                                                carries” </p>
                                            <div class="client_shap3 bubble-6">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                        <div class="clinnt_slider_img jb_cover">
                                            <img src="/assets/sheImages/home_pic/testimonial1.jpg"
                                                class="img-responsive" width="300px;" id="img_align" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="row">
                                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                        <div class="client_wrapper_cntnt jb_cover">
                                            <div class="client_shap1 bubble-8">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <div class="client_shap2 bubble-9">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <img src="/assets/sheImages/home_pic/quote1.png" alt="img">
                                            <h1><a>Pallavi Gupta </a> <span> (Organisation change consultant )</span>
                                            </h1>
                                            <p>“Shenzyn is a step forward towards making the difference at workplace. I
                                                strongly believe that organizations will get benefited with the new
                                                diversified workforce and minds that come together to work with
                                                excellence”
                                            </p>
                                            <div class="client_shap3 bubble-6">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                        <div class="clinnt_slider_img jb_cover">
                                            <img src="/assets/sheImages/home_pic/testimonial2.jpg"
                                                class="img-responsive" width="300px;" id="img_align" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="row">
                                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                        <div class="client_wrapper_cntnt jb_cover">
                                            <div class="client_shap1 bubble-8">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <div class="client_shap2 bubble-9">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <img src="/assets/sheImages/home_pic/quote1.png" alt="img">
                                            <h1><a>Geethapriya Iyer</a> <span> (Content Writer)</span></h1>
                                            <p>Taking a career break was unavoidable due to my motherhood. But, getting
                                                rejected for the same was depressive when I tried to make a comeback.
                                                When I
                                                came in contact with Shenzyn, providing a career platform for many women
                                                like me, it re-established a ray of hope at the end of the dark tunnel.
                                                In
                                                fact, I talked this exclusive platform to my sister in law who also
                                                faced
                                                the same hurdles. </p>
                                            <div class="client_shap3 bubble-6">
                                                <img src="/assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                        <div class="clinnt_slider_img jb_cover">
                                            <img src="/assets/sheImages/home_pic/testimonial3.jpg"
                                                class="img-responsive" width="300px;" id="img_align" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="row">
                                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                                        <div class="client_wrapper_cntnt jb_cover">
                                            <div class="client_shap1 bubble-8">
                                                <img src="../../assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <div class="client_shap2 bubble-9">
                                                <img src="../../assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                            <img src="../../assets/sheImages/home_pic/quote1.png" alt="img">
                                            <h1><a>Antra Gupta</a> <span> (Developer)</span></h1>
                                            <p> “I chose Shenzyn over other portals because they appeared to be the kind
                                                who
                                                would understand the challenges faced by fresher. ”

                                                shenzyn is very user friendly I find the website very interactive and
                                                easy
                                                to use. I can choose an organization to work based on all the things i
                                                need
                                                in a company which are women-friendly.
                                            <div class="client_shap3 bubble-6">
                                                <img src="../../assets/sheImages/bubble.png" class="img-responsive"
                                                    alt="img">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                        <div class="clinnt_slider_img jb_cover">
                                            <img src="../../assets/sheImages/home_pic/testimonial5.jpg"
                                                class="img-responsive" width="300px;" id="img_align" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider_small3_shape shapenew">
            <img src="../../assets/sheImages/shape4.png" class="img-responsive" alt="img">
        </div>
    </div>
    <div class="pricing_table_3 jb_cover">
        <div class="line_shape">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                    <div class="jb_heading_wraper" style="margin-top:20px;">
                        <h3 data-aos="fade-up " class="separator">Sh <bdi
                                style="color:#ff5d6d;font-weight:bold;text-transform: lowercase;letter-spacing:8px;">e</bdi>
                            empowering world
                        </h3>
                        <p data-aos="fade-up" style="align-content: center;"> Shenzyn is an ecosystem for working women
                            professionals based on insights from 2000+ working women. An exclusive platform for
                            diversity growth through AI-powered Next Generation Marketplace that streamlines the process
                            of hiring, training and grabbing your dream job. Want to see our journey so far? </p>
                    </div>
                </div>
                <div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12 ">

                    <div class="video">
                        <video poster="/assets/video/img_video6.png" style="width:100%;" controls>
                            <!-- <source src="https://shenzyn-uploads.s3.ap-south-1.amazonaws.com/Assets/Shenzyn%2BVideo.mp4"
                                type="video/mp4" /> -->
                            <source src="https://shenzyn-uploads.s3.ap-south-1.amazonaws.com/Assets/shenzyn_5.mp4"
                                type="video/mp4" />
                            Browser not supported
                        </video>
                    </div>

                </div>
            </div>
        </div>
        <div class="counter_jbbb2 jb_cover">
        </div>
    </div>
    <div class="pricing_table_3 recent_resume_wrapper jb_cover">
        <div class="slider_small_shape44">
            <img src="/assets/sheImages/p2.png" class="img-responsive " alt="img">
        </div>
        <div class="counter_jbbb2 jb_cover">
            <img src="/assets/sheImages/line3.png" class="img-responsive" alt="img">
        </div>
    </div>
    <div class="grow_next_wrapper jb_cover">
        <div class="jb_heading_wraper" data-aos="fade-up">
            <h3 style="margin-left:15%;margin-right:15%;" class="separator">Our Offerings</h3>
        </div><br />
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="jb_heading_wraper left_jb_jeading" data-aos="fade-up" style="text-align: left;">
                        <h3>Sh<span style="color:#ff5d6d;font-weight:bold;">e</span> Orbit</h3>
                    </div>
                    <div class="grow_next_text jb_cover" data-aos="fade-up">
                        <p>An exclusive platform for women employment with access to thousands of opportunities filtered
                            as
                            per her preferences with our cutting-edge intelligence engine
                            <br>
                            <br> So, orbit your way into a planet full of possibilities! </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="grow_next_img jb_cover" data-aos="fade-up">
                        <img src="/assets/sheImages/product/group-42.svg" width="400px" class="img-responsive"
                            alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grow_next_wrapper jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="grow_next_img jb_cover" data-aos="fade-up">
                        <img src="/assets/sheImages/product/group.svg" width="350px" class="img-responsive" alt="img">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="jb_heading_wraper left_jb_jeading text-right" data-aos="fade-up">
                        <h3 style="text-align:left;">Sh<span style="color:#ff5d6d;font-weight:bold;">e</span> Start</h3>
                    </div>
                    <div class="grow_next_text jb_cover" data-aos="fade-up">
                        <p>An innovative portal that inspires, empowers and leads forward women freshers and bridges the
                            Campus to Corporate pathway. Gives upcoming professionals a perfect way to kickstart their
                            career into the corporate world
                            <br>
                            <br> START your professional journey through SHE Start! </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grow_next_wrapper jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="jb_heading_wraper left_jb_jeading" data-aos="fade-up" style="text-align: left;">
                        <h3>Sh<span style="color:#ff5d6d;font-weight:bold;">e</span> Coach</h3>

                    </div>
                    <div class="grow_next_text jb_cover" data-aos="fade-up">
                        <p> A mentor-ship program by industry experts with specialized focus on aspects like career 2.0.
                            Helps women take off their career rigidly with the certifications, resume building workshops
                            for
                            right profiling
                            <br>
                            <br> Just put your Super Woman cape on and fly high with SHE Coach! </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="grow_next_img jb_cover text-right" data-aos="fade-up">
                        <img src="/assets/sheImages/product/group-34.svg" width="350px" class="img-responsive"
                            alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grow_next_wrapper jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="grow_next_img jb_cover" data-aos="fade-up">
                        <img src="/assets/sheImages/product/group-46.svg" width="400px" class="img-responsive"
                            alt="img">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="jb_heading_wraper text-right" data-aos="fade-up">
                        <h3 style="text-align: left;height:20px;">Sh<span
                                style="color:#ff5d6d;font-weight:bold;">e</span>
                            Assess</h3>
                    </div>
                    <div class="grow_next_text jb_cover" data-aos="fade-up" style="margin-top:20px;">

                        <p> Expert-designed assessment tools for the candidates. Comprises of psychometric, technical
                            and
                            body language based assessment tools. Integration of AI Intervention and video based test
                            improve assessment experience
                            <br>
                            <br>So process, access and assess with SHE Assess! </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grow_next_wrapper jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="jb_heading_wraper left_jb_jeading" data-aos="fade-up" style="text-align: left;">

                        <h3>Sh<span style="color:#ff5d6d;font-weight:bold;">e</span> Event</h3>
                    </div>
                    <div class="grow_next_text jb_cover" data-aos="fade-up">
                        <p>Meet-up events for professional women from technology and business. Attended by various
                            categories of women - from freshers to leaders, these diversity events are the right place
                            to
                            create better women network
                            <br>
                            <br> Log on to SHE Event and get to know about all the diversity events in hiring,
                            leadership
                            and technology workforce! </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="grow_next_img jb_cover text-right" data-aos="fade-up">
                        <img src="/assets/sheImages/product/group-48.svg" width="400px" class="img-responsive"
                            alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grow_next_wrapper jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="grow_next_img jb_cover" data-aos="fade-up">
                        <img src="/assets/sheImages/product/rsz_shenzyn_research.png" width="400" class="img-responsive"
                            alt="img">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 col-sm-12">
                    <div class="jb_heading_wraper text-right" data-aos="fade-up">
                        <h3 style="text-align: left;height:20px;">Sh<span
                                style="color:#ff5d6d;font-weight:bold;">e</span>
                            Research</h3>
                    </div>
                    <div class="grow_next_text jb_cover" data-aos="fade-up" style="margin-top:20px;">

                        <p> Market intelligence, Industry, Skill & Talent mapping, Research reports, geographical
                            analysis and various key statistics for business strategy building and growth!
                            <br>
                            <br>So process, access and assess with SHE Assess! </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Webinar Modal -->
    <div class="modal " id="GroupWebinarModal" tabindex="0" role="dialog" aria-labelledby="GroupWebinar"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <a (click)="closePublisModal()" [routerLink]="['/webinar']">
                        <img class="img-fluid" src="assets/sheImages/webinar/bank2-min.png" height="450px">
                    </a>
                </div>
            </div>
        </div>
    </div>
    
</div>

<app-footer></app-footer>