import { Http, Headers, Response } from "@angular/http";
import { Injectable, EventEmitter } from "@angular/core";
import { PlatformLocation } from "@angular/common";
import { environment } from "../../environments/environment";
import { AuthServices } from "./auth.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class RecruiterService {
  name: string;
  token: string;
  email: string;
  Bearer: any = "Bearer ";
  jobId: string;
  public redirectUrl: string;
  companyName: string;
  constructor(private http: Http, private authService: AuthServices, private router: Router) {
  }
  // recruiter profile update
  updateCompanyProfile(req_Data) {
    this.email = localStorage.getItem('emailId')
    var url = environment.endPointApi + "company/updatecompanydetails/" + this.email.replace(/['"]+/g, '')
    var body = JSON.stringify(req_Data);
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, body, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    });
  }
  // recruiter job posting Job Details
  JobDetails(formdata: any) {
    // console.log(formdata)
    // console.log(formdata.jobTitle)
    // console.log(formdata.jobLocation)
    let data = {
      "companyName": localStorage.getItem('companyName').replace(/['"]+/g, ''),
      "jobPostedBy": JSON.parse(localStorage.getItem('emailId')),
      "jobId" : localStorage.getItem('jobId').replace(/['"]+/g, ''),
      "jobTitle": formdata.JobTitle,
      "jobDescription": formdata.JobDescribtion,
      "videoJd": formdata.videoJd,
      "vacancies": formdata.NoOfVacancy,
      "jobLocation": formdata.JobLocation,
      "employementType": formdata.employementType,
      "travelRequired": formdata.travelRequired,
      "minCTC": formdata.minSalary,
      "maxCTC": formdata.maxSalary, 
      "salaryHide": formdata.salaryHide,
      "otherMonetoryBenefits": formdata.otherDetails
    }
    //var url = environment.endPointApi + "company/postjobdetails"
    var url = environment.endPointApi + "recruiter/postjobdetails"
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, data, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }
  // recruiter job posting Job Details
  candiateDetails(formdata:any) {
    let data ={
      "companyName":localStorage.getItem('companyName').replace(/['"]+/g,''),
      "jobId":localStorage.getItem('jobId').replace(/['"]+/g,''),
      "minexperience":formdata.minexperience,
      "maxexperience":formdata.maxexperience,
      "industries":[formdata.industries],
      "functionalArea":[formdata.functionalArea],
      "noticePeriod":formdata.noticePeriod,
      "skills": formdata.skills,
      "languagesKnown":formdata.languagesKnown
    }
    //var url = environment.endPointApi + "company/postcandidatedetails"
    var url = environment.endPointApi + "recruiter/postcandidatedetails"
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization',this.Bearer+this.token.replace(/['"]+/g,''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, data, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }
  // recruiter job posting post education details
  educationdetails(formdata: any) {
    let data = {
      "companyName": localStorage.getItem('companyName').replace(/['"]+/g, ''),
      "jobId": localStorage.getItem('jobId').replace(/['"]+/g, ''),
      "educationalDetails": formdata.educationalDetails,
      "certificatesDesired": formdata.certificatesDesired
    }
    //var url = environment.endPointApi + "company/posteducationdetails"
    var url = environment.endPointApi + "recruiter/posteducationdetails"
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, data, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    });
  }
  // recruiter job posting post manage responses
  manageresponses(formdata: any) {
   // var approvalRequest = localStorage.getItem('approveMode').replace(/['"]+/g, '');
    let data = {
      "companyName": localStorage.getItem('companyName').replace(/['"]+/g, ''),
      "jobId": localStorage.getItem('jobId').replace(/['"]+/g, ''),
      "forwardApplication": [formdata.forwardApplication],
      "recepientEmailAddress": [formdata.recepientEmailAddress],
      "approvedBy": localStorage.getItem('emailId').replace(/['"]+/g, '')
    }

    let url = environment.endPointApi + "recruiter/postmanageresponses";

    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, data, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    });
  }


  rejectJobPosting(comments,recepientEmailAddress,forwardApplication)
  {
    const approvalRequest = localStorage.getItem('approveMode').replace(/['"]+/g, '');
    const data = {
      "companyName": localStorage.getItem('companyName').replace(/['"]+/g, ''),
      "jobId": localStorage.getItem('jobId').replace(/['"]+/g, ''),
      "forwardApplication": forwardApplication,
      "recepientEmailAddress": recepientEmailAddress,
      "approverdBy": localStorage.getItem('emailId').replace(/['"]+/g, ''),
      "comments": comments
    }
    var url = environment.endPointApi + "recruiter/rejectjobpost";
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, data, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    });
  }

  approveJobPosting(comments,recepientEmailAddress,forwardApplication)
  {
    var approvalRequest = localStorage.getItem('approveMode').replace(/['"]+/g, '');
    let data = {
      "companyName": localStorage.getItem('companyName').replace(/['"]+/g, ''),
      "jobId": localStorage.getItem('jobId').replace(/['"]+/g, ''),
      "forwardApplication": forwardApplication,
      "recepientEmailAddress": recepientEmailAddress,
      "approverdBy": localStorage.getItem('emailId').replace(/['"]+/g, ''),
      "comments": comments
    }
    var url = environment.endPointApi + "recruiter/approvejobpost";
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, data, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    });
  }
   // get jobs company/getjobdetails
   public getjobdetails() {
    this.jobId = localStorage.getItem('jobId')
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.get(environment.endPointApi + 'recruiter/getjobdetails/' + this.jobId.replace(/['"]+/g, ''),
      { headers: headers }).map((response: Response) => {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      }).catch((error) => {

        if (error.status === 500) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 400) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 401) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        }
      });
  }
  // get jobs company/getcandidatedetails
  public getcandidatedetails() {
    this.jobId = localStorage.getItem('jobId')
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    // console.log(this.token.replace(/['"]+/g, ''))

    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');


    return this.http.get(environment.endPointApi + 'recruiter/getcandidatedetails/' + this.jobId.replace(/['"]+/g, ''),
      { headers: headers }).map((response: Response) => {
        // console.log(response.status);
        const user = response.json();
        user.responseStatus = response.status;
        // console.log(user);
        return user;
      }).catch((error) => {
        // console.log('error', error);
        if (error.status === 500) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        } else if (error.status === 400) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        } else if (error.status === 401) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        }
      });
  }
  // get jobs company/getcandidatedetails
  public geteducationaldetails() {
    this.jobId = localStorage.getItem('jobId')
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    // console.log(this.token.replace(/['"]+/g, ''))

    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');


    return this.http.get(environment.endPointApi + 'recruiter/geteducationaldetails/' + this.jobId.replace(/['"]+/g, ''),
      { headers: headers }).map((response: Response) => {
        // console.log(response.status);
        const user = response.json();
        user.responseStatus = response.status;
        // console.log(user);
        return user;
      }).catch((error) => {
        // console.log('error', error);
        if (error.status === 500) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        } else if (error.status === 400) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        } else if (error.status === 401) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        }
      });
  }
  // get jobs company/getmanageresponses
  public getmanageresponses() {
    this.jobId = localStorage.getItem('jobId')
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    // console.log(this.token.replace(/['"]+/g, ''))

    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');


    return this.http.get(environment.endPointApi + 'recruiter/getmanageresponses/' + this.jobId.replace(/['"]+/g, ''),
      { headers: headers }).map((response: Response) => {
        // console.log(response.status);
        const user = response.json();
        user.responseStatus = response.status;
        // console.log(user);
        return user;
      }).catch((error) => {
        // console.log('error', error);
        if (error.status === 500) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        } else if (error.status === 400) {
          // console.log('status', error /.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        } else if (error.status === 401) {
          // console.log('status', error.status);
          const err = error.json();
          err.responseStatus = error.status;
          // console.log('status', err.responseStatus);
          return Observable.throw(err);
        }
      });
  }

  // get all active posted jobs for the recruiter
  public activeJobs() {
    const companyName = localStorage.getItem('companyName');
    const emailId = localStorage.getItem('emailId');
    const isRecruiter = JSON.parse(localStorage.getItem('isRecruiter'));
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');

    var rourePath = 'recruiter/getallactivepostedjobsbyrecruiter/'+ emailId.replace(/['"]+/g, '');
    if(!isRecruiter){
      rourePath = 'recruiter/getallactivepostedjobsbycompany/'+ companyName.replace(/['"]+/g, '');
    }

    return this.http.get(environment.endPointApi + rourePath, 
     { headers: headers }).map((response: Response) => {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      }).catch((error) => {
        if (error.status === 500) { 
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 400) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 401) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        }
      });
  }

  
  // get all active posted jobs for the recruiter
  public inActiveJobs() {
    const companyName = localStorage.getItem('companyName');
    const emailId = localStorage.getItem('emailId');
    const isRecruiter = JSON.parse(localStorage.getItem('isRecruiter'));
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');

    var rourePath = 'recruiter/getallinactivepostedjobsbyrecruiter/'+ emailId.replace(/['"]+/g, '');
    if(!isRecruiter){
      rourePath = 'recruiter/getallinactivepostedjobsbycompany/'+ companyName.replace(/['"]+/g, '');
    }

    return this.http.get(environment.endPointApi + rourePath, 
     { headers: headers }).map((response: Response) => {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      }).catch((error) => {
        if (error.status === 500) { 
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 400) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 401) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        }
      });
  }

  // get jobsbyjobid
   // active jobs
   public jobsByJobid(num1) {
    var headers = new Headers();
    let data = {
      "list":num1
  }
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.post(environment.endPointApi + 'company/getapplicantsdetails',data,
      { headers: headers }).map((response: Response) => {
        const user = response.json();
        user.responseStatus = response.status;
        return user;
      }).catch((error) => {
        if (error.status === 500) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 400) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 401) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        }
      });
  }
     // shortlist jobs
     public shortlist(email,userApplyJobtitle,userApplyJobId) {
      
      var headers = new Headers();
      const companyName = localStorage.getItem('companyName')
      let data = {
          
          "jobId":userApplyJobId,
          "companyName":companyName.replace(/['"]+/g, ''),
          "jobTitle":userApplyJobtitle,
          "emailId":email
      
    }
      this.token = localStorage.getItem('authtoken')
      headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
      headers.append('Content-Type', 'application/json');
      return this.http.post(environment.endPointApi + 'company/shortlist',data,
        { headers: headers }).map((response: Response) => {
          const user = response.json();
          user.responseStatus = response.status;
          return user;
        }).catch((error) => {
          if (error.status === 500) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
          } else if (error.status === 400) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
          } else if (error.status === 401) {
            const err = error.json();
            err.responseStatus = error.status;
            return Observable.throw(err);
          }
        });
    }
  getBasicDetails() {
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.get(environment.endPointApi + 'company/getbasicdetails', { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }
  getVideoHtml() {
    return this.http.get('assets/capture-video.html').map((response: Response) => {
      const user = response;
      return user;
    }).catch((error) => {
        const err = error;
        err.responseStatus = error.status;
        return Observable.throw(err);
    });
  }
  getRecruitersList(companyName: string) {
    var headers = new Headers();
    this.token = JSON.parse(localStorage.getItem('authtoken'));
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.get(environment.endPointApi + 'recruiter/getrecruitersbycompanyname/'+companyName, { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

//GetPartnerRecruiterList
getPartnerRecruitersList(companyName: string){
  var headers = new Headers();
  this.token = JSON.parse(localStorage.getItem('authtoken'));
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'partner/getrecruitersbycompanyname/'+companyName, { headers: headers }).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
  });
} 

  getRecruiterBasicDetails(emailId: string) {
    var headers = new Headers();
    this.token = JSON.parse(localStorage.getItem('authtoken'));
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    let data = {
      "emailId":emailId
    }
    return this.http.get(environment.endPointApi + 'recruiter/profile/'+emailId, { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

// Get Partners Selected Recruiter
getPartnerRecruiterBasicDetails(emailId: string) {
  var headers = new Headers();
  this.token = JSON.parse(localStorage.getItem('authtoken'));
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  let data = {
    "emailId":emailId
  }
  return this.http.get(environment.endPointApi + 'partner/profile/'+emailId, { headers: headers }).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
  });
}

  getAdvanceDetails() {
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.get(environment.endPointApi + 'company/getadvancedetails', { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

  updateBasicDetails(body) {
    var headers = new Headers();
    var emailid = JSON.parse(localStorage.getItem('emailId'));
    this.token = JSON.parse(localStorage.getItem('authtoken'));
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'company/updatebasicdetails/' + emailid.replace(/['"]+/g, ''), body, { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

  updateAdvanceDetails(body) {
    var headers = new Headers();
    var emailid = localStorage.getItem('emailId')
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    return this.http.put(environment.endPointApi + 'company/updateadvancedetails/' + emailid.replace(/['"]+/g, ''), body, { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

  uploadFiletoS3(body) {
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken')
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    return this.http.post(environment.endPointApi + 'company/savetos3/Logos', body, { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }

  coverImage(fileToUpload: File) {
    this.token = localStorage.getItem('authtoken')
    var headers = new Headers();
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http
      .post(environment.endPointApi + 'company/updatecoverphoto', formData, { headers: headers }).map((response: Response) => {
        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
        } else {
          const user = response.json();
          user.responseStatus = response.status;
          return user;
        }
      })
      .catch((error) => {
        if (error.status === 500) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 400) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        } else if (error.status === 401) {
          const err = error.json();
          err.responseStatus = error.status;
          return Observable.throw(err);
        }
      });
  }

  // getapplicantscount
  getapplicantscount() {
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken');
    this.companyName = localStorage.getItem('companyName');
    this.email = localStorage.getItem('emailId');
    var isRecruiter = JSON.parse(localStorage.getItem('isRecruiter'));
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    
    var routePath = 'recruiter/getapplicantscountforactivejobsbyrecruiter/'+ this.email.replace(/['"]+/g, '');
    if(!isRecruiter){
      routePath = 'recruiter/getapplicantscountforactivejobsbycompany/'+ this.companyName.replace(/['"]+/g, '');
    }

    return this.http.get(environment.endPointApi + routePath, { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }
  // getactivejobs
  getactivejobs() {
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken');
    this.companyName = localStorage.getItem('companyName');
    this.email = localStorage.getItem('emailId');
    var isRecruiter = JSON.parse(localStorage.getItem('isRecruiter'));
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    headers.append('Content-Type', 'application/json');
    var routePath = 'recruiter/getactivepostedjobscountbyrecruiter/'+ this.email.replace(/['"]+/g, '');
    if(!isRecruiter){
      routePath = 'recruiter/getactivepostedjobscountbycompany/'+ this.companyName.replace(/['"]+/g, '');
    }

    return this.http.get(environment.endPointApi +routePath, { headers: headers }).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
    });
  }




// getInActivejobs
getinActivejobs() {
  var headers = new Headers();
  this.token = localStorage.getItem('authtoken');
  this.companyName = localStorage.getItem('companyName');
  this.email = localStorage.getItem('emailId');
  var isRecruiter = JSON.parse(localStorage.getItem('isRecruiter'));
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  var routePath = 'recruiter/getinactivepostedjobscountbyrecruiter/'+ this.email.replace(/['"]+/g, '');
  if(!isRecruiter){
    routePath = 'recruiter/getinactivepostedjobscountbycompany/'+ this.companyName.replace(/['"]+/g, '');
  }

  return this.http.get(environment.endPointApi +routePath, { headers: headers }).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
  });
}


  SaveVideo(formdata) {
    var jobId = JSON.parse(localStorage.getItem('jobId'));
    var url = environment.endPointApi + "recruiter/saveVideo/VideoJDs/"+jobId;
    var headers = new Headers();
    this.token = localStorage.getItem('authtoken');
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    return this.http.post(url, formdata, { headers: headers }).map((response: Response) => {
      let user = response.json();
      user.responseStatus = response.status;
      return user;
    });
  }
  public userProfile(email) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(environment.endPointApi + 'jobseekers/profile/'+email,
    { headers: headers } ).map((response: Response) => {
      const user = response.json();
      user.responseStatus = response.status;
      return user;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
} 
message(emailId,body) {
  var headers = new Headers();
  this.token = localStorage.getItem('authtoken')
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  let data ={
    "toEmailId": emailId,
    "fromEmailId": localStorage.getItem('emailId').replace(/['"]+/g, ''),
    "mailBody":  body,
    "companyName": localStorage.getItem('companyName').replace(/['"]+/g, '')
  }
  return this.http.post(environment.endPointApi + 'search/sendmessage' , data, { headers: headers }).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
  });
}

generateJobId() {
  var headers = new Headers();
  var companyName = JSON.parse(localStorage.getItem('companyName'));
  var jobPostedBy = JSON.parse(localStorage.getItem('emailId'));
  let body ={
    "companyName" : companyName,
    "jobPostedBy" : jobPostedBy 
  }
  this.token = JSON.parse(localStorage.getItem('authtoken'));
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');


  //return this.http.post(environment.endPointApi + 'company/generatejobid', body , { headers: headers }).map((response: Response) => {
    return this.http.post(environment.endPointApi + 'recruiter/generatejobid', body , { headers: headers }).map((response: Response) => {
    // console.log(response.status);
    const user = response.json();
    user.responseStatus = response.status;
    // console.log(user);
    return user;
  }).catch((error) => {
    console.log('error', error);
    if (error.status === 500) {
      // console.log('status', error.status);
      const err = error.json();
      err.responseStatus = error.status;
      // console.log('status', err.responseStatus);
      return Observable.throw(err);
    } else if (error.status === 400) {
      // console.log('status', error.status);
      const err = error.json();
      err.responseStatus = error.status;
      // console.log('status', err.responseStatus);
      return Observable.throw(err);
    } else if (error.status === 401) {
      // console.log('status', error.status);
      const err = error.json();
      err.responseStatus = error.status;
      // console.log('status', err.responseStatus);
      return Observable.throw(err);
    }
  });
}

//pending jobs by recruiter
public getJobPendingApproval() {
  var headers = new Headers();
  this.companyName = localStorage.getItem('companyName');
  this.token = localStorage.getItem('authtoken');
  this.email=localStorage.getItem('emailId');
  var isRecruiter = JSON.parse(localStorage.getItem('isRecruiter'));
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');

  var routePath = 'recruiter/getjobspendingapprovalbyrecruiter/'+ this.email.replace(/['"]+/g, '');
  if(!isRecruiter){
    routePath = 'recruiter/getjobspendingapprovalbycompany/'+ this.companyName.replace(/['"]+/g, '');
  }

  return this.http.get(environment.endPointApi + routePath,
  { headers: headers } ).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}

public getDraftjob() {
  var headers = new Headers();
  this.companyName = localStorage.getItem('companyName');
  this.token = localStorage.getItem('authtoken');
  this.email=localStorage.getItem('emailId');
  var isRecruiter = JSON.parse(localStorage.getItem('isRecruiter'));
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');

  var routePath = 'recruiter/getdraftjobsbyrecruiter/'+ this.email.replace(/['"]+/g, '');
  if(!isRecruiter){
    routePath = 'recruiter/getdraftjobsbycompany/'+ this.companyName.replace(/['"]+/g, '');
  }

  return this.http.get(environment.endPointApi + routePath,
  { headers: headers } ).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
});
}
// inactive jobs
inactiveJob(jobId,active) {
  var headers = new Headers();
  // console.log(active);
  this.token = localStorage.getItem('authtoken')
  var body ={
    "jobId":jobId,
    "status":active,
    "companyName":localStorage.getItem('companyName').replace(/['"]+/g, ''),
    "emailId":localStorage.getItem('emailId').replace(/['"]+/g, '')
  }
  // console.log(body)
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi + 'company/inactive', body, { headers: headers }).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    if (error.status === 500) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 400) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    } else if (error.status === 401) {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
    }
  });
}
uploadVideo(formData: any) {
  var jobId = JSON.parse(localStorage.getItem('jobId'));
  var token = JSON.parse(localStorage.getItem('authtoken'));
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + token);
  return this.http.post(environment.endPointApi + 'recruiter/uploadVideo/'+jobId, formData,{headers:headers}).map((files) => {
    return files.json();
  }).catch((error) => {
    return this.handleError(error);
  });
  // .subscribe(
  //   res => {
  //     console.log('res ', res); 
  //     return res;
  //   }
  // );


  // return this.http.post(environment.endPointApi+'recruiter/uploadVideo/'+jobId, formData,{ headers: headers }).map((res) =>{
  //   console.log(res,' its upload Video res');
  //   return res.json();
  // }).catch((error) => {
  //   return this.handleError(error);
  // });
}
getEventsList(companyName: string) {
  var headers = new Headers();
  var token = JSON.parse(localStorage.getItem('authtoken'));
  headers.append('Authorization', this.Bearer + token);
  headers.append('Content-Type', 'application/json');
  const isRecruiter = JSON.parse(localStorage.getItem("isRecruiter"));
  var url = 'events/geteventsbycompany/'+companyName;
  if(isRecruiter){
    var emailId = JSON.parse(localStorage.getItem('emailId'));
    url = 'events/geteventsbyrecruiter/'+companyName + '/' + emailId;
  }
  return this.http.get(environment.endPointApi + url, { headers: headers }).map((response: Response) => {
    var user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
    return this.handleError(error);
  });
}
getEventBasicDetails(eventId: string) {
  var headers = new Headers();
  this.token = JSON.parse(localStorage.getItem('authtoken'));
  headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
  headers.append('Content-Type', 'application/json');
  return this.http.get(environment.endPointApi + 'events/geteventdetails/'+eventId, { headers: headers }).map((response: Response) => {
    const event = response.json();
    event.responseStatus = response.status;
    return event;
  }).catch((error) => {
    return this.handleError(error);
  });
}
public EventUpdate(formdata: any){
  const eventId = formdata.eventId;
  var token = JSON.parse(localStorage.getItem('authtoken'));
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + token);
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi+'events/posteventdetails/'+eventId,formdata,{ headers: headers }).map((res) =>{
    // console.log(res,' its events update res');
    return res.json();
  }).catch((error) => {
    return this.handleError(error);
  });
}
public EventRegister(formdata){
  const emailId = JSON.parse(localStorage.getItem('emailId'));
  var token = JSON.parse(localStorage.getItem('authtoken'));
  formdata.recruiterEmailId = emailId;
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + token);
  headers.append('Content-Type', 'application/json');
  return this.http.post(environment.endPointApi+'events/generateeventid',formdata,{ headers: headers }).map((res) =>{
    // console.log(res,' its Events register res');
    return res.json();
  }).catch((error) => {
    return this.handleError(error);
  });
}
public PostEventDetails(formdata, level){

  var token = JSON.parse(localStorage.getItem('authtoken'));
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + token);
  headers.append('Content-Type', 'application/json');
  var url = '';
  if(level){
    url = 'events/posteventdetails';
  } else {
    url = 'events/posteventconstraints';
  }
  return this.http.post(environment.endPointApi+url,formdata,{ headers: headers }).map((res) =>{
    // console.log(res,' its Events register res');
    return res.json();
  }).catch((error) => {
    return this.handleError(error);
  });
}
public ApproveEvent(formdata){
  var token = JSON.parse(localStorage.getItem('authtoken'));
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + token);
  headers.append('Content-Type', 'application/json');
  return this.http.post(environment.endPointApi + 'events/approveevent', formdata,{ headers: headers }).map((res) =>{
    // console.log(res,' Event Approved');
    return res.json();
  }).catch((error) => {
    return this.handleError(error);
  });
}
public DeleteEvent(eventId){
  var headers = new Headers();
  var token = JSON.parse(localStorage.getItem('authtoken'));
  var body ={
    "eventId": eventId
  }
  headers.append('Authorization', this.Bearer + token);
  headers.append('Content-Type', 'application/json');
  return this.http.put(environment.endPointApi + 'events/inactive', body, { headers: headers }).map((response: Response) => {
    const user = response.json();
    user.responseStatus = response.status;
    return user;
  }).catch((error) => {
      const err = error.json();
      err.responseStatus = error.status;
      return Observable.throw(err);
  });
}
public UpdateApplicantStatus(formdata){
  var token = JSON.parse(localStorage.getItem('authtoken'));
  var headers = new Headers();
  headers.append('Authorization', this.Bearer + token);
  headers.append('Content-Type', 'application/json');
  return this.http.post(environment.endPointApi + 'events/updprofilestatus', formdata,{ headers: headers }).map((res) =>{
    // console.log(res,' Event Approved');
    return res.json();
  }).catch((error) => {
    return this.handleError(error);
  });
}
handleError(error: any){
  if (error.status === 500) {
    const err = error.json();
    err.responseStatus = error.status;
    return Observable.throw(err);
  } else if (error.status === 400) {
    const err = error.json();
    err.responseStatus = error.status;
    return Observable.throw(err);
  } else if (error.status === 401) {
    const err = error.json();
    err.responseStatus = error.status;
    return Observable.throw(err);
  }
}
// getjobDeta() {
//   const jobId = localStorage.getItem('jobId').replace(/['"]+/g,'')
//   var headers = new Headers();
//   this.token = localStorage.getItem('authtoken')
//   headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
//   headers.append('Content-Type', 'application/json');
//   return this.http.get(environment.endPointApi + 'company/getjobdetails/' + jobId .replace(/['"]+/g, ''), { headers: headers }).map((response: Response) => {
//     console.log(response.status);
//     const user = response.json();
//     user.responseStatus = response.status;
//     console.log(user);
//     return user;
//   }).catch((error) => {
//     console.log('error', error);
//     if (error.status === 500) {
//       console.log('status', error.status);
//       const err = error.json();
//       err.responseStatus = error.status;
//       console.log('status', err.responseStatus);
//       return Observable.throw(err);
//     } else if (error.status === 400) {
//       console.log('status', error.status);
//       const err = error.json();
//       err.responseStatus = error.status;
//       console.log('status', err.responseStatus);
//       return Observable.throw(err);
//     } else if (error.status === 401) {
//       console.log('status', error.status);
//       const err = error.json();
//       err.responseStatus = error.status;
//       console.log('status', err.responseStatus);
//       return Observable.throw(err);
//     }
//   });
// }
}
