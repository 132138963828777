import { Http, Headers, Response } from "@angular/http";
import { Injectable,  } from "@angular/core";
import { environment } from "../../environments/environment";
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class PaymentServices {

  Bearer: any = "Bearer ";
  token: string;
  customerEmail: string;
  customerName: string;
  customerPhone : string;

  constructor(private route: ActivatedRoute, private http: Http, private router: Router) {
  }
  

  public getPartnerPaymentDetails(){
    this.customerEmail = localStorage.getItem('emailId').replace(/['"]+/g, '') ;
    this.token = localStorage.getItem('authtoken');
    this.customerPhone = localStorage.getItem('contactNo');
    this.customerName = localStorage.getItem('name');
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization':this.Bearer + this.token.replace(/['"]+/g, '')
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new Headers(headerDict), 
    };
    var body = JSON.stringify({"customerEmail": this.customerEmail, "customerPhone" : this.customerPhone, "customerName": this.customerName });
    return this.http.post(environment.endPointApi + 'payments/generatepartnerpaymentdetails',body,requestOptions).map((response: Response) => {
        const user = response.json();
        return user.data;
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  


  
  public getPartnerSubscriptionDetails() {
    this.token = localStorage.getItem('authtoken')
    this.customerEmail = localStorage.getItem('emailId').replace(/['"]+/g, '') ;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    return this.http.get(environment.endPointApi + 'payments/getsubscriptioninfo/'+this.customerEmail, { headers: headers }).map((response: Response) => {
      // if (this.redirectUrl) {
      //   this.router.navigate([this.redirectUrl]);
      //   this.redirectUrl = null;
      // } else {
        const user = response.json();
        user.responseStatus = response.status;
        // console.log(user.data);
        return user.data[0];
      // } 
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }
  
  

  public getPartnerLastTxnDetails() {
    this.token = localStorage.getItem('authtoken')
    this.customerEmail = localStorage.getItem('emailId').replace(/['"]+/g, '') ;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.Bearer + this.token.replace(/['"]+/g, ''));
    return this.http.get(environment.endPointApi + 'payments/getlasttransactioninfo/'+this.customerEmail, { headers: headers }).map((response: Response) => {

        const user = response.json();
        user.responseStatus = response.status;
        return user.data[0];
    }).catch((error) => {
      if (error.status === 500) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 400) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      } else if (error.status === 401) {
        const err = error.json();
        err.responseStatus = error.status;
        return Observable.throw(err);
      }
  });
  }

}

