import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthServices } from '../../services/auth.service';
import { JobseekerService } from '../../services/jobseeker.operations';
import swal from 'sweetalert2';
import { environment } from "../../../environments/environment";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(document, fb, authService, modalService, modalService2, toastr, router, route, JobseekerService) {
        this.fb = fb;
        this.authService = authService;
        this.modalService = modalService;
        this.modalService2 = modalService2;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this.JobseekerService = JobseekerService;
        this.loading = false;
        this.submitted = false;
        this.fileToUpload = null;
        this.subForm = fb.group({
            'emailId': [null, Validators.required],
        });
    }
    Object.defineProperty(FooterComponent.prototype, "f", {
        get: function () { return this.subForm.controls; },
        enumerable: true,
        configurable: true
    });
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadAPI = new Promise(function (resolve) {
            _this.loadScript();
            resolve(true);
        });
        this.partnersUrlLink = environment.partnersWebUrl;
        this.jobsUrlLink = environment.jobsWebUrl;
    };
    FooterComponent.prototype.loadScript = function () {
        var isFound = false;
        var scripts = document.getElementsByTagName("script");
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
                isFound = true;
            }
        }
        if (!isFound) {
            var dynamicScripts = [
                "../assets/js/jquery-3.2.1.min.js",
                "../assets/js/bootstrap.min.js",
                "../assets/js/selectBnfit.js",
                "../assets/js/plugin.js",
                "../assets/js/owl.carousel.js",
                "../assets/js/jquery.countTo.js",
                "../assets/js/jquery.magnific-popup.js",
                "../assets/js/dropify.min.js",
                "../assets/js/jquery.inview.min.js",
                "../assets/js/jquery.nice-select.min.js",
                "../assets/js/imagesloaded.pkgd.min.js",
                "../assets/js/isotope.pkgd.min.js",
                //"../assets/js/custom.js",
                "../assets/js/functions.js",
            ];
            for (var i = 0; i < dynamicScripts.length; i++) {
                var node = document.createElement('script');
                node.src = dynamicScripts[i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                document.getElementsByTagName('head')[0].appendChild(node);
            }
        }
    };
    FooterComponent.prototype.open2 = function (content) {
        var _this = this;
        this.modalService.open(content).result.then(function (result) {
            _this.closeResult = "Closed with: " + result;
        }, function (reason) {
            _this.closeResult = "Dismissed " + _this.getDismissReason(reason);
        });
    };
    FooterComponent.prototype.open = function (content) {
        this.modalService2.open(content, { windowClass: 'dark-modal' });
    };
    FooterComponent.prototype.getDismissReason = function (reason) {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    FooterComponent.prototype.fileChangeEvent = function (event) {
        var _this = this;
        var file = event.target.files[0];
        this.fileToUpload = file;
        this.JobseekerService.resumeUpload(this.fileToUpload).subscribe(function (data) {
            _this.userData = (data);
            // console.log("----------------",data)
            if ((_this.userData.responseStatus === 200)) {
                localStorage.setItem("email", data.data.email[0]);
                // console.log(data)
                // this.router.navigate(['/user-signup']);
                document.location.href = "https://jobs.shenzyn.com/#/user/sign-up";
            }
        }, function (error) {
            console.log(error);
        });
    };
    FooterComponent.prototype.subscribe = function (formdData) {
        var _this = this;
        this.submitted = true;
        if (this.subForm.invalid) {
            return false;
        }
        this.loading = true;
        var emailId;
        emailId = this.emailId;
        if ((emailId !== null)) {
            this.authService.subscribe(emailId).subscribe(function (data) {
                _this.userData = (data);
                if ((_this.userData.responseStatus === 200)) {
                    swal({
                        title: 'Success',
                        text: data.message,
                        type: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#535BE2',
                        cancelButtonColor: '#FF7E39',
                        confirmButtonText: 'OK',
                    });
                }
            }, function (error) {
                if (error.responseStatus === 401) {
                    swal({
                        title: 'Warning',
                        text: error.message,
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#535BE2',
                        cancelButtonColor: '#FF7E39',
                        confirmButtonText: 'OK',
                    });
                }
                else if (error.responseStatus === 500) {
                    swal({
                        title: 'Warning',
                        text: error.message,
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#535BE2',
                        cancelButtonColor: '#FF7E39',
                        confirmButtonText: 'OK',
                    });
                }
                else if (error.responseStatus === 400) {
                    swal({
                        title: 'Warning',
                        text: error.message,
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#535BE2',
                        cancelButtonColor: '#FF7E39',
                        confirmButtonText: 'OK',
                    });
                }
            }, function () { return console.log(); });
        }
    };
    return FooterComponent;
}());
export { FooterComponent };
