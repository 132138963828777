import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';


@Component({
  selector: 'app-partners-email-verify',
  templateUrl: './partners-email-verify.component.html',
  styleUrls: ['./partners-email-verify.component.css']
})
export class PartnersEmailVerifyComponent implements OnInit {
  secret:any;
  token: any;
  url:String;

  constructor(private route: ActivatedRoute,private _router:Router,private authService: AuthServices) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('token'); 
    this.secret=id;
    this.url ="partner/confirm-partner/"
    this.authService.PartnerEmailVerify(this.secret,this.url);
  }

}
