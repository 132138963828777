/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./partners.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./partners.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../services/auth.service";
import * as i5 from "@angular/router";
var styles_PartnersComponent = [i0.styles];
var RenderType_PartnersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PartnersComponent, data: {} });
export { RenderType_PartnersComponent as RenderType_PartnersComponent };
export function View_PartnersComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_PartnersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-partners", [], null, null, null, View_PartnersComponent_0, RenderType_PartnersComponent)), i1.ɵdid(1, 114688, null, 0, i2.PartnersComponent, [i3.FormBuilder, i4.AuthServices, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartnersComponentNgFactory = i1.ɵccf("app-partners", i2.PartnersComponent, View_PartnersComponent_Host_0, {}, {}, []);
export { PartnersComponentNgFactory as PartnersComponentNgFactory };
