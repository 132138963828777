import { Component, OnInit } from '@angular/core';
import { RecruiterService } from '../../services/recruiter.operations';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators, EmailValidator } from '@angular/forms';
import { AuthServices } from '../../services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partner-add-recruiter',
  templateUrl: './partner-add-recruiter.component.html',
  styleUrls: ['./partner-add-recruiter.component.css']
})
export class PartnerAddRecruiterComponent implements OnInit {
  companylogo: any;
  fileURL: any;
  userData: any;
  loading: boolean;
  erroMsg: string;
  logo: any;
  companyName: any;
  loadAPI: Promise<any>;
  recruitersList: Array<any>;
  recruiterDetails: any = { recruiterName: "", companyName: "", emailId: "" };
  viewMode: boolean = true;
  editMode: boolean = false;
  addMode: boolean = false;
  editDisabled: boolean = true;
  isRecruiter: boolean;
  title: string = 'View Recruiter';
  partnerUrl:String;
  typeOfUser:Boolean;
  recruiter = {
  }

  recruiterForm: FormGroup;

  createForm() {
    this.recruiterForm = this.fb.group({
      recruitername: ['', Validators.required],
      emailId: ['', Validators.required],
      password: ['', Validators.required],
      address: [''],
      contactNo: [''],
      linkedinId: [''],
      location: [''],
    });
  }

  constructor(
    private fb: FormBuilder,
    public apiService: RecruiterService,
    private router: Router,
    private RecruiterService: RecruiterService,
    private authservice: AuthServices
  ) {
    this.createForm();
   }

  ngOnInit() {
    this.RecruiterService.getBasicDetails().subscribe((response: any) => {
      this.logo = response.data.logo;
      this.companyName = response.data.companyName
    }, (error: any) => {
      console.log(error);
    })
    const companyName = JSON.parse(localStorage.getItem("companyName"));
    this.refreshListGrid(companyName);
    this.companylogo;
    
    // if (localStorage.getItem('isRecruiter')) {
    //   console.log(' its inside local', JSON.parse(localStorage.getItem('isRecruiter')));

    //   this.isRecruiter = JSON.parse(localStorage.getItem('isRecruiter')) == true ? false : true;
    // }
  }


  refreshListGrid(companyName: string) {
    this.RecruiterService.getPartnerRecruitersList(companyName).subscribe((response: any) => {
      this.recruitersList = response.data;
      if (this.recruitersList.length > 0) {
        this.onShowRecruiterDetails(null, this.recruitersList[0].emailId);
      }  
      else {
        this.editMode = false;
        this.addMode = true;
        this.viewMode = false;
        this.title = 'Add Recruiter';
      }
    }, (error: any) => {
      console.log(error);
    })
  }
  onShowRecruiterDetails(event, emailId: string) {
    if (this.editMode) return;
    this.RecruiterService.getPartnerRecruiterBasicDetails(emailId).subscribe((response: any) => {
      this.recruiterDetails = response.data;
      this.editMode = false;
      this.addMode = false;
      this.viewMode = true;
      this.editDisabled = false;
      this.title = 'View Recruiter';
    }, (error: any) => {
      console.log(error);
    })
  }
  // Add Recruiter
  onRecruiterAdd() {
    this.editMode = false;
    this.addMode = true;
    this.viewMode = false;
    this.editDisabled = true;
    this.title = "Add Recruiter";
  }
  onCancelAdd() {
    const companyName = JSON.parse(localStorage.getItem("companyName"));
    this.refreshListGrid(companyName);
  }
  // Edit Recruiter
  onRecruiterEdit() {
    this.editMode = true;
    this.addMode = false;
    this.viewMode = false;
    this.editDisabled = true;
    this.title = "Edit Recruiter";
  }
  onRecruiterUpdate() {
    this.editMode = false;
    this.addMode = false;
    this.viewMode = true;
    this.editDisabled = false;
    this.title = "View Recruiter";
    this.authservice.PartnerRecruiterUpdate(this.recruiterDetails).subscribe((res: any) => {
      console.log(res, ' its update res');

      this.alertMsg(res.message, 'success');
    }, (error: any) => {
      console.log(error);
      this.alertMsg(error.message, 'warning');
    })
  }
  onRecruiterUpdateCancel() {
    this.editMode = false;
    this.addMode = false;
    this.viewMode = true;
    this.editDisabled = false;
    this.title = "View Recruiter";
  }
  /*
 // Delete Recruiter
 onRecruiterDelete() {
   const emailId = this.recruiterDetails.emailId;
   this.authservice.DeactivateRecruiter(emailId).subscribe((res: any)=>{
     console.log(res,' its update res');
     this.refreshListGrid(this.recruiterDetails.companyName);
     this.alertMsg(res.message, 'success');
   }, (error: any) => {
     console.log(error);
     this.alertMsg(error.message, 'warning');
   })
 }
 */

  // Delete Recruiter
  onRecruiterDelete() {
    Swal({
      title: 'Are you sure?',
      text: "It will permanently delete the employee !",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.value){
      const emailId = this.recruiterDetails.emailId;
      this.authservice.DeactivateRecruiter(emailId).subscribe((res: any) => {
        console.log(res, ' its update res');
        this.refreshListGrid(this.recruiterDetails.companyName);
        this.alertMsg(res.message, 'success');
      }, (error: any) => {
        console.log(error);
        this.alertMsg(error.message, 'warning');
      })
      Swal(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )}
    })
  }

  // Submit Recruiter Form
  onRecruiterFormSubmit(formdata: any) {
    console.log(formdata);
    formdata.companyName = JSON.parse(localStorage.getItem("companyName"));
    // var Url = 'recruiter/register';
    this.typeOfUser = JSON.parse(localStorage.getItem('isPartner'));
    console.log(this.typeOfUser,' its type of User');
    
    switch(this.typeOfUser)
    {
      case true:
        formdata.PartnerAdminEmail = JSON.parse(localStorage.getItem('emailId'));
        formdata.isPartnerRecruiter = true;
        this.partnerUrl = "partner/registerrecruiter";
        break;
      case false:
        formdata.isPartnerRecruiter = false;
        this.partnerUrl = "recruiter/register";       

        break;
      default:
        formdata.isPartnerRecruiter = false;
        break;
    }
    
    this.authservice.RecruiterRegister(formdata,this.partnerUrl).subscribe((res: any)=>{
      console.log(res,' its res');
      this.refreshListGrid(formdata.companyName);
      // Reset Form after submitting
      this.formReset();  
      this.editMode = false;  
      this.title = "View Recruiter";  
      this.alertMsg(res.message, 'success');
    }, (error: any) => {
      console.log(error);
      this.alertMsg(error.message, 'warning');
    })



}
  alertMsg(msg: string, type: string) {
    if (type == 'success') {
      Swal({
        title: 'Success',
        text: msg,
        type: 'success',
        showCancelButton: false,
        confirmButtonColor: '#535BE2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK',
      });
    } 
    else if (type == 'warning') {
      Swal({
        title: 'Warning',
        html: msg,
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1976d2',
        cancelButtonColor: '#FF7E39',
        confirmButtonText: 'OK!',
      });
    }
    
  }
  // Reset Form Starts
  formReset() {
    this.createForm();
  }

  // Logout Button
  onLogout() {
    localStorage.removeItem('companyName')
    localStorage.removeItem('authtoken');
    localStorage.removeItem('emailId');
    localStorage.removeItem('isPartnerAdmin');
    localStorage.removeItem('name');
    localStorage.removeItem('jobId');
    localStorage.removeItem('defaultcompanylogo');
    this.router.navigate(['/partners-login']);
  }





}
