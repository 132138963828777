import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'partners-signup',
  templateUrl: './partners-signup.component.html',
  styleUrls: ['./partners-signup.component.css']
})
export class PartnersSignupComponent implements OnInit {
  RegistrationForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  erroMsg: any;
  userData: any;
  formBuilder: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:any = {};

  constructor(private fb: FormBuilder, private authService: AuthServices, private router: Router) {
    this.RegistrationForm = fb.group({
      'companyName': [null, Validators.required],
      'address': [null, Validators.required],
      'contactNo': [null, Validators.required],
      'emailId': [null, Validators.required],
      // 'password': [null, Validators.required],
      'gstin': [null, Validators.required]
    })
  }
  get f() { return this.RegistrationForm.controls; }

  ngOnInit() {
    // $(".toggle-password").click(function() {
    //   $(this).toggleClass("fa-eye fa-eye-slash");
    //   var input = $($(this).attr("toggle"));
    //   if (input.attr("type") == "password") {
    //     input.attr("type", "text");
    //   } else {
    //     input.attr("type", "password");
    //   }
    // });
    this.dropdownList = [
      { item_id: 1, item_text: 'IT' },
      { item_id: 2, item_text: 'BPO/KPO' },
      { item_id: 3, item_text: 'BFSI' },
      { item_id: 4, item_text: 'Manufacturing' },
      { item_id: 5, item_text: 'Pharma' }
    ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings= {
      limitSelection:3,
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  // custom specs selection
  onItemSelect(e:any){
    // console.log(e,'its event');
    this.selectedItems.push(e);
    console.warn(this.selectedItems);
  }
  onSelectAll(e:any){
    console.warn(e,' its event');    
  }

  // form validation
  register(formdata: any) {
    
    this.submitted = true;
    formdata.selectedItems = this.selectedItems;
    console.log(formdata)
    // if (this.RegistrationForm.invalid) {
    //   console.warn('its inside tere');
    //   return
    // }
    this.loading = true;
    this.authService.partnerRegisterpartner(formdata).subscribe(
      data => {
        this.userData = (data);
        if ((this.userData.responseStatus === 200)) {
          console.log('200-Success');
          this.loading = false;
          this.erroMsg = '';
          this.submitted = false;
          swal({
            title: 'Success',
            text: data.message,
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#535BE2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK',
          }).then((confirm) => {
            if (confirm) {
              this.router.navigate(['/homenew']);
            }
          });
        }
      },
      error => {
        if (error.responseStatus === 401) {
          this.loading = false;
          this.erroMsg = error.message;
        } else if (error.responseStatus === 500) {
          this.loading = false;
          this.erroMsg = error.message;
        } else if (error.responseStatus === 400) {
          this.loading = false;
          this.erroMsg = error.message;
        }
      },
      () => console.log()
    );

  }
}
