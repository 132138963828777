import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from './auth.service';

@Injectable()
export class AppGuard implements CanActivate {
  user: any;
  constructor(public auth: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   // this.user = JSON.parse(localStorage.getItem(AuthService.USER_KEY));

   //For users logged in through Linked-in response would be redirected with reevent parameters.
   //Save the query parameters if they are in the URL.
   const queryParams = route.queryParams;
   
   if (queryParams.email && queryParams.jwt_token && queryParams.name){
    localStorage.setItem('emailId', queryParams.email);
    localStorage.setItem('authtoken', queryParams.jwt_token);
    localStorage.setItem('name', queryParams.name);
   }

   if(queryParams.error)
   {
    //TODO if we get any error from linkedin login display the same in login page
   }

   if (!this.auth.isAuthenticated()) {
    this.router.navigate(['login']);
    return false;
  } else {
      return true;
  }
}
}