/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./common-header.component-clean.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./common-header.component";
import * as i5 from "../../services/recruiter.operations";
var styles_CommonHeaderComponent = [i0.styles];
var RenderType_CommonHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommonHeaderComponent, data: {} });
export { RenderType_CommonHeaderComponent as RenderType_CommonHeaderComponent };
export function View_CommonHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "return_top3"], ["href", "javascript:"], ["id", "return-to-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-angle-double-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 49, "div", [["class", "cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover"], ["id", "header_data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "cp_logo_wrapper index_2_logo "]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["routerLink", "/employe-dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "logo"], ["src", "assets/sheImages/logo8.svg"], ["style", "width:110%;height:auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 44, "div", [["class", "menu_btn_box jb_cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 43, "label", [["class", "dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["style", "font-weight:400;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "img"], ["src", "assets/sheImages/nav_menu.svg"], ["style", "width: 62px;height: 60px; margin-left: 150px;border-radius: 50%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["class", "dd-input"], ["id", "test"], ["type", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 39, "ul", [["class", "dd-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["routerLink", "/job-dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fas fa-outdent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Job Dashboard"])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "a", [["routerLink", "/user-profile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" View/Edit Profile"])), (_l()(), i1.ɵeld(23, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 3, "a", [["routerLink", "/recruiterprofile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "fas fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" View /Edit "])), (_l()(), i1.ɵeld(28, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 3, "a", [["routerLink", "/manage-recruiters"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 0, "i", [["class", "fas fa-user-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Manage Recruiters "])), (_l()(), i1.ɵeld(33, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 3, "a", [["routerLink", "/schedule-events"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 35).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(35, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(36, 0, null, null, 0, "i", [["class", "fas fa-tasks"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Schedule Events "])), (_l()(), i1.ɵeld(38, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 3, "a", [["routerLink", "/partners-addrecruiter"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 40).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(41, 0, null, null, 0, "i", [["class", "fa fa-user-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Associates"])), (_l()(), i1.ɵeld(43, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 3, "a", [["routerLink", "/partners-subscription"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 45).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(45, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(46, 0, null, null, 0, "i", [["class", "fa fa-credit-card"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Payments Plan"])), (_l()(), i1.ɵeld(48, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(49, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(50, 0, null, null, 0, "i", [["class", "fas fa-sign-in-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout"]))], function (_ck, _v) { var currVal_2 = "/employe-dashboard"; _ck(_v, 5, 0, currVal_2); var currVal_5 = "/job-dashboard"; _ck(_v, 15, 0, currVal_5); var currVal_8 = "/user-profile"; _ck(_v, 20, 0, currVal_8); var currVal_11 = "/recruiterprofile"; _ck(_v, 25, 0, currVal_11); var currVal_14 = "/manage-recruiters"; _ck(_v, 30, 0, currVal_14); var currVal_17 = "/schedule-events"; _ck(_v, 35, 0, currVal_17); var currVal_20 = "/partners-addrecruiter"; _ck(_v, 40, 0, currVal_20); var currVal_23 = "/partners-subscription"; _ck(_v, 45, 0, currVal_23); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 15).target; var currVal_4 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 20).target; var currVal_7 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 25).target; var currVal_10 = i1.ɵnov(_v, 25).href; _ck(_v, 24, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 30).target; var currVal_13 = i1.ɵnov(_v, 30).href; _ck(_v, 29, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵnov(_v, 35).target; var currVal_16 = i1.ɵnov(_v, 35).href; _ck(_v, 34, 0, currVal_15, currVal_16); var currVal_18 = i1.ɵnov(_v, 40).target; var currVal_19 = i1.ɵnov(_v, 40).href; _ck(_v, 39, 0, currVal_18, currVal_19); var currVal_21 = i1.ɵnov(_v, 45).target; var currVal_22 = i1.ɵnov(_v, 45).href; _ck(_v, 44, 0, currVal_21, currVal_22); }); }
export function View_CommonHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "common-header", [], null, null, null, View_CommonHeaderComponent_0, RenderType_CommonHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.CommonHeaderComponent, [i5.RecruiterService, i2.Router, i5.RecruiterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommonHeaderComponentNgFactory = i1.ɵccf("common-header", i4.CommonHeaderComponent, View_CommonHeaderComponent_Host_0, { pageTitle: "pageTitle" }, {}, []);
export { CommonHeaderComponentNgFactory as CommonHeaderComponentNgFactory };
