
<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<!--Accordion wrapper-->
<div class="accordion md-accordion accordion-1" id="accordionEx23" role="tablist">
  <div class="card">
    <div class="card-header blue lighten-3 z-depth-1" role="tab" id="heading96">
      <h5 class="text-uppercase mb-0 py-1">
        <a class="white-text font-weight-bold" data-toggle="collapse" href="#collapse96" aria-expanded="true"
          aria-controls="collapse96">
          Dashboards
        </a>
      </h5>
    </div>
    <div id="collapse96" class="collapse show" role="tabpanel" aria-labelledby="heading96"
      data-parent="#accordionEx23">
      <div class="card-body">
        <div class="row my-4">
          <div class="col-md-2">
            <h2 class="font-weight-bold mb-3 black-text" style="text-align: center;">Jobseekers</h2>
              <iframe style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="200" height="150" src="https://charts.mongodb.com/charts-project-0-oiwyj/embed/charts?id=6d7ac611-c658-4163-8fd7-a1d4b9b71d05&theme=light"></iframe>
              <a href="https://charts.mongodb.com/charts-project-0-oiwyj/public/dashboards/420133f4-0880-4869-8ffd-b18889d3027d" target="_blank" style="position:absolute; top:0; left:0; display:inline-block; width:200px; height:150px; z-index:5;"></a>
          </div>
          <div class="col-md-2">
            <h2 class="font-weight-bold mb-3 black-text" style="text-align: center;">Jobs</h2>
             <iframe style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="200" height="150" src="https://charts.mongodb.com/charts-project-0-oiwyj/embed/charts?id=b950457a-cac2-4852-9819-5edba36cf205&theme=light"></iframe>
              <a href="https://charts.mongodb.com/charts-project-0-oiwyj/public/dashboards/a7b9be36-4880-4dbe-878c-cdafb9281bf6" target="_blank" style="position:absolute; top:0; right:0; display:inline-block; width:200px; height:150px; z-index:5;"></a>
          </div>
          <div class="col-md-2">
            <h2 class="font-weight-bold mb-3 black-text" style="text-align: center;">Companies</h2>
            <iframe style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="200" height="150" src="https://charts.mongodb.com/charts-project-0-oiwyj/embed/charts?id=76391e63-c906-459e-a3ef-ad16b226d6ab&theme=light"></iframe>
              <a href="https://charts.mongodb.com/charts-project-0-oiwyj/public/dashboards/0626b841-b329-4b4f-ba68-ab618e17e11b" target="_blank" style="position:absolute; top:0; right:0; display:inline-block; width:200px; height:150px; z-index:5;"></a>
          </div>
          <div class="col-md-2">
            <h2 class="font-weight-bold mb-3 black-text" style="text-align: center;">Partners</h2>
            <iframe style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="200" height="150" src="https://charts.mongodb.com/charts-project-0-oiwyj/embed/charts?id=0eaa6bfe-5199-401b-9b53-f22e7a6a9bbb&theme=light"></iframe>
              <a href="https://charts.mongodb.com/charts-project-0-oiwyj/public/dashboards/56105a03-16ad-412b-8c88-82025196272b" target="_blank" style="position:absolute; top:0; right:0; display:inline-block; width:200px; height:150px; z-index:5;"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header blue lighten-3 z-depth-1" role="tab" id="heading97">
      <h5 class="text-uppercase mb-0 py-1">
        <a class="collapsed font-weight-bold white-text" data-toggle="collapse" href="#collapse97"
          aria-expanded="false" aria-controls="collapse97">
          Approvals
        </a>
      </h5>
    </div>
    <div id="collapse97" class="collapse" role="tabpanel" aria-labelledby="heading97"
      data-parent="#accordionEx23">
      <div class="card-body">
        <div class="row my-4">
          <div class="col-md-8">
            <h2 class="font-weight-bold mb-3 black-text">Partner Approvals</h2>
           
            <p class="">Partner 1 Name</p>
            <p class="mb-0">Partner 1 Details.</p>
          </div>
          <!-- <div class="col-md-4 mt-3 pt-2">
            <div class="view z-depth-1">
              <img src="https://mdbootstrap.com/img/Photos/Others/nature.jpeg" alt="" class="img-fluid">
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</div>

