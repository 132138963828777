<div >
        <div>
                <h1 style="width:100%;height:14%;text-align:center;position:relative;top:40%;">Oops</h1><br/> 
                <h2 style="width:100%;height:10%;text-align:center;position:relative;top:40%;">Not sure how you reached here, the page you are looking for is not here.</h2><br/> 
                <div align="center" >
                        <h3>
                        <a text-align="center" href="https://www.shenzyn.com">Go Back to Shenzyn </a> 
                        </h3>
                </div>
                <img src="../../assets/sheImages/others/404Error.jpg" display=block margin-left=auto margin-right=auto 
                alt='404-Page not found'>

        </div>
                
        <div align="center" >
     


                <!-- <a href="http://www.freepik.com" >Designed by pikisuperstar / Freepik</a>  -->
        </div>
</div>      
        



