import { Component, OnInit, HostListener, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthServices } from '../../services/auth.service';
import { JobseekerService } from '../../services/jobseeker.operations';
import swal from 'sweetalert2';
import { environment } from "../../../environments/environment";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  loadAPI: Promise<any>;
  closeResult: string;
  loading: boolean = false;
  erroMsg: any;
  submitted: boolean = false;
  userData: any;
  subForm: FormGroup;
  emailId: any;
  fileToUpload: File = null;
  partnersUrlLink: String;
  jobsUrlLink: String;


  constructor(@Inject(DOCUMENT) document, private fb: FormBuilder, private authService: AuthServices, private modalService: NgbModal, private modalService2: NgbModal, private toastr: ToastrService, private router: Router, private route: ActivatedRoute, private JobseekerService: JobseekerService, ) {
    this.subForm = fb.group({
      'emailId': [null, Validators.required],
    });
  }
  get f() { return this.subForm.controls; }
  ngOnInit() {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
    this.partnersUrlLink = environment.partnersWebUrl;
    this.jobsUrlLink = environment.jobsWebUrl;
  }
  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        "../assets/js/jquery-3.2.1.min.js",
        "../assets/js/bootstrap.min.js",
        "../assets/js/selectBnfit.js",
        "../assets/js/plugin.js",
        "../assets/js/owl.carousel.js",
        "../assets/js/jquery.countTo.js",
        "../assets/js/jquery.magnific-popup.js",
        "../assets/js/dropify.min.js",
        "../assets/js/jquery.inview.min.js",
        "../assets/js/jquery.nice-select.min.js",
        "../assets/js/imagesloaded.pkgd.min.js",
        "../assets/js/isotope.pkgd.min.js",
        //"../assets/js/custom.js",
        "../assets/js/functions.js",

      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }
  open2(content) {
    this.modalService.open(content).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  open(content) {
    this.modalService2.open(content, { windowClass: 'dark-modal' });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  fileChangeEvent(event: any) {
    var file = event.target.files[0];
    this.fileToUpload = file

    this.JobseekerService.resumeUpload(this.fileToUpload).subscribe(data => {
      this.userData = (data);
      // console.log("----------------",data)
      if ((this.userData.responseStatus === 200)) {
        localStorage.setItem("email", data.data.email[0]);
        // console.log(data)
        // this.router.navigate(['/user-signup']);
        document.location.href = "https://jobs.shenzyn.com/#/user/sign-up"
      }
    }, error => {
      console.log(error);
    });
  }

  subscribe(formdData: any) {

    this.submitted = true;
    if (this.subForm.invalid) {
      return false;
    }
    this.loading = true;
    var emailId;
    emailId = this.emailId;
    if ((emailId !== null)) {
      this.authService.subscribe(emailId).subscribe(
        data => {
          this.userData = (data);
          if ((this.userData.responseStatus === 200)) {
            swal({
              title: 'Success',
              text: data.message,
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#535BE2',
              cancelButtonColor: '#FF7E39',
              confirmButtonText: 'OK',
            })
          }
        },
        error => {
          if (error.responseStatus === 401) {
            swal({
              title: 'Warning',
              text: error.message,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#535BE2',
              cancelButtonColor: '#FF7E39',
              confirmButtonText: 'OK',
            })
          } else if (error.responseStatus === 500) {
            swal({
              title: 'Warning',
              text: error.message,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#535BE2',
              cancelButtonColor: '#FF7E39',
              confirmButtonText: 'OK',
            })
          } else if (error.responseStatus === 400) {
            swal({
              title: 'Warning',
              text: error.message,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#535BE2',
              cancelButtonColor: '#FF7E39',
              confirmButtonText: 'OK',
            })
          }
        },
        () => console.log()
      );
    }
  }
}
