import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WebinarService } from '../../services/webinar.service';
import Swal from 'sweetalert2';
var WebinarComponent = /** @class */ (function () {
    function WebinarComponent(fb, webinarService, route, router) {
        this.fb = fb;
        this.webinarService = webinarService;
        this.route = route;
        this.router = router;
        this.fileToUpload = null;
        this.File = null;
        this.showUploadWait = false;
        this.tileCouter = 0;
        this.selectedModalData = {
            webinarTitle: "",
            webinarDate: "",
            webinarLink: "",
            webinarApp: "",
            webinarId: "",
            webinarTime: "",
            webinarDescription: "",
            webinarKeyTakeAways: "",
            preseneterTitle: "",
            preseneterBio: "",
            preseneterName: "",
            acceptFutureWebinar: false
        };
        this.selectedVideoModal = {
            webinarVideoLink: "",
            webinarId: "",
            webinarTitle: ""
        };
        // Variables for upcoming webinar pagination
        this.sortedWebinarArray = [];
        this.sortedCompletedWebinarArray = [];
        this.currentPage = 0;
        this.nextPage = 2;
        this.previousPage = 0;
        this.tilesPerPage = 6;
        this.start = 0;
        this.carousalleftindex = 0;
        this.carousalrightindex = 1;
        this.carousalItemsPerPage = 2;
        this.wId = '';
        this.addWebinarParticipantsForm = fb.group({
            'contactNo': [null, Validators.required],
            'emailId': [null, Validators.required],
            'resumeUrl': [null, Validators.required],
            'termsandconditions': [false, Validators.pattern('true')],
            'source': [,]
        });
        this.createForm();
    }
    WebinarComponent.prototype.createForm = function () {
        this.addWebinarParticipantsForm = this.fb.group({
            participantFirstName: [null, Validators.required],
            participantLastName: [null, Validators.required],
            participantEmailId: [null, [Validators.required]],
            participantPhone: [null, [Validators.required]]
        });
    };
    WebinarComponent.prototype.webinars = function (array, size) {
        var results = [];
        results = [];
        while (array.length) {
            results.push(array.splice(0, size));
        }
        return results;
    };
    Object.defineProperty(WebinarComponent.prototype, "f", {
        get: function () { return this.RegistrationForm.controls; },
        enumerable: true,
        configurable: true
    });
    WebinarComponent.prototype.ngOnInit = function () {
        var _this = this;
        var queryParams = this.route.snapshot.queryParams;
        this.wId = queryParams ? queryParams.id : '';
        // console.log(this.wId);
        // var ImageMap = function (map, img) {
        //   var n,
        //     areas = map.getElementsByTagName('area'),
        //     len = areas.length,
        //     coords = [],
        //     previousWidth = 2820;
        //   for (n = 0; n < len; n++) {
        //     coords[n] = areas[n].coords.split(',');
        //   }
        //   this.resize = function () {
        //     var n, m, clen,
        //       x = img.offsetWidth / previousWidth;
        //     for (n = 0; n < len; n++) {
        //       clen = coords[n].length;
        //       for (m = 0; m < clen; m++) {
        //         coords[n][m] *= x;
        //       }
        //       areas[n].coords = coords[n].join(',');
        //     }
        //     previousWidth = document.body.clientWidth;
        //     return true;
        //   };
        //   window.onresize = this.resize;
        // },
        //   imageMap = new ImageMap(document.getElementById('map_ID'), document.getElementById('img_ID'));
        // imageMap.resize();
        // return;
        // $('#publicisSpaientModal').modal('show');
        var screenWidth = window.innerWidth;
        this.tileCouter = 0;
        //backend call to get all webinar data
        this.webinarService.getWebinarDetails().subscribe(function (data) {
            //sort tht data based on seq id.
            data.data.sort(function (x, y) { if (!x["seqId"] && !y["seqId"]) {
                return 0;
            }
            else {
                return x["seqId"] - y["seqId"];
            } });
            _this.sortedWebinarArray = data.data;
            _this.pageCount = Math.ceil(_this.sortedWebinarArray.length / _this.tilesPerPage);
            _this.end = _this.start + _this.tilesPerPage;
            _this.allWebinars = _this.sortedWebinarArray.slice(_this.start, _this.end);
            // console.log(this.allWebinars);
            if (_this.wId !== '') {
                _this.allWebinars.forEach(function (element) {
                    if (element.webinarId === _this.wId) {
                        _this.showModalByWebinarID(_this.wId);
                    }
                });
                // console.log(this.allWebinars);
            }
        }, function (error) {
            if (error.responseStatus === 401) {
            }
            else if (error.responseStatus === 500) {
            }
            else if (error.responseStatus === 400) {
            }
        }, function () { return console.log(); });
        this.webinarService.getCompletedWebinars().subscribe(function (data) {
            data.data.sort(function (x, y) { if (!x["seqId"] && !y["seqId"]) {
                return 0;
            }
            else {
                return y["seqId"] - x["seqId"];
            } });
            _this.sortedCompletedWebinarArray = data.data;
            _this.completedWebinarCount = _this.sortedCompletedWebinarArray.length;
            _this.carousalItemsPerPage = Math.ceil(screenWidth / 750);
            // console.log(this.carousalItemsPerPage);
            _this.carousalrightindex = _this.carousalleftindex + _this.carousalItemsPerPage;
            _this.completedWebinars = _this.sortedCompletedWebinarArray.slice(_this.carousalleftindex, _this.carousalrightindex);
        }, function (error) {
            if (error.responseStatus === 401) {
            }
            else if (error.responseStatus === 500) {
            }
            else if (error.responseStatus === 400) {
            }
        }, function () { return console.log(); });
        this.route.queryParams
            .filter(function (params) { return params.source; })
            .subscribe(function (params) {
            console.log(params);
            _this.source = params.source;
        });
    };
    //Cancel validation
    WebinarComponent.prototype.remove = function () {
        this.addWebinarParticipantsForm.reset();
    };
    WebinarComponent.prototype.loadWebinarTiles = function (pageNumber) {
        if ((this.pageCount - pageNumber) <= 0) {
            return;
        }
        this.start = pageNumber * this.tilesPerPage;
        this.end = this.start + this.tilesPerPage;
        if (this.start > this.sortedWebinarArray.length) {
            return;
        }
        this.allWebinars = this.sortedWebinarArray.slice(this.start, this.end);
        this.currentPage = pageNumber;
    };
    WebinarComponent.prototype.loadPrevious = function () {
        if ((this.currentPage - 1) < 0) {
            return;
        }
        this.start = (this.currentPage - 1) * this.tilesPerPage;
        this.end = this.start + this.tilesPerPage;
        this.allWebinars = this.sortedWebinarArray.slice(this.start, this.end);
        this.currentPage = this.currentPage - 1;
    };
    WebinarComponent.prototype.loadNext = function () {
        if ((this.currentPage + 1) >= this.pageCount) {
            return;
        }
        this.start = (this.currentPage + 1) * this.tilesPerPage;
        this.end = this.start + this.tilesPerPage;
        this.allWebinars = this.sortedWebinarArray.slice(this.start, this.end);
        this.currentPage = this.currentPage + 1;
    };
    WebinarComponent.prototype.carousalScroll = function (direction) {
        if (direction == "left") {
            if (this.carousalleftindex != 0) {
                this.carousalleftindex--;
                this.carousalrightindex = this.carousalleftindex + this.carousalItemsPerPage;
            }
        }
        else if (direction == "right") {
            if (this.carousalrightindex < this.completedWebinarCount) {
                this.carousalrightindex++;
                this.carousalleftindex = this.carousalrightindex - this.carousalItemsPerPage;
                ;
            }
        }
        this.completedWebinars = this.sortedCompletedWebinarArray.slice(this.carousalleftindex, this.carousalrightindex);
        //  console.log(this.completedWebinars);
    };
    WebinarComponent.prototype.showModal = function (webData) {
        this.modalData = webData;
        this.success = false;
        this.failure = false;
        this.selectedModalData.webinarLink = webData.webinarLink;
        this.selectedModalData.webinarId = webData.webinarId;
        this.selectedModalData.webinarApp = webData.webinarApp;
        this.selectedModalData.webinarDate = webData.webinarDate;
        this.selectedModalData.webinarTitle = webData.webinarTitle;
        this.selectedModalData.webinarTime = webData.webinarTime;
        this.selectedModalData.preseneterName = webData.preseneterName;
        this.selectedModalData.preseneterBio = webData.preseneterBio;
        this.selectedModalData.preseneterTitle = webData.preseneterTitle;
        this.selectedModalData.webinarDescription = webData.webinarDescription;
        this.selectedModalData.webinarKeyTakeAways = webData.webinarKeyTakeAways;
        this.selectedModalData.acceptFutureWebinar = false;
        $('#registrationModal').modal('show');
    };
    WebinarComponent.prototype.showModalByWebinarID = function (webinarId) {
        var _this = this;
        if (this.allWebinars.length > 0) {
            this.allWebinars.forEach(function (element) {
                if (webinarId === element.webinarId) {
                    _this.showModal(element);
                }
            });
        }
    };
    WebinarComponent.prototype.showVideoModal = function (completedWebinarData) {
        // console.log(completedWebinarData);
        this.videoModalData = completedWebinarData;
        this.selectedVideoModal.webinarId = completedWebinarData.webinarId;
        this.selectedVideoModal.webinarTitle = completedWebinarData.webinarTitle;
        this.selectedVideoModal.webinarVideoLink = completedWebinarData.webinarVideoLink;
        $('#completedWebinarModal').modal('show');
    };
    WebinarComponent.prototype.addParticipants = function () {
        var _this = this;
        var formData = this.addWebinarParticipantsForm.value;
        console.log(this.addWebinarParticipantsForm.value);
        formData.webinarId = this.selectedModalData.webinarId;
        formData.webinarLink = this.selectedModalData.webinarLink;
        formData.webinarApp = this.selectedModalData.webinarApp;
        formData.webinarDate = this.selectedModalData.webinarDate;
        formData.webinarTitle = this.selectedModalData.webinarTitle;
        formData.webinarTime = this.selectedModalData.webinarTime;
        formData.webinarKeyTakeAways = this.selectedModalData.webinarKeyTakeAways;
        formData.preseneterName = this.selectedModalData.preseneterName;
        formData.preseneterBio = this.selectedModalData.preseneterBio;
        formData.preseneterTitle = this.selectedModalData.preseneterTitle;
        formData.acceptFutureWebinar = this.selectedModalData.acceptFutureWebinar;
        console.log(formData);
        this.webinarService.AddParticipants(formData).subscribe(function (res) {
            console.log(res);
            if (res.status == true) {
                _this.success = true;
                _this.failure = false;
            }
            else {
                _this.success = false;
                _this.failure = true;
            }
            _this.addWebinarParticipantsForm.reset();
        }, function (error) {
            console.log(_this.success);
            _this.success = false;
            _this.failure = true;
            _this.alertMsg(error.message, 'warning');
        });
    };
    WebinarComponent.prototype.alertMsg = function (msg, type) {
        if (type == 'success') {
            Swal({
                title: 'Success',
                text: msg,
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#535BE2',
                cancelButtonColor: '#FF7E39',
                confirmButtonText: 'OK',
            });
        }
        else if (type == 'warning') {
            Swal({
                title: 'Warning',
                html: msg,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#1976d2',
                cancelButtonColor: '#FF7E39',
                confirmButtonText: 'OK!',
            });
        }
    };
    WebinarComponent.prototype.navigateToSection = function (anchorHash) {
        setTimeout(function () {
            var anchor = document.getElementById(anchorHash);
            if (anchor) {
                anchor.focus();
                anchor.scrollIntoView();
            }
        });
    };
    return WebinarComponent;
}());
export { WebinarComponent };
