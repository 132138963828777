import { Component, OnInit, HostListener, Input, Pipe, Injectable } from '@angular/core';
import { AuthServices } from '../../services/auth.service';
import { RecruiterService } from '../../services/recruiter.operations'
import { ActivatedRoute, Router } from '@angular/router';
//import { from } from 'rxjs';

@Component({
  selector: 'app-emp-serach',
  templateUrl: './emp-serach.component.html',
  styleUrls: ['./emp-serach.component.css']
})
export class EmpSerachComponent implements OnInit {
  value:any
  location:any
  total_application: boolean = false;
  relevant_profile: boolean = false;
  search_view: boolean = true;
  dataValue: any;
  workExp: any;
  companyName: any;
  logo: any;
  profilePicture: any;
  currentLocation: any;
  currentDesignation: any;
  briefBio: any;
  currentCompany: any;
  name: any;
  filterSalaryValue: any = 'any';
  filterNpValue: any = 'any';
  filterEducationValue: any = 'any';
  filterExperienceValue: any = 'any';
  filterFarea:any ='any';
  Finalvalue: any[];
  loading = true;
  functionalAreaFilter: any;
  joiningDate: any;
  lastDate: any;
  totalWorkExperience: any;
  currentCtc: any;
  institute: any;
  degree: any;
  specialization: any;
  passingYear: any;
  technicalSkill: any;
  constructor(private route: ActivatedRoute, private authService: AuthServices, private RecruiterService: RecruiterService, private router: Router) {

  }

  ngOnInit(): void {
    var searchString
    this.route.params.subscribe(params => {
      this.value = params.id; 
      this.location = params.location;
  {
  if(this.value==="undefined" && this.location==="undefined"){
    this.value= "";
    this.location= "";
  }else if(this.value==="undefined"){
    this.value= "";
  }
  else if(this.location==="undefined"){
    this.location="";
  }
} 
    });

    let datas: '' = this.value;
    let locations: '' = this.location


    this.authService.profileSearch(datas, locations).subscribe(
      data => {
        const dataVal = data.data
        this.Finalvalue = dataVal
        this.dataValue = dataVal
     },
      error => {
        if (error.responseStatus === 401) {
        } else if (error.responseStatus === 500) {
        } else if (error.responseStatus === 400) {
        }
      },
      () => console.log()
    );
  }
  
  filterEd(selectedValue) {
    this.filterEducationValue = selectedValue;
  }
  filterNP(selectedValue) {
    this.filterNpValue = selectedValue;
  }
  filterSalary(selectedValue) {
    this.filterSalaryValue = selectedValue;
  }
  filterExperience(selectedValue) {
    this.filterExperienceValue = selectedValue;
  }
  filterFa(selectedValue) {
    this.functionalAreaFilter = selectedValue;
  }
  finalFilter() {
    const result = this.companyfilteredSet(this.filterExperienceValue, this.filterSalaryValue, this.filterNpValue, this.filterEducationValue,this.functionalAreaFilter);
    this.Finalvalue = result;
  }
  companyfilteredSet(expFilter, salaryFilter, noticePeriodFilter, educationFilter, functionalAreaFilter) {
    var i, j;
    var resultSet = [];
    var dbExpI, expFilterI, dbctcI, salaryFilterI, dbnoticePeriodI, noticePeriodFilterI;
    expFilterI = +expFilter
    salaryFilterI = +salaryFilter;
    noticePeriodFilterI = +noticePeriodFilter;
    if (expFilter == "any" && salaryFilter == "any" && noticePeriodFilter == "any" && educationFilter == "any" && functionalAreaFilter == "any") {
    resultSet = this.dataValue;
    } else {
    for (i = 0; i < this.dataValue.length; i++) {
    dbExpI = +this.dataValue[i].totalWorkExperience;
    dbctcI = +this.dataValue[i].preferredCompanies[0].desiredCTC;
    dbnoticePeriodI = +this.dataValue[i].workExperiences[0].noticePeriod;
    if ((dbExpI >= expFilterI || expFilter == "any")
    && (dbctcI >= salaryFilterI || salaryFilter == "any")
    && (dbnoticePeriodI >= noticePeriodFilterI || noticePeriodFilter == "any")
    && (this.dataValue[i].educationDetails[0].degree == educationFilter || educationFilter == "any") 
    && (this.dataValue[i].workExperiences[0].functionalArea == functionalAreaFilter || functionalAreaFilter == "any")) {
    if (resultSet.indexOf(this.dataValue[i]) == -1) {
    resultSet.push(this.dataValue[i]);
    }
    }
    }
    }
    return resultSet;
   }

  openCity(evt, cityName) {

    console.log(evt, cityName);
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
    // this.job_description=false;
  }
  totalApplication() {
    this.search_view = false;
    this.total_application = true;
    this.relevant_profile = false;
  }
  relevantProfile() {
    this.search_view = false;
    this.total_application = false;
    this.relevant_profile = true;
  }

  goToSearch() {
    console.log(this.value, this.location)


    this.router.navigate(['/emp-search', this.value, this.location]);
  }
  myFunc(num1) {
    console.log(num1);//here you will get input value through ng-model
    this.RecruiterService.userProfile(num1).subscribe(

      data => {
        console.log(data) 
        this.loading = false;
        console.log(data.data)
        this.name = data.data.name
        this.profilePicture = data.data.profilePicture
        this.currentLocation = data.data.currentLocation
        this.totalWorkExperience = data.data.totalWorkExperience
        this.institute = data.data.educationDetails[0].institute
        this.degree = data.data.educationDetails[0].degree
        this.specialization = data.data.educationDetails[0].specialization
        this.passingYear = data.data.educationDetails[0].passingYear
        this.currentDesignation = data.data.workExperiences[0].currentDesignation
        
        this.currentCompany = data.data.workExperiences[0].currentCompany
        this.joiningDate  = data.data.workExperiences[0].joiningDate
        this.lastDate  = data.data.workExperiences[0].lastDate
        this.currentCtc = data.data.workExperiences[0].currentCtc
        this.briefBio = data.data.briefBio
        this.technicalSkill = data.data.technicalSkills
      

      },
      error => {
        // console.log('error', error.message);

        if (error.responseStatus === 401) {
          // console.log('inside if');

        } else if (error.responseStatus === 500) {
          // console.log('inside if');

        } else if (error.responseStatus === 400) {
          // console.log('inside if'); 

        }
      },
      () => console.log()
    );
  }

}
