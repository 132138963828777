<a id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
    <div class="cp_logo_wrapper index_2_logo">
        <a routerLink="/homenew">
            <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
        </a>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="ffht">
            <div class="registration_1">
                <div class="clear1"></div>
            </div>
            <div class="faq_holder"><a name="h1" id="h1"></a>
                <div class="sort_searchmid1 sa_head">Terms and Conditions </div>
                <br><br>
                <hr style="margin-left:auto !important; margin-right:auto !important; border-top: 1px dashed #d80075;">
                <div class="clear"></div>
            </div>
            <div class="">
                <br>
                <p>The following terms and conditions (‘T&C’) are applicable upon all members of the general public
                    within India and are of the age of 18 years and above, who are using the Shenzyn platform (which
                    includes website, mobile application, social media, etc. of <a href="javascript:void(0);"> 4GEN
                        Technologies Pvt</a>. Ltd All Rights Reserved.</p>

                <p>(hereinafter referred to as ‘Shenzyn)) and/or who are availing the recruitment services/related
                    services (collectively herein after referred to as ‘Services’) of Shenzyn in India or abroad.</p>
                <p> These T&C shall also be applicable on the "Job Seeker" (hereinafter referred to as ‘JS’) i.e., a
                    candidate who applies for a vacancy advertised on the portal of SHENZYN viz., www.shenzyn.com and/or
                    a person who uses the portal www.shenzyn.com or Recruitment Centre or any other channels described
                    hereinabove above, for the purpose of finding/seeking employment or for learning and improving her
                    chances to apply for and be selected for the right job and/or a person who has voluntarily evinced
                    interest in having her own job profile by creation of a profile in Shenzyn </p>
                <p>The Offering of Shenzyn, will be shared with the interested employer(s) and/or any third parties
                    (authorized to list jobs on Shenzyn’s portal) such as “recruitment consultants”/ “recruitment
                    partners” (collectively herein after referred to as ‘Clients’) who are availing the Services of
                    Shenzyn in India or abroad. The JS and Clients of Shenzyn shall hereinafter be collectively referred
                    to as the Users. </p>
                <p>It is hereby clarified by SHENZYN that the Services being rendered by SHENZYN to the Users are in the
                    nature of being recruitment/training/related services; in course of which SHENZYN is required to
                    provide details and information of prospective candidates, including Profiles™ to the Clients of
                    SHENZYN, who use SHENZYN’s subscription-based services upon their specific requirements
                    (skill/qualification based or on other parameters).</p>
                <p>Basis the above need, SHENZYN is creating a database of Profiles (using proprietary technology of
                    SHENZYN) of JS wherein the JS voluntarily provides his/her information/details/personally
                    identifiable data with SHENZYN for the creation of the Profile.</p>
                <div class="sub_head_new"><span></span>Terms and conditions applicable to JS’s </div>
                <p>All Profiles created by SHENZYN are subject to the following terms and conditions that compulsorily
                    are applicable upon the JS who have permitted their respective Profiles to be created by SHENZYN.
                </p>

                <p><b>Voluntary Disclosure:</b>The JS confirms, agrees and acknowledges that She is providing their
                    information/details/personally identifiable data with SHENZYN for the creation of the Profile
                    voluntarily. The JS also confirms and acknowledges that they have been disclosed for the purpose of
                    the submission of the information/data and accordingly agree/consent to the terms and conditions
                    applicable upon the JS as stipulated by SHENZYN from time to time.</p>

                <p><b>Veracity of Information:</b>The JS hereby confirms, agrees and acknowledges that She has provided
                    true and correct information/details/personally identifiable data to SHENZYN for the creation of
                    their Profile. JS acknowledges that the information/ data being provided by the JS to SHENZYN is
                    correct, complete and updated in all respects at all times and does not contain any false,
                    distorted, manipulated, fraudulent or misleading facts or averments. SHENZYN will not be liable for
                    any misrepresentation/incorrect facts, etc. that are provided by the JS and each JS shall be
                    entirely responsible & liable for the veracity of the information shared with SHENZYN for the
                    creation of the Profile. JS acknowledges the fact that SHENZYN will reply upon the information/data
                    provided (by the JSs to SHENZYN) and accordingly share the information of the JS(s) with interested
                    3rd parties/clients. The JS(s) agree(s) to bear the sole risk and responsibility for the information
                    provided including the veracity and accuracy thereof and also agree(s) to indemnify SHENZYN for all
                    losses incurred by SHENZYN due to any false, distorted, manipulated, fraudulent or misleading facts
                    given by JS(s) to SHENZYN.</p>

                <p><b>Continuing acceptance of terms and conditions:</b>The JS acknowledges that by sharing his/her
                    information/data or responding to any marketing communications of SHENZYN or by creation of Profile
                    (regardless of the level of completion); She has voluntarily accepted the terms and conditions
                    applicable upon the JS from time to time including future version thereof, for which SHENZYN will
                    not be responsible or liable (in any manner) to intimate the JS of the changes in the terms and
                    conditions (at any point in time).</p>

                <p><b>Waiver of Rights:</b>The JS hereby confirms that by creation of the Profile, the JS has granted
                    the right to SHENZYN (to the exclusion / waiver of the individual JS’s rights) to (i) provide/share
                    the information of the JS to/with the prospective employers/interested third party
                    recruiter(s)/consultant(s) on a repeated basis based on job opportunities and requirements to keep
                    Profiles™ current and usable, without seeking JS’s consent each time, (ii) make calls/send SMS’s to
                    the JS pursuant which shall not constitute unsolicited commercial communications/calls/SMS under the
                    Telecom Regulatory Authority of India (TRAI) regulations and/or any such applicable law, (iii) send
                    e-mails to the JS pursuant to any job-related information or otherwise (iv) use the information of
                    the Profiles™ for offering value-added services to the JS as well as related marketing/business
                    activities determined by SHENZYN at its sole discretion, (v) share information of any/all JS with
                    3rd parties for purposes including but not limited to background, skill development courses to JS,
                    etc.</p>
                <p>By creation of the Profile, the JS hereby also agrees and acknowledges that She may also receive
                    e-mails and/or SMS’s from prospective employers and/or recruiters/consultants for any recruitment
                    related tasks/queries including, updating of profiles.</p>
                <p><b>Grant of Permission:</b>By creation of the Profile, the JS confirms that SHENZYN has the right to
                    assess JS(s) eligibility/fitness for any particular job(s) based on SHENZYN’s proprietary tools.
                    Further, basis such assessment, SHENZYN has the right to infer capabilities and skills of the JS(s)
                    vis-a-vis score the JS(s) for a fit as regards any particular job(s) on a best effort basis. The
                    JS(s) shall at no time have any right to question and/or dispute the manner & methodology of SHENZYN
                    in this regard. SHENZYN also has the right to revise/adjust scores based on SHENZYN’s internal
                    methodology which would be based on the background, skills, preferences and other related
                    information disclosed by the JS(s). For illustration, a JS could face a revision of scores due to
                    “no shows” for interviews or incorrect information given in the preparation of the Profile. The
                    JS(s) unequivocally agree to the manner/method for the scoring adopted by SHENZYN without any
                    protest/dispute towards the same. JS will not hold SHENZYN liable for any such assessment and/or
                    consequent score provided to client of SHENZYN/ the prospective employer by SHENZYN.</p>
                <p>Notwithstanding to what has been agreed under these T&C’s, inability of SHENZYN to provide suitable
                    job opportunities to JS, shall not be considered as deficiency of service under any circumstances,
                    at any point of time.</p>
                <p><b>Disclaimer & Limitation of Liability:</b>The JS understand(s) and acknowledge(s) that creation of
                    Profile or any such assessment by SHENZYN of JS does not constitute an “interview” for any
                    employment opportunities nor does the Profile assure/guarantee/promise (in any manner whatsoever)
                    prospective employment to each/any JS. JS also understand(s) and acknowledge(s) that while offering
                    such Services, SHENZYN is merely acting as an intermediary/facilitator between the JS and the Client
                    of SHENZYN/ prospective employer. By doing so, SHENZYN does cannot assure/guarantee the JS of any
                    assured employment or any other aspect with regards the prospective employment including but not
                    limited to description of employment, designation, grant of benefits, quality work environment, etc.
                    No JS shall hold SHENZYN responsible and/or liable in the event JS is not shortlisted for and/or
                    fails to receive any employment opportunities and/or any response/satisfactory response from any
                    prospective employer, pursuant to availing the recruitment services of SHENZYN. No JS can hold
                    SHENZYN responsible for any actions/omissions that may occur inadvertently and/or due to human error
                    and/or on the part of any prospective employer(s).</p>
                <p><b>Skill Marketplace:</b>JS understand(s) that some course provider(s)/institutes (as Clients of
                    SHENZYN) may also be listing their courses on Recruitment Center of SHENZYN, which may come up with
                    “job guarantee”. The course provider(s) /institutes shall provide details/specifications as regards
                    placements/ “job guarantee” (if applicable). In doing so/while offering such services, SHENZYN shall
                    only act as a facilitator inviting JS who evince interest in the courses being listed / offered by
                    the course provider(s)/institutes. Any interested JS shall directly approach and deal with the
                    respective course provider regarding any fees, courses offered, timelines, placements, employment
                    opportunities etc. JS agrees that SHENZYN is not endorsing any courses or any course provider and
                    shall not be involved in any arrangement of the JS with the course provider under any circumstances.
                    The JS agrees that courses offered would be subject to a private contract privy only to the JS and
                    the course provider. SHENZYN shall not entertain/address any of JS’s grievances/claim(s), which may
                    emerge from any arrangement that the JS may have with the course provider(s)/institutes. SHENZYN
                    makes no representation or warranty as regards placement/prospective employment of any JS, who opts
                    and completes any course of the course provider. No JS shall hold SHENZYN responsible and/or liable
                    in the event JS fails to receive any employment opportunities/suitable employment opportunity,
                    pursuant to completion of any of the courses of the course provider.</p>
                <p>SHENZYN does not take any responsibility for any security bonds (if) demanded by the Employer(s) from
                    the JS(s) at the time of confirmation of employment. However, it is being clarified that SHENZYN
                    does not encourage such practices by the Employer(s). For purposes herein, SHENZYN does not have any
                    role in the dealing(s)/arrangement(s) between the Employer(s) and JS(s). </p>
                <p><b>Continued Applicability:</b>By creation of the Profile, the JS confirms that SHENZYN will keep and
                    regard the Profile(s) to continue within the SHENZYN database until the particular JS contacts
                    SHENZYN for the removal of the Profile. Furthermore, SHENZYN is not under any obligation to
                    validate, update or revalidate the contents of the Profile of any JS and it is the obligation of the
                    JS to voluntarily update his/her Profile with SHENZYN. Also, these terms and conditions (as
                    applicable from time to time) continue to be applicable to JS until such time that the JS contacts
                    SHENZYN for the removal of the Profile. The JS, by getting the Profile done through SHENZYN, also
                    agrees that the Profile forms a part of SHENZYN’s proprietary database which can be shared with
                    SHENZYN’s prospective clients including any employers at any point of time. SHENZYN cannot be held
                    liable for sharing of any data with clients or other unrelated 3rd parties, if the JS does not
                    voluntarily remove their Profile from SHENZYN’s proprietary database.</p>
                <p><b>Opt-out:</b>In case JS intends to remove his/her Profile from SHENZYN database and/or does not
                    want the same to be shared with any particular section of Clients of SHENZYN and/or wants to
                    discontinue use of services of SHENZYN, then JS shall be required to give reasonable prior notice to
                    that effect in writing to SHENZYN.</p>
                <p><b>Dispute Resolution and Jurisdiction:</b>All disputes/differences of any JS with regards SHENZYN
                    and/or the Services, shall be subject to the laws of the Republic of India and the exclusive
                    jurisdiction of the courts at New Delhi only.</p>
                <div class="sub_head_new"><span></span>Terms and conditions applicable to
                    employers/consultants/recruiters (“Clients”) </div>
                <p>All ‘Employer Online Access’ (EOA) product features created by SHENZYN are subject to the following
                    terms and conditions that compulsorily are applicable upon the Clients who have or are proposing to
                    register with the EOA Product(s) of SHENZYN.</p>
                <p>Notwithstanding the other defined terms already applicable in the T&C or elsewhere in Terms of Use or
                    Privacy Policy of SHENZYN; specifically for this section (EOA), the following definitions would be
                    applicable.</p>
                <p><b>Definitions: </b>In these conditions the following words and phrases shall have the following
                    meanings unless the context requires otherwise:</p>
                <p><b>Campaign/Job: </b>Any job listing issued by SHENZYN through its portal/website/Recruitment Centre,
                    viz,  www.shenzyn.com  and SHENZYN’s mobile application, on behalf of the Client.</p>
                <p><b>"Job Seeker" /‘JS’ </b>i.e., a candidate who applies for a vacancy advertised on the portal of
                    SHENZYN viz.,  www.shenzyn.com  and/or a person who uses the portal  www.shenzyn.com  or Recruitment
                    Centre or any other channels described hereinabove above, for the purpose of finding/seeking
                    employment or for learning and improving his/her chances to apply for and be selected for the right
                    job and/or a person who has voluntarily evinced interest in having his/her own job profile by
                    creation of a Profile on SHENZYN’s platform or any person whose profiles are sourced/uploaded/shared
                    by any recruiter/consultant who has profiles of such persons, which She is uploading so that those
                    persons become job seekers.</p>
                <p><b>Dashboard: </b>Platform to track the job application for the Client.</p>
                <p><b>Subscriber/Client: </b>The person(s) who have signed a Service Agreement for use of the Services
                    of the SHENZYN and includes an employer/recruiter/consultant.</p>
                <p><b>Profile: </b>Visual display of resume capturing skills, preference and intent of the Job Seeker.
                </p>
                <p><b>Services: </b>The Services which SHENZYN has agreed to provide to the Client and the Services
                    which are available on the portal/website/Recruitment Centre of the SHENZYN for use by the JS(s)
                    including service to the JS wishing to post a resume or curriculum vitae for the purpose of seeking
                    employment. Also, Services would include service to the User who wishes to receive advertisements
                    and promotional messages on the portal/website/Recruitment Centre of SHENZYN viz.,  www.shenzyn.com
                     and through emails.</p>

                <p><b>Products: </b>SHENZYN is a platform for managing, screening and sourcing profiles. There are 2 key
                    offerings for anyone looking to hire using SHENZYN’s platform.</p>
                <p>The Products of SHENZYN are broadly categorised in two parts: (a) SmartScreen (an online access
                    product) available for employer(s)/ recruiter(s)/consultant(s) and such other third parties, and (b)
                    SmartHire.
                    Under SmartScreen, the employer(s)/ recruiter(s)/consultant(s) and such other third parties, can
                    post job(s) and set the required custom screening criteria. SHENZYN’s matching algorithms will
                    instantly share profiles that match the requirement of employer(s)/ recruiter(s)/consultant(s) and
                    such other third parties. The said profiles are sourced from profiles uploaded by the user (being
                    the Client) itself, profiles uploaded and shared by other users and profiles owned by SHENZYN. If
                    the user wants access to contact details for a profile owned by SHENZYN or for a profile uploaded
                    and shared by other users, the said user shall have to pay and unlock the required profile.</p>
                <p>Under SmartHire, the employer(s) share their requirement with SHENZYN instead of managing multiple
                    consultant(s). SHENZYN will share the requirement with its network of consultant(s), making sure
                    that consultant(s) are sharing the right kind of profiles. SHENZYN will be invoicing the employer(s)
                    on every success and in consideration of consultant(s) business development efforts, the
                    consultant(s) will receive payment/revenue share from SHENZYN for every success.</p>
                <p>The Product details along with features and price structure are more specifically detailed under the
                    employer section of the SHENZYN portal, which may be amended/ removed by SHENZYN at its sole
                    discretion. The Products available on the site/portal/Recruitment Centre of SHENZYN can be availed
                    by the Client(s) in combinations or individually.</p>

                <p><b>User: </b>Is either a JS or an employer or consultant or recruitment agency or any other
                    person/entity who avails/subscribes to /of any Service of the SHENZYN, whether or not registered on
                    portal of the SHENZYN and includes his/her successors and permitted assigns.</p>

                <p><b>Registration Form: </b>Form required to be filled/completed/accepted by the User for use of
                    Services under the Campaign. </p>

                <p><b>Agreement: </b>shall mean and include the completed application form (available on SHENZYN
                    portal), its attachment(s) and the terms and conditions stated herein, duly
                    completed/executed/accepted by the User. It shall be deemed to have been executed in New Delhi.</p>

                <p><b>Service Agreement: </b>The arrangement/ agreement executed by the SHENZYN with the Client(s).</p>

                <p><b>Date of Commencement:</b> is the date of registration indicating the acceptance of the Terms &
                    Conditions by the User of the Service.</p>
                <p><b>Registration Data:</b>Is the database of all the particulars and information supplied by the User
                    on initial application and subscription, including but without limiting to the User's name,
                    telephone number, mailing address, account and email address.</p>
                <div>
                    <h4 class="sub_head_new">Payment , Cancellation &amp; Refund Policy</h4><b>Payment Policy</b>
                    <p>This payment policy covers the use of the website(s) shenzyn.com, equip-app.com and its
                        subdomains which is
                        independently owned and operated by 4 GEN Technologies Pvt Ltd. Throughout the site, the terms
                        "Shenzyn",
                        “equip-app”, “we”, “us” and “our” refer to 4GEN Technologies Pvt Ltd.<br><br>By placing an order
                        on this site
                        you are entering into a purchase/sale transaction solely with Shenzyn. This applies to all cases
                        where the
                        payment is made by all modes i.e. all credit card, bank card, debit card, bank transfer and
                        other payments are
                        to be made to Shenzyn.<br><br>Your credit card or debit card statements may reflect transaction
                        as done towards
                        “CashFree”. Post successful payment you would get a confirmation mail from Shenzyn with
                        reference to payment and
                        services availed.</p><b>Cancellation Policy</b>
                    <p>In the event of cancellation of any paid services for which payment has been successfully made
                        and a confirmation
                        number has been generated, and the services has not been concluded due to any avoidable /
                        unavoidable reason(s)
                        we must be notified of the same in writing – by an email at wehearyou@shenzyn.com.</p><b>Refund
                        Policy</b> <br>
                    <p>In the event your request for services is accepted but post-payment due to unexpected and
                        extenuating
                        circumstances, your request for services is rejected or cancelled; You will be given an option
                        of opting for
                        another request for services OR will be offered a complete refund, if you so desire. The refund
                        will be credited
                        to your account within 10 working days.<br><br>In case your credit card/debit card/payment
                        account has been
                        accidentally over-charged, please notify Shenzyn of the same at the earliest. Shenzyn will
                        refund the
                        outstanding amount to your Payment Account within 15 working days.</p>
                    <p>It is mandatory for the users to maintain unique Transaction ID numbers, which would be provided
                        at the time of
                        placing a request. This number would be required to address any problems, if any.</p>
                    <p>No refund in any case whatsoever can be claimed from Shenzyn for the amount paid to the
                        third-party directly by
                        the user. The refund will be processed directly in your Bank / Credit Card account that was used
                        to make the
                        payment. Refund Processing Charges would be applicable. Contact us (at wehearyou@shenzyn.com)
                        for more details
                        or any related queries and feedbacks.</p>
                </div>
                <div class="sub_head_new"><span></span>Terms applicable for Job listing on the job search section(s) of
                    SHENZYN</div>

                <p><b>1.</b> SHENZYN shall place the information relating to vacancies in the job search sections of
                    SHENZYN viz., merajob.in or such other mirror or parallel site(s) or in allied publications as
                    SHENZYN may deem fit and proper but such additional web hosting shall be without any extra cost to
                    the subscriber / Client. The detailed features and functionality of the job listing solutions are
                    available on the employer section of SHENZYN site/portal, links of which are shared hereinabove.</p>


                <p><b>2.</b> The insertion so displayed in the job search section of SHENZYN /merajob.in shall be for a
                    period (currently maximum 30 days, depending upon the Product(s) category), which period is subject
                    to change without notice. Every instance of reposting of existing listing entitles the Client to an
                    additional period (currently maximum 30 days, as per the Product(s)) starting from the date on which
                    the listing is reposted and shall be counted as fresh posting; however prevailing reposting charges
                    will apply.</p>


                <p><b>3.</b> SHENZYN reserves its right to reject any insertion or information/data provided by the
                    Subscriber/Client without assigning any reason either before uploading or after uploading the
                    vacancy details, but in such an eventuality, any amount so paid for, may be refunded to the
                    Subscriber/Client on a pro-rata basis at the sole discretion of SHENZYN.</p>


                <p><b>4.</b> By posting/uploading a job on the portal/website/Recruitment Centre, Client confirm that
                    they have obtained all licenses/permits as are necessary for the purposes of recruitment and
                    accordingly, Client agrees to indemnify SHENZYN against all claims, damages arising out of
                    actions/claims that may be made/brought against SHENZYN in relation to the same.</p>


                <p><b>5.</b> SHENZYN has the right to make modifications/edits to the vacancy details, as it may deem
                    fit, in order to fit the same to its database.</p>


                <p><b>6.</b> All information provided by the Client and displayed by SHENZYN becomes public knowledge
                    and SHENZYN may at its sole discretion include the vacancy provided by a subscriber for display on
                    the portal of SHENZYN viz., merajob.in in any other media including the print media at no extra
                    costs to the subscriber. It is clarified that SHENZYN shall not be held liable for usage/publicity
                    of such information.</p>

                <p><b>7.</b> SHENZYN offers no guarantee/warranty that the Client would receive any response/
                    satisfactory response once the job is put on display.</p>


                <p><b>8.</b> SHENZYN shall in no way be held liable for any information received by the
                    recruiter/employer and it shall be the sole responsibility of the subscriber to check, authenticate
                    and verify the information/response received at its own cost and expense.SHENZYN shall be under no
                    obligation to validate, update or revalidate the contents of the Profile of any JS and/or the
                    contents of any profiles sourced from profiles uploaded by the user itself or any other user.</p>


                <p><b>9.</b> SHENZYN would not be held liable for any loss of data, technical or otherwise, information,
                    particulars supplied by the Subscriber, due to reasons beyond its control like corruption of data or
                    delay or failure to perform as a result of any causes or conditions that are beyond SHENZYN's
                    reasonable control including but not limited to strike, riots, civil unrest, Govt. policies,
                    tampering of data by unauthorized persons like hackers, war and natural calamities.</p>


                <p><b>10.</b> SHENZYN will commence providing Services only upon receipt of the requisite
                    amount/charges/fees upfront either from the Subscriber/Client or from an authorized third party on
                    behalf of the Subscriber/Client.</p>


                <p><b>11.</b> The Client shall be deemed to have given an undertaking to SHENZYN that the jobs sought to
                    be advertised on the job search section of SHENZYN viz., merajob.in are in existence, are genuine
                    and that the Subscriber/Client has the authority to advertise such jobs.</p>


                <p><b>12.</b> The Subscriber/Client must give an undertaking to SHENZYN that there will be no fee
                    charged from any person who responds to jobs advertised on the job search section of SHENZYN viz.,
                    merajob.in for processing of applications / responses from such person.</p>

                <p><b>13.</b> SHENZYN reserves its right to change the look, feel, design, prominence, depiction,
                    classification of the job search section of SHENZYN.com at any time without assigning any reason and
                    without giving any notice.</p>


                <p><b>14.</b> The Client/Subscriber shall be assigned password (s) by SHENZYN to enable the
                    Client/Subscriber to post vacancies on the portal of SHENZYN in agreed upon section(s), but the sole
                    responsibility of the safe custody of the password shall be that of the Client and SHENZYN shall
                    under no circumstances be responsible for data loss/theft of data/corruption of data or the wrong
                    usage/misuse of the password and/or any damage and/or leak of information and its consequential
                    usage by a third party. SHENZYN undertakes to take all reasonable precautions at its end to ensure
                    that there is no leakage/misuse of the password granted to the subscriber by SHENZYN.</p>


                <p><b>15.</b> The User of the Services does not claim any copyright or other intellectual property
                    right(s) over the data uploaded by him/her on the website of SHENZYN.</p>


                <p><b>16.</b> Further, SHENZYN shall not be responsible for any failure on the part of JS to furnish any
                    security bonds and/or for any breach of employment terms/policy of the Client.</p>


                <p><b>17.</b> SHENZYN may, in its sole discretion update the changes made to JS application status by
                    the employer/recruiter/consultant.</p>

                <p>To enable the SmartScreen and SmartHire plans, there are certain actions that an
                    employer/consultant/recruiter can take on SHENZYN’s platform, these include:</p>

                <p><b>1</b> Upload profile data in the form of CVs or excel</p>
                <p> A profile can be uploaded either in ‘private’ mode or ‘shared’ mode. If a profile is uploaded as a
                    private profile, only the owner of the profile/Client will have access to the profile information.
                    This profile will not be suggested to other Client(s) if it matches their job requirement(s).
                    SHENZYN will not be sending any jobs to a profile in case it’s private. However, if the owner of a
                    private profile invites the candidate online to provide details for a job, in that case owner may be
                    able to see all available jobs. Also, in case the candidate chooses to search for other available
                    opportunities on SHENZYN, She will be allowed to do so. In case the candidate applies for a job
                    posted by another employer, a public version of profile will automatically be created for the said
                    JS.</p>
                <p> A profile uploaded in shared mode will be suggested to other Client(s) in case it matches their
                    requirements. A shared profile will also receive job recommendations from SHENZYN and will be
                    prompted to apply for other jobs if the JS logs-in to SHENZYN.</p>


                <p> A profile uploaded in shared mode will be suggested to other Client(s) in case it matches their
                    requirements. A shared profile will also receive job recommendations from SHENZYN and will be
                    prompted to apply for other jobs if the JS logs-in to SHENZYN.</p>

                <p> The same profile could be uploaded as shared and private by different Client(s). If a shared
                    instance exists for a profile, SHENZYN will treat that profile as a shared profile and will be
                    sharing relevant job opportunities with the candidate.</p>

                <p> Different versions of the profile can be uploaded by different Client(s) and they will be stored
                    separately. However, if the JS logs in to review and update his/her profile, that version will be
                    considered as the master profile and all other instances of the profile will get updated with this
                    information.</p>

                <p> <b>2</b> Send e-mails/SMS to JSs. Employer(s) can send templated communication to JSs to complete
                    actions like applying for a job, updating profile information and uploading their resume etc.
                    Employer(s) will be able to track if the JS opened the e-mail or not, or if the JS has completed the
                    desired action or not. All of these communications will be sent out on behalf of the employer and
                    will be triggered by the employer; however, the sender ID will be SHENZYNs.</p>

                <p> <b>3</b> Manage candidates for a job on an application tracking system.</p>

                <p> <b>4</b> Rate profiles and record feedback</p>


                <p> <b>5</b> Submit the profiles with client and receive feedback i.e. Accept or Reject</p>


                <p> <b>6</b> Bookmark/Tag profiles for using later</p>

            </div>
        </div>
        <div class="clear"></div>
    </div>
</div>
<!-- news app wrapper start-->
<!-- <div class="news_letter_wrapper shaa jb_cover" >
        <div class="sha1 bubble-180">
            <img src="assets/sheImages/bubble2.png" class="img-responsive " alt="img">
        </div>
        <hr class="style-three">
        <div class="sha2 bubble-185">
            <img src="assets/sheImages/bubble2.png" class="img-responsive " alt="img">
        </div>
    </div>   -->
<app-footer></app-footer>