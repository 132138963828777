<!-- Desktop View -->
<div id="desktop-view">
  <div class="form-body">
    <div class="website-logo">
      <a routerLink="/homenew">
        <img class="logo-size" src="/assets/sheImages/logo56.svg" alt="" style="width:170px;height:47px;">
      </a>
    </div>
    <div class="row">
      <div class="img-holder">
        <div class="bg"></div>
        <div class="info-holder">
          <img src="/assets/sheImages/graphic1.svg" alt="">
        </div>
      </div>
      <div class="form-holder">
        <div class="form-content">
          <div class="form-items" style="max-width: 596px;">
            <h1 id="header_font">Password Reset</h1>
            <br />
            <p>To reset your password, enter the email address you use to sign in to Shenzyn</p>
            <br />
            <form [formGroup]="forgotForm" novalidate (submit)="forgot(forgotForm.value)">
              <input type="text" class="form-control email" placeholder="Email id *" id="emailId" name="emailId"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [formControl]="forgotForm.controls['emailId']"
                [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
              <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                <div *ngIf="f.emailId.errors.required">Email Id is required</div>
                <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
                  Email must be a valid email address.
                </div>
              </div>
              <p style="color:red;">{{erroMsg}}</p>
              <div class="form-button">
                <button id="submit" type="submit" [disabled]="loading" class="ibtn btn-forget"
                  style="background-color: #d80075">Send Reset Link</button>
                <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile Response -->
<div id="mobile-view">
  <div class="container">
    <div class="row form-content">
      <div class="col-sm-12">
        <div class="col-sm-3 form-items logo-login" style="max-width: 596px;">
          <a routerLink="/homenew">
            <img class="logo-size" src="assets/sheImages/logo8.svg" alt="Shenzyn-logo">
          </a>

        </div>
        <div class="form-items text-center" style="max-width: 596px;">
          <h1 id="header_font">Password Reset</h1>
          <br />
          <p>To reset your password, enter the email address you use to sign in to Shenzyn</p>

          <br />

          <form [formGroup]="forgotForm" novalidate (submit)="forgot(forgotForm.value)">
            <input type="text" class="form-control email" placeholder="Email id *" id="emailId" name="emailId"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [formControl]="forgotForm.controls['emailId']"
              [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
              <div *ngIf="f.emailId.errors.required">Email Id is required</div>
              <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
                Email is invalid
              </div>
            </div>
            <p style="color:red;">{{erroMsg}}</p>
            <div class="form-button">
              <button id="submit" type="submit" [disabled]="loading" class="ibtn btn-forget"
                style="background-color: #d80075">Send Reset Link</button>
              <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>