<!-- Desktop View -->
<div id="desktop-view">
  <div class="form-body">
    <div class="website-logo">
      <a routerLink="/homenew">
        <img class="logo-size" src="/assets/sheImages/logo56.svg" alt="" style="width: 172px; height: 43;">
      </a>
    </div>
    <div class="row">
      <div class="img-holder">
        <div class="bg"></div>
        <div class="info-holder">
          <img src="/assets/sheImages/graphic1.svg" alt="">
        </div>
      </div>
      <div class="form-holder">
        <div class="form-content">
          <div class="form-items" style="max-width: 596px;">
            <h1 id="header_font" style="font-size:44px;line-height:57px;"> Partner’s Registration </h1>
            <br />
            <p class="p"> Welcome to the Shenzyn Partner Extranet Portal. By inserting
              your data below you can register as member for Shenzyn. </p>
            <div class="page-links">
              <a routerLink="/partners-login">Login</a>
              <a routerLink="/partners-signup" class="active">Register</a>
            </div>

            <form [formGroup]="RegistrationForm" novalidate (submit)="register(RegistrationForm.value)">
              <input type="text" class="form-control " name="name" id="name" placeholder="Company name*"
                pattern="[a-zA-Z ]*" [formControl]="RegistrationForm.controls['companyName']"
                [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }">
              <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                <div *ngIf="f.companyName.errors.required">Company name is required</div>
                <div *ngIf="submitted && f.companyName.errors && f.companyName.errors.pattern">
                  Company name should conatin only alphabets (a/A-z/Z)
                </div>
              </div>
              <input type="text" class="form-control " name="address" id="address" placeholder="Company address*"
                [formControl]="RegistrationForm.controls['address']"
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
              <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <div *ngIf="f.address.errors.required"> Address required</div>

              </div>
              <input type="number" class="form-control" name="contactNo" id="contactNo"
                pattern="^((\\+91-?)|0)?[0-9]{10}$" placeholder="Contact No*"
                [formControl]="RegistrationForm.controls['contactNo']"
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
              <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                <div *ngIf="f.contactNo.errors.required">Contact no required</div>
                <div *ngIf="submitted && f.contactNo.errors && f.contactNo.errors.pattern">
                  It is not a valid mobile number.
                </div>
              </div>
              <input type="email" class="form-control " placeholder="Email id *" id="emailId" name="emailId"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [formControl]="RegistrationForm.controls['emailId']"
                [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
              <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                <div *ngIf="f.emailId.errors.required">Email Id is required</div>
                <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
                  Email must be a valid email address.
                </div>
              </div>
              <!-- <div class="type-drp">
              <ng-multiselect-dropdown
                            [placeholder]="'Specialization    Max 3'"
                            [data]="dropdownList"
                            [(ngModel)]="selectedItems"
                            [settings]="dropdownSettings"
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                          >
            </ng-multiselect-dropdown>
          </div> -->

              <!-- <input type="password" class="form-control email" placeholder=" Password *" id="password-field"
                name="password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{8,}"
                [formControl]="RegistrationForm.controls['password']"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <span toggle="#password-field" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
          margin-right: 20px;
          margin-top: -42px;
          position: relative;
          z-index: 2;"></span>
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="submitted && f.password.errors && f.password.errors.pattern">
                  At least 8 characters in length,
                  Password should have at least 1 Number and at least 1 letter in capital
                </div>
              </div> -->

              <input type="text" class="form-control " name="gstin" id="gstin" placeholder="GSTIN*"
                pattern="^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}$"
                [formControl]="RegistrationForm.controls['gstin']"
                [ngClass]="{ 'is-invalid': submitted && f.gstin.errors }">
              <div *ngIf="submitted && f.gstin.errors" class="invalid-feedback">
                <div *ngIf="f.gstin.errors.required">gstin required</div>
                <div *ngIf="submitted && f.gstin.errors && f.gstin.errors.pattern">
                  Enter a valid GSTIN number
                </div>
              </div>
              <p style="color:red;">{{erroMsg}}</p>
              <div class="form-button">
                <button id="submit" type="submit" [disabled]="loading" class="ibtn "
                  style="background-color: #d80075">Register</button>
                <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
              </div>
            </form>

            <!-- <div class="other-links">
              <span>Or login with</span>
              <a><i class="fab fa-google" (click)="socialSignIn('google')"></i></a>
              <a><i class="fab fa-linkedin-in" (click)="socialSignIn('linkedin')"></i></a>
            </div> -->

            <div class="other-links">
              <p style="font-size:13px;margin-top:10px;">Already a user? Please <a id="reg_txt"
                  routerLink="/partners-login">Login</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div id="mobile-view">
  <div class="container">
    <div class="row form-content">
      <div class="col-sm-12">
        <div class="col-sm-3 form-items logo-login" style="max-width: 596px;">
          <a routerLink="/homenew">
            <img class="logo-size" src="assets/sheImages/logo8.svg" alt="">
          </a>

        </div>
        <div class="form-items" style="max-width: 596px;">
          <h3 id="header_font"> Partner’s Registration </h3>
          <br />
          <!-- <p> Partner’s Registration </p> -->
          <p class="p"> Welcome to the Shenzyn Partner Extranet Portal. By inserting
            your data below you can register as member for Shenzyn.</p>
          <div class="page-links">
            <a routerLink="/partners-login">Login</a>
            <a routerLink="/partners-signup" class="active">Register</a>
          </div>

          <form [formGroup]="RegistrationForm" novalidate (submit)="register(RegistrationForm.value)">
            <input type="text" class="form-control " name="name" id="name" placeholder="Company name*"
              pattern="[a-zA-Z ]*" [formControl]="RegistrationForm.controls['companyName']"
              [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }">
            <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
              <div *ngIf="f.companyName.errors.required">Company name is required</div>
              <div *ngIf="submitted && f.companyName.errors && f.companyName.errors.pattern">
                Company name should conatin only alphabets (a/A-z/Z)
              </div>
            </div>
            <input type="text" class="form-control " name="address" id="address" placeholder="Company address*"
              [formControl]="RegistrationForm.controls['address']"
              [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required"> Address required</div>

            </div>
            <input type="number" class="form-control" name="contactNo" id="contactNo"
              pattern="^((\\+91-?)|0)?[0-9]{10}$" placeholder="Contact No*"
              [formControl]="RegistrationForm.controls['contactNo']"
              [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
            <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
              <div *ngIf="f.contactNo.errors.required">Contact no required</div>
              <div *ngIf="submitted && f.contactNo.errors && f.contactNo.errors.pattern">
                It is not a valid mobile number.
              </div>
            </div>
            <input type="email" class="form-control " placeholder="Email id *" id="emailId" name="emailId"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [formControl]="RegistrationForm.controls['emailId']"
              [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
              <div *ngIf="f.emailId.errors.required">Email Id is required</div>
              <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern">
                Email must be a valid email address.
              </div>
            </div>

            <!-- <input type="password" class="form-control email" placeholder=" Password *" id="password-field"
              name="password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{8,}"
              [formControl]="RegistrationForm.controls['password']"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            <span toggle="#password-field" class="fa fa-fw fa-eye  toggle-password" style=" float: right;
                            margin-right: 20px;
                            margin-top: -42px;
                            position: relative;
                            z-index: 2;"></span>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="submitted && f.password.errors && f.password.errors.pattern">
                At least 8 characters in length,
                Password should have at least 1 Number and at least 1 letter in capital
              </div>
            </div> -->

            <input type="text" class="form-control " name="gstin" id="gstin" placeholder="GSTIN*"
              pattern="^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}$"
              [formControl]="RegistrationForm.controls['gstin']"
              [ngClass]="{ 'is-invalid': submitted && f.gstin.errors }">
            <div *ngIf="submitted && f.gstin.errors" class="invalid-feedback">
              <div *ngIf="f.gstin.errors.required">GSTIN is Required</div>
              <div *ngIf="submitted && f.gstin.errors && f.gstin.errors.pattern">
                Enter a valid GSTIN number
              </div>
            </div>
            <p style="color:red;">{{erroMsg}}</p>
            <div class="form-button">
              <button id="submit" type="submit" [disabled]="loading" class="ibtn "
                style="background-color: #d80075">Register</button>
              <i *ngIf="loading" class="fa fa-spinner fa-spin" style="font-size: 20px;margin-left: 10px;"></i>
            </div>
          </form>

          <!-- <div class="other-links">
            <span>Or login with</span>
            <a><i class="fab fa-google" (click)="socialSignIn('google')"></i></a>
            <a><i class="fab fa-linkedin-in" (click)="socialSignIn('linkedin')"></i></a>
          </div> -->

          <!-- <div class="other-links">
            <p style="font-size:13px;margin-top:10px;">Already a user? Please <a id="reg_txt"
                routerLink="/partners-login">Login</a></p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>