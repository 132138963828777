
<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<app-header></app-header>
<!-- contact_icon_section start-->
<div class="section overflow-hidden no-padding-bottom is-header bg-accent">
        <h1 style="margin-left:80px;" id="header_font">Hola, let&#x27;s talk!</h1><br/>
    <div class="c-contact1" >
        <div class="c-contact1__wrapper">
            <div class="c-contact1_inner">
                <div class="c-contact1__form w-form col-12">
                        <div class="row col-12">
                                <div class="col lg-4 col-4" >
                                    <div class="tile">
                                            <a href="https://www.facebook.com/Shenzyn-108397103967073/" target="_blank"class="social_main" >
                                                <i class="fab fa-facebook-f" style="transition: all 1s ease-in-out;"></i>
                                            </a>
                                            <p class="social_media">Facebook</p>
                                            <p class="social_text">Send us <br>a message on facebook</p>
                                    </div>                     
                                </div>
                                <div class="col lg-4 col-4" >                     
                                        <div class="tile">
                                                <a href="https://twitter.com/Shenzyn_EW" target="_blank" class="social_main">
                                                    <i class="fab fa-twitter" style="transition: all 1s ease-in-out;"></i>
                                                </a>
                                                <p class="social_media">Twitter</p>
                                                <p class="social_text">Tweet us for help</p>
                                        </div> 
                                </div>
                                <div class="col lg-4 col-4" >                     
                                        <div class="tile">
                                                <a href="mailto:wehearyou@shenzyn.com?Subject=Hello Shenzyn" target="_blank" class="social_main">
                                                    <i class="fas fa-envelope" style="transition: all 1s ease-in-out;"></i>
                                                </a>
                                                <p class="social_media">Email</p>
                                                <p class="social_text">Send an email to <br/> <a class="mob-margin" href="mailto:wehearyou@shenzyn.com?Subject=Hello Shenzyn" target="_blank"style="text-decoration: underline">wehearyou@shenzyn.com</a></p>       
                                        </div> 
                                </div>
                                </div>
                                 <div class="row" >
                                        <div class="col lg-4 col-4" >
                                            <div class="tile">
                                                    <a href="#" class="social_main">
                                                        <i class="fab fa-whatsapp-square" style="transition: all 1s ease-in-out;"></i>
                                                    </a>
                                                    <p class="social_media">WhatsApp</p>
                                                    <p class="social_text">Chat & Media<br/>
                                                        file for easy communication</p>
                                            </div>                    
                                        </div>
                                        <div class="col lg-4 col-4" >                     
                                                <div class="tile">
                                                        <a href=" https://www.linkedin.com/company/28714426/admin/"target="_blank" class="social_main">
                                                                <i class="fab fa-linkedin" style="transition: all 1s ease-in-out;"></i>
                                                        </a>
                                                        <p class="social_media">Linkedin</p>
                                                        <p class="social_text">Connect  <br/> with us on linkedin </p>
                                                </div> 
                                        </div>
                                        <div class="col lg-4 col-4" >                     
                                                <div class="tile">
                                                        <a href="https://www.instagram.com/we_shenzyn/" target="_blank"class="social_main">
                                                            <i class="fab fa-instagram" style="transition: all 1s ease-in-out;"></i>
                                                        </a>
                                                        <p class="social_media">Instagram</p>
                                                        <p class="social_text">Check us out on Instagram</p>
                                                </div> 
                                        </div>
                                        </div> 
                </div>
                <div class="c-contact1__form w-form col-12" >
                        <form [formGroup]="ContactForm" novalidate (submit)="contactUs(ContactForm.value)">
                            <div>
                                <input type="email" class="form-input-text style1 w-input form-control" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                maxlength="256" name="email-2" data-name="Email 2" placeholder="Email" style="font-size:15px;" id="email-2"
                               [formControl]="ContactForm.controls['email']" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                               <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required" style="font-size:15px;" >Enter a email Id.</div>
                                    <div  *ngIf="submitted && f.email.errors && f.email.errors.pattern">
                                        Email must be a valid email address.
                                    </div>  
                                </div>
                            </div>
                                <textarea
                                placeholder="Your Message" maxlength="5000" style="font-size:15px;"id="Message-2" name="Message-2"
                                data-name="Message 2" required=""
                                class="form-input-text textarea style1 w-input form-control" [formControl]="ContactForm.controls['text_area']" [ngClass]="{ 'is-invalid': submitted && f.text_area.errors }"></textarea>
                                <div *ngIf="submitted && f.text_area.errors" class="invalid-feedback">
                                        <div *ngIf="f.text_area.errors.required"  style="font-size:15px;">Enter a message.</div>
                                    </div>
                                <input type="submit" [disabled]="loading"
                                value="Submit" data-wait="Please wait..." style="font-weight:900;" class="button-primary margin-top w-button btn ">
                            </form>
                    </div>
            </div>
        </div>
        
    </div>
</div>
<app-footer ></app-footer>
