<div class="news_letter_wrapper shaa jb_cover" data-aos="fade-up">
    <div class="sha1 bubble-180">
        <img src="/assets/sheImages/bubble2.png" class="img-responsive " alt="img">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="job_newsletter_wrapper footer_hr">
                    <hr class="style-three">
                </div>
            </div>
        </div>
    </div>
    <div class="sha2 bubble-185">
        <img src="/assets/sheImages/bubble2.png " class="img-responsive " alt="img">
    </div>
</div>
<div class="footer index2_footer_wrapper footer_index3 shaa jb_cover">
    <div class="ft_shape bubble-18">
        <img src="/assets/sheImages/bubble2.png" class="img-responsive " alt="img">
    </div>
    <div class="containers">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="footerNav jb_cover">
                    <a routerLink="/homenew"> <img src="assets/sheImages/logo8.svg" alt="logo"
                            style="width:70%;height:auto;"></a>
                    <ul class="footer_first_contact">
                        <li><i class="flaticon-location-pointer"></i>
                            <p>#1192, 2nd Sector, HSR Layout
                                Bangalore, 560102 India.
                            </p>
                        </li>
                        <li><i class="flaticon-telephone"></i>
                            <p>+91-78928 19209
                                <br> +91-99029 92085</p>
                        </li>
                        <li><i class="flaticon-envelope"></i>
                            <a href="mailto:wehearyou@shenzyn.com?Subject=Hello Shenzyn" target="_blank"
                                style="text-decoration: underline;margin-top: 7px;color:#7472ea;">
                                wehearyou@shenzyn.com</a>
                        </li>
                    </ul>
                    <ul class="icon_list_news index2_icon_list jb_cover">
                        <li><a href="https://www.facebook.com/Shenzyn-108397103967073/" target="_blank"><i
                                    class="fab fa-facebook-f"></i></a></li>
                        <li>
                            <a href="https://twitter.com/Shenzyn_EW" target="_blank"><i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li><a href=" https://www.linkedin.com/company/28714426/admin/" target="_blank"><i
                                    class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCJ3hSpePirYqXCAJo75OIhQ" target="_blank"><i
                                    class="fab fa-youtube"></i></a></li>
                        <li> <a href="https://www.instagram.com/we_shenzyn/" target="_blank">
                                <i class="fab fa-instagram" style="font-weight:900;"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="footerNav jb_cover footer_border_displ">
                    <h5>Information</h5>
                    <ul class="nav-widget">
                        <li><a routerLink="/aboutus"><i class="fa fa-square"></i><span style="padding-left:5px;">About
                                    Us</span></a></li>
                        <li><a routerLink="/contact" href="javascript:"><i class="fa fa-square"></i> Contact Us</a></li>
                        <!-- <li><a routerLink="/partners-signup"><i class="fa fa-square"></i> Partners</a></li> -->
                        <!-- <li><a href={{partnersUrlLink}}><i class="fa fa-square"></i> Partners</a></li> -->
                        <li><a routerLink="/webinar"><i class="fa fa-square"></i> Webinars</a></li>
                        <li><a href="https://blog.shenzyn.com//" target="_blank"><i class="fa fa-square"></i> Blogs</a>
                        </li>
                        <li><a routerLink="/termsandconditions"><i class="fa fa-square"></i> Terms & Conditions </a>
                        </li>
                        <li><a routerLink="/privacypolicy"><i class="fa fa-square"></i> Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="footerNav jb_cover footer_border_displ">
                    <h5>Job Seekers</h5>
                    <ul class="nav-widget">
                        <!-- <li>
                            <label for="file-upload" class="custom-file-upload" style="color: #9d51ff;">
                                <a> <i class="fa fa-square"></i> Upload Resume
                                </a>
                            </label>
                            <input id="file-upload" class="d-none" (change)="fileChangeEvent($event)" type="file" />
                        </li> -->
                        <li><a [routerLink]="['/search-view','','']"><i class="fa fa-square"></i> Search Jobs </a></li>
                        <li><a href={{jobsUrlLink}}><i class="fa fa-square"></i> Login</a></li>
                        <li><a href="javascript:void(0);" (click)="open2(content)"><i class="fa fa-square"></i> Create
                                Job Alert</a></li>
                        <li><a routerLink="/career"><i class="fa fa-square"></i> Career with Shenzyn</a></li>
                    </ul>
                    <h5>Newsletter <span><img src="/assets/sheImages/blogs/msg.svg"
                                class="attachment-img200 wp-post-image" alt="Recruitment Process thumbnail" width="30px"
                                height="30px"> </span></h5>
                    <p class="new_letter" style="border: 1px solid #87caf5 !important; ">For the latest news,blogs and
                        product updates,Subscribe to our newsletter</p>
                    <ul class="nav-widget">
                        <section class="home-newsletter">
                            <form [formGroup]="subForm" novalidate (submit)="subscribe(subForm.value)">
                                <div class="single email-sub">
                                    <div class="input-group subscr">
                                        <input type="email" [(ngModel)]="emailId" name="emailId"
                                            [formControl]="subForm.controls['emailId']"
                                            [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }"
                                            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control"
                                            placeholder="Enter your email here..">
                                        <span class="input-group-btn">
                                            <button class="btn btn-theme" type="submit">Subscribe</button>
                                        </span>
                                        <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback"
                                            style="padding-right:295px;">
                                            <div *ngIf="f.emailId.errors.required" class="enter-email">Enter a email Id.
                                            </div>
                                            <div *ngIf="submitted && f.emailId.errors && f.emailId.errors.pattern"
                                                class="email-valid">
                                                Email must be a valid email address.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </section>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="footerNav jb_cover footer_border_displ">
                    <h5>employer</h5>
                    <ul class="nav-widget">
                        <li><a href="https://employer.shenzyn.com/#/user/sign-up"><i class="fas fa-square"></i><span
                                    style="padding-left:5px;">Post a job</span></a></li>
                        <!-- <li><a [routerLink]="['/employe-signup']"><i class="fas fa-square"></i><span
                                    style="padding-left:5px;">Post a job</span></a></li> -->
                        <li><a [routerLink]="['/emp-search','','']"><i class="fas fa-square"></i> Search resume</a></li>
                    </ul>
                    <h5>Shenzyn on Mobile</h5>
                    <h6><span class="blink blinker-two">Coming soon..</span></h6>
                    <ul class="nav-widget">
                        <li>
                            <a href="javascript:void(0);"><img src="/assets/sheImages/home_pic/googleapi.png"
                                    style="width:45%;" alt="img">
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);"><img src="/assets/sheImages/home_pic/apple.png"
                                    style="width:42%;" alt="img">
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="copyright_left job-dash"><i class="fa fa-copyright"></i> 2019 <a href="javascript:void(0);">
                    4GEN
                    Technologies Pvt. Ltd </a> All Rights Reserved.
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
    <div class="waveWrapper waveAnimation " style="bottom:-40px;">
        <div class="waveWrapperInner bgTop gradient-color">
            <div class="wave waveTop wavetop_1 wavetop_3"></div>
        </div>
        <div class="waveWrapperInner bgMiddle">
            <div class="wave waveMiddle"></div>
        </div>
        <div class="waveWrapperInner bgBottom">
            <div class="wave waveBottom wavebottom_1 wavebottom_3"></div>
        </div>
    </div>
    <div class="ft_shape2 bubble-190">
        <img src="/assets/sheImages/bubble2.png" class="img-responsive " alt="img">
    </div>
    <div class="ft_shape1 bubble-19">
        <img src="/assets/sheImages/bubble2.png" class="img-responsive " alt="img">
    </div>
</div>
<!-- <div id="chat-circle" class="btn btn-raised circle_index3">
    <i class="fas fa-comment-alt"></i>
</div>
<div class="chat-box chat_box_3">
    <span class="chat-box-toggle"><i class="fas fa-times"></i></span>

    <iframe allow="microphone;" width="350" height="430"
        src="https://console.dialogflow.com/api-client/demo/embedded/2481d6cc-70a1-4ee9-b35b-0d64a96ab65b">
    </iframe>
</div> -->
<div class="col-md-6">
    <div class="card-body">
        <ng-template #content let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title"> Create Job Alert</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <input class="form-control form-control-lg" type="text" required=" " placeholder="Skills">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <input class="form-control form-control-lg" type="text" required=" " placeholder="Location">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <input class="form-control form-control-lg" type="text" required=" " placeholder="Salary">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <input class="form-control form-control-lg" type="text" required=" " placeholder="Email">
                        </div>
                    </div>
                    <div class="form-group text-center ">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <button class="btn btn-block btn-lg btn-info create-btn" (click)="d('Cross click')"
                                type="submit ">CREATE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>
    </div>
</div>