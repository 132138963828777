import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JobseekerService } from '../../services/jobseeker.operations';
import swal from 'sweetalert2';
var CampaignRegisterComponent = /** @class */ (function () {
    function CampaignRegisterComponent(fb, jobSeekerService, route, router) {
        this.fb = fb;
        this.jobSeekerService = jobSeekerService;
        this.route = route;
        this.router = router;
        this.fileToUpload = null;
        this.File = null;
        this.showUploadWait = false;
        this.RegistrationForm = fb.group({
            'contactNo': [null, Validators.required],
            'emailId': [null, Validators.required],
            'resumeUrl': [null, Validators.required],
            // 'jobRole': [null, Validators.required],
            'submittedBy': [null, Validators.required],
            'termsandconditions': [false, Validators.pattern('true')],
            'source': [,]
        });
    }
    Object.defineProperty(CampaignRegisterComponent.prototype, "f", {
        get: function () { return this.RegistrationForm.controls; },
        enumerable: true,
        configurable: true
    });
    CampaignRegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams
            .filter(function (params) { return params.source; })
            .subscribe(function (params) {
            console.log(params);
            _this.source = params.source;
        });
    };
    // form validation
    CampaignRegisterComponent.prototype.register = function (formdata) {
        var _this = this;
        this.submitted = true;
        if (this.RegistrationForm.invalid) {
            this.loading = false;
            console.warn('its inside tere');
            return;
        }
        //this.loading = true;
        this.jobSeekerService.campaignRegister(formdata).subscribe(function (data) {
            _this.userData = (data);
            if ((_this.userData.responseStatus === 200)) {
                console.log('200-Success');
                _this.loading = false;
                _this.erroMsg = '';
                _this.submitted = false;
                swal({
                    title: 'Success',
                    text: data.message + data.emailId,
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#535BE2',
                    cancelButtonColor: '#FF7E39',
                    confirmButtonText: 'OK',
                }).then(function (confirm) {
                    if (confirm) {
                        _this.router.navigate(['/homenew']);
                    }
                });
            }
        }, function (error) {
            if (error.responseStatus === 401) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 500) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
            else if (error.responseStatus === 400) {
                _this.loading = false;
                _this.erroMsg = error.message;
            }
        }, function () { return console.log(); });
    };
    CampaignRegisterComponent.prototype.onCheckboxChange = function (event) {
        this.jobRoles.push(event.target.value);
        console.log(this.jobRoles);
    };
    //   // upload resume
    CampaignRegisterComponent.prototype.fileChangeEvent = function (event) {
        var _this = this;
        this.uploadErr = "";
        var file = event.target.files[0];
        if (file.type.match(/pdf\/*/) || file.type.match(/msword\/*/) || file.type.match(/vnd.openxmlformats-officedocument.wordprocessingml.document\/*/)) {
            this.uploadErr = "";
            //this.resumeName = file.name
            this.showUploadWait = true;
            this.fileToUpload = file;
            this.jobSeekerService.resumeUpload(this.fileToUpload).subscribe(function (data) {
                _this.showUploadWait = false;
                _this.userData = (data);
                if ((_this.userData.responseStatus === 200)) {
                    _this.resumeStatus = "File uploaded successfully!";
                    setTimeout(function () {
                        _this.resumeStatus = "";
                    }, 2000);
                    if (data.data.phone && typeof (data.data.phone) === 'object' && data.data.phone.length > 0) {
                        _this.contactNo = data.data.phone[0].replace('+91', '').replace('-', '').replace(' ', '');
                    }
                    if (data.data.email && typeof (data.data.email) === 'object' && data.data.email.length > 0) {
                        _this.email = data.data.email[0];
                    }
                    //if (data.location && data.location.length > 0) {
                    _this.resumeName = data.location;
                    //}
                }
            }, function (error) {
                _this.resumeStatus = error.message;
            });
        }
        else {
            this.uploadErr = 'File should be either pdf / doc / docx format!';
            return false;
        }
    };
    return CampaignRegisterComponent;
}());
export { CampaignRegisterComponent };
