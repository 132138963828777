import { OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from "@angular/http";
import { AuthServices } from '../../services/auth.service';
var PartnersLoginComponent = /** @class */ (function () {
    function PartnersLoginComponent(ngZone, route, http, fb, authService, router) {
        this.ngZone = ngZone;
        this.route = route;
        this.http = http;
        this.fb = fb;
        this.authService = authService;
        this.router = router;
        this.submitted = false;
        this.disableLogin = false;
        this.isPartnerAdmin = "isPartnerAdmin";
        this.isPartner = "true";
        this.loginForm = fb.group({
            'emailId': [null, Validators.required],
            'password': [null, Validators.required],
        });
    }
    Object.defineProperty(PartnersLoginComponent.prototype, "f", {
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    PartnersLoginComponent.prototype.ngOnInit = function () {
        $(".toggle-password").click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            }
            else {
                input.attr("type", "password");
            }
        });
    };
    PartnersLoginComponent.prototype.login = function (formdata) {
        var _this = this;
        this.submitted = true;
        if ((formdata.emailId !== null) && (formdata.password !== null)) {
            this.loading = true;
            this.authService.Partnerlogin(formdata).subscribe(function (data) {
                console.log(data, ' its data');
                _this.userData = (data);
                if ((_this.userData.responseStatus === 200)) {
                    localStorage.setItem("authtoken", JSON.stringify(data.authtoken));
                    localStorage.setItem("emailId", JSON.stringify(data.emailId));
                    localStorage.setItem("name", JSON.stringify(data.name));
                    localStorage.setItem("companyName", JSON.stringify(data.companyName));
                    localStorage.setItem("isPartner", data.isPartner);
                    localStorage.setItem("contactNo", data.contactNo);
                    // localStorage.setItem(this.isPartnerAdmin,this.isPartner);
                    if (data.isPartner && data.isPartner == true) {
                        _this.router.navigate(['partners-admin']);
                    }
                    else {
                        localStorage.setItem("partnerEmail", JSON.stringify(data.partnerEmail));
                        _this.router.navigate(['partners-admin']);
                    }
                }
                else if (data.responseStatus === 201) {
                    _this.loading = false;
                    _this.erroMsg = data.message;
                }
            }, function (error) {
                if (error.responseStatus === 401) {
                    _this.loading = false;
                    _this.erroMsg = error.message;
                }
                else if (error.responseStatus === 500) {
                    _this.loading = false;
                    _this.erroMsg = error.message;
                }
                else if (error.responseStatus === 400) {
                    _this.loading = false;
                    _this.erroMsg = error.message;
                }
            }, function () { return console.log(); });
        }
    };
    return PartnersLoginComponent;
}());
export { PartnersLoginComponent };
