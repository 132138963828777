import { Component, OnInit,HostListener } from '@angular/core';
import { RegistrationModal,IMAGE_URL, RegistrationPhase1Modal, PostRegistrationPhase1Modal, RegistrationPhase2Modal, PostRegistrationPhase2Modal } from '../classes';
import { RecruiterService } from '../../services/recruiter.operations';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-emp-profile',
  templateUrl: './emp-profile.component.html',
  styleUrls: ['./emp-profile.component.css']
})

export class RegistrationComponent implements OnInit {
  registrationphase: any;
  registrationphase1status: any;
  registrationphase2status: any;
  phase1: any;
  phase2: any;
  companylogo:any;
  fileURL: any;
  userData: any;
  loading: boolean;
  erroMsg: string;
  logo:any;
  companyName:any;
  isRecruiter:boolean;
  loadAPI: Promise<any>;

  constructor(public apiService: RecruiterService,private router:Router,private RecruiterService: RecruiterService) {
 
  }
  ngOnInit() {
    this.RecruiterService.getBasicDetails().subscribe((response: any) => {
      this.logo = response.data.logo;
      this.companyName = response.data.companyName,
      this.phase1.companyname = JSON.parse(localStorage.getItem('companyName'));
    }, (error: any) => {
      console.log(error);
    })
    this.registrationphase = 1;
    this.registrationphase1status = 0;
    this.registrationphase2status = 0;
    this.companylogo;
    this.phase1 = {};
    this.phase2 = {};
    this.getBasicData();


    if(localStorage.getItem('isRecruiter'))
    {
      // console.log(' its inside local' , JSON.parse(localStorage.getItem('isRecruiter')));
      this.isRecruiter = JSON.parse(localStorage.getItem('isRecruiter')) == true ? false : true;
    }
  }

  checkFormPhase1Input() {
    let validationStatus = false; 
    if 
    (this.phase1.logofile !== undefined && this.phase1.logofile !== '') {
      this.phase1.logofileerror = false;
      validationStatus = true;
    } else
     {
      this.phase1.logofileerror = true;
      validationStatus = false;
    }

    if (this.phase1.website !== undefined && this.phase1.website !== '' && ((/^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/).test(this.phase1.website))) {
      this.phase1.websiteerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.websiteerror = true;
      validationStatus = false;
    }

    if (this.phase1.addressline1 !== undefined && this.phase1.addressline1 !== '') {
      this.phase1.addressline1error = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.addressline1error = true;
      validationStatus = false;
    }

    if (this.phase1.city !== undefined && this.phase1.city !== '') {
      this.phase1.cityerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.cityerror = true;
      validationStatus = false;
    }


    if (this.phase1.pincode !== undefined && this.phase1.pincode !== '' && this.phase1.pincode.length === 6 && ((/^\d+$/).test(this.phase1.pincode))) {
      this.phase1.pincodeerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.pincodeerror = true;
      validationStatus = false;
    }

    if (this.phase1.state !== undefined && this.phase1.state !== '') {
      this.phase1.stateerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.stateerror = true;
      validationStatus = false;
    }

    if (this.phase1.country !== undefined && this.phase1.country !== '') {
      this.phase1.countryerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.countryerror = true;
      validationStatus = false;
    }

    if (this.phase1.gstin !== undefined && this.phase1.gstin !== ''  && ((/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z]{1}/).test(this.phase1.gstin))  && this.phase1.gstin.length === 15 ) {
      this.phase1.gstinerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.gstinerror = true;
      validationStatus = false;
    }

    if (this.phase1.sachac !== undefined && this.phase1.sachac !== '') {
      this.phase1.sachacerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.sachacerror = true;
      validationStatus = false;
    }
    if (this.phase1.contact !== undefined && this.phase1.contact !== '' && this.phase1.contact.length === 10 && ((/^\d+$/).test(this.phase1.contact))) {
      this.phase1.contacterror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.contacterror = true;
      validationStatus = false;
    }
    if (this.phase1.companyname !== undefined && this.phase1.companyname !== '') {
      this.phase1.companynameerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.companynameerror = true;
      validationStatus = false;
    }
    if (this.phase1.companyindustry !== undefined && this.phase1.companyindustry !== '') {
      this.phase1.companyindustryerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.companyindustryerror = true;
      validationStatus = false;
    }

    if (this.phase1.location !== undefined && this.phase1.location !== '') {
      this.phase1.locationerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase1.locationerror = true;
      validationStatus = false;
    }
    return validationStatus;
  }

  onRegistrationPhase1Submit() {
    if (this.checkFormPhase1Input()) {
      
      const address = this.phase1.addressline1 + "#$" + this.phase1.city + "#$"+this.phase1.pincode+"#$"+this.phase1.state+"#$"+this.phase1.country;
      const postdata = new PostRegistrationPhase1Modal(this.phase1.companyname, address, this.phase1.website, this.phase1.location, this.phase1.gstin, this.phase1.sachac, this.fileURL,this.phase1.contact,this.phase1.companyindustry);
      this.apiService.updateBasicDetails(postdata).subscribe(res=>{
        this.companylogo = res.data.logo;
      }, (err: any)=>{
        console.log(err);
      })
     this.registrationphase = 2;
      this.getAdvanceData();
    } else {
      this.registrationphase = 1;
    }
  }

  onRegistrationPhase2Submit() {
    if (this.checkFormPhase2Input()) {
      const registrationphase2data=new PostRegistrationPhase2Modal(this.phase2.aboutus, this.phase2.culture, this.phase2.employeestrength, this.phase2.genderdiversity, this.phase2.additionalbenifits, this.phase2.benifits, this.phase2.otherBenefits);
      this.apiService.updateAdvanceDetails(registrationphase2data).subscribe(res=>{
        this.userData =res
        if ((this.userData.responseStatus === 200)) {
          console.log('200-Success');
          this.loading = false;
          this.erroMsg = '';
          Swal({
            title: 'Success',
            text: res.message,
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#535BE2',
            cancelButtonColor: '#FF7E39',
            confirmButtonText: 'OK',
          }).then((confirm) => {
                if (confirm) {
                  this.router.navigate(['/employe-dashboard']);
                }
              });
        }
      },(err:any)=>{
        console.log(err);
      })
    }
  }

  checkFormPhase2Input() {
    let validationStatus = false;
    if (this.phase2.aboutus !== undefined && this.phase2.aboutus !== '') {
      this.phase2.aboutuserror = false;
      validationStatus = true;
    } else {
      this.phase2.aboutuserror = true;
      validationStatus = false;
    }

    if (this.phase2.culture !== undefined && this.phase2.culture !== '') {
      this.phase2.cultureerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase2.cultureerror = true;
      validationStatus = false;
    }

    if (this.phase2.employeestrength !== undefined && this.phase2.employeestrength !== '' && ((/^\d+$/).test(this.phase2.employeestrength))) {
      this.phase2.employeestrengtherror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase2.employeestrengtherror = true;
      validationStatus = false;
    }
    if (this.phase2.genderdiversity !== undefined && this.phase2.genderdiversity !== '' ) {
      this.phase2.genderdiversityerror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase2.genderdiversityerror = true;
      validationStatus = false;
    }

    if (this.phase2.additionalbenifits !== undefined && this.phase2.additionalbenifits !== '') {
      this.phase2.additionalbenifitserror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase2.additionalbenifitserror = true;
      validationStatus = false;
    }

    if (this.phase2.benifits !== undefined && this.phase2.benifits.length > 0) {
      this.phase2.benifitserror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase2.benifitserror = true;
      validationStatus = false;
    }

    if (this.phase2.otherBenefits !== undefined && this.phase2.otherBenefits !== '') {
      this.phase2.otherBenefitserror = false;
      validationStatus = validationStatus ? true : false;
    } else {
      this.phase2.otherBenefitserror = true;
      validationStatus = false;
    }

    return validationStatus;
  }

  onHandlePhase1Form(e: any) {
    const { name, value } = e.target;
    switch (name) {
      case 'website':
        this.phase1 = { ...this.phase1, website: value, websiteerror: false };
        break;
      case 'addressline1':
        this.phase1 = { ...this.phase1, addressline1: value, addressline1error: false };
        break;
      case 'city':
        this.phase1 = { ...this.phase1, city: value, cityerror: false };
        break;
      case 'pincode':
        this.phase1 = { ...this.phase1, pincode: value, pincodeerror: false };
        break;
      case 'state':
        this.phase1 = { ...this.phase1, state: value, stateerror: false };
        break;
      case 'country':
        this.phase1 = { ...this.phase1, country: value, countryerror: false };
        break;
      case 'gstin':
        this.phase1 = { ...this.phase1, gstin: value, gstinerror: false };
        break;
      case 'sachac':
        this.phase1 = { ...this.phase1, sachac: value, sachacerror: false };
        break;
      case 'contact':
        this.phase1 = { ...this.phase1, contact: value, contacterror: false };
        break;
      case 'companyname':
        this.phase1 = { ...this.phase1, companyname: value, companynameerror:false}
        break;
      case 'companyindustry':
        this.phase1 = { ...this.phase1, companyindustry: value, companyindustryerror: false };
        break;
      case 'location':
        this.phase1 = { ...this.phase1, location: value, locationerror: false };
        break;
      case 'logofile':
        const file = (e.target.files as HTMLInputElement)[0];
        const imageForm = new FormData();
        imageForm.append('file', file);
        this.apiService.uploadFiletoS3(imageForm).subscribe(res=>{
          const data =res;
          this.fileURL = data.fileURL;
        },err=>{
          console.log(err);
        })
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
        break;
     }
  }

  onHandlePhase2Form(e: any) {
    const { name, value } = e.target;
    switch (name) {
      case 'aboutus':
        this.phase2 = { ...this.phase2, aboutus: value, aboutuserror: false };
        break;
      case 'culture':
        this.phase2 = { ...this.phase2, culture: value, cultureerror: false };
        break;
      case 'employeestrength':
        this.phase2 = { ...this.phase2, employeestrength: value, employeestrengtherror: false };
        break;
      case 'genderdiversity':
        this.phase2 = { ...this.phase2, genderdiversity: value, genderdiversityerror: false };
        break;
      case 'additionalbenifits':
        this.phase2 = { ...this.phase2, additionalbenifits: value, additionalbenifitserror: false };
        break;
      case 'otherBenefits':
        this.phase2 = { ...this.phase2, otherBenefits: value, otherBenefitserror: false };
        break;
      case 'benifits':
        if (this.phase2.benifits !== undefined) {
          let benifits: any = this.phase2.benifits;
          if (benifits.indexOf(value) !== -1) {
            benifits.splice(benifits.indexOf(value), 1);
          } else {
            benifits.push(value);
          }
          this.phase2.benifits = benifits;
        } else {
          this.phase2 = { ...this.phase2, benifits: [value], benifitserror: false };
        }
        break;
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.phase1 = { ...this.phase1, logofile: btoa(binaryString), logofileerror: false };
  }

  onBack() {
    window.scrollTo(0, 0);
    this.registrationphase = 1;
    this.getBasicData();
  }

  getBasicData(){
    this.apiService.getBasicDetails().subscribe((response: any) => {
      const { address, companyName, contactNo, gstin, industry, location, logo, sacorhac, website } = response.data;
      const addresssplit = address.split('#$');
      const addressline1 = (addresssplit[0] === undefined) ? "" : addresssplit[0];
      const city = (addresssplit[1] === undefined) ? "" : addresssplit[1];
      const pincode = (addresssplit[2] === undefined) ? "" : addresssplit[2];
      const state = (addresssplit[3] === undefined) ? "" : addresssplit[3];
      const country = (addresssplit[4] === undefined) ? "" : addresssplit[4];
      this.phase1 = new RegistrationPhase1Modal(website, false, addressline1, false, city, false, pincode, false, state, false, country, false, gstin, false, sacorhac, false, contactNo, false, companyName, false, industry, false, location, false, logo, false);
      this.companylogo = logo;
    }, (error: any) => {
    })
  }

  getAdvanceData(){
    this.apiService.getAdvanceDetails().subscribe((res:any)=>{
      const { aboutus, otherBenefits, womenCentricPolicies, additionalMaternityBenefits, coverPhoto, culture, employeeStrength, genderDiversity} = res.data;
      this.phase2 = new RegistrationPhase2Modal(aboutus, false, culture, false, employeeStrength, false, genderDiversity, false, additionalMaternityBenefits, false, womenCentricPolicies,false,otherBenefits,false,coverPhoto,false);
    },(err:any)=>{
      console.log(err);
    })
  }


  onLogout() {
    localStorage.removeItem('companyName')
    localStorage.removeItem('authtoken');
    localStorage.removeItem('emailId');
    this.router.navigate(['/employe-login']);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('header_data');
      element.style.background = "rgba(255,255,255,0.9)";
      element.style.position = "fixed";
      element.style.color=" rgba(0, 0, 0, 0.6)";
      element.style.marginTop = "-2px";
      element.style.transitionDuration = "0.6s";
      element.style.boxShadow = "0px 1px 10px rgba(0,0,0,0.4)";
    } else {
      let element = document.getElementById('header_data');
      element.style.background = "";
      element.style.position = "";
      element.style.marginTop = "";
      element.style.boxShadow = "";
      element.style.color="#fff";

    }
  }


}
