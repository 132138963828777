<a href="javascript:" id="return-to-top"><i class="fas fa-angle-double-up"></i></a>
        <div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover" id="header_data">
            <div class="cp_logo_wrapper index_2_logo">
                <a routerLink="/homenew">
                        <img src="assets/sheImages/logo8.svg"  alt="logo" style="width:110%;height:auto;">
                </a>
            </div>
        </div>
    <div class="company_details_wrapper jb_cover" >
            <!-- <img [src]="url"  style="height: 50%;"   > -->
    </div>
    <div class="row" id="image_file">
      </div>
    <div class="webstrot_tech_detail jb_cover" style="background:#f6f6f6;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="job_listing_left_fullwidth jb_cover" style="background-image: linear-gradient(#f3f5f9,#fff);">
                        <div class="row">
                            <div class="col-lg-8 col-md-7 col-sm-12 col-12" >
                                <div class="jp_job_post_side_img">
                                    <img src="../../assets/sheImages/company_single/logo1.png"alt="post_img" style="border-radius:6px;width:100px;height:100px;border:1px solid #e2e2e2">
                                </div>
                                <div class="jp_job_post_right_cont web_text" >
                                    <h5 style="color:#1f217e; padding: 2px;"><b>Webstrot Technology</b></h5>
                                    
                                    <h6 style="color:#1f217e; padding: 5px;">Software Development</h6>
                                    <!-- <h6 style="color:#1f217e;">{{currentCompany}} </h6> -->
                                    <h6 style="padding: 2px;"><i class="flaticon-location-pointer" style="color:gray;"></i>&nbsp;<span style="color:gray;">Bangalore</span></h6>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                <div class="jp_job_post_right_btn_wrapper web_single_btn mt-3">
                                        <div class="row">
                                                <div class="col-lg-4 col-md-7 col-sm-12 col-12 text-right">
                                                    <i class="fa fa-suitcase" aria-hidden="true" style="font-size:15px;color:#d80075"></i><br/>
                                                    <img src="../../assets/sheImages/company_single/s_icon.png"alt="post_img" style="width:20px;height:20px;">
                                                </div>
                                                <div class="col-lg-8 col-md-7 col-sm-12 col-12 text-left">
                                                    <div class="job_adds_right">
                                                            <h6 style="color:gray;font-size:15px;padding-top:5px;">1-50 Employees</h6> 
                                                            <h6 style="color:gray;font-size:15px;padding-top:5px;">4 Open Position</h6> 
                                                     </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
    <div class="popular_wrapper jb_cover" style="background:#f6f6f6;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="jp_register_section_main_wrapper jb_cover" style="background:#fff2f2"> 
                            <div class="jp_job_post_right_cont web_text"style="padding-bottom:20px;">
                                <h5 style="padding-top:20px;color:#1f217e"><b>About Us</b></h5>
                                <br/>
                                <ul>
                                    <li><P style="margin:0;color:#1f217e">The apparel field is a very competitive business. Every brand holds its unique identity and continues to grow with the changing times. While been associated with organizations, I have learnt to be flexible with the changes and constant with my approach.</P></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12 col-12 mt-5">
                        <div class="jb_listing_single_overview jb_cover">
                            <div class="jp_job_des jb_cover">
                                <h2 class="job_description_heading" style="color:#1f217e"><b>Benifits for Her</b></h2>
                                <div class="row">
                                    <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                        <div class="jb_newslwtteter_button">
                                            <div class="btn_hover slider_btn jobs_btn_3">
                                                <a href="#" style="color:#fff;">Transportation Facility</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                        <div class="jb_newslwtteter_button">
                                            <div class="btn_hover slider_btn jobs_btn_3">
                                                <a href="#" style="color:#fff;">Maternity Benefit</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                        <div class="jb_newslwtteter_button">
                                            <div class="btn_hover slider_btn jobs_btn_3">
                                                <a href="#" style="color:#fff;">Flexi Timing</a>
                                            </div>
                                        </div>
                                    </div>
                                </div><br/>
                                <div class="row">
                                    <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                        <div class="jb_newslwtteter_button">
                                            <div class="btn_hover slider_btn jobs_btn_3">
                                                <a href="#" style="color:#fff;">Work from home</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                        <div class="jb_newslwtteter_button">
                                            <div class="btn_hover slider_btn jobs_btn_3">
                                                <a href="#" style="color:#fff;">Food on Campus</a>
                                            </div>
                                        </div>
                                    </div>
                       
                                </div>
                            </div>
                        </div><br/>
                        <div class="jb_listing_single_overview jb_cover">
                            <div class="jp_job_des jb_cover" style="padding-left: 2px;">
                                <h2 class="job_description_heading" style="color:#1f217e;padding-left:20px;"><b>Open Positions</b></h2>
                                <div class="row">
                                        <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                                <div id="js-careerItem-20518" class="careers-grid__item js-careersGridItem" >
                                                        <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Market Intelligence Representative">
                                                            <span class="careers-card__title" title="Market Intelligence Representative">
                                                                Software Developer </span>
                                                            <h1 class="careers-card__location">2-3 years</h1>
                                                          <p class="careers-card__location">Bangalore, India</p>
                                                               <div class="careers-card__footer">
                                                                <span class="careers-card__label careers-card__label--marketing">Developer</span>
                                                                <span class="careers-card__icon"></span>
                                                            </div>
                                                        </a>
                                                    </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5 col-sm-12 col-12" >
                                                <div id="js-careerItem-20517" class="careers-grid__item js-careersGridItem">
                                                        <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Market Intelligence Representative- Russian Speaker">
                                                            <h3 class="careers-card__title" title="Market Intelligence Representative- Russian Speaker">
                                                                UI/UX Engineer  </h3>
                                                            <p class="careers-card__location">2-3 years</p>
                                                            <p class="careers-card__location">Bangalore, India</p>
                                                            <div class="careers-card__footer">
                                                                <span class="careers-card__label careers-card__label--marketing">Designer</span>
                                                                <span class="careers-card__icon"></span>
                                                            </div>
                                                        </a>
                                                    </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                                <div id="js-careerItem-20516" class="careers-grid__item js-careersGridItem">
                                                        <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Product Manager – Data Collection &amp; Privacy">
                                                            <h3 class="careers-card__title" title="Product Manager – Data Collection &amp; Privacy">
                                                               Testing Engineer </h3>
                                                            <p class="careers-card__location">3-5 years</p>
                                                            <p class="careers-card__location">Bangalore, India</p>
                                                            <div class="careers-card__footer">
                                                                <span class="careers-card__label careers-card__label--product">Tester</span>
                                                                <span class="careers-card__icon"></span>
                                                            </div>
                                                        </a>
                                                    </div>

                                        </div>
                                </div>
                                <div class="row">
                                        <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                                <div id="js-careerItem-20515" class="careers-grid__item js-careersGridItem">
                                                        <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Product Marketing Manager">
                                                            <h3 class="careers-card__title" title="Product Marketing Manager">
                                                                Blockchain Developer                          </h3>
                                                                <p class="careers-card__location">3-5 years</p>
                                                            <p class="careers-card__location">Hyderabad, India</p>
                                                            <div class="careers-card__footer">
                                                                <span class="careers-card__label careers-card__label--sales">Developer</span>
                                                                <span class="careers-card__icon"></span>
                                                            </div>
                                                        </a>
                                                    </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5 col-sm-12 col-12" >
                                                <div id="js-careerItem-20509" class="careers-grid__item js-careersGridItem" >
                                                        <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Content Manager, Marketing Solution">
                                                            <h3 class="careers-card__title" title="Content Manager, Marketing Solution">
                                                                Digital Marketing </h3>
                                                            <p class="careers-card__location">3-5 years</p>
                                                            <p class="careers-card__location">Kolkata, India</p>
                                                            <div class="careers-card__footer">
                                                                <span class="careers-card__label careers-card__label--marketing">Marketing</span>
                                                                <span class="careers-card__icon"></span>
                                                            </div>
                                                        </a>
                                                    </div>

                                        </div>
                                        <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                                                <div id="js-careerItem-20508" class="careers-grid__item js-careersGridItem" >
                                                        <a href="#" class="careers-card" target="_blank" rel="noopener" data-analytics-category="External link" data-analytics-label="Product Marketing Manager">
                                                            <h3 class="careers-card__title" title="Product Marketing Manager">
                                                                Graphics Designer                         </h3>
                                                            <p class="careers-card__location">3-5 years</p>
                                                            <p class="careers-card__location">Kolkata, India</p>
                                                            <div class="careers-card__footer">
                                                                <span class="careers-card__label careers-card__label--marketing">Designer</span>
                                                                <span class="careers-card__icon"></span>
                                                            </div>
                                                        </a>
                                                    </div>
                                        </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 mt-5">
                            <div class="blog_newsleeter jb_cover">
                                <h1>Request Call Back</h1>
                                <p style="color:#fff;">What do all consultants need? In short trust This is achieved with professional and the ability to communicate. and the ability to communicate.</p>
                                <div class="contect_form3 blog_letter">
        
                                    <input type="text" name="name" placeholder="how can we help ?">
                                </div>
                                <div class="contect_form3 blog_letter">
        
                                    <input type="text" name="name" placeholder="your name">
                                </div>
                                <div class="contect_form3 blog_letter">
        
                                    <input type="email" name="name" placeholder="your email">
                                </div>
                                <div class="header_btn search_btn submit_btn jb_cover">
        
                                    <a href="#" style="color:#fff">submit</a>
        
                                </div>
                            </div>
        
                        </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <app-footer ></app-footer>
    