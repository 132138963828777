<a href="javascript:" id="return-to-top"><i class="fas fa-angle-double-up"></i></a>
<!-- <app-header-employer></app-header-employer> -->
<div class="sh-header" id="header_data">
  <div class="sh-logo">
    <a routerLink="/employe-dashboard">
      <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
    </a>
  </div>
  <div class="sh-menutbtn">
    <div class="jb_profile_box">
      <div class="nice-select" tabindex="0">
        <span class="current">
          <img src="assets/sheImages/nav_menu.svg" class="nice-select userPic_header" alt="img" style="width: 62px;height: 60px;">

        </span>
        <ul class="list">
          <li *ngIf="isRecruiter"><a routerLink="/recruiterprofile"><i class="fas fa-user-edit"></i> View
              /Edit</a></li>
          <li *ngIf="isRecruiter"><a routerLink="/manage-recruiters"><i class="fas fa-user"></i> Manage
              Recruiters</a></li>
          <li><a routerLink="/schedule-events"><i class="fas fa-user"></i> Schedule Events </a></li>
          <li><a (click)="onLogout()"><i class="fas fa-sign-in-alt"></i> Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="grow_next_wrapper jb_cover sh-widget-padding">
  <div class="row">
    <div class="slider_small_shape44">
      <img src="assets/sheImages/jobseeker/c1.png" class="img-responsive " alt="img">
    </div>
    <div class="counter_jbbb2 jb_cover">
      <img src="assets/sheImages/jobseeker/c2.png" class="img-responsive" alt="img">
    </div>
  </div>
  <div class="container">
    <div class="row pt-4 pb-4">
      <div class="col-md-8 col-12 offset-md-2 ">
        <div class="card registration-card">
          <div class="card-body pl-md-5 pr-md-5" *ngIf="registrationphase===1">
            <div class="row mt-2">
              <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                <div class="jb_heading_wraper">
                  <h3 class="separator">Company Profile</h3>
                </div>
              </div>
            </div>
            <div class="form-row ">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Logo</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <img src="/assets/sheImages/recruiter/user_pic.png" style="width:60px;height:60px;"
                  (click)="logofile.click()">
                <input type="file" class="d-none" name="logofile" #logofile (input)="onHandlePhase1Form($event)"
                  accept="image/*">
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.logofileerror">
                <p class="text-danger font-14 mb-0">Please do upload a logo</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Company Name*</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" class="form-control" value={{phase1?.companyname}} name="companyname"
                  (input)="onHandlePhase1Form($event)" readonly />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.companynameerror">
                <p class="text-danger font-14 mb-0">Enter a company name</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Industry*</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" class="form-control" value={{phase1?.companyindustry}} name="companyindustry"
                  (input)="onHandlePhase1Form($event)" />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.companyindustryerror">
                <p class="text-danger font-14 mb-0">Enter a industry</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Website</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" name="website" class="form-control" value={{phase1?.website}}
                  (input)="onHandlePhase1Form($event)" />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.websiteerror">
                <p class="text-danger font-14 mb-0">Enter a proper website </p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Contact Number*</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" class="form-control" value={{phase1?.contact}} name="contact"
                  (input)="onHandlePhase1Form($event)" />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.contacterror">
                <p class="text-danger font-14 mb-0">Enter a valid contact number</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Address</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" class="form-control-plaintext" value={{phase1?.addressline1}} name="addressline1"
                  (input)="onHandlePhase1Form($event)" placeholder="Address Line1" />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.addressline1error">
                <p class="text-danger font-14 mb-0">Enter a address</p>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-4 d-flex align-items-center">
              </div>
              <div class="col-8 align-items-center">
                <div class="form-row">
                  <div class="col-md-5  col-6">
                    <input type="text" class="form-control-plaintext" value={{phase1?.city}} name="city"
                      (input)="onHandlePhase1Form($event)" placeholder="City" />
                    <div class="d-block" *ngIf="phase1?.cityerror">
                      <p class="text-danger font-14 mb-0">Enter a city</p>
                    </div>
                  </div>
                  <div class="col-md-5 col-6 offset-md-2">
                    <input type="text" class="form-control-plaintext" value={{phase1?.pincode}} name="pincode"
                      (input)="onHandlePhase1Form($event)" placeholder="Pincode" />
                    <div class="d-block" *ngIf="phase1?.pincodeerror">
                      <p class="text-danger font-14 mb-0">Enter a valid pincode</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-4 d-flex align-items-center">
              </div>
              <div class="col-8 align-items-center">
                <div class="form-row">
                  <div class="col-md-5  col-6">
                    <input type="text" class="form-control-plaintext" value={{phase1?.state}} name="state"
                      (input)="onHandlePhase1Form($event)" placeholder="State" />
                    <div class="d-block" *ngIf="phase1?.stateerror">
                      <p class="text-danger font-14 mb-0">Enter a state</p>
                    </div>
                  </div>
                  <div class="col-md-5 col-6 offset-md-2">
                    <input type="text" class="form-control-plaintext" value={{phase1?.country}} name="country"
                      (input)="onHandlePhase1Form($event)" placeholder="Country" />
                    <div class="d-block" *ngIf="phase1?.countryerror">
                      <p class="text-danger font-14 mb-0">Enter a country</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Location</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" class="form-control" value={{phase1?.location}} name="location"
                  (input)="onHandlePhase1Form($event)" />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.locationerror">
                <p class="text-danger font-14 mb-0">Enter a location</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">GSTIN*</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" class="form-control" value={{phase1?.gstin}} name="gstin"
                  (input)="onHandlePhase1Form($event)" />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.gstinerror">
                <p class="text-danger font-14 mb-0">Enter a valid GSTIN </p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-4 d-flex align-items-center">
                <label class="mb-0 font-weight-600">SAC/HSN</label>
              </div>
              <div class="col-8 d-flex align-items-center">
                <input type="text" class="form-control" value={{phase1?.sachac}} name="sachac"
                  (input)="onHandlePhase1Form($event)" />
              </div>
              <div class="col-8 offset-4" *ngIf="phase1?.sachacerror">
                <p class="text-danger font-14 mb-0">Enter SAC or HSN</p>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="col-12 text-right">
                <button type="submit" class="btn login-botton" (click)="onRegistrationPhase1Submit()">
                  Next
                </button>
              </div>
            </div>
          </div>
          <div class="card-body pl-md-5 pr-md-5" *ngIf="registrationphase===2">
            <div class="row-2">
              <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                <div class="jb_heading_wraper">
                  <h3 class="separator">About Company</h3>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 text-primary">
                <div class="companylogo w-100 d-flex">
                  <img [src]="companylogo" alt="logo" class="img-fluid" />
                  <div class="d-inline-block ml-md-3 ">
                    <p class="text-black font-16 mb-0 font-weight-600" style="color:#fff;padding-top:10px;">
                      <i class="fa fa-industry" aria-hidden="true" style="font-size:12px;"></i> {{phase1.companyname}}
                    </p>
                    <p class="text-black font-12 " style="color:#fff;"><i class="font-14 fa fa-map-marker "></i> <span
                        class="ml-1"><b>{{this.phase1.location}}</b></span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="col-md-5 col-12 d-flex align-items-center">
                <label class="mb-0 font-weight-600">About Us</label>
              </div>
              <div class="col-md-7 col-12 mt-md-0 mt-2 d-flex align-items-center">
                <textarea class="form-control" rows="2" name="aboutus"
                  (input)="onHandlePhase2Form($event)">{{phase2.aboutus}}</textarea>
              </div>
              <div class="col-md-7 offset-md-5 col-12" *ngIf="phase2?.aboutuserror">
                <p class="text-danger font-14 mb-0">Enter aboutus</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-md-5 col-12 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Culture</label>
              </div>
              <div class="col-md-7 col-12 mt-md-0 mt-2 d-flex align-items-center">
                <textarea class="form-control" rows="2" name="culture"
                  (input)="onHandlePhase2Form($event)">{{phase2.culture}}</textarea>
              </div>
              <div class="col-md-7 offset-md-5 col-12" *ngIf="phase2?.cultureerror">
                <p class="text-danger font-14 mb-0">Enter a culture</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-md-5 col-12 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Employee Strength</label>
              </div>
              <div class="col-md-7 col-12 mt-md-0 mt-2 d-flex align-items-center">
                <input type="text" class="form-control" name="employeestrength" value={{phase2.employeestrength}}
                  (input)="onHandlePhase2Form($event)" />
              </div>
              <div class="col-md-7 offset-md-5 col-12" *ngIf="phase2?.employeestrengtherror">
                <p class="text-danger font-14 mb-0">Enter a valid Employee Strength</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-md-5 col-12  d-flex align-items-center">
                <label class="mb-0 font-weight-600">Gender Diversity (%)</label>
              </div>
              <div class="col-md-7 col-12 mt-md-0 mt-2 d-flex align-items-center">
                <input type="text" class="form-control" name="genderdiversity" value={{phase2.genderdiversity}}
                  (input)="onHandlePhase2Form($event)" />
              </div>
              <div class="col-md-7 offset-md-5 col-12" *ngIf="phase2?.genderdiversityerror">
                <p class="text-danger font-14 mb-0">Enter a valid Gender Diversity</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-md-5 col-12 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Additional Maternity Benefits</label>
              </div>
              <div class="col-md-7 col-12 mt-md-0 mt-2 d-flex align-items-center">
                <input type="text" class="form-control" name="additionalbenifits" value={{phase2.additionalbenifits}}
                  (input)="onHandlePhase2Form($event)" />
              </div>
              <div class="col-md-7 offset-md-5 col-12" *ngIf="phase2?.additionalbenifitserror">
                <p class="text-danger font-14 mb-0">Enter a Additional Maternity Benefits</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-12 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Benefits For Her</label>
              </div>
            </div>
            <div class="form-row benifits-options">
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Transportation'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Transportation')!==-1))?'../../assets/Icons/car-coral.png':'../../assets/Icons/car-grey.png'"
                  alt="" />
                <p class="mb-0">Transportation</p>
              </div>
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Paternal Leaves'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Paternal Leaves')!==-1))?'../../assets/Icons/child-care-coral.png':'../../assets/Icons/child-care-grey.png'"
                  alt="" />
                <p class="mb-0">Paternal Leaves</p>
              </div>
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Child Care'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Child Care')!==-1))?'../../assets/Icons/childcare-coral.png':'../../assets/Icons/childcare-grey.png'"
                  alt="" />
                <p class="mb-0">Child Care</p>
              </div>
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Food On Campous'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Food On Campous')!==-1))?'../../assets/Icons/food-coral.png':'../../assets/Icons/food-grey.png'"
                  alt="" />
                <p class="mb-0">Food On Campous</p>
              </div>
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Fitness Initiatives'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Fitness Initiatives')!==-1))?'../../assets/Icons/gym-coral.png':'../../assets/Icons/gym-grey.png'"
                  alt="" />
                <p class="mb-0">Fitness Initiatives</p>
              </div>
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Social Activities'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Social Activities')!==-1))?'../../assets/Icons/social-coral.png':'../../assets/Icons/social-grey.png'"
                  alt="" />
                <p class="mb-0">Social Activities</p>
              </div>
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Flex Timings'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Flex Timings')!==-1))?'../../assets/Icons/time-coral.png':'../../assets/Icons/time-grey.png'"
                  alt="" />
                <p class="mb-0">Flex Timings</p>
              </div>
              <div class="col-md-3 mt-4 text-center cursor-pointer"
                (click)="onHandlePhase2Form({target:{name:'benifits',value:'Work From Home'}})">
                <img class="benifits"
                  [src]="(phase2?.benifits && (phase2.benifits.indexOf('Work From Home')!==-1))?'../../assets/Icons/work-coral.png':'../../assets/Icons/work-grey.png'"
                  alt="" />
                <p class="mb-0">Work From Home</p>
              </div>
              <div class="col-12" *ngIf="phase2?.benifitserror">
                <p class="text-danger text-center mt-4 font-14 mb-0">Select a benifit</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-md-5 col-12 d-flex align-items-center">
                <label class="mb-0 font-weight-600">Other Benefits</label>
              </div>
              <div class="col-md-7 col-12 mt-md-0 mt-2 d-flex align-items-center">
                <input type="text" class="form-control" value={{phase2.otherBenefits}} name="otherBenefits"
                  (input)="onHandlePhase2Form($event)" />
              </div>
              <div class="col-md-7 offset-md-5 col-12" *ngIf="phase2?.otherBenefitserror">
                <p class="text-danger font-14 mb-0">Enter a Other Benefits</p>
              </div>
            </div>
            <div class="form-row mt-2rem">
              <div class="col-sm-8"></div>
              <div class="col-sm-2"><button type="button" name="backward" value="Change" class="backward"
                  (click)="onBack()">Prev</button> &nbsp;&nbsp;</div>
              <div class="col-sm-2">
                <button type="submit" class="btn login-botton" (click)="onRegistrationPhase2Submit()">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-minimalistic-footer></app-minimalistic-footer>