import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import swal from 'sweetalert2';
var PartnersForgetComponent = /** @class */ (function () {
    function PartnersForgetComponent(fb, authService, router) {
        this.fb = fb;
        this.authService = authService;
        this.router = router;
        this.submitted = false;
        this.disableLogin = false;
        this.forgotForm = fb.group({
            'emailId': [null, Validators.required],
        });
    }
    Object.defineProperty(PartnersForgetComponent.prototype, "f", {
        get: function () { return this.forgotForm.controls; },
        enumerable: true,
        configurable: true
    });
    PartnersForgetComponent.prototype.ngOnInit = function () {
    };
    PartnersForgetComponent.prototype.forgot = function (formdata) {
        var _this = this;
        this.submitted = true;
        if (this.forgotForm.value.emailId == '') {
            return false;
        }
        if ((formdata.emailId !== null)) {
            this.loading = true;
            this.authService.Partnerforgot(formdata).subscribe(function (data) {
                _this.userData = (data);
                if ((_this.userData.responseStatus === 201)) {
                    _this.loading = false;
                    _this.erroMsg = '';
                    _this.submitted = false;
                    swal({
                        title: 'Success',
                        text: data.message,
                        type: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#535BE2',
                        cancelButtonColor: '#FF7E39',
                        confirmButtonText: 'OK',
                    }).then(function (confirm) {
                        if (confirm) {
                            _this.router.navigate(['/login']);
                        }
                    });
                }
            }, function (error) {
                if (error.responseStatus === 401) {
                    _this.loading = false;
                    _this.erroMsg = error.message;
                }
                else if (error.responseStatus === 500) {
                    _this.loading = false;
                    _this.erroMsg = error.message;
                }
                else if (error.responseStatus === 400) {
                    _this.loading = false;
                    _this.erroMsg = error.message;
                }
            }, function () { return console.log(); });
        }
    };
    return PartnersForgetComponent;
}());
export { PartnersForgetComponent };
