import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'partners-dashboard',
  templateUrl: './partners-dashboard.component.html',
  styleUrls: ['./partners-dashboard.component.css']
})
export class PartnersDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
