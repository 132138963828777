<a href="javascript:" id="return-to-top" class="return_top3"><i class="fas fa-angle-double-up"></i></a>
<div class="cp_navi_main_wrapper index_2_top_header index_3_top_header jb_cover">
    <div class="cp_logo_wrapper index_2_logo ">
        <a routerLink="/homenew">
            <img src="assets/sheImages/logo8.svg" alt="logo" style="width:110%;height:auto;">
        </a>
    </div>
    <div class="jb_navigation_wrapper index_2_right_menu index_3_right_menu">
        <div class="row" style="float:left;width:75%;height:auto;margin-top:10px;">
            <div class="container">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <form class="navbar-form" role="search">
                        <div class="input-group add-on">
                            <input type="text" class="form-control" #search [(ngModel)]="value"
                                placeholder="Search by title, skills or keyword" name="name" id="srch-term1">
                            <input type="text" class="form-control" [(ngModel)]="location" placeholder="Location"
                                name="location" id="srch-term2">
                            <div class="input-group-btn">
                                <button class="btn btn-default" type="submit" (click)="goToSearch()"><i
                                        class="fas fa-search"></i></button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
        <div class="posting_job employer_gap" style="float:right;">
            <ul>
                <li class="btn_hover">
                    <!-- <a routerLink="/employe-login"><b>employer</b></a> -->
                    <a href="https://employer.shenzyn.com/#/user/login"><b>employer</b></a>
                </li>
            </ul>
        </div>
        <div class="mainmenu green_main_menu blue_main_menu d-xl-block d-lg-block d-md-none d-sm-none d-none">
            <ul class="main_nav_ul menu_2_ul" style="margin:0px !important;">
                <!-- <li class="has-mega gc_main_navigation"><a routerLink="/login"
                        class="gc_main_navigation active_class active_class2 active_class3">Login</a>
                </li> -->
                <li class="has-mega gc_main_navigation"><a href="https://jobs.shenzyn.com/#/user/login"
                        class="gc_main_navigation active_class active_class2 active_class3">Login</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="best_jobs_wrapper index3_best_job_wrapper  jb_cover"
    style="background-image: linear-gradient(#f3f5f9,#fff);">
    <div class="grow_next_wrapper jb_cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="index3_form_box jb_cover">
                        <div class="select_box3">
                            <select name="Company type" id="filter_dropdown" aria-placeholder="Company type"
                                (change)="filterCompany($event.target.value);finalFilter()">
                                <option value="" selected data-default>Company type</option>
                                <option value="Startup">Start Up</option>
                                <option value="MidSize">Mid size</option>
                                <option value="Large">Large</option>
                                <option value="any"> Any </option>
                            </select>
                        </div>
                        <div class="select_box3">
                            <select name="Freshness" id="filter_dropdown1" aria-placeholder="Freshness"
                                (change)="filterFreshness($event.target.value);finalFilter()">
                                <option value="" selected data-default>Freshness</option>
                                <option value="1"> 24 hours</option>
                                <option value="2"> Week</option>
                                <option value="3"> Month</option>
                                <option value="any"> Any </option>
                            </select>
                        </div>
                        <div class="select_box3">
                            <select name="Salary (L/A)" id="filter_dropdown2" aria-placeholder="Salary (L/A)"
                                (change)="filterSalary($event.target.value);finalFilter()">

                                <option value="" selected data-default>Salary (L/A)</option>
                                <option value="100000">Above 1 </option>
                                <option value="200000">2 </option>
                                <option value="400000">4 </option>
                                <option value="600000">6 </option>
                                <option value="800000">8 </option>
                                <option value="1000000">10 + </option>
                                <option value="2000000">20 + </option>
                                <option value="any">Any </option>
                            </select>
                        </div>
                        <div class="select_box3">
                            <select name="Experience Level" id="filter_dropdown3" aria-placeholder="Experience Level"
                                (change)="filterExperience($event.target.value);finalFilter()">
                                <option value="" selected data-default>Experience Level</option>
                                <option value="0">Fresher</option>
                                <option value="1-3">1-3 Years</option>
                                <option value="3-5">More than 3 Years</option>
                                <option value="5-7">More than 5 years</option>
                                <option value="10-15">10 years and more</option>
                                <option value="any">Any</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><br>
    <div class="row">
        <div class="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
            <div class="jb_heading_wraper">
                <h3 class="separator">Recommended Jobs</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-4 col-md-12 col-sm-12 " style="height:600px;overflow-y:scroll;">
                        <div class="job_listing_left_fullwidth job_listing_grid_wrapper index2_listing_jobs index3_listing_jobs jb_cover"
                            *ngFor="let row of Finalvalue ">
                            <div class="row" (click)="myFunc(row.jobId, row.companyName)">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="jp_job_post_side_img">
                                        <img src={{row.logo}} style="width:70%;padding:10px 0 10px 0;" alt="post_img" />
                                    </div>
                                    <div class="jp_job_post_right_cont">
                                        <h4>
                                            <p hidden> <input type="text" value="jobId" [(ngModel)]="row.jobId" />
                                                <input type="text" value="companyName" name="companyName"
                                                    [(ngModel)]="row.companyName"> {{row.jobId}} </p>
                                            <a style="color:#393939!important;">{{row.jobTitle}}</a>
                                        </h4>
                                        <ul>
                                            <li class="social_media" style="width:100%;font-size:15px !important;">
                                                <p><i class="fa fa-industry" aria-hidden="true"
                                                        style="color:#797979!important;font-size:11px !important;"></i>&nbsp;{{row.companyName}}
                                                </p>
                                            </li>
                                            <li style="width:100%;font-size:15px !important;">
                                                <p><i class="fas fa-suitcase"
                                                        style="color:#797979;font-size:11px;"></i>&nbsp;{{row.minexperience}}-{{row.maxexperience}}Yrs
                                                    | {{row.minannualCTC}}-{{row.maxannualCTC}}</p>
                                            </li>
                                            <li style="width:100%;font-size:15px !important;">
                                                <p><i class="fa fa-map-marker" aria-hidden="true"
                                                        style="color:#797979!important;font-size:11px !important;"></i>&nbsp;{{row.jobLocation}}
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <div
                            class="job_listing_left_fullwidth1 job_listing_grid_wrapper index2_listing_jobs index3_listing_jobs jb_cover mt-3">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="!loading">
                                    <div class="jp_job_des jb_cover">
                                        <video controls style="width: inherit;">
                                            <source src={{jdVideo}} type="video/mp4" width="inherit" alt="post_video"
                                                style="width: inherit;" />
                                        </video>
                                    </div>
                                    <div class="jp_job_des jb_cover">
                                        <h5 style="color:#393939!important;font-size:18px;font-weight:900;">{{jobTitle}}
                                        </h5>
                                    </div>
                                    <div class="jp_job_res jb_cover mt-1">
                                        <h2 class="job_description_heading"
                                            style="color:#393939!important;font-size:15px;font-weight:900;">Company :-
                                        </h2>
                                        <p><i class="fa fa-industry" aria-hidden="true"
                                                style="color:#797979!important;font-size:11px !important;"></i>&nbsp;{{companyName}}
                                        </p>
                                    </div>
                                    <div class="jp_job_res jb_cover mt-1">
                                        <h2 class="job_description_heading"
                                            style="color:#393939!important;font-size:15px;font-weight:900;">Job
                                            Highlights :-</h2>
                                        <span><i class="flaticon-statistics"
                                                style="color:#797979!important;font-size:11px !important;"></i>&nbsp;{{minexperience}}-{{maxexperience}}
                                            Yrs |
                                            <span><i class="fa fa-map-marker" aria-hidden="true"
                                                    style="color:#797979!important;font-size:11px !important;"></i>&nbsp;{{jobLocation}}</span>
                                            |
                                            <span style="font-size:13px;"></span>{{minannualCTC}} - <span
                                                style="font-size:13px;"></span>{{maxannualCTC}}</span>
                                    </div>
                                    <div class="jp_job_res jb_cover mt-1">
                                        <h2 class="job_description_heading"
                                            style="color:#393939!important;font-size:15px;font-weight:900;">Job
                                            Description :-</h2>
                                        <p [innerHTML]="jobDescription">
                                            <!-- {{jobDescription}}. -->
                                        </p>
                                    </div>
                                    <div class="jp_job_res jb_cover mt-1">
                                        <h2 class="job_description_heading"
                                            style="color:#393939!important;font-size:15px;font-weight:900;">Skills :-
                                        </h2>
                                        <ul>
                                            <li style="font-size:15px;"><i
                                                    class="fa fa-caret-right"></i>&nbsp;&nbsp;{{skills}} </li>

                                        </ul>
                                    </div>
                                    <div class="jp_job_res jb_cover">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="jp_job_post_right_btn_wrapper jb_cover ">
                                                <ul>
                                                    <!-- <li>
                                                        <div class="job_adds_right"> -->
                                                            <!-- <a routerLink="/user-signup" style="color:#d80075"> <i class="far fa-heart"></i> </a> -->
                                                            <!-- <a href="https://jobs.shenzyn.com/#/user/sign-up"
                                                                style="color:#d80075"> <i class="far fa-heart"></i> </a>

                                                        </div>
                                                    </li> -->
                                                    <!-- <li> <a routerLink="/user-signup" id="job-apply">Apply</a></li> -->
                                                    <!-- <li> <a href="https://jobs.shenzyn.com/#/user/sign-up"
                                                            id="job-apply">Apply</a></li>
                                                    -->
                                                    <li>  
                                                        <div data-animation="animated fadeInUp" class="btn_hover slider_btn upload-btn">
                                                            <label for="file-upload" class="custom-file-upload" style="color: #fff;">
                                                                <a style="margin: 2px;"> {{uploadbtntxt}}
                                                                </a> 
                                                                <!-- TODO add a wait indicator-->
                                                            </label>
                                                            <input id="file-upload" (change)="fileChangeEvent($event)" type="file" />
                                                        </div> 
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12">
                        <div
                            class="job_listing_left_fullwidth1 job_listing_grid_wrapper index2_listing_jobs index3_listing_jobs jb_cover mt-3">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="!loading">
                                    <div class="services_content jb_cover">
                                        <img src={{logo}} style="width:70%;" alt="post_img" />
                                        <h3><a> <i class="fa fa-industry" aria-hidden="true"
                                                    style="color:#797979!important;font-size:15px;"></i>
                                                {{companyName}}</a></h3>
                                        <span> <i class="fas fa-map-marker-alt" style="font-size:11px;"></i> {{loc}}
                                        </span><br />
                                        <span> <i class="fas fa-globe-asia" style="font-size:11px;"></i> {{website}}
                                        </span>
                                    </div>
                                    <div class="row">
                                        <p class="company-emp"> <i class="flaticon-man-user"></i> Employees
                                            {{employeeStrength}} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>