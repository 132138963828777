import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServices } from '../../services/auth.service';
import swal from 'sweetalert2';

@Component({
  selector: 'partners-forget',
  templateUrl: './partners-forget.component.html',
  styleUrls: ['./partners-forget.component.css']
})
export class PartnersForgetComponent implements OnInit {
  forgotForm: FormGroup;
  userData: any;
  submitted = false;
  erroMsg: string;
  loading: boolean;
  disableLogin: boolean = false;
  constructor(private fb: FormBuilder, private authService: AuthServices, private router: Router) {
    this.forgotForm = fb.group({
      'emailId': [null, Validators.required],
    });
  }
  get f() { return this.forgotForm.controls; }

  ngOnInit() {
  }

  forgot(formdata: any) {
    this.submitted = true;
    if (this.forgotForm.value.emailId == '') {
      return false;
    }
    if ((formdata.emailId !== null)) {
      this.loading = true;
      this.authService.Partnerforgot(formdata).subscribe(
        data => {
          this.userData = (data);
          if ((this.userData.responseStatus === 201)) {
            this.loading = false;
            this.erroMsg = '';
            this.submitted = false;
            swal({
              title: 'Success',
              text: data.message,
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#535BE2',
              cancelButtonColor: '#FF7E39',
              confirmButtonText: 'OK',
            }).then((confirm) => {
              if (confirm) {
                this.router.navigate(['/login']);
              }
            });
          }
        },
        error => {
          if (error.responseStatus === 401) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 500) {
            this.loading = false;
            this.erroMsg = error.message;
          } else if (error.responseStatus === 400) {
            this.loading = false;
            this.erroMsg = error.message;
          }
        },
        () => console.log()
      );
    }
  }

}
