/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./partners-dashboard.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./partners-dashboard.component";
var styles_PartnersDashboardComponent = [i0.styles];
var RenderType_PartnersDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PartnersDashboardComponent, data: {} });
export { RenderType_PartnersDashboardComponent as RenderType_PartnersDashboardComponent };
export function View_PartnersDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" partners-dashboard works!\n"]))], null, null); }
export function View_PartnersDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "partners-dashboard", [], null, null, null, View_PartnersDashboardComponent_0, RenderType_PartnersDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i2.PartnersDashboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartnersDashboardComponentNgFactory = i1.ɵccf("partners-dashboard", i2.PartnersDashboardComponent, View_PartnersDashboardComponent_Host_0, {}, {}, []);
export { PartnersDashboardComponentNgFactory as PartnersDashboardComponentNgFactory };
